import React from 'react';
import  './bar.css'
import Avatar from '@material-ui/core/Avatar';
export default function Bar({ data, totalTests }) {
  return (
    <>
      <Avatar style={{
        alignSelf: "center"
      }}>{totalTests}</Avatar>
    <div className="BarChart">
      {data &&
      data.map((d, i) => {
        return (
            <div key={i} className="BarData" style={{ background: `${d.color}`, width: `${d.percent}%` }}>
              {d.percent > 4 && <p className="PercentText">{d.percent + '%'}</p>}
            </div>
        );
      })}
    </div>
    </>
  );
};
