export const TEST_RESULTS_FETCH_SUCCEEDED = 'TEST_RESULTS_FETCH_SUCCEEDED';
export const TEST_RESULTS_FETCH_FAILED = 'TEST_RESULTS_FETCH_FAILED';
export const TEST_RESULTS_FETCH_REQUESTED = 'TEST_RESULTS_FETCH_REQUESTED';

export const JIRA_DETAIL_FETCH_SUCCEEDED = 'JIRA_DETAIL_FETCH_SUCCEEDED';
export const JIRA_DETAIL_FETCH_FAILED = 'JIRA_DETAIL_FETCH_FAILED';
export const JIRA_DETAIL_FETCH_REQUESTED = 'JIRA_DETAIL_FETCH_REQUESTED';

export const HEIMDALL_SETTING_GET_ALL_SUCCEEDED = 'HEIMDALL_SETTING_GET_ALL_SUCCEEDED';
export const HEIMDALL_SETTING_GET_ALL_FAILED = 'HEIMDALL_SETTING_GET_ALL_FAILED';
export const HEIMDALL_SETTING_GET_ALL_REQUESTED = 'HEIMDALL_SETTING_GET_ALL_REQUESTED';

export const HEIMDALL_SETTING_CREATE_SUCCEEDED = 'HEIMDALL_SETTING_CREATE_SUCCEEDED';
export const HEIMDALL_SETTING_CREATE_FAILED = 'HEIMDALL_SETTING_CREATE_FAILED';
export const HEIMDALL_SETTING_CREATE_REQUESTED = 'HEIMDALL_SETTING_CREATE_REQUESTED';

export const HEIMDALL_SETTING_UPDATE_SUCCEEDED = 'HEIMDALL_SETTING_UPDATE_SUCCEEDED';
export const HEIMDALL_SETTING_UPDATE_FAILED = 'HEIMDALL_SETTING_UPDATE_FAILED';
export const HEIMDALL_SETTING_UPDATE_REQUESTED = 'HEIMDALL_SETTING_UPDATE_REQUESTED';

export const HEIMDALL_SETTING_DELETE_SUCCEEDED = 'HEIMDALL_SETTING_DELETE_SUCCEEDED';
export const HEIMDALL_SETTING_DELETE_FAILED = 'HEIMDALL_SETTING_DELETE_FAILED';
export const HEIMDALL_SETTING_DELETE_REQUESTED = 'HEIMDALL_SETTING_DELETE_REQUESTED';

export const TRACEABILITY_MATRIX_FETCH_SUCCEEDED = 'TRACEABILITY_MATRIX_FETCH_SUCCEEDED';
export const TRACEABILITY_MATRIX_FETCH_FAILED = 'TRACEABILITY_MATRIX_FETCH_FAILED';
export const TRACEABILITY_MATRIX_FETCH_REQUESTED = 'TRACEABILITY_MATRIX_FETCH_REQUESTED';

export const TEST_RESULT_HISTORY_FETCH_SUCCEEDED = 'TEST_RESULT_HISTORY_FETCH_SUCCEEDED';
export const TEST_RESULT_HISTORY_FETCH_FAILED = 'TEST_RESULT_HISTORY_FETCH_FAILED';
export const TEST_RESULT_HISTORY_FETCH_REQUESTED = 'TEST_RESULT_HISTORY_FETCH_REQUESTED';

export const TRACEABILITY_RMS_UPDATE_SUCCEEDED = 'TRACEABILITY_RMS_UPDATE_SUCCEEDED';
export const TRACEABILITY_RMS_UPDATE_FAILED = 'TRACEABILITY_RMS_UPDATE_FAILED';
export const TRACEABILITY_RMS_UPDATE_REQUESTED = 'TRACEABILITY_RMS_UPDATE_REQUESTED';

export const PRINT_FETCH_SUCCEEDED = 'PRINT_FETCH_SUCCEEDED';
export const PRINT_FETCH_FAILED = 'PRINT_FETCH_FAILED';
export const PRINT_FETCH_REQUESTED = 'PRINT_FETCH_REQUESTED';

export const API_TEST_RESULTS_FETCH_SUCCEEDED = 'API_TEST_RESULTS_FETCH_SUCCEEDED';
export const API_TEST_RESULTS_FETCH_FAILED = 'API_TEST_RESULTS_FETCH_FAILED';
export const API_TEST_RESULTS_FETCH_REQUESTED = 'API_TEST_RESULTS_FETCH_REQUESTED';

export const API_REQUEST_LOG_FETCH_SUCCEEDED = 'API_REQUEST_LOG_FETCH_SUCCEEDED';
export const API_REQUEST_LOG_FETCH_FAILED = 'API_REQUEST_LOG_FETCH_FAILED';
export const API_REQUEST_LOG_FETCH_REQUESTED = 'API_REQUEST_LOG_FETCH_REQUESTED';

export const TEST_RESULTS_DIALOG_CLOSE = 'TEST_RESULTS_DIALOG_CLOSE';
export const TEST_RESULTS_DIALOG_OPEN = 'TEST_RESULTS_DIALOG_OPEN';

export const API_TEST_RESULTS_DIALOG_CLOSE = 'API_TEST_RESULTS_DIALOG_CLOSE';
export const API_TEST_RESULTS_DIALOG_OPEN = 'API_TEST_RESULTS_DIALOG_OPEN';

export const TEST_RESULTS_STEPS_FETCH_SUCCEEDED = 'TEST_RESULTS_STEPS_FETCH_SUCCEEDED';
export const TEST_RESULTS_STEPS_FETCH_FAILED = 'TEST_RESULTS_STEPS_FETCH_FAILED';
export const TEST_RESULTS_STEPS_FETCH_REQUESTED = 'TEST_RESULTS_STEPS_FETCH_REQUESTED';

export const RESULTS_DETAIL_DIALOG_OPEN = 'RESULTS_DETAIL_DIALOG_OPEN';
export const RESULTS_DETAIL_DIALOG_CLOSE = 'RESULTS_DETAIL_DIALOG_CLOSE';

export const TEST_RUNS_FETCH_SUCCEEDED = 'TEST_RUNS_FETCH_SUCCEEDED';
export const TEST_RUNS_FETCH_FAILED = 'TEST_RUNS_FETCH_FAILED';
export const TEST_RUNS_FETCH_REQUESTED = 'TEST_RUNS_FETCH_REQUESTED';

export const LOAD_TEST_RUNS_FETCH_SUCCEEDED = 'LOAD_TEST_RUNS_FETCH_SUCCEEDED';
export const LOAD_TEST_RUNS_FETCH_FAILED = 'LOAD_TEST_RUNS_FETCH_FAILED';
export const LOAD_TEST_RUNS_FETCH_REQUESTED = 'LOAD_TEST_RUNS_FETCH_REQUESTED';

export const API_TEST_RUNS_FETCH_SUCCEEDED = 'API_TEST_RUNS_FETCH_SUCCEEDED';
export const API_TEST_RUNS_FETCH_FAILED = 'API_TEST_RUNS_FETCH_FAILED';
export const API_TEST_RUNS_FETCH_REQUESTED = 'API_TEST_RUNS_FETCH_REQUESTED';

export const BUILDS_FETCH_REQUESTED = 'BUILDS_FETCH_REQUESTED';
export const BUILDS_FETCH_SUCCEEDED = 'BUILDS_FETCH_SUCCEEDED';
export const BUILDS_FETCH_FAILED = 'BUILDS_FETCH_FAILED';

export const BUILDS_CHINA_FETCH_REQUESTED = 'BUILDS_CHINA_FETCH_REQUESTED';
export const BUILDS_CHINA_FETCH_SUCCEEDED = 'BUILDS_CHINA_FETCH_SUCCEEDED';
export const BUILDS_CHINA_FETCH_FAILED = 'BUILDS_CHINA_FETCH_FAILED';

export const API_BUILDS_FETCH_REQUESTED = 'API_BUILDS_FETCH_REQUESTED';
export const API_BUILDS_FETCH_SUCCEEDED = 'API_BUILDS_FETCH_SUCCEEDED';
export const API_BUILDS_FETCH_FAILED = 'API_BUILDS_FETCH_FAILED';

export const LOAD_BUILDS_FETCH_REQUESTED = 'LOAD_BUILDS_FETCH_REQUESTED';
export const LOAD_BUILDS_FETCH_SUCCEEDED = 'LOAD_BUILDS_FETCH_SUCCEEDED';
export const LOAD_BUILDS_FETCH_FAILED = 'LOAD_BUILDS_FETCH_FAILED';

export const BUILDS_CREATE_REQUESTED = 'BUILDS_CREATE_REQUESTED';
export const BUILDS_CREATE_SUCCEEDED = 'BUILDS_CREATE_SUCCEEDED';
export const BUILDS_CREATE_FAILED = 'BUILDS_CREATE_FAILED';

export const BUILDS_CHINA_CREATE_REQUESTED = 'BUILDS_CHINA_CREATE_REQUESTED';
export const BUILDS_CHINA_CREATE_SUCCEEDED = 'BUILDS_CHINA_CREATE_SUCCEEDED';
export const BUILDS_CHINA_CREATE_FAILED = 'BUILDS_CHINA_CREATE_FAILED';

export const API_BUILDS_CREATE_REQUESTED = 'API_BUILDS_CREATE_REQUESTED';
export const API_BUILDS_CREATE_SUCCEEDED = 'API_BUILDS_CREATE_SUCCEEDED';
export const API_BUILDS_CREATE_FAILED = 'API_BUILDS_CREATE_FAILED';

export const LOAD_BUILDS_CREATE_REQUESTED = 'LOAD_BUILDS_CREATE_REQUESTED';
export const LOAD_BUILDS_CREATE_SUCCEEDED = 'LOAD_BUILDS_CREATE_SUCCEEDED';
export const LOAD_BUILDS_CREATE_FAILED = 'LOAD_BUILDS_CREATE_FAILED';

export const API_TEST_RESULTS_DETAIL_FETCH_REQUESTED = 'API_TEST_RESULTS_DETAIL_FETCH_REQUESTED';
export const API_TEST_RESULTS_DETAIL_FETCH_FAILED = 'API_TEST_RESULTS_DETAIL_FETCH_FAILED';
export const API_TEST_RESULTS_DETAIL_FETCH_SUCCEEDED = 'API_TEST_RESULTS_DETAIL_FETCH_SUCCEEDED';

export const BUILDS_DIALOG = 'BUILDS_DIALOG';
export const BUILDS_DIALOG_CLOSE = 'BUILDS_DIALOG_CLOSE';

export const API_BUILDS_DIALOG = 'API_BUILDS_DIALOG';
export const API_BUILDS_DIALOG_CLOSE = 'API_BUILDS_DIALOG_CLOSE';

export const LOAD_BUILDS_DIALOG = 'LOAD_BUILDS_DIALOG';
export const LOAD_BUILDS_DIALOG_CLOSE = 'LOAD_BUILDS_DIALOG_CLOSE';

export const CLEAR_FILTER = 'CLEAR_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';

export const TOP_FAILS_FETCH_SUCCEEDED = 'TOP_FAILS_FETCH_SUCCEEDED';
export const TOP_FAILS_FETCH_FAILED = 'TOP_FAILS_FETCH_FAILED';
export const TOP_FAILS_FETCH_REQUESTED = 'TOP_FAILS_FETCH_REQUESTED';

export const NEXT_GEN_HISTORY_FETCH_SUCCEEDED = 'NEXT_GEN_HISTORY_FETCH_SUCCEEDED';
export const HISTORY_FETCH_SUCCEEDED = 'HISTORY_FETCH_SUCCEEDED';
export const HISTORY_FETCH_FAILED = 'HISTORY_FETCH_FAILED';
export const HISTORY_FETCH_REQUESTED = 'HISTORY_FETCH_REQUESTED';

export const DTAG_CREATE_SUCCEEDED = 'DTAG_CREATE_SUCCEEDED';
export const DTAG_CREATE_FAILED = 'DTAG_CREATE_FAILED';
export const DTAG_CREATE_REQUESTED = 'DTAG_CREATE_REQUESTED';

export const DTAG_FETCH_SUCCEEDED = 'DTAG_FETCH_SUCCEEDED';
export const DTAG_FETCH_FAILED = 'DTAG_FETCH_FAILED';
export const DTAG_FETCH_REQUESTED = 'DTAG_FETCH_REQUESTED';

export const JIRA_FETCH_SUCCEEDED = 'JIRA_FETCH_SUCCEEDED';
export const JIRA_FETCH_FAILED = 'JIRA_FETCH_FAILED';
export const JIRA_FETCH_REQUESTED = 'JIRA_FETCH_REQUESTED';

export const DTAG_ADD_SUCCEEDED = 'DTAG_ADD_SUCCEEDED';
export const DTAG_ADD_FAILED = 'DTAG_ADD_FAILED';
export const DTAG_ADD_REQUESTED = 'DTAG_ADD_REQUESTED';

export const DTAG_DELETE_SUCCEEDED = 'DTAG_ADD_SUCCEEDED';
export const DTAG_DELETE_FAILED = 'DTAG_ADD_FAILED';
export const DTAG_DELETE_REQUESTED = 'DTAG_ADD_REQUESTED';

export const DTAG_REMOVE_SUCCEEDED = 'DTAG_REMOVE_SUCCEEDED';
export const DTAG_REMOVE_FAILED = 'DTAG_REMOVE_FAILED';
export const DTAG_REMOVE_REQUESTED = 'DTAG_REMOVE_REQUESTED';

export const LOGIN_LOGGED_IN = 'LOGIN_LOGGED_IN';
export const LOGIN_LOGGED_OUT = 'LOGIN_LOGGED_OUT';
export const LOGIN_USER_UPDATE = 'LOGIN_USER_UPDATE';

export const TAB_ZERO_CLICKED = 'TAB_ZERO_CLICKED';
export const TAB_ONE_CLICKED = 'TAB_ONE_CLICKED';
export const TAB_TWO_CLICKED = 'TAB_TWO_CLICKED';

export const JIRA_RELEASE_VERSION_REQUESTED = 'JIRA_RELEASE_VERSION_REQUESTED';
export const JIRA_RELEASE_VERSION_SUCCEEDED = 'JIRA_RELEASE_VERSION_SUCCEEDED';
export const JIRA_RELEASE_VERSION_FAILED = 'JIRA_RELEASE_VERSION_FAILED';

export const SPIRA_RELEASE_REQUESTED = 'SPIRA_RELEASE_REQUESTED';
export const SPIRA_RELEASE_SUCCEEDED = 'SPIRA_RELEASE_SUCCEEDED';
export const SPIRA_RELEASE_FAILED = 'SPIRA_RELEASE_FAILED';

export const SPIRA_ALL_RELEASE_REQUESTED = 'SPIRA_ALL_RELEASE_REQUESTED';
export const SPIRA_ALL_RELEASE_SUCCEEDED = 'SPIRA_ALL_RELEASE_SUCCEEDED';
export const SPIRA_ALL_RELEASE_FAILED = 'SPIRA_ALL_RELEASE_FAILED';

export const DATAHARMONIZATION_FETCH_SQL_DATA_REQUESTED =
  'DATAHARMONIZATION_FETCH_SQL_DATA_REQUESTED';
export const DATAHARMONIZATION_FETCH_SQL_DATA_SUCCEEDED =
  'DATAHARMONIZATION_FETCH_SQL_DATA_SUCCEEDED';
export const DATAHARMONIZATION_FETCH_SQL_DATA_FAILED = 'DATAHARMONIZATION_FETCH_SQL_DATA_FAILED';

export const DATAHARMONIZATION_FETCH_NOSQL_DATA_REQUESTED =
  'DATAHARMONIZATION_FETCH_NOSQL_DATA_REQUESTED';
export const DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED =
  'DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED';
export const DATAHARMONIZATION_FETCH_NOSQL_DATA_FAILED =
  'DATAHARMONIZATION_FETCH_NOSQL_DATA_FAILED';

export const DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_REQUESTED =
  'DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_REQUESTED';
export const DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_SUCCEEDED =
  'DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_SUCCEEDED';
export const DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_FAILED =
  'DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_FAILED';

export const DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED =
  'DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED';
export const DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_SUCCEEDED =
  'DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_SUCCEEDED';
export const DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_FAILED =
  'DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_FAILED';

export const DATAHARMONIZATION_FHIR_TO_FHIRLITE_REQUESTED =
  'DATAHARMONIZATION_FHIR_TO_FHIRLITE_REQUESTED';
export const DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED =
  'DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED';
export const DATAHARMONIZATION_FHIR_TO_FHIRLITE_FAILED =
  'DATAHARMONIZATION_FHIR_TO_FHIRLITE_FAILED';

export const DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED =
  'DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED';
export const DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED =
  'DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED';
export const DATAHARMONIZATION_FHIR_BUCKET_DATA_FAILED =
  'DATAHARMONIZATION_FHIR_BUCKET_DATA_FAILED';

export const DATAHARMONIZATION_SFDC_DOCUMENTS_REQUESTED =
  'DATAHARMONIZATION_SFDC_DOCUMENTS_REQUESTED';
export const DATAHARMONIZATION_SFDC_DOCUMENTS_SUCCEEDED =
  'DATAHARMONIZATION_SFDC_DOCUMENTS_SUCCEEDED';
export const DATAHARMONIZATION_SFDC_DOCUMENTS_FAILED = 'DATAHARMONIZATION_SFDC_DOCUMENTS_FAILED';

export const DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED =
  'DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED';
export const DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED =
  'DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED';
export const DATAHARMONIZATION_SFDC_DOCUMENT_DATA_FAILED =
  'DATAHARMONIZATION_SFDC_DOCUMENT_DATA_FAILED';

export const DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED =
  'DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED';
export const DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_SUCCEEDED =
  'DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_SUCCEEDED';
export const DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_FAILED =
  'DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_FAILED';

export const DATAHARMONIZATION_GLOBAL_DATA_REQUESTED = 'DATAHARMONIZATION_GLOBAL_DATA_REQUESTED';
export const DATAHARMONIZATION_GLOBAL_DATA_SUCCEEDED = 'DATAHARMONIZATION_GLOBAL_DATA_SUCCEEDED';
export const DATAHARMONIZATION_GLOBAL_DATA_FAILED = 'DATAHARMONIZATION_GLOBAL_DATA_FAILED';
