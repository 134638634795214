import 'regenerator-runtime/runtime';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import testResource from '../../lib/TestResource';
import { watchTestResultSteps } from './TestResultSteps';
import { watchPrint } from './Print';
import { watchApiTestRuns } from './ApiTestRuns';
import { watchLoadTestRuns } from './LoadTestRuns';
import { watchApiTestResults } from './ApiTestResults';
import { watchDynamicTags } from './DynamicTags';
import { watchReleaseReport } from './SpiraResult';
import { watchTraceabilityMatrix } from './TraceabilityMatrix';
import { watchDataHarmonizationValidation } from './DHValidation';
import { watchHeimdallSetting } from './HeimdallSetting';

import {
  NEXT_GEN_HISTORY_FETCH_SUCCEEDED,
  HISTORY_FETCH_REQUESTED,
  HISTORY_FETCH_FAILED,
  HISTORY_FETCH_SUCCEEDED,
  TOP_FAILS_FETCH_REQUESTED,
  TOP_FAILS_FETCH_FAILED,
  TOP_FAILS_FETCH_SUCCEEDED,
  TEST_RESULTS_FETCH_SUCCEEDED,
  TEST_RESULTS_FETCH_FAILED,
  TEST_RESULTS_FETCH_REQUESTED,
  TEST_RUNS_FETCH_SUCCEEDED,
  TEST_RUNS_FETCH_FAILED,
  TEST_RUNS_FETCH_REQUESTED,
  BUILDS_FETCH_SUCCEEDED,
  BUILDS_FETCH_FAILED,
  BUILDS_FETCH_REQUESTED,
  BUILDS_CREATE_REQUESTED,
  BUILDS_CREATE_SUCCEEDED,
  BUILDS_CREATE_FAILED,
  API_BUILDS_FETCH_SUCCEEDED,
  API_BUILDS_FETCH_FAILED,
  API_BUILDS_FETCH_REQUESTED,
  API_BUILDS_CREATE_REQUESTED,
  API_BUILDS_CREATE_SUCCEEDED,
  API_BUILDS_CREATE_FAILED,
  LOAD_BUILDS_CREATE_SUCCEEDED,
  LOAD_BUILDS_FETCH_REQUESTED,
  LOAD_BUILDS_CREATE_FAILED,
  LOAD_BUILDS_CREATE_REQUESTED,
  LOAD_BUILDS_FETCH_SUCCEEDED,
  LOAD_BUILDS_FETCH_FAILED,
  BUILDS_CHINA_FETCH_REQUESTED,
  BUILDS_CHINA_FETCH_FAILED,
  BUILDS_CHINA_FETCH_SUCCEEDED,
  BUILDS_CHINA_CREATE_SUCCEEDED,
  BUILDS_CHINA_CREATE_FAILED,
  BUILDS_CHINA_CREATE_REQUESTED
} from '../actions';

function* fetchTestRuns(action) {
  try {
    const testRuns = yield call(testResource.getTestRuns, action.payload);
    yield put({ type: TEST_RUNS_FETCH_SUCCEEDED, payload: testRuns.data });
  } catch (e) {
    yield put({ type: TEST_RUNS_FETCH_FAILED, payload: e.message });
  }
}

function* fetchTestResults(action) {
  try {
    const testResults = yield call(testResource.getTestResults, action.payload);
    yield put({
      type: TEST_RESULTS_FETCH_SUCCEEDED,
      payload: testResults.data
    });
  } catch (e) {
    yield put({ type: TEST_RESULTS_FETCH_FAILED, payload: e.message });
  }
}

function* fetchBuilds() {
  try {
    const buildResults = yield call(testResource.getBuilds);
    yield put({ type: BUILDS_FETCH_SUCCEEDED, payload: buildResults.data });
  } catch (e) {
    yield put({ type: BUILDS_FETCH_FAILED, payload: e.message });
  }
}

function* fetchChinaBuilds() {
  try {
    const buildResults = yield call(testResource.getChinaBuilds);
    yield put({ type: BUILDS_CHINA_FETCH_SUCCEEDED, payload: buildResults.data });
  } catch (e) {
    yield put({ type: BUILDS_CHINA_FETCH_FAILED, payload: e.message });
  }
}

function* fetchAPIBuilds() {
  try {
    const buildResults = yield call(testResource.getAPIBuilds);
    yield put({ type: API_BUILDS_FETCH_SUCCEEDED, payload: buildResults.data });
  } catch (e) {
    yield put({ type: API_BUILDS_FETCH_FAILED, payload: e.message });
  }
}

function* fetchLoadBuilds() {
  try {
    const buildResults = yield call(testResource.getLoadBuilds);
    yield put({ type: LOAD_BUILDS_FETCH_SUCCEEDED, payload: buildResults.data });
  } catch (e) {
    yield put({ type: LOAD_BUILDS_FETCH_FAILED, payload: e.message });
  }
}

function* fetchHistory(action) {
  try {
    const { id, isNextGen, noraSite } = action.payload;
    const results = yield call(testResource.getHistory, id, isNextGen, noraSite);
    if (isNextGen) {
      yield put({ type: NEXT_GEN_HISTORY_FETCH_SUCCEEDED, payload: { data: results.data, id } });
    } else {
      yield put({ type: HISTORY_FETCH_SUCCEEDED, payload: results.data });
    }
  } catch (e) {
    yield put({ type: HISTORY_FETCH_FAILED, payload: e.message });
  }
}

function* createBuilds(action) {
  try {
    const buildResults = yield call(testResource.createBuilds, action.payload);
    yield put({ type: BUILDS_CREATE_SUCCEEDED, payload: buildResults.data });
    yield put({ type: BUILDS_FETCH_REQUESTED });
  } catch (e) {
    yield put({ type: BUILDS_CREATE_FAILED, payload: e.message });
  }
}

function* createChinaBuilds(action) {
  try {
    const buildResults = yield call(testResource.createChinaBuilds, action.payload);
    yield put({ type: BUILDS_CHINA_CREATE_SUCCEEDED, payload: buildResults.data });
    yield put({ type: BUILDS_CHINA_FETCH_REQUESTED });
  } catch (e) {
    yield put({ type: BUILDS_CHINA_CREATE_FAILED, payload: e.message });
  }
}

function* createAPIBuilds(action) {
  try {
    const buildResults = yield call(testResource.createAPIBuilds, action.payload);
    yield put({ type: API_BUILDS_CREATE_SUCCEEDED, payload: buildResults.data });
    yield put({ type: API_BUILDS_FETCH_REQUESTED });
  } catch (e) {
    yield put({ type: API_BUILDS_CREATE_FAILED, payload: e.message });
  }
}

function* createLoadBuilds(action) {
  try {
    const buildResults = yield call(testResource.createLoadBuilds, action.payload);
    yield put({ type: LOAD_BUILDS_CREATE_SUCCEEDED, payload: buildResults.data });
    yield put({ type: LOAD_BUILDS_FETCH_REQUESTED });
  } catch (e) {
    yield put({ type: LOAD_BUILDS_CREATE_FAILED, payload: e.message });
  }
}

function* fetchTopFails() {
  try {
    const results = yield call(testResource.getTopFails);
    yield put({ type: TOP_FAILS_FETCH_SUCCEEDED, payload: results.data });
  } catch (e) {
    yield put({ type: TOP_FAILS_FETCH_FAILED, payload: e.message });
  }
}

export function* watcherSaga() {
  yield takeLatest(HISTORY_FETCH_REQUESTED, fetchHistory);
  yield takeLatest(TOP_FAILS_FETCH_REQUESTED, fetchTopFails);
  yield takeLatest(TEST_RESULTS_FETCH_REQUESTED, fetchTestResults);
  yield takeLatest(TEST_RUNS_FETCH_REQUESTED, fetchTestRuns);
  yield takeLatest(BUILDS_FETCH_REQUESTED, fetchBuilds);
  yield takeLatest(BUILDS_CHINA_FETCH_REQUESTED, fetchChinaBuilds);
  yield takeLatest(API_BUILDS_FETCH_REQUESTED, fetchAPIBuilds);
  yield takeLatest(LOAD_BUILDS_FETCH_REQUESTED, fetchLoadBuilds);
  yield takeLatest(BUILDS_CREATE_REQUESTED, createBuilds);
  yield takeLatest(BUILDS_CHINA_CREATE_REQUESTED, createChinaBuilds);
  yield takeLatest(API_BUILDS_CREATE_REQUESTED, createAPIBuilds);
  yield takeLatest(LOAD_BUILDS_CREATE_REQUESTED, createLoadBuilds);
  yield all([
    watchApiTestResults(),
    watchTestResultSteps(),
    watchDynamicTags(),
    watchApiTestRuns(),
    watchLoadTestRuns(),
    watchPrint(),
    watchReleaseReport(),
    watchTraceabilityMatrix(),
    watchDataHarmonizationValidation(),
    watchHeimdallSetting()
  ]);
}
