import {
  HEIMDALL_SETTING_CREATE_REQUESTED,
  HEIMDALL_SETTING_CREATE_FAILED,
  HEIMDALL_SETTING_CREATE_SUCCEEDED,
  HEIMDALL_SETTING_GET_ALL_REQUESTED,
  HEIMDALL_SETTING_GET_ALL_FAILED,
  HEIMDALL_SETTING_GET_ALL_SUCCEEDED,
  HEIMDALL_SETTING_UPDATE_REQUESTED,
  HEIMDALL_SETTING_UPDATE_FAILED,
  HEIMDALL_SETTING_UPDATE_SUCCEEDED,
  HEIMDALL_SETTING_DELETE_REQUESTED,
  HEIMDALL_SETTING_DELETE_FAILED,
  HEIMDALL_SETTING_DELETE_SUCCEEDED
} from '../actions';

const defaultError = {
  heimdallSettingCreateError: false,
  heimdallSettingCreateFetching: false,
  heimdallSettingCreateSuccess: false,

  heimdallSettingUpdateError: false,
  heimdallSettingUpdateFetching: false,
  heimdallSettingUpdateSuccess: false,

  heimdallSettingDeleteError: false,
  heimdallSettingDeleteFetching: false,
  heimdallSettingDeleteSuccess: false,

  heimdallSettingGetAllError: false,
  heimdallSettingGetAllFetching: false,
  heimdallSettingGetAllSuccess: false
};

const defaultState = {
  settings: [],
  ...defaultError
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case HEIMDALL_SETTING_CREATE_REQUESTED:
      return {
        ...state,
        heimdallSettingCreateFetching: true,
        heimdallSettingCreateError: false,
        heimdallSettingCreateSuccess: false
      };
    case HEIMDALL_SETTING_CREATE_FAILED:
      return {
        ...state,
        heimdallSettingCreateFetching: false,
        heimdallSettingCreateError: true,
        heimdallSettingCreateSuccess: false
      };
    case HEIMDALL_SETTING_CREATE_SUCCEEDED:
      return {
        ...state,
        heimdallSettingCreateFetching: false,
        heimdallSettingCreateError: false,
        heimdallSettingCreateSuccess: true
      };

    case HEIMDALL_SETTING_UPDATE_REQUESTED:
      return {
        ...state,
        heimdallSettingUpdateFetching: true,
        heimdallSettingUpdateError: false,
        heimdallSettingUpdateSuccess: false
      };
    case HEIMDALL_SETTING_UPDATE_FAILED:
      return {
        ...state,
        heimdallSettingUpdateFetching: false,
        heimdallSettingUpdateError: true,
        heimdallSettingUpdateSuccess: false
      };
    case HEIMDALL_SETTING_UPDATE_SUCCEEDED:
      return {
        ...state,
        heimdallSettingUpdateFetching: false,
        heimdallSettingUpdateError: false,
        heimdallSettingUpdateSuccess: true
      };

    case HEIMDALL_SETTING_GET_ALL_REQUESTED:
      return {
        ...state,
        heimdallSettingGetAllFetching: true,
        heimdallSettingGetAllError: false
      };
    case HEIMDALL_SETTING_GET_ALL_FAILED:
      return {
        ...state,
        heimdallSettingGetAllFetching: false,
        heimdallSettingGetAllError: true
      };
    case HEIMDALL_SETTING_GET_ALL_SUCCEEDED:
      return {
        ...state,
        settings: action.payload,
        heimdallSettingGetAllFetching: false,
        heimdallSettingGetAllError: false
      };

    case HEIMDALL_SETTING_DELETE_REQUESTED:
      return {
        ...state,
        heimdallSettingDeleteFetching: true,
        heimdallSettingDeleteError: false,
        heimdallSettingDeleteSuccess: false
      };
    case HEIMDALL_SETTING_DELETE_FAILED:
      return {
        ...state,
        heimdallSettingDeleteFetching: false,
        heimdallSettingDeleteError: true,
        heimdallSettingDeleteSuccess: false
      };
    case HEIMDALL_SETTING_DELETE_SUCCEEDED:
      return {
        ...state,
        heimdallSettingDeleteFetching: false,
        heimdallSettingDeleteError: false,
        heimdallSettingDeleteSuccess: true
      };
    default:
      return state;
  }
}
