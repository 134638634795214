import testResource from '../../lib/TestResource';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  API_TEST_RUNS_FETCH_SUCCEEDED,
  API_TEST_RUNS_FETCH_FAILED,
  API_TEST_RUNS_FETCH_REQUESTED
} from '../actions';

function* fetchApiTestRuns(action) {
  try {
    const testRuns = yield call(testResource.getApiTestRuns, action.payload);
    yield put({
      type: API_TEST_RUNS_FETCH_SUCCEEDED,
      payload: testRuns.data
    });
  } catch (e) {
    yield put({ type: API_TEST_RUNS_FETCH_FAILED, payload: e.message });
  }
}

export function* watchApiTestRuns() {
  yield takeLatest(API_TEST_RUNS_FETCH_REQUESTED, fetchApiTestRuns);
}
