import {
  TEST_RESULTS_STEPS_FETCH_SUCCEEDED,
  TEST_RESULTS_STEPS_FETCH_FAILED,
  TEST_RESULTS_STEPS_FETCH_REQUESTED,
} from '../actions';

const defaultState = {
  fetching: false,
  error: false,
  testDetail: {},
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case TEST_RESULTS_STEPS_FETCH_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case TEST_RESULTS_STEPS_FETCH_SUCCEEDED:
      const testResult = action.payload[0] || {};
      return {
        ...state,
        [testResult.test_run_test_id]: testResult,
        error: false,
        fetching: false
      };
    case TEST_RESULTS_STEPS_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        error: true
      };
    default:
      return state;
  }
}
