export default function(theme) {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    formControl: {
      margin: '20px',
      minWidth: 200,
      width: 500,
      maxWidth: 500,
      minHeight: '48px'
    },
    translationNote: {
      marginLeft: '20px',
      marginRight: '20px',
      display: 'flex',
      alignItems: 'end',
      flexDirection: 'column',
      fontStyle: 'italic',
      fontSize: '0.9em'
    },
    isNextGen: {
      marginTop: '20px',
      marginBottom: '20px',
      marginRight: '20px',
      marginLeft: '20px',
      minWidth: 200,
      width: 200,
      maxWidth: 200,
      minHeight: '48px'
    },
    siteField: {
      marginTop: '20px',
      marginBottom: '20px',
      minWidth: 280,
      width: 280,
      maxWidth: 280,
      minHeight: '48px'
    },
    regionField: {
      marginTop: '10px',
      marginRight: '30px',
      marginBottom: '20px',
      minWidth: 100,
      width: 100,
      maxWidth: 100,
      minHeight: '48px'
    },
    buttonsContainer: {
      margin: '0 40px 20px'
    },
    radioGroup: {
      margin: '0px'
    },
    searchTag: {
      paddingTop: '5px'
    }
  };
}
