import {
  LOAD_TEST_RUNS_FETCH_SUCCEEDED,
  LOAD_TEST_RUNS_FETCH_FAILED,
  LOAD_TEST_RUNS_FETCH_REQUESTED,
  LOAD_BUILDS_FETCH_FAILED,
  LOAD_BUILDS_FETCH_REQUESTED,
  LOAD_BUILDS_FETCH_SUCCEEDED
} from '../actions';

const defaultState = {
  testRuns: [],
  builds: [],
  test_runs_error: false,
  builds_error: false,
  test_runs_fetching: false,
  builds_fetching: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOAD_TEST_RUNS_FETCH_REQUESTED:
      return {
        ...state,
        testRuns: [],
        test_runs_fetching: true,
        test_runs_error: false
      };
    case LOAD_TEST_RUNS_FETCH_FAILED:
      return { ...state, test_runs_fetching: false, test_runs_error: true };
    case LOAD_TEST_RUNS_FETCH_SUCCEEDED:
      return {
        ...state,
        testRuns: action.payload,
        test_runs_error: false,
        test_runs_fetching: false
      };
    case LOAD_BUILDS_FETCH_REQUESTED:
      return {
        ...state,
        builds: [],
        builds_error: false,
        builds_fetching: true
      };
    case LOAD_BUILDS_FETCH_SUCCEEDED:
      return {
        ...state,
        builds: action.payload,
        builds_error: false,
        builds_fetching: false
      };
    case LOAD_BUILDS_FETCH_FAILED:
      return { ...state, builds_fetching: false, builds_error: false };
    default:
      return state;
  }
}
