import { FailColor, PassColor } from '../../assets/jss/colors';

export default function(theme) {
  return {
    headerIcon: {
      marginLeft: '10px'
    },
    progress: {
      margin: '300px auto',
      display: 'block'
    },
    pagebreak: {
      pageBreakInside: 'avoid'
    },
    step_check_icon: {
      position: 'relative',
      color: PassColor,
      'font-size': '11px'
    },
    step_fail_icon: {
      position: 'relative',
      color: FailColor,
      'font-size': '11px'
    }
  };
}
