import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { CircularProgress, withStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import {
  JIRA_DETAIL_FETCH_REQUESTED,
} from '../../redux/actions';

const useStyles = makeStyles(styles);

export default function BugViewAction ({ data }) {
  const cs = useStyles();

  const dispatch = useDispatch();
  const [jiraTickets, setJiraTickets] = useState([])
  const [open, setOpen] = React.useState(false);
  const jiraTicketDetails = useSelector(state => state.traceabilityMatrix.jiraDetail)
  const jiraFetching = useSelector(state => state.traceabilityMatrix.jiraFetching)

  useEffect(() => {
    const tickets = []
    if(data && data.length > 0){
      let testRuns = data.map(d => d.testRuns);
      testRuns = [].concat.apply([], testRuns);
      for (let i = 0; i < testRuns.length; i++) {
        if(testRuns[i].errorMessage && testRuns[i].errorMessage.includes("nxl")){
          tickets.push(testRuns[i].errorMessage.toUpperCase())
        }
      }
    }
    setJiraTickets(tickets)
  }, [data])

  useEffect(() => {
    if(open && jiraTickets.length > 0 && jiraTicketDetails.length === 0){
      fetchData();
    }
  }, [open, jiraTickets])

  const fetchData = () => {
    const payload = {
      jiraTickets
    }
    dispatch({ type: JIRA_DETAIL_FETCH_REQUESTED, payload: { payload } });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderJiraDetail = (jiraTicketDetails) => {
    return jiraTicketDetails.map((ticket, i) => {
        const { key: jiraTicket, fields: { summary, status }} = ticket
      return (
        <div key={i} className={cs.jiraContainer}>
          <div className={cs.initialJiraGroup}>
          <div className={cs.jiraTicket}>
            <a  href={`https://science37.atlassian.net/browse/${jiraTicket}`} target="_blank">{jiraTicket}</a>
          </div>
          <div className={cs.jiraSummary}>
            <div>
            {summary}
            </div>
          </div>
          </div>
          <div>
          <div className={cs.jiraSummary}>
            <Chip
              size="small"
              label={status.name}
              color="default"
            />
          </div>
          </div>
        </div>
      )
    })
  }

  return (<div>
      <Button
        onClick={handleClickOpen}
        color="primary"
        variant="contained"
      >
        View Linked Bugs
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Linked Jira Bugs</DialogTitle>
        <DialogContent >
          {jiraFetching &&
                <div style={{
                    display: "flex",
                    justifyContent: "center"
                  }}>
                  <CircularProgress size={50} color="primary" />
                </div>
          }
          {jiraTicketDetails.length > 0 && !jiraFetching &&
            renderJiraDetail(jiraTicketDetails)
          }
          {jiraTicketDetails.length === 0 && !jiraFetching &&
            `N/A`
          }
        </DialogContent>
      </Dialog>
    </div>
  )
}


