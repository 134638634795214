import {
  API_TEST_RUNS_FETCH_SUCCEEDED,
  API_TEST_RUNS_FETCH_FAILED,
  API_TEST_RUNS_FETCH_REQUESTED,
  API_BUILDS_FETCH_FAILED,
  API_BUILDS_FETCH_REQUESTED,
  API_BUILDS_FETCH_SUCCEEDED
} from '../actions';

const defaultState = {
  testResults: [],
  detailResult: {},
  testRuns: [],
  builds: [],
  test_runs_error: false,
  builds_error: false,
  test_runs_fetching: false,
  test_run_key: '',
  builds_fetching: false,
  results_detail_dialog_open: false,
  history: [],
  fails: [],
  history_fetching: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case API_TEST_RUNS_FETCH_REQUESTED:
      return {
        ...state,
        testRuns: [],
        test_runs_fetching: true,
        test_runs_error: false
      };
    case API_TEST_RUNS_FETCH_FAILED:
      return { ...state, test_runs_fetching: false, test_runs_error: true };
    case API_TEST_RUNS_FETCH_SUCCEEDED:
      return {
        ...state,
        testRuns: action.payload,
        test_runs_error: false,
        test_runs_fetching: false
      };
    case API_BUILDS_FETCH_REQUESTED:
      return {
        ...state,
        builds: [],
        builds_error: false,
        builds_fetching: true
      };
    case API_BUILDS_FETCH_SUCCEEDED:
      return {
        ...state,
        builds: action.payload,
        builds_error: false,
        builds_fetching: false
      };
    case API_BUILDS_FETCH_FAILED:
      return { ...state, builds_fetching: false, builds_error: false };
    default:
      return state;
  }
}
