import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import styles from './styles';

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <p className={classes.footerText}>
          <span>
            <span>&copy;</span>
            <span className={classes.copyright}>{1900 + new Date().getYear()}</span>
            <a href="https://www.science37.com" className={classes.a}>
              Science 37
            </a>
            , House of Automation
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
