import React, { useState, useEffect } from 'react';
import { MainCard } from 'heimdall-components';
import { DataGrid, GridRowsProp, GridColDef } from '@material-ui/data-grid';
import { connect, useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ApiIcon from '@mui/icons-material/Api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED } from '../../redux/actions';

import {
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  LinearProgress,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import { Typography } from '@mui/material';

import Stack from '@mui/material/Stack';
import dhClass from './dataharmonization.module.css';
import * as _ from 'lodash';

export default function DHGlobalComponent({ globalData, platform }) {
  const [expanded, setExpanded] = useState(true);
  const [showErrors, setShowErrors] = useState(false);

  const [selectedRecord, setSelectedRecord] = useState(null);

  const columns = [
    { field: 'entity', headerName: 'Entity', flex: 1 },
    { field: 'fhirCount', headerName: 'FHIR File Count', flex: 1 },
    { field: 'fhirLiteCount', headerName: 'FHIR LITE File Count', flex: 1 },
    { field: 'redshiftCount', headerName: 'Redshift Data Count', flex: 1 }
    // { field: 'difference', headerName: 'Difference', flex: 1 }
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ display: platform?.indexOf('global') !== -1 ? 'block' : 'none' }}>
      <Paper>
        {
          <div>
            {globalData?.map((record) => (
              <Accordion
                style={{ display: showErrors === false ? 'block' : 'none' }}
                expanded={expanded === record['id']}
                onChange={handleChange(record['id'])}
                key={record['id']}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '50%', flexShrink: 0 }}>{record['entity']}</Typography>
                  <Typography sx={{ width: '50%', color: 'text.secondary' }}>
                    {record['status']}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    // <DataGrid id='sfdcGrid'
                    //   style={{minHeight: '55vh'}}
                    //   rows={record['tableCounts'] ? record['tableCounts'] : []}
                    //   columns={columns ? columns : []}
                    //   onSelectionModelChange={(ids) => onGridRowSelected(ids)}
                    // />
                    <div style={{ display: 'block', width: '100%', height: 'auto' }}>
                      {record['tableCounts']?.map((tableRec) => (
                        <div
                          key={tableRec[0] + 'div'}
                          style={{ width: '32%', margin: '5px', display: 'inline-block' }}
                        >
                          <Card key={tableRec[0]} style={{ position: 'relative' }}>
                            <CardContent>
                              <h5>{tableRec[0]}</h5>
                              <ErrorIcon
                                className={dhClass.floatRight}
                                style={{
                                  display:
                                    tableRec[1]['errors'].length > 0 ? 'inline-block' : 'none'
                                }}
                              />
                              <br />
                              <p>My SQL Count: {tableRec[1]['mySqlCount']} </p>
                              <p>Redshift Count: {tableRec[1]['redshiftCount']}</p>

                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  display: tableRec[1]['errors'].length > 0 ? 'block' : 'none'
                                }}
                                onClick={() => {
                                  setSelectedRecord(tableRec[1]);
                                  setShowErrors(true);
                                }}
                              >
                                Errors
                              </Button>
                            </CardContent>
                          </Card>
                        </div>
                      ))}
                    </div>
                  }
                </AccordionDetails>
              </Accordion>
            ))}

            <Card style={{ display: showErrors === true ? 'block' : 'none' }}>
              <CardContent>
                {
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setShowErrors(false)}
                    >
                      X
                    </Button>
                    <h4>Errors</h4>
                    <List>
                      {selectedRecord?.errors?.map((err, errIndex) => (
                        <ListItem key={errIndex.toString()}>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {err['message'] ? err['message'] : err}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                }
              </CardContent>
            </Card>
          </div>
        }
      </Paper>
    </div>
  );
}
