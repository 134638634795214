import {
  SPIRA_RELEASE_REQUESTED,
  SPIRA_RELEASE_SUCCEEDED,
  SPIRA_RELEASE_FAILED,
  SPIRA_ALL_RELEASE_FAILED,
  SPIRA_ALL_RELEASE_REQUESTED,
  SPIRA_ALL_RELEASE_SUCCEEDED,
  JIRA_RELEASE_VERSION_REQUESTED,
  JIRA_RELEASE_VERSION_FAILED,
  JIRA_RELEASE_VERSION_SUCCEEDED
} from '../actions';

const defaultState = {
  fetching: false,
  error: false,
  releaseReport: [],
  spiraReleases: [],
  jiraReleases: []
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case JIRA_RELEASE_VERSION_REQUESTED:
      return {
        ...state,
        fetching: true,
        jiraReleases: [],
        error: false
      };
    case JIRA_RELEASE_VERSION_SUCCEEDED:
      const jiraReleases = action.payload;
      return {
        ...state,
        jiraReleases,
        error: false,
        fetching: false
      };
    case JIRA_RELEASE_VERSION_FAILED:
      return {
        ...state,
        fetching: false,
        jiraReleases: [],
        error: true
      };

    case SPIRA_RELEASE_REQUESTED:
      return {
        ...state,
        fetching: true,
        releaseReport: [],
        error: false
      };
    case SPIRA_RELEASE_SUCCEEDED:
      const releaseReport = action.payload;
      return {
        ...state,
        releaseReport: releaseReport,
        error: false,
        fetching: false
      };
    case SPIRA_RELEASE_FAILED:
      return {
        ...state,
        fetching: false,
        releaseReport: [],
        error: true
      };
    case SPIRA_ALL_RELEASE_REQUESTED:
      return {
        ...state,
        fetching: true,
        spiraReleases: [],
        error: false
      };
    case SPIRA_ALL_RELEASE_SUCCEEDED:
      const spiraReleases = action.payload;
      return {
        ...state,
        spiraReleases: spiraReleases,
        error: false,
        fetching: false
      };
    case SPIRA_ALL_RELEASE_FAILED:
      return {
        ...state,
        fetching: false,
        spiraReleases: [],
        error: true
      };
    default:
      return state;
  }
}
