import React, { useState, useEffect, useCallback } from 'react';
import { MainCard } from 'heimdall-components';
import { DataGrid, GridRowsProp, GridColDef } from '@material-ui/data-grid';
import { connect, useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ApiIcon from '@mui/icons-material/Api';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {
  DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED,
  DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED
} from '../../redux/actions';

import {
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  LinearProgress,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Divider
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import dhClass from './dataharmonization.module.css';
import * as _ from 'lodash';

export default function DHSFDCComponent({
  sfdcDetails,
  environment,
  platform,
  sqlType,
  envType,
  dataFetching
}) {
  const dispatch = useDispatch();

  const [showSFDCDetails, setShowSFDCDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]); //Environments
  const [sfdcData, setSfdcData] = useState([]);
  const [selectedSFDCState, setSelectedSFDCState] = useState('');
  const sfdcDetailsColumns = [
    { field: 'trial_id', headerName: 'Trial Id', flex: 1 },
    { field: 'lead_id', headerName: 'Lead Id', flex: 1 },
    { field: 'account_id', headerName: 'Account ID', flex: 1 },
    { field: 'first_name', headerName: 'First Name', flex: 1 },
    { field: 'last_name', headerName: 'Last Name', flex: 1 },
    { field: 'gender', headerName: 'Gender', flex: 1 },
    { field: 'birth_date', headerName: 'Birth Date', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'state', headerName: 'State', flex: 1 },
    { field: 'postal_code', headerName: 'Postal Code', flex: 1 },
    { field: 'time_zone', headerName: 'Time Zone', flex: 1 },
    { field: 'ethnicity', headerName: 'Ethnicity', flex: 1 }
  ];
  let dataSFDCDocumentData = useSelector((state) => state.dhValidation.sfdcDocumentData);

  const onSFDCRowSelected = (rowIds) => {
    let rowSelected = sfdcDetails.data.find((a) => a['id'] == rowIds[0]);
    setSelectedRow(rowSelected);
    setShowSFDCDetails(true);

    let sfdcBucketName = rowSelected['bucket'];
    let requestBucket = {
      status: 'Pending...',
      bucketName: sfdcBucketName,
      docPath: rowSelected['docPath'],
      lastUpdated: rowSelected['lastUpdated'],
      s3data: [],
      mySqlData: []
    };

    dispatch({
      type: DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED,
      payload: {
        bucket: requestBucket,
        environment: environment,
        platform: platform,
        type: sqlType,
        envType: envType
      }
    });
  };

  return (
    <div>
      <div
        style={{
          minHeight: '60vh',
          width: '100%',
          display: platform?.indexOf('sfdc') !== -1 && showSFDCDetails === false ? 'block' : 'none'
        }}
      >
        <h5>SFDC</h5>
        <DataGrid
          style={{ minHeight: '55vh' }}
          rows={sfdcDetails.data ? sfdcDetails.data : []}
          columns={sfdcDetails.columns ? sfdcDetails.columns : []}
          onSelectionModelChange={(ids) => onSFDCRowSelected(ids)}
        />
      </div>

      {/*SFDC DETAILS*/}
      <Paper style={{ display: showSFDCDetails ? 'block' : 'none' }}>
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Button
              variant="contained"
              onClick={() => setShowSFDCDetails(false)}
              color="primary"
              disabled={dataFetching}
            >
              Back
            </Button>

            <br />

            <h4>{selectedRow['lastUpdated']}</h4>
            <label>{dataSFDCDocumentData?.['status']}</label>
            {dataSFDCDocumentData ? (
              <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    setSfdcData(dataSFDCDocumentData['s3Data']);
                    setSelectedSFDCState('S3 Data');
                  }}
                  color="primary"
                  disabled={dataFetching}
                >
                  S3 Records:{' '}
                  {dataSFDCDocumentData?.['s3Data']
                    ? Object.keys(dataSFDCDocumentData?.['s3Data'])?.length
                    : 0}
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    setSfdcData(dataSFDCDocumentData['mySqlData']);
                    setSelectedSFDCState('My Sql Data');
                  }}
                  color="primary"
                  disabled={dataFetching}
                >
                  My Sql Records:{' '}
                  {dataSFDCDocumentData?.['mySqlData']
                    ? Object.keys(dataSFDCDocumentData?.['mySqlData'])?.length
                    : 0}
                </Button>

                <Button
                  variant="contained"
                  onClick={() => {
                    setSfdcData(dataSFDCDocumentData['descrepancies']);
                    setSelectedSFDCState('Descrepancies');
                  }}
                  color="primary"
                  disabled={dataFetching}
                >
                  Discrepancies:{' '}
                  {
                    dataSFDCDocumentData?.['combinedResultData']?.filter((a) => a['exist'] == false)
                      .length
                  }
                </Button>

                {
                  <div>
                    <br />
                    <h6>{selectedSFDCState}</h6>
                    <div class="gridScroll" style={{ width: '100%' }}>
                      <DataGrid
                        style={{ minHeight: '55vh' }}
                        id="sfdcDetailData"
                        rows={sfdcData ? sfdcData : []}
                        columns={sfdcDetailsColumns}
                      />
                    </div>
                  </div>
                }
              </div>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      </Paper>
    </div>
  );
}
