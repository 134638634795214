import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { FailColor, PassColor, SkipColor, TotalColor } from '../../assets/jss/colors';
import { CircularProgress, withStyles } from '@material-ui/core';
import moment from 'moment';
const MIN_TEST_TOTALS = 150; // minimum tests that ran
const EXECUTOR = ['@daily_robot'];
// const EXECUTOR = ['@daily_robot', '@daily_robot_rerun_failed'];

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  progressContainer: {
    display: 'flex',
    marginTop: '100px',
    justifyContent: 'center'
  },
  chartContainer: {
    padding: '0 20px 20px 20px'
  }
});

class TestRunsGraph extends Component {
  constructor(props) {
    super(props);
  }

  getPlatformUrl(env, platform) {
    if (platform === 'nextGen') {
      if (env === 'dev') {
        return 'https://groovy.dev1-platform.s37dev.com/';
      } else if (env === 'stage') {
        return 'https://stage-platform.s37dev.com/';
      }
    } else if (platform === 'legacy') {
      if (env === 'dev') {
        return 'https://dev.nora.science37.com/';
      } else if (env === 'stage') {
        return 'https://stage.nora.science37.com/';
      }
    }
  }
  getData(testRuns, env, platform) {
    let data = [];
    const platformUrl = this.getPlatformUrl(env, platform);
    for (let i = testRuns.length - 1; i >= 0; i--) {
      let obj = {};
      // if (total > MIN_TEST_TOTALS && testRuns[i].nora_site.includes(`${env}.nora.science37.com`)) {
      if (testRuns[i].nora_site.includes(platformUrl) && EXECUTOR.includes(testRuns[i].executor)) {
        const total = parseInt(testRuns[i].total, 10);
        obj['name'] = moment(testRuns[i].test_run_name, 'YYYY-MM-DD_HH-mm-ss-SSS').format('MM-DD');
        obj['total'] = total;
        obj['failed'] = parseInt(testRuns[i].failed, 10);
        obj['skipped'] = parseInt(testRuns[i].pending, 10);
        obj['passed'] = parseInt(testRuns[i].passed, 10);
        data.push(obj);
      }
    }

    return data;
  }

  render() {
    const { testRuns, env, platform, classes: cs } = this.props;
    const data = this.getData(testRuns, env, platform);
    if (data !== undefined && data.length > 0) {
      return (
        <div className={cs.chartContainer}>
          <ResponsiveContainer width="98%" height={500}>
            <LineChart
              outerRadius="100%"
              data={data}
              margin={{ top: 40, right: 10, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="total" stroke={TotalColor} />
              <Line type="monotone" dataKey="passed" stroke={PassColor} />
              <Line type="monotone" dataKey="failed" stroke={FailColor} />
              <Line type="monotone" dataKey="skipped" stroke={SkipColor} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    } else {
      return (
        <div className={cs.progressContainer}>
          <CircularProgress size={50} color="primary" className={cs.progress} />
        </div>
      );
    }
  }
}

export default withStyles(styles)(TestRunsGraph);
