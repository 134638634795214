import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import styles from './styles';

import { withStyles, Grid, Card, CardHeader, CardContent } from '@material-ui/core';

function ScreenshotCard({ ...props }) {
  const { classes: cs, description, src, index, screenshots, timestamp, steps } = props;
  return (
    <Card>
      <CardHeader
        title={description}
        action={<span className={cs.screenshotCount}>{`${index + 1}/${screenshots.length}`}</span>}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <a href={src} target="_blank" rel="noopener noreferrer">
              <img
                className={cs.screenshot}
                src={src}
                alt={description}
                height="256px"
                width="250px"
              />
            </a>
          </Grid>
          <Grid item xs={6}>
            <ol className={cs.card_steps}>{steps}</ol>
          </Grid>
        </Grid>
        <span className={cs.screenshotTimestamp}>{`Screenshot created on ${moment(timestamp).format(
          'DD-MMM-YYYY hh:mm'
        )}`}</span>
      </CardContent>
    </Card>
  );
}

// <img className={cs.screenshot} src={src} alt="" height="512px" width="500px" />
ScreenshotCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ScreenshotCard);
