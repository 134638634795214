import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import { compose } from 'redux';

class SimpleExpansionPanel extends Component {
  render() {
    const { classes, title, child } = this.props;
    return (
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {title}
          </AccordionSummary>
          <AccordionDetails>{child}</AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default compose(withStyles(styles))(SimpleExpansionPanel);
