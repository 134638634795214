export const NORA_SITES = [
  {
    value: 'https://dev.nora.science37.com/',
    label: 'Nora - DEV'
  },
  {
    value: 'https://stage.nora.science37.com/',
    label: 'Nora - STAGE'
  },
  {
    value: 'https://terrislc9.nora.science37.com/',
    label: 'Terris Cloud 9'
  },
  {
    value: 'https://sullahkhanc9.s37dev.com/',
    label: 'Saad Ullah Cloud 9'
  },
  {
    value: 'https://kgautamc9.s37dev.com/',
    label: "Kiran's Cloud 9"
  },
  {
    value: 'https://brian-francoisc9.nora.science37.com/',
    label: "Brian's Cloud 9"
  },
  {
    value: 'https://willdc9.nora.science37.com/',
    label: "Will's Cloud 9"
  },
  {
    value: 'https://danfrancoc9.s37dev.com/',
    label: "Dan Franco's Cloud 9"
  },
  {
    value: 'https://amitc9.nora.science37.com/',
    label: "Amit's Cloud 9"
  },
  {
    value: 'https://violettakc9.nora.science37.com/',
    label: 'Violetta`s Cloud 9'
  },
  {
    value: 'https://dbravermanc9.nora.science37.com/',
    label: 'David B Cloud 9'
  },
  {
    value: 'https://amerazc9.nora.science37.com/',
    label: "Angel's Cloud 9"
  },
  {
    value: 'https://nora.science37.com/',
    label: 'LegacyPROD - US'
  },
  {
    value: 'https://eu1.science37.com/',
    label: 'LegacyPROD - Europe'
  },
  {
    value: 'https://cn1.37services37.com/',
    label: 'LegacyPROD - China'
  },
  {
    value: 'https://ap1.science37.com/',
    label: 'LegacyPROD - APAC'
  },
  {
    value: 'https://russia.science37.com/',
    label: 'LegacyPROD - Russia'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export const NORA_SITES_NEXT = [
  {
    value: 'https://dev1-platform.s37dev.com/',
    label: 'NextGen Global - DEV1 (dev1-platform)'
  },
  {
    value: 'https://stage-platform.s37dev.com/',
    label: 'NextGen Global - STAGE (stage-platform)'
  },
  {
    value: 'https://extval-platform.science37.com/',
    label: 'NextGen Global - ExtVAL (extval-platform)'
  },
  {
    value: 'https://intval-platform.science37.com/',
    label: 'NextGen Global - IntVAL (intval-platform)'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export const DATABASE_HOSTS = [
  {
    value: 'db-nora-dev.cti10lnrh4rb.us-west-2.rds.amazonaws.com',
    label: 'DB Nora - DEV'
  },
  {
    value: 'db-nora-stage.cti10lnrh4rb.us-west-2.rds.amazonaws.com',
    label: 'DB Nora - STAGE'
  },
  {
    value: 'db-nora-dev-xl.cti10lnrh4rb.us-west-2.rds.amazonaws.com',
    label: 'DB Nora XL - DEV'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export const PARALLEL_OPTIONS = [
  {
    value: 1,
    label: 'One Instance'
  },
  {
    value: 2,
    label: 'Two Instances'
  },
  {
    value: 3,
    label: 'Three Instances'
  },
  {
    value: 4,
    label: 'Four Instances'
  },
  {
    value: 5,
    label: 'Five Instances'
  },
  {
    value: 6,
    label: 'Six Instances'
  },
  {
    value: 7,
    label: 'Seven Instances'
  },
  {
    value: 8,
    label: 'Eight Instances'
  },
  {
    value: 9,
    label: 'Nine Instances'
  },
  {
    value: 10,
    label: 'Ten Instances'
  }
];

export const RUN_TYPE = {
  RUN_ALL: 'run_all',
  BY_TAGS: 'by_tags',
  BY_DYNAMIC_TAGS: 'by_dynamic_tags',
  BY_SUB_SET: 'by_sub_set',
  OQ_TEST: 'oq_test',
  TRANSLATION_TEST: 'translation_test',
  BULK_IMAGE_UPLOAD: 'bulk_image_upload'
};

export const LEGACY_TRANSLATION_TEST_TAG = '@loginPageScreenshots';
export const NEXTGEN_TRANSLATION_TEST_TAG = '@translationScreenshots';
export const LEGACY_TRANSLATION_TEST_SITE = 'https://stage.nora.science37.com/';
export const NEXTGEN_TRANSLATION_TEST_SITE = 'https://stage-platform.s37dev.com/';

export const LANGUAGE_LEGACY = [
  { translationCode: 'SPA', languageCode: 'SPA', language: 'Spanish', languageAbbreviation: 'es' },
  { translationCode: 'ENG', languageCode: 'ENG', language: 'English', languageAbbreviation: 'en' },
  {
    translationCode: 'CHHK',
    languageCode: 'ZHK',
    language: 'Chinese - Traditional (Hong Kong)',
    languageAbbreviation: 'zh-Hant-HK'
  },
  {
    translationCode: 'CHTA',
    languageCode: 'ZHT',
    language: 'Chinese - Traditional (Taiwan)',
    languageAbbreviation: 'zh-Hant-TW'
  },
  {
    translationCode: 'CHS',
    languageCode: 'ZHS',
    language: 'Chinese - Simplified',
    languageAbbreviation: 'zh-Hans-CN'
  },
  {
    translationCode: 'JPN',
    languageCode: 'JPN',
    language: 'Japanese',
    languageAbbreviation: 'ja-JP'
  },
  { translationCode: 'KOR', languageCode: 'KOR', language: 'Korean', languageAbbreviation: 'ko' },
  { translationCode: 'ITA', languageCode: 'ITA', language: 'Italian', languageAbbreviation: 'it' },
  { translationCode: 'FRE', languageCode: 'FRA', language: 'French', languageAbbreviation: 'fr' },
  {
    translationCode: 'ESAR',
    languageCode: 'ARG',
    language: 'Spanish - Argentina',
    languageAbbreviation: 'es-AR'
  },
  { translationCode: 'GER', languageCode: 'DEU', language: 'German', languageAbbreviation: 'de' },
  {
    translationCode: 'HUN',
    languageCode: 'HUN',
    language: 'Hungarian',
    languageAbbreviation: 'hu-HU'
  },
  {
    translationCode: 'POL',
    languageCode: 'POL',
    language: 'Polish',
    languageAbbreviation: 'pl-PL'
  },
  {
    translationCode: 'SRP',
    languageCode: 'SRB',
    language: 'Serbian',
    languageAbbreviation: 'sr-Cyrl'
  },
  {
    translationCode: 'BUL',
    languageCode: 'BGR',
    language: 'Bulgarian',
    languageAbbreviation: 'bg-BG'
  },
  {
    translationCode: 'RUM',
    languageCode: 'ROU',
    language: 'Romanian',
    languageAbbreviation: 'ro-RO'
  },
  {
    translationCode: 'LIT',
    languageCode: 'LTU',
    language: 'Lithuanian',
    languageAbbreviation: 'lt-LT'
  },
  {
    translationCode: 'PTB',
    languageCode: 'BRA',
    language: 'Portuguese - Brazil',
    languageAbbreviation: 'pt-BR'
  },
  { translationCode: 'ARA', languageCode: 'ARA', language: 'Arabic', languageAbbreviation: 'ar' },
  { translationCode: 'RUS', languageCode: 'RUS', language: 'Russian', languageAbbreviation: 'ru' },
  {
    translationCode: 'HEB',
    languageCode: 'HEB',
    language: 'Hebrew',
    languageAbbreviation: 'he-IL'
  },
  { translationCode: 'GRE', languageCode: 'GRC', language: 'Greek', languageAbbreviation: 'el-GR' },
  { translationCode: 'DUT', languageCode: 'DUT', language: 'Dutch', languageAbbreviation: 'nl-NL' },
  {
    translationCode: 'DAN',
    languageCode: 'DAN',
    language: 'Danish',
    languageAbbreviation: 'da-DK'
  },
  {
    translationCode: 'AFR',
    languageCode: 'AFR',
    language: 'Afrikaans',
    languageAbbreviation: 'af-ZA'
  },
  {
    translationCode: 'NLD',
    languageCode: 'NLD',
    language: 'Dutch - Belgium',
    languageAbbreviation: 'nl-BE'
  },
  {
    translationCode: 'SWE',
    languageCode: 'SWE',
    language: 'Swedish',
    languageAbbreviation: 'sv-SE'
  },
  {
    translationCode: 'POR',
    languageCode: 'POR',
    language: 'Portuguese',
    languageAbbreviation: 'pt-PT'
  },
  { translationCode: 'CZE', languageCode: 'CZE', language: 'Czech', languageAbbreviation: 'cs-CZ' },
  { translationCode: 'MAY', languageCode: 'MAY', language: 'Malay', languageAbbreviation: 'ms-MY' },
  { translationCode: 'TAM', languageCode: 'TAM', language: 'Tamil', languageAbbreviation: 'ta' },
  {
    translationCode: 'FRC',
    languageCode: 'CAN',
    language: 'French - Canada',
    languageAbbreviation: 'fr-CA'
  },
  {
    translationCode: 'NOB',
    languageCode: 'NOB',
    language: 'Norwegian',
    languageAbbreviation: 'nb-NO'
  },
  {
    translationCode: 'UKR',
    languageCode: 'UKR',
    language: 'Ukrainian',
    languageAbbreviation: 'uk-UA'
  },
  {
    translationCode: 'VIE',
    languageCode: 'VIE',
    language: 'Vietnamese',
    languageAbbreviation: 'vi-VN'
  },
  { translationCode: 'HIN', languageCode: 'HIN', language: 'Hindi', languageAbbreviation: 'hi-IN' },
  {
    translationCode: 'ESMX',
    languageCode: 'MEX',
    language: 'Spanish - Mexico',
    languageAbbreviation: 'es-MX'
  },
  {
    translationCode: 'TUR',
    languageCode: 'TUR',
    language: 'Turkish',
    languageAbbreviation: 'tr-TR'
  },
  { translationCode: 'THA', languageCode: 'THA', language: 'Thai', languageAbbreviation: 'th-TH' },
  {
    translationCode: 'SLO',
    languageCode: 'SLO',
    language: 'Slovak',
    languageAbbreviation: 'sk-SK'
  },
  {
    translationCode: 'ESLA',
    languageCode: 'LAT',
    language: 'Spanish - Latin America',
    languageAbbreviation: 'es-419'
  },
  {
    translationCode: 'GEO',
    languageCode: 'GEO',
    language: 'Georgian',
    languageAbbreviation: 'ka-GE'
  },
  {
    translationCode: 'SLV',
    languageCode: 'SLV',
    language: 'Slovenian',
    languageAbbreviation: 'sl-SI'
  },
  {
    translationCode: 'ICE',
    languageCode: 'ICE',
    language: 'Icelandic',
    languageAbbreviation: 'is-IS'
  },
  {
    translationCode: 'FIN',
    languageCode: 'FIN',
    language: 'Finnish',
    languageAbbreviation: 'fi-FI'
  },
  {
    translationCode: 'TGL',
    languageCode: 'TGL',
    language: 'Filipino',
    languageAbbreviation: 'tl-PH'
  },
  { translationCode: 'URD', languageCode: 'URD', language: 'Urdu', languageAbbreviation: 'ur-PK' },
  {
    translationCode: 'XHO',
    languageCode: 'XHO',
    language: 'Xhosa',
    languageAbbreviation: 'xho-ZA'
  },
  { translationCode: 'ZUL', languageCode: 'ZUL', language: 'Zulu', languageAbbreviation: 'zu-ZA' },
  {
    translationCode: 'ASM',
    languageCode: 'ASM',
    language: 'Assamese',
    languageAbbreviation: 'as-IN'
  },
  {
    translationCode: 'GUJ',
    languageCode: 'GUJ',
    language: 'Gujarati',
    languageAbbreviation: 'gu-IN'
  },
  {
    translationCode: 'LAV',
    languageCode: 'LAV',
    language: 'Latvian',
    languageAbbreviation: 'lv-LV'
  },
  {
    translationCode: 'EST',
    languageCode: 'EST',
    language: 'Estonian',
    languageAbbreviation: 'et-EE'
  },
  {
    translationCode: 'MAR',
    languageCode: 'MAR',
    language: 'Marathi',
    languageAbbreviation: 'mr-IN'
  },
  {
    translationCode: 'KAN',
    languageCode: 'KAN',
    language: 'Kannada',
    languageAbbreviation: 'kn-IN'
  },
  {
    translationCode: 'TEL',
    languageCode: 'TEL',
    language: 'Telugu',
    languageAbbreviation: 'te-IN'
  },
  {
    translationCode: 'BEN',
    languageCode: 'BEN',
    language: 'Bengali',
    languageAbbreviation: 'bn'
  }
];

// GET: https://acme.stage-platform.s37dev.com/api/v1/platform/reference/languages
export const LANGUAGE_NEXT_GEN = [
  {
    languageId: '3be006fb-50c2-548a-823c-2320a6d28446',
    uiLanguageCode: 'zh-Hans',
    languageName: 'Chinese (Simplified Han, China)',
    formLanguageCode: 'zh-Hans-CN'
  },
  {
    languageId: 'b60388f7-2623-5330-9de3-f52ead8ea7a6',
    uiLanguageCode: 'zh-Hans',
    languageName: 'Chinese (Simplified Han, Malaysia)',
    formLanguageCode: 'zh-Hans-MY'
  },
  {
    languageId: 'd9c38c35-92b1-516b-bb6b-e68c9c532c8e',
    uiLanguageCode: 'zh-Hans',
    languageName: 'Chinese (Simplified Han, Singapore)',
    formLanguageCode: 'zh-Hans-SG'
  },
  {
    languageId: '8a87a62e-6e4a-5f46-8444-a6b09f79925f',
    uiLanguageCode: 'zh-Hant',
    languageName: 'Chinese (Traditional Han, Hong Kong)',
    formLanguageCode: 'zh-Hant-HK'
  },
  {
    languageId: '301ac0e4-989b-5589-a627-99ba4c20c378',
    uiLanguageCode: 'zh-Hant',
    languageName: 'Chinese (Traditional Han, Taiwan)',
    formLanguageCode: 'zh-Hant-TW'
  },
  {
    languageId: '0816de02-0a35-592a-a7c5-020575a19a23',
    uiLanguageCode: 'cs',
    languageName: 'Czech',
    formLanguageCode: 'cs-CZ'
  },
  {
    languageId: '63716b83-4cb2-501c-87ef-f6b838a26c6e',
    uiLanguageCode: 'da',
    languageName: 'Danish',
    formLanguageCode: 'da-DK'
  },
  {
    languageId: 'fe8cc335-26e9-578c-a05b-a5efa1e3906e',
    uiLanguageCode: 'nl',
    languageName: 'Dutch (Netherlands)',
    formLanguageCode: 'nl-NL'
  },
  {
    languageId: 'e80d79b5-bb07-46b2-9842-3b8135ff4adc',
    uiLanguageCode: 'en',
    languageName: 'English',
    formLanguageCode: 'en'
  },
  {
    languageId: 'c7dd9570-af6c-5532-b945-b63b74ac5fe3',
    uiLanguageCode: 'en-GB',
    languageName: 'English (Australia)',
    formLanguageCode: 'en-AU'
  },
  {
    languageId: '46aada48-d4f2-54dd-b4f9-7285f8c81794',
    uiLanguageCode: 'en-GB',
    languageName: 'English (United Kingdom)',
    formLanguageCode: 'en-GB'
  },
  {
    languageId: 'fe90b1c0-4a20-5b21-b5a0-8b3efbd1f597',
    uiLanguageCode: 'en-US',
    languageName: 'English (United States)',
    formLanguageCode: 'en-US'
  },
  {
    languageId: 'cdae4bea-d4e8-5d2a-9e07-b49d7e58bbf2',
    uiLanguageCode: 'en-GB',
    languageName: 'European English',
    formLanguageCode: 'en-150'
  },
  {
    languageId: '5a744b08-5cf4-5e59-a94e-b0e3c4826cc0',
    uiLanguageCode: 'pt-PT',
    languageName: 'European Portuguese',
    formLanguageCode: 'pt-PT'
  },
  {
    languageId: 'a7a732a1-32d9-5d9d-bd38-dd1a175ee055',
    uiLanguageCode: 'fi',
    languageName: 'Finnish',
    formLanguageCode: 'fi-FI'
  },
  {
    languageId: '46486885-fac5-5e5f-8667-577d0147d290',
    uiLanguageCode: 'nl-BE',
    languageName: 'Flemish',
    formLanguageCode: 'nl-BE'
  },
  {
    languageId: 'ce7b3876-dab0-5449-a9f5-b63bdbd10e25',
    uiLanguageCode: 'fr',
    languageName: 'French',
    formLanguageCode: 'fr-FR'
  },
  {
    languageId: 'c6364c2d-e82d-5c15-a899-0793c270de56',
    uiLanguageCode: 'fr',
    languageName: 'French (Belgium)',
    formLanguageCode: 'fr-BE'
  },
  {
    languageId: '536b3986-deec-49d3-b114-17007abe76f7',
    uiLanguageCode: 'fr-CA',
    languageName: 'French (Canada)',
    formLanguageCode: 'fr-CA'
  },
  {
    languageId: 'fb12ca3a-44df-57a5-9dba-99c576bd8314',
    uiLanguageCode: 'fr',
    languageName: 'French (Switzerland)',
    formLanguageCode: 'fr-CH'
  },
  {
    languageId: 'a7fb9f8b-55a9-46b8-a665-af146bf89059',
    uiLanguageCode: 'de',
    languageName: 'German',
    formLanguageCode: 'de-DE'
  },
  {
    languageId: 'e8f38996-3085-5023-8732-a7b867b99e56',
    uiLanguageCode: 'de',
    languageName: 'German (Austria)',
    formLanguageCode: 'de-AT'
  },
  {
    languageId: '56b91915-98c9-532b-b5b4-ba5ba561c9ba',
    uiLanguageCode: 'el',
    languageName: 'Greek',
    formLanguageCode: 'el-GR'
  },
  {
    languageId: '2d4bcd3b-5965-5375-9ed9-044167d3b265',
    uiLanguageCode: 'hu',
    languageName: 'Hungarian',
    formLanguageCode: 'hu-HU'
  },
  {
    languageId: '042f08fd-6289-5bdd-bfd2-3a6f84ddfe0a',
    uiLanguageCode: 'it',
    languageName: 'Italian',
    formLanguageCode: 'it-IT'
  },
  {
    languageId: 'cbac4779-819f-5050-98dc-a347a5ee8239',
    uiLanguageCode: 'it',
    languageName: 'Italian (Switzerland)',
    formLanguageCode: 'it-CH'
  },
  {
    languageId: 'b319b494-93aa-464a-8d50-7f043313fd50',
    uiLanguageCode: 'ja',
    languageName: 'Japanese',
    formLanguageCode: 'ja-JP'
  },
  {
    languageId: '3a827927-3637-569d-ae16-f34931dcc600',
    uiLanguageCode: 'ko',
    languageName: 'Korean',
    formLanguageCode: 'ko-KR'
  },
  {
    languageId: '44894438-3c92-5b44-9f43-37389d9506ba',
    uiLanguageCode: 'ms',
    languageName: 'Malay',
    formLanguageCode: 'ms-MY'
  },
  {
    languageId: 'c8f0eafd-1bf7-5bcc-8fef-f4b64bb808e4',
    uiLanguageCode: 'ms',
    languageName: 'Malay (Singapore)',
    formLanguageCode: 'ms-SG'
  },
  {
    languageId: '85445cf8-fc90-522d-9fdb-c5f9e109d8e9',
    uiLanguageCode: 'nb',
    languageName: 'Norwegian Bokmål',
    formLanguageCode: 'nb-NO'
  },
  {
    languageId: '4f0e6292-a9b1-58f8-ae23-0feb6ed93790',
    uiLanguageCode: 'pl',
    languageName: 'Polish',
    formLanguageCode: 'pl-PL'
  },
  {
    languageId: '7c8d0ba3-bf71-566a-b307-468fab3a7345',
    uiLanguageCode: 'pt-BR',
    languageName: 'Portuguese (Brazil)',
    formLanguageCode: 'pt-BR'
  },
  {
    languageId: 'ad73c635-0111-5f19-a3f7-adaecea3077a',
    uiLanguageCode: 'ro',
    languageName: 'Romanian',
    formLanguageCode: 'ro-RO'
  },
  {
    languageId: '2b42fefe-d3be-52cd-b2fe-443762460dc8',
    uiLanguageCode: 'ru',
    languageName: 'Russian',
    formLanguageCode: 'ru-RU'
  },
  {
    languageId: 'a7997e5f-059d-4118-b8dc-2367edb7ab62',
    uiLanguageCode: 'es-419',
    languageName: 'Spanish (Latin America)',
    formLanguageCode: 'es-419'
  },
  {
    languageId: 'c8614097-2d96-5536-b8c9-7d4248fd009f',
    uiLanguageCode: 'es-419',
    languageName: 'Spanish (Mexico)',
    formLanguageCode: 'es-MX'
  },
  {
    languageId: 'f577d0d0-2d6e-59e4-b87a-f1b1aa887813',
    uiLanguageCode: 'es-419',
    languageName: 'Spanish (Peru)',
    formLanguageCode: 'es-PE'
  },
  {
    languageId: '941b88a3-c8f9-53ca-ae01-a3296bab9dcf',
    uiLanguageCode: 'es-ES',
    languageName: 'Spanish (Spain)',
    formLanguageCode: 'es-ES'
  },
  {
    languageId: '46d98a67-b4d9-4ef0-a6b7-f6068e15a150',
    uiLanguageCode: 'es-US',
    languageName: 'Spanish (United States)',
    formLanguageCode: 'es-US'
  },
  {
    languageId: 'fd1bd322-f3c3-568b-abc1-2621fc79d4df',
    uiLanguageCode: 'sv',
    languageName: 'Swedish',
    formLanguageCode: 'sv-SE'
  },
  {
    languageId: 'ef9b132d-f593-5cdf-ad28-f7ea43af3ed0',
    uiLanguageCode: 'de',
    languageName: 'Swiss German',
    formLanguageCode: 'de-CH'
  },
  {
    languageId: 'a7e2833e-0e39-58a2-b058-bc8b71ab8e83',
    uiLanguageCode: 'th',
    languageName: 'Thai',
    formLanguageCode: 'th-TH'
  },
  {
    languageId: 'e9afcc1e-0d5c-5b4a-9e29-c5a0af72bc5b',
    uiLanguageCode: 'tr',
    languageName: 'Turkish',
    formLanguageCode: 'tr-TR'
  },
  {
    languageId: '822e77ac-7022-56d8-afdb-6535612c0d7d',
    uiLanguageCode: 'uk',
    languageName: 'Ukrainian',
    formLanguageCode: 'uk-UA'
  },
  {
    languageId: 'bc6d9a14-4823-534f-8d09-95d6b7583405',
    uiLanguageCode: 'vi',
    languageName: 'Vietnamese',
    formLanguageCode: 'vi-VN'
  },
  {
    languageId: 'da7424f3-75ae-5bb0-b927-c91d2c39d5d3',
    uiLanguageCode: 'en-US',
    languageName: 'World English',
    formLanguageCode: 'en-001'
  }
];

export const languageCompare = function(a, b) {
  if (a.language < b.language) {
    return -1;
  }
  if (a.language > b.language) {
    return 1;
  }
  return 0;
};

export const nextGenLanguageCompare = function(a, b) {
  if (a.languageName < b.languageName) {
    return -1;
  }
  if (a.languageName > b.languageName) {
    return 1;
  }
  return 0;
};
