import React from 'react';
import { withStyles, Card, CardContent } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CLEAR_FILTER, UPDATE_FILTER } from '../../redux/actions';

import styles from './styles';

class InfoCard extends React.Component {
  render() {
    const { classes, title, description, sideContent, summary } = this.props;
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <h2 className={classes.cardCategory}>{title}</h2>
          <h2 className={classes.cardTitle}>{description}</h2>
          {sideContent}
          <div
            style={{
              display: 'flex',
              flexFlow: 'row wrap'
            }}
          >
            {summary}
          </div>
        </CardContent>
      </Card>
    );
  }
}

InfoCard.defaultProps = {
  iconColor: 'purple',
  statIconColor: 'gray'
};

const mapStateToProps = (state) => {
  return {
    filter: state.filter
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateFilter: (filter) => {
    dispatch({ type: UPDATE_FILTER, payload: filter });
  },
  clearFilter: () => {
    dispatch({ type: CLEAR_FILTER });
  }
});

InfoCard.propTypes = {
  classes: PropTypes.object.isRequired,
  iconColor: PropTypes.oneOf(['orange', 'green', 'red', 'blue', 'purple']),
  title: PropTypes.node,
  description: PropTypes.node,
  sideContent: PropTypes.node,
  statIconColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray'
  ]),
  statLink: PropTypes.object,
  statText: PropTypes.node
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(InfoCard);
