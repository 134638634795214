import { PRINT_FETCH_REQUESTED, PRINT_FETCH_FAILED, PRINT_FETCH_SUCCEEDED } from '../actions';

const defaultState = {
  fetching: false,
  error: false,
  result: {}
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case PRINT_FETCH_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case PRINT_FETCH_SUCCEEDED:
      return {
        ...state,
        result: action.payload && action.payload[0],
        error: false,
        fetching: false
      };
    case PRINT_FETCH_FAILED:
      return {
        ...state,
        result: {},
        fetching: false,
        error: true
      };
    default:
      return state;
  }
}
