export default (theme) => {
  return {
    chip: {
      backgroundColor: 'white'
    },
    dynamicTagContainer: {
      width: '50%',
      position: 'relative'
    },
    pop: {
      marginTop: '8px',
      overflow: 'auto',
      maxHeight: '300px'
    },
    popItem: {
      minWidth: '200px'
    }
  };
};
