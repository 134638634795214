import React, { Component } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { FailColor, PassColor, SkipColor } from '../../assets/jss/colors';
const COLORS = [FailColor, PassColor, SkipColor];

const chartStyle = {
  //margin: 'auto'
};

class DonutChart extends Component {
  getData(testRun) {
    const failed = parseInt(testRun.failed, 10);
    const pending = parseInt(testRun.pending, 10);
    const passed = parseInt(testRun.passed, 10);
    const data = [
      { name: 'Failed', value: failed },
      { name: 'Passed', value: passed },
      { name: 'Skipped', value: pending }
    ];
    return data;
  }

  render() {
    const { testRun } = this.props;
    const data = this.getData(testRun);
    return (
      <PieChart style={chartStyle} width={40} height={40} onMouseEnter={this.onPieEnter}>
        <Pie
          isAnimationActive={false}
          dataKey="value"
          data={data}
          cx={10}
          cy={15}
          innerRadius={5}
          outerRadius={15}
          fill="#8884d8"
          paddingAngle={2}
        >
          {data.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    );
  }
}

export default DonutChart;
