import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  Grid,
  CircularProgress,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table
} from '@material-ui/core';

import { TOP_FAILS_FETCH_REQUESTED } from '../../redux/actions';
import PropTypes from 'prop-types';
import { MainCard } from 'heimdall-components';

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  },
  progress: {
    margin: '20px auto',
    display: 'block'
  }
});

const CustomTableCell = withStyles((theme) => ({
  head: {
    //backgroundColor: '#FF5252',
    //color: theme.palette.common.white,
    textAlign: 'left',
    fontSize: 14,
    minWidth: '150px'
  },
  body: {
    paddingLeft: '20px',
    fontSize: 14
  }
}))(TableCell);

class TopFails extends React.Component {
  constructor(props) {
    super(props);
    const { fails } = this.props;

    if (fails.length <= 0) {
      this.props.requestFails();
    }
  }

  renderHistoryLink(id) {
    if (id) {
      return <Link to={`/history/${id}`}>{id}</Link>;
    }
  }

  renderRows() {
    const { fails, classes } = this.props;
    return fails.map((fail, idx) => {
      return (
        <TableRow className={classes.row} key={idx}>
          <CustomTableCell scope="row">{this.renderHistoryLink(fail.id)}</CustomTableCell>
          <CustomTableCell>{fail.title}</CustomTableCell>
          <CustomTableCell>{fail.fail_count}</CustomTableCell>
        </TableRow>
      );
    });
  }

  renderHeader() {
    return (
      <TableHead>
        <TableRow>
          <CustomTableCell>Test ID</CustomTableCell>
          <CustomTableCell>Title</CustomTableCell>
          <CustomTableCell>Fail Count</CustomTableCell>
        </TableRow>
      </TableHead>
    );
  }

  renderContent() {
    const { fails, classes } = this.props;

    if (fails.length > 0) {
      return (
        <Paper className={classes.root}>
          <Table>
            {this.renderHeader()}
            <TableBody>{this.renderRows()}</TableBody>
          </Table>
        </Paper>
      );
    } else {
      return <CircularProgress size={50} color="primary" className={classes.progress} />;
    }
  }

  render() {
    return (
      <MainCard title="Top Failed Tests" content={<Grid container>{this.renderContent()}</Grid>} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fails: state.testRuns.fails
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestFails: () => {
    dispatch({ type: TOP_FAILS_FETCH_REQUESTED });
  }
});

TopFails.propTypes = {
  classes: PropTypes.object.isRequired,
  fails: PropTypes.array,
  requestFails: PropTypes.func
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TopFails);
