import { TAB_ZERO_CLICKED, TAB_ONE_CLICKED, TAB_TWO_CLICKED } from '../actions';

const defaultState = {
  active: 0
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case TAB_ZERO_CLICKED:
      return {
        active: 0
      };
    case TAB_ONE_CLICKED:
      return {
        active: 1
      };
    case TAB_TWO_CLICKED:
      return {
        active: 2
      };
    default:
      return state;
  }
}
