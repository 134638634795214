import {
  API_REQUEST_LOG_FETCH_SUCCEEDED,
  API_REQUEST_LOG_FETCH_FAILED,
  API_REQUEST_LOG_FETCH_REQUESTED
} from '../actions';

const defaultState = {
  fetching: false,
  error: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case API_REQUEST_LOG_FETCH_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case API_REQUEST_LOG_FETCH_SUCCEEDED:
      const testResult = action.payload.data;
      const key = action.payload.key;
      return {
        ...state,
        [key]: testResult,
        error: false,
        fetching: false
      };
    case API_REQUEST_LOG_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        error: true
      };
    default:
      return state;
  }
}
