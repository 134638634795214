import { LOAD_BUILDS_DIALOG, LOAD_BUILDS_DIALOG_CLOSE } from '../actions';

const defaultState = {
  open: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOAD_BUILDS_DIALOG:
      return {
        ...state,
        open: true
      };
    case LOAD_BUILDS_DIALOG_CLOSE:
      return { ...state, open: false };
    default:
      return state;
  }
}
