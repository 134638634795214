export const API_ENV = [
  {
    value: 'dev1_global',
    host: 'https://dev1-platform.s37dev.com',
    label: 'NextGen Global Dev1 - dev1-platform'
  },
  {
    value: 'stage_global',
    host: 'https://stage-platform.s37dev.com',
    label: 'NextGen Global Stage - stage-platform'
  },
  {
    value: 'dev2_global',
    host: 'https://dev2-platform.s37dev.com',
    label: 'NextGen Global Dev2 - dev2-platform'
  },
  {
    value: 'dev1_us-w2_cloudhub',
    host: 'https://dev1.us-w2.cloudhub.io',
    label: 'Dev1 us-w2 CloudHub'
  },
  {
    value: 'dev1-eu-de_de-c1_cloudhub',
    host: 'https://dev1-eu-de.de-c1.cloudhub.io',
    label: 'Dev1-eu-de de-c1 CloudHub'
  },
  {
    value: 'dev2_us-w2_cloudhub',
    host: 'https://dev2.us-w2.cloudhub.io',
    label: 'Dev2 us-w2 CloudHub'
  },
  {
    value: 'legacy_dev',
    host: 'https://dev.nora.science37.com',
    label: 'Legacy Dev - dev.nora',
    dbTest: true
  },
  {
    value: 'Other',
    host: 'Other',
    label: 'Other'
  }
];

export const TEAM = [
  {
    label: 'NextGen',
    value: 'nextGen'
  },
  {
    label: 'Legacy',
    value: 'legacy'
  },
  {
    label: 'MySQL DB',
    value: 'db'
  },
  {
    label: 'MSRC - POC',
    value: 'poc'
  },
  {
    label: 'Data Harmonization 3',
    value: 'dh3'
  },
  {
    label: 'Data Harmonization 4',
    value: 'dh4'
  }
];

export const LOAD_TEST_FILES = {
  nextGen: [
    {
      label: 'ALL',
      value: 'locust_file_next_gen.py'
    },
    {
      label: 'Admin',
      value: 'test_files/next_gen/admin.py'
    },
    {
      label: 'Form',
      value: 'test_files/next_gen/form.py'
    },
    {
      label: 'Form - Large Payload',
      value: 'test_files/next_gen/form_large.py'
    },
    {
      label: 'Patient',
      value: 'test_files/next_gen/patient.py'
    },
    {
      label: 'Coordinator Save Form',
      value: 'test_files/next_gen/coordinatorSaveForm.py'
    },
    {
      label: 'Patient Save Form',
      value: 'test_files/next_gen/patientSaveForm.py'
    },
    {
      label: 'Patient Save Form Only',
      value: 'test_files/next_gen/patientSaveFormOnly.py'
    },
    {
      label: 'Visit Plan',
      value: 'test_files/next_gen/visitPlan.py'
    },
    {
      label: 'Care Plan',
      value: 'test_files/next_gen/carePlan.py'
    },
    {
      label: 'Get All Participants',
      value: 'test_files/next_gen/getAllPatients.py'
    },
    {
      label: 'Audit Trail',
      value: 'test_files/next_gen/auditTrail.py'
    }
  ],
  legacy: [
    {
      label: 'ALL',
      value: 'locust_file_legacy.py'
    },
    {
      label: 'Coordinator Workflow',
      value: 'test_files/legacy/coordinatorWorkflow.py'
    }
  ],
  db: [
    {
      label: 'ALL',
      value: 'locust_file_db.py'
    },
    {
      label: 'Custom DB Test',
      value: 'test_files/db/customDBTest.py'
    }
  ],
  poc: [
    {
      label: 'ALL',
      value: 'locust_file_poc.py'
    },
    {
      label: 'Language Test',
      value: 'test_files/msrc_poc/language.py'
    }
  ],
  dh3: [
    {
      label: 'ALL',
      value: 'locust_file_dh3.py'
    },
    {
      label: 'Standard Reports',
      value: 'test_files/dh3/standardReports.py'
    },
    {
      label: 'Studies',
      value: 'test_files/dh3/studies.py'
    },
    {
      label: 'Study Form Reports',
      value: 'test_files/dh3/studyFormReports.py'
    }
  ],
  dh4: [
    {
      label: 'ALL',
      value: 'locust_file_dh4.py'
    },
    {
      label: 'Study Site Public API',
      value: 'test_files/dh4/studySitePublicApi.py'
    },
    {
      label: 'Study Visit Plan Public API',
      value: 'test_files/dh4/studyVisitPlanPublicApi.py'
    },
    {
      label: 'Study Form Responses Public API',
      value: 'test_files/dh4/studyFormResponsesPublicApi.py'
    }
  ]
};

export const LOAD_LOCATION = [
  {
    label: 'US (Oregon)',
    value: 'us-west-2'
  },
  {
    label: 'EUROPE (Frankfurt)',
    value: 'eu-central-1'
  },
  {
    label: 'Asia (Singapore)',
    value: 'ap-southeast-1'
  }
];
