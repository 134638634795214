import { LOGIN_LOGGED_IN, LOGIN_LOGGED_OUT, LOGIN_USER_UPDATE } from '../actions';

const defaultState = {
  authState: 'logged_out',
  hideSignUp: true,
  userData: {}
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case LOGIN_LOGGED_IN:
      return {
        ...state,
        userData: action.payload,
        authState: 'logged_in'
      };
    case LOGIN_LOGGED_OUT:
      return { defaultState };
    case LOGIN_USER_UPDATE:
      return {
        ...state,
        userData: { ...state.userData, ...action.payload }
      };
    default:
      return state;
  }
}
