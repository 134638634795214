import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { FormControl } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { TRACEABILITY_RMS_UPDATE_REQUESTED } from '../../redux/actions';

const useStyles = makeStyles(styles);

export default function RmsAction ({ rmsDetail }) {
  const cs = useStyles();
  const [id, setId]= useState();
  const [note, setNote]= useState("");
  const [noTestNeeded, setNoTestNeeded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setNote(rmsDetail.note);
    setNoTestNeeded(!!+rmsDetail.noTestNeeded);
    setId(rmsDetail.id);
  }, [rmsDetail]);

  useEffect(() => {
    setNote(rmsDetail.note);
    setNoTestNeeded(!!+rmsDetail.noTestNeeded);
    setId(rmsDetail.id);
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setNote(rmsDetail.note);
    setNoTestNeeded(!!+rmsDetail.noTestNeeded);
    setId(rmsDetail.id);
  };

  const handleClose = () => {
    setNote("");
    setNoTestNeeded(false);
    setId(null);
    setOpen(false);
  };

  const handleSubmit = () => {
    const payload = {
      note,
      noTestNeeded
    }
    dispatch({ type: TRACEABILITY_RMS_UPDATE_REQUESTED, payload: { payload, id } });
  };

  return (<div>
      <IconButton color="primary" aria-label="Edit RMS Detail" onClick={handleClickOpen}>
        <EditRoundedIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update: {rmsDetail.rms}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {rmsDetail.summary && rmsDetail.summary}
          </DialogContentText>

          <FormControl className={cs.formControl}>
            <TextField
              label="Note"
              id="note"
              multiline
              rows={3}
              onChange={(e)=> {
                setNote(e.target.value)
              }}
              value={note || ""}
            />
          </FormControl>
          <div className={cs.groupOption}>
            <Switch
              className={cs.alignCenter}
              checked={noTestNeeded || false}
              onChange={(e) => setNoTestNeeded(e.target.checked)}
              name="noTestNeeded"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <div className={cs.switchText}>No Automation for this RMS</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


