import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CircularProgress, Button, withStyles } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { MainCard, TestResultsDialog, APITestResultsDialog } from 'heimdall-components';
import { ScrollableTabs } from '../../components';
import _ from 'lodash';

import {
  TEST_RESULTS_DIALOG_OPEN,
  API_TEST_RESULTS_DIALOG_OPEN,
  TEST_RUNS_FETCH_REQUESTED,
  API_TEST_RUNS_FETCH_REQUESTED,
  TAB_ZERO_CLICKED,
  LOAD_TEST_RUNS_FETCH_REQUESTED
} from '../../redux/actions';

import RunsTable from './RunsTable';
import styles from './styles';
import { regionToLocationMapping } from '../../lib/utils';

class TestRunsPage extends React.Component {
  constructor(props) {
    super(props);

    const testRunKey = this.props.match.params.key || '';
    const testKey = this.props.match.params.test_key || '';
    const params = new URLSearchParams(window.location.search);
    const backend = params.has('be');

    if (testRunKey.length > 0) {
      if (backend) {
        this.props.openAPITestResult(testRunKey);
      } else {
        this.props.openTestResult(testRunKey, testKey);
      }
    }
  }

  componentDidMount() {
    this.props.defaultTab();
  }
  handleTestRunClicked = () => {
    this.props.onTestRunsRequestClicked();
  };

  handleAPITestRunClicked = () => {
    this.props.onAPITestRunsRequestClicked();
  };
  handleLoadTestRunClicked = () => {
    this.props.onLoadTestRunsRequestClicked();
  };

  handleTestRunDetailClick = (key) => {
    this.props.openTestResult(key);
  };

  handleAPITestRunDetailClick = (key) => {
    this.props.openAPITestResult(key);
  };

  parseTestRuns() {
    const { testRuns } = this.props;
    return _.map(testRuns, (r) => {
      return [
        r.is_china_region || false,
        r.test_run_name || '',
        r.nora_site || '',
        r.executor || '',
        r.total || '',
        r.passed || '',
        r.failed || 0,
        r.pending || 0,
        r || [],
        r.test_run_key || 0
      ];
    });
  }

  parseAPITestRuns() {
    const { apiTestRuns } = this.props;
    return _.map(apiTestRuns, (r) => {
      return [
        r.is_china_region || false,
        r.test_run_name || '',
        r.env || r.baseurl || '',
        r.executor || '',
        r.total || '',
        r.passed || '',
        r.failed || 0,
        r.pending || 0,
        r || [],
        r.test_run_key || 0
      ];
    });
  }

  parseLoadTestRuns() {
    const { loadTestRuns } = this.props;
    return _.map(loadTestRuns, (r) => {
      return [
        r.env ? r.env.toUpperCase() : '',
        r.host || '',
        r.test_file || '',
        r.executor || '',
        r.users || '',
        r.run_time || '',
        r.spawn_rate ? `${r.spawn_rate} users/sec` : '',
        r.load_region
          ? regionToLocationMapping(r.load_region)
          : regionToLocationMapping('us-west-2'),
        r.created_at,
        r.report_location || 0
      ];
    });
  }

  renderTestRunContent() {
    const { testRuns, classes, fetchingError, fetching, classes: cs } = this.props;
    if (testRuns.length > 0) {
      return <RunsTable data={this.parseTestRuns()} onClick={this.handleTestRunDetailClick} />;
    } else if (fetchingError) {
      return (
        <div className={cs.errorMessage}>
          Error retrieving run list. Please try again and refresh.
        </div>
      );
    } else if (fetching) {
      return <CircularProgress size={50} color="primary" className={classes.progress} />;
    }
  }

  renderAPITestRunContent() {
    const { apiTestRuns, classes, apiFetching, apiError, classes: cs } = this.props;
    if (apiTestRuns && apiTestRuns.length > 0) {
      return (
        <RunsTable data={this.parseAPITestRuns()} onClick={this.handleAPITestRunDetailClick} />
      );
    } else if (apiError) {
      return (
        <div className={cs.errorMessage}>
          Error retrieving run list. Please try again and refresh.
        </div>
      );
    } else if (apiFetching) {
      return <CircularProgress size={50} color="primary" className={classes.progress} />;
    }
  }

  renderLoadTestRunContent() {
    const { loadTestRuns, classes, loadFetching, loadError, classes: cs } = this.props;
    if (loadTestRuns && loadTestRuns.length > 0) {
      return <RunsTable data={this.parseLoadTestRuns()} isLoad={true} />;
    } else if (loadError) {
      return (
        <div className={cs.errorMessage}>
          Error retrieving run list. Please try again and refresh.
        </div>
      );
    } else if (loadFetching) {
      return <CircularProgress size={50} color="primary" className={classes.progress} />;
    }
  }

  render({ ...props }) {
    const { classes: cs, activeTab } = this.props;
    let handleOnClick;
    if (activeTab === 0) {
      handleOnClick = this.handleTestRunClicked;
    } else if (activeTab === 1) {
      handleOnClick = this.handleAPITestRunClicked;
    } else {
      handleOnClick = this.handleLoadTestRunClicked;
    }
    return (
      <React.Fragment>
        <MainCard
          title="Test Reports"
          headerAction={
            <React.Fragment>
              <Button
                onClick={handleOnClick}
                className={cs.headerButton}
                color="primary"
                variant="outlined"
              >
                Refresh
                <Refresh className={cs.headerIcon} />
              </Button>
            </React.Fragment>
          }
        />
        <ScrollableTabs
          tab1Name="Frontend"
          tab1Component={<React.Fragment>{this.renderTestRunContent()}</React.Fragment>}
          tab2Name="Backend"
          tab2Component={<React.Fragment>{this.renderAPITestRunContent()}</React.Fragment>}
          tab3Name="Load"
          tab3Component={<React.Fragment>{this.renderLoadTestRunContent()}</React.Fragment>}
        />
        <TestResultsDialog />
        <APITestResultsDialog />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testResults: state.testRuns.testResults,
    testRuns: state.testRuns.testRuns,
    fetching: state.testRuns.test_runs_fetching,
    fetchingError: state.testRuns.test_runs_error,
    apiTestRuns: state.apiTestRuns.testRuns,
    apiFetching: state.apiTestRuns.test_runs_fetching,
    apiError: state.apiTestRuns.test_runs_error,
    loadTestRuns: state.loadTestRuns.testRuns,
    loadFetching: state.loadTestRuns.test_runs_fetching,
    loadError: state.loadTestRuns.test_runs_error,
    activeTab: state.tab.active
  };
};

const mapDispatchToProps = (dispatch) => ({
  openTestResult: (test_run_key, test_key) => {
    dispatch({ type: TEST_RESULTS_DIALOG_OPEN, payload: { test_run_key, test_key } });
  },
  openAPITestResult: (test_run_key) => {
    dispatch({ type: API_TEST_RESULTS_DIALOG_OPEN, payload: { test_run_key } });
  },
  onTestRunsRequestClicked: () => {
    dispatch({ type: TEST_RUNS_FETCH_REQUESTED, payload: true });
  },
  onAPITestRunsRequestClicked: () => {
    dispatch({ type: API_TEST_RUNS_FETCH_REQUESTED, payload: true });
  },
  onLoadTestRunsRequestClicked: () => {
    dispatch({ type: LOAD_TEST_RUNS_FETCH_REQUESTED, payload: true });
  },
  defaultTab: () => {
    dispatch({ type: TAB_ZERO_CLICKED });
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(TestRunsPage);
