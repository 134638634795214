import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Tooltip, Button } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';

const options = {
  filterType: 'checkbox',
  selectableRows: false,
  download: false,
  print: false,
  responsive: 'scroll',
  rowsPerPage: 15,
  rowsPerPageOptions: [10, 15, 20, 30, 100]
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: '20px'
      }
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        padding: '0 0 0 25px'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        maxWidth: '240px',
        width: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '20px 10px 10px 20px',
        '&:nth-child(2)': { width: '6%' },
        '&:nth-child(4)': { width: '8%' },
        '&:nth-child(6)': { width: '6%' },
        '&:nth-child(8)': { width: '6%' },
        '&:nth-child(10)': { width: '6%' },
        '&:nth-child(12)': { width: '6%' },
        '&:nth-child(14)': { width: '6%' },
        '&:nth-child(16)': { width: '6%' },
        '&:nth-child(18)': { width: '6%' },
        '&:last-child': { paddingRight: 0 }
      }
    }
  }
});

function getColumnConfig(isChina) {
  return [
    {
      name: 'Name',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Env',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Executor',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Tag',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Branch',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Phase',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Status',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Start Time',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Time',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let codebuildLink = `https://us-west-2.console.aws.amazon.com/codebuild/home?region=us-west-2#/builds/${value}/view/new`;
          if (isChina) {
            codebuildLink = `https://cn-northwest-1.console.amazonaws.cn/codebuild/home?region=cn-northwest-1#/builds/${value}/view/new`;
          }
          return (
            <Tooltip title="Open Codebuild log">
              <Button color="primary" target="_blank" href={codebuildLink}>
                <OpenInNew />
              </Button>
            </Tooltip>
          );
        }
      }
    }
  ];
}

function getAPIColumnConfig() {
  return [
    {
      name: 'Name',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Env',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Executor',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Tag',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Branch',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Phase',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Status',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Start Time',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Time',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const codebuildLink = `https://us-west-2.console.aws.amazon.com/codebuild/home?region=us-west-2#/builds/${value}/view/new`;
          return (
            <Tooltip title="Open Codebuild log">
              <Button color="primary" target="_blank" href={codebuildLink}>
                <OpenInNew />
              </Button>
            </Tooltip>
          );
        }
      }
    }
  ];
}

function getLoadColumnConfig() {
  return [
    {
      name: 'ENV',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Host',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Executor',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Test File',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Users',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Duration',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Spawn Rate',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Branch',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Phase',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Status',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Start Time',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Time',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const codebuildLink = `https://us-west-2.console.aws.amazon.com/codebuild/home?region=us-west-2#/builds/${value}/view/new`;
          return (
            <Tooltip title="Open Codebuild log">
              <Button color="primary" target="_blank" href={codebuildLink}>
                <OpenInNew />
              </Button>
            </Tooltip>
          );
        }
      }
    }
  ];
}
export default (props) => {
  const { data, isAPI, isLoad, hot, isChina } = props;
  let getConfig;
  if (isAPI) {
    getConfig = getAPIColumnConfig();
  } else if (isLoad) {
    getConfig = getLoadColumnConfig();
  } else {
    getConfig = getColumnConfig(isChina);
  }
  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable options={options} columns={getConfig} data={data} />
    </MuiThemeProvider>
  );
};
