import React from 'react';
import moment from 'moment';
import path from 'path';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RESULTS_DETAIL_DIALOG_OPEN, TEST_RESULTS_DIALOG_CLOSE } from '../../redux/actions';
import { withStyles, Button } from '@material-ui/core';
import PassIcon from '@material-ui/icons/CheckCircle';
import FailIcon from '@material-ui/icons/HighlightOff';
import SkipIcon from '@material-ui/icons/PauseCircleOutline';
import DetailsIcon from '@material-ui/icons/ChevronRight';
import TestHistoryIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

import DynamicTag from '../DynamicTag';

import styles from './styles';

const TIMESTAMP_FORMAT = 'YYYY-MM-DD hh:mm:ss.SSS';

class TestResultCard extends React.Component {
  constructor(props) {
    super(props);
    const { testResult, test_key } = props;
    const { id, file, error_message, test_run_test_id } = testResult;
    this.pending = error_message.includes('Pending reason');

    if (test_run_test_id === test_key) {
      this.props.openResultDetail(props.testResult);
    }

    // If we don't get to the scenario, we don't have all the data
    this.id = id || path.basename(file).split('_')[0];
    this.state = {
      history: null
    };
  }

  duration() {
    const { start_time, end_time } = this.props.testResult;
    const endTime = moment.utc(end_time, TIMESTAMP_FORMAT);
    const startTime = moment.utc(start_time, TIMESTAMP_FORMAT);
    const timeInSec = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
    return `Duration: ${timeInSec} sec`;
  }

  renderIcon() {
    const { passed } = this.props.testResult;
    const cs = this.props.classes;
    let icon = <FailIcon className={cs.icon} />;

    let avatarClass = cs.failAvatar;
    if (passed) {
      icon = <PassIcon className={cs.icon} />;
      avatarClass = cs.passAvatar;
    } else if (this.pending) {
      icon = <SkipIcon className={cs.icon} />;
      avatarClass = cs.skipAvatar;
    }
    return <div className={avatarClass}>{icon}</div>;
  }

  renderErrorMessage(errorString, title) {
    const cs = this.props.classes;

    if (errorString) {
      return (
        <div className={cs.errorContainer}>
          <div className={cs.errorLabel}>{title}</div>
          <div className={cs.errorMessage}>{errorString}</div>
        </div>
      );
    }
  }

  renderErrors(errors, title) {
    const cs = this.props.classes;

    if (errors) {
      return (
        <div className={cs.errorContainer}>
          <div className={cs.errorLabel}>
            {title} ({errors.length} error types)
          </div>
          <div className={cs.errorMessage}>
            {errors.map((err, index) => (
              <div key={index}>{err}</div>
            ))}
          </div>
        </div>
      );
    }
  }

  filter() {
    const { passed } = this.props.testResult;
    const { filter, dTags } = this.props;

    if (passed && filter.passed) {
      return true;
    }

    let unstableIDs = [];
    if (dTags['@unstable']) {
      unstableIDs = dTags['@unstable'].test_ids;
      unstableIDs = unstableIDs.map(String);
    }
    unstableIDs = unstableIDs.map(String);

    // pending has a failed status
    if (!passed && filter.failed && !this.pending && !unstableIDs.includes(this.id)) {
      return true;
    }

    if (this.pending && filter.skipped) {
      return true;
    }

    if (!passed && filter.unstable && unstableIDs.includes(this.id)) {
      return true;
    }

    return false;
  }

  /**
   * Returns truthy if test's dynamic tag matches at least
   * one of the filter tags
   */
  filterByTags() {
    const { byTestId, filterTags } = this.props;
    const tags = byTestId[this.id] || [];
    return filterTags.some((ftag) => tags.find((tag) => tag.text === ftag.text));
  }

  filterByFilterList() {
    const { filterList } = this.props;
    let showTest = false;
    if (!filterList) {
      return true;
    }

    const myHistory = this.getHistory();
    if (myHistory && myHistory.length > 0) {
      if (filterList == 'newFail') {
        if (myHistory.length > 1) {
          showTest = myHistory[0].status === 'failed' && myHistory[1].status != 'failed';
        } else {
          showTest = myHistory[0].status === 'failed';
        }
      } else if (filterList == 'threeFail') {
        if (myHistory.length >= 3) {
          showTest =
            myHistory[0].status === 'failed' &&
            myHistory[1].status === 'failed' &&
            myHistory[2].status === 'failed';
        } else {
          showTest = false;
        }
      } else {
        showTest = true;
      }
    }
    return showTest;
  }

  renderScreenshot() {
    const { screenshot } = this.props.testResult;
    const cs = this.props.classes;

    if (screenshot) {
      const baseS3 = 'https://s3-us-west-2.amazonaws.com/test-automation-science37/output';
      const src = `${baseS3}/${screenshot}`;
      return (
        <div className={cs.screenshot}>
          <a href={src} target="_blank" rel="noopener noreferrer">
            <img alt="screenshot" src={src} className={cs.screenshot} />
          </a>
        </div>
      );
    } else {
      return <div className={cs.screenshot}>No error screenshot found.</div>;
    }
  }

  getHistory() {
    if (this.state.history) {
      return this.state.history;
    }
    const history = this.props.testHistory;
    const myHistory = history.filter(
      (f) => f.testId == this.id && f.url == this.props.testResult.nora_site
    );
    this.setState({ history: myHistory });
    return myHistory;
  }

  renderHistory() {
    const myHistory = this.getHistory();
    return myHistory.map((hist, i) => {
      if (hist.status === 'failed') {
        return (
          <Tooltip key={i} title={hist.date}>
            <Button>🚩</Button>
          </Tooltip>
        );
      } else if (hist.status === 'passed') {
        return (
          <Tooltip key={i} title={hist.date}>
            <Button>✅</Button>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip key={i} title={hist.date}>
            <Button>⌛</Button>
          </Tooltip>
        );
      }
    });
  }

  renderHeader() {
    const {
      title,
      scenario,
      version,
      commit_hash,
      nora_site,
      chrome_version
    } = this.props.testResult;
    const cs = this.props.classes;

    return (
      <div className={cs.header}>
        <div className={cs.idContainer}>
          <div>
            {this.renderIcon()}
            <div className={cs.id}>{`TC${this.id}`}</div>
          </div>
          <div className={cs.withMarginRight}>{this.renderHistory()}</div>
        </div>
        <div className={cs.subheader}>{title}</div>
        <div className={cs.subheader}>{scenario}</div>
        <div className={cs.subheader}>{this.duration()}</div>
        <div className={cs.subheader}>{`Site: ${nora_site}`}</div>
        {chrome_version && (
          <div className={cs.subheader}>{`Chrome Version: ${chrome_version}`}</div>
        )}
        {version && commit_hash && (
          <div className={cs.subheader}>{`Platform: ${version} - CommitHash: ${commit_hash}`}</div>
        )}
      </div>
    );
  }

  renderErrorContent() {
    const cs = this.props.classes;
    const { error_message, console_errors, request_errors } = this.props.testResult;

    let errorTitle = 'Error';
    let errorMessage = error_message;

    if (this.pending) {
      errorTitle = 'Skipped Test';
      errorMessage = `Reason: ${errorMessage.split('==')[1]}`;
    }

    return (
      <div className={cs.errorContainer}>
        {this.renderErrorMessage(errorMessage, errorTitle)}
        {this.renderErrors(console_errors, 'Console Errors')}
        {this.renderErrors(request_errors, 'Request Errors')}
      </div>
    );
  }

  renderButtons() {
    const cs = this.props.classes;
    return (
      <div className={cs.buttonsContainer}>
        <Button
          className={cs.button}
          href={`/history/${this.id}`}
          color="secondary"
          variant="outlined"
        >
          History
          <TestHistoryIcon className={cs.buttonIcon} />
        </Button>
        <Button
          className={cs.button}
          onClick={() => this.props.openResultDetail(this.props.testResult)}
          variant="outlined"
          color="primary"
        >
          Details
          <DetailsIcon className={cs.buttonIcon} />
        </Button>
      </div>
    );
  }

  renderJiraDetail() {
    const cs = this.props.classes;
    const testId = this.id;
    const { byTestId, jiraDetail } = this.props;
    const numberRegex = /^\d+$/;

    const tags = byTestId[testId] || [];
    if (tags && tags.length > 0) {
      const jiraTags = tags
        .map((tag) => tag.text.toLowerCase().trim())
        .filter((tag) => tag.startsWith('nxl-') && numberRegex.test(tag.split('nxl-').pop()));
      if (jiraTags.length > 0) {
        const tags = jiraTags.map((jira) => {
          const myJira = jiraDetail.find((jd) => jd.key === jira.toUpperCase());
          if (!_.isEmpty(myJira)) {
            return (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center'
                }}
              >
                <span
                  style={{
                    fontWeight: 'bold'
                  }}
                >
                  <a
                    style={{ textDecoration: 'none' }}
                    href={`https://science37.atlassian.net/browse/${myJira.key}`}
                    target={'_blank'}
                  >
                    {myJira.key}
                  </a>
                </span>
                <span
                  style={{
                    marginLeft: '10px'
                  }}
                >
                  {myJira?.fields?.summary}
                </span>
                <span
                  style={{
                    background: myJira?.fields?.status?.name === 'Done' ? '#45b145' : 'navajowhite',
                    padding: '5px',
                    borderRadius: '20px',
                    marginLeft: '10px'
                  }}
                >
                  {myJira?.fields?.status?.name}
                </span>
              </div>
            );
          }
        });
        if (tags.length > 0) {
          return (
            <>
              <div className={cs.jiraLabel}>Jira Detail</div>
              {tags}
            </>
          );
        }
      }
    } else {
      return null;
    }
  }

  render() {
    const { classes: cs, filterTags, testResult } = this.props;
    const { title } = testResult;

    if (this.filter()) {
      return null;
    }

    if (filterTags.length > 0 && !this.filterByTags()) {
      return null;
    }

    if (!this.filterByFilterList()) {
      return null;
    }

    return (
      <div className={cs.resultContainer}>
        {this.renderScreenshot()}
        <div className={cs.infoContainer}>
          {this.renderHeader()}
          {this.renderErrorContent()}
          <div className={cs.jiraDetail}>{this.renderJiraDetail()}</div>
          <div className={cs.footer}>
            <DynamicTag testId={this.id} testName={title} />
            {this.renderButtons()}
          </div>
        </div>
      </div>
    );
  }
}

TestResultCard.propTypes = {
  classes: PropTypes.object.isRequired,
  testResult: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    filterList: state.filter.filterList,
    filterTags: state.filter.tags,
    filter: state.filter,
    test_key: state.testResults.test_key,
    byTestId: state.dtags.byTestId,
    dTags: state.dtags.byTag,
    jiraDetail: state.dtags.jiraDetail,
    testHistory: state.traceabilityMatrix.testResultHistory
  };
};

const mapDispatchToProps = (dispatch) => ({
  openResultDetail: (testResult) => {
    dispatch({ type: RESULTS_DETAIL_DIALOG_OPEN, payload: testResult });
  },
  onClose: () => {
    dispatch({ type: TEST_RESULTS_DIALOG_CLOSE });
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(TestResultCard);
