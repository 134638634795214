import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { TAB_ONE_CLICKED, TAB_ZERO_CLICKED, TAB_TWO_CLICKED } from '../../redux/actions';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class ScrollableTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  setValue = (event, newValue) => {
    this.setState({
      value: newValue
    });
  };

  handleChange(event, newValue) {
    const { onTabZeroClicked, onTabOneClicked, onTabTwoClicked } = this.props;
    this.setValue(event, newValue);
    if (newValue === 0) {
      onTabZeroClicked();
    } else if (newValue === 1) {
      onTabOneClicked();
    } else {
      onTabTwoClicked();
    }
  }

  render() {
    const {
      tab1Name,
      tab1Component,
      tab2Name,
      tab2Component,
      tab3Name,
      tab3Component,
      classes
    } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.root}>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={tab1Name} />
          <Tab label={tab2Name} />
          <Tab label={tab3Name} />
        </Tabs>
        {value === 0 && <TabContainer>{tab1Component}</TabContainer>}
        {value === 1 && <TabContainer>{tab2Component}</TabContainer>}
        {value === 2 && <TabContainer>{tab3Component}</TabContainer>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tab: state.tab.active
  };
};

const mapDispatchToProps = (dispatch) => ({
  onTabZeroClicked: () => {
    dispatch({ type: TAB_ZERO_CLICKED });
  },
  onTabOneClicked: () => {
    dispatch({ type: TAB_ONE_CLICKED });
  },
  onTabTwoClicked: () => {
    dispatch({ type: TAB_TWO_CLICKED });
  }
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ScrollableTabs);
