import RemoteConnection from './RemoteConnection';
const bifrostUrl =
  process.env.REACT_APP_BIFROST_URL ||
  'https://9uo74nv1gk.execute-api.us-west-2.amazonaws.com/Prod';
const bifrostChinaUrl =
  process.env.REACT_APP_BIFROST_CHINA_URL ||
  'https://c09wd40sc0.execute-api.cn-northwest-1.amazonaws.com.cn/Prod';
const dataPipelineDevUrl = process.env.REACT_APP_DATA_PIPELINE_DEV_URL;
const dataPipelineProdUrl = process.env.REACT_APP_DATA_PIPELINE_PROD_URL;

export default class RemoteResource {
  static connection;

  static getConnection(baseURL) {
    RemoteResource.connection = new RemoteConnection(baseURL);
    return RemoteResource.connection;
  }

  getTopFails() {
    return RemoteResource.getConnection(bifrostUrl).get('/v1/fails');
  }

  getTraceabilityMatrix() {
    return RemoteResource.getConnection(bifrostUrl).get('/v1/traceability-matrix');
  }

  getTestResultHistory() {
    return RemoteResource.getConnection(bifrostUrl).get('/v1/traceability/test-history');
  }

  updateRMS(id, payload) {
    return RemoteResource.getConnection(bifrostUrl).put(`/v1/traceability/rms/${id}`, payload);
  }

  getHistory(id, isNextGen, noraSite) {
    let filterValue = '?isNextGen=true';
    if (noraSite) {
      filterValue += `&noraSite=${noraSite}`;
    }
    return RemoteResource.getConnection(bifrostUrl).get(
      `/v1/history/${id}${isNextGen ? filterValue : ''}`
    );
  }

  getApiTestResults(key, force) {
    const queryParams = force ? '/?force=true' : '';
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/api-test-run/${key}${queryParams}`);
  }

  getApiTestRuns(force) {
    const queryParams = force ? '/?force=true' : '';
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/api-test-runs${queryParams}`);
  }

  getLoadTestRuns(force) {
    const queryParams = force ? '/?force=true' : '';
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/load-test-runs${queryParams}`);
  }

  getTestRuns(force) {
    const queryParams = force ? '/?force=true' : '';
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/test-runs${queryParams}`);
  }

  getTestResultDetail(key, id) {
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/test-detail/${key}/${id}`);
  }

  getApiTestResultDetail(key, id) {
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/api-test-detail/${key}/${id}`);
  }

  getApiRequestLogDetail(key) {
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/api-test-run/api-log/${key}`);
  }

  async getTestResults(key) {
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/test-runs/${key}`);
  }

  getBuilds() {
    return RemoteResource.getConnection(bifrostUrl).get('/v1/builds');
  }

  getChinaBuilds() {
    return RemoteResource.getConnection(bifrostChinaUrl).get('/v1/china/builds');
  }

  getAPIBuilds() {
    return RemoteResource.getConnection(bifrostUrl).get('/v1/api/builds');
  }

  getLoadBuilds() {
    return RemoteResource.getConnection(bifrostUrl).get('/v1/load/builds');
  }

  createBuilds(payload) {
    return RemoteResource.getConnection(bifrostUrl).post('/v1/builds', payload);
  }

  createChinaBuilds(payload) {
    return RemoteResource.getConnection(bifrostChinaUrl).post('/v1/china/builds', payload);
  }

  createAPIBuilds(payload) {
    return RemoteResource.getConnection(bifrostUrl).post('/v1/api/builds', payload);
  }

  createLoadBuilds(payload) {
    return RemoteResource.getConnection(bifrostUrl).post('/v1/load/builds', payload);
  }

  fetchTags() {
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/dtag`);
  }

  addTag(dtagId, testId, testName) {
    const payload = {
      testName
    };
    return RemoteResource.getConnection(bifrostUrl).put(
      `/v1/dtag/${dtagId}/test/${testId}`,
      payload
    );
  }

  deleteTag(id) {
    return RemoteResource.getConnection(bifrostUrl).delete(`/v1/dtag/${id}`);
  }

  removeTag(id) {
    return RemoteResource.getConnection(bifrostUrl).delete(`/v1/tagged_test/${id}`);
  }

  createTag(dtag, testId, testName) {
    const payload = {
      name: dtag,
      testId,
      testName
    };
    return RemoteResource.getConnection(bifrostUrl).post(`/v1/dtag/`, payload);
  }

  createSetting(payload) {
    return RemoteResource.getConnection(bifrostUrl).post(`/v1/setting`, payload);
  }

  updateSetting(id, payload) {
    return RemoteResource.getConnection(bifrostUrl).put(`/v1/setting/${id}`, payload);
  }

  deleteSetting(id) {
    return RemoteResource.getConnection(bifrostUrl).delete(`/v1/setting/${id}`);
  }

  getAllSetting() {
    return RemoteResource.getConnection(bifrostUrl).get(`/v1/setting`);
  }

  //////////////////////////////////
  //GET DATA HARMONIZATION SQL DATA
  getDHPlatformEnvironments(platform, sqlType, envType) {
    return RemoteResource.getConnection(
      dataPipelineDevUrl + platform + '/' + sqlType + '/' + envType + '/environments'
    ).get();
  }

  getDHSQLData(environment, platform, type, envType) {
    if (environment.toLowerCase().includes('prod')) {
      return RemoteResource.getConnection(
        dataPipelineProdUrl + 'report' + '/' + platform + '/' + type + '/' + environment
      ).get(envType);
    } else {
      return RemoteResource.getConnection(
        dataPipelineDevUrl + 'report' + '/' + platform + '/' + type + '/' + environment
      ).get(envType);
    }
  }

  //GET DATA HARMONIZATION NOSQL DATA
  getDHNOSQLData(environment, documentName, platform, envType) {
    if (environment.toLowerCase() === 'prod') {
      return RemoteResource.getConnection(
        dataPipelineProdUrl +
          'nosql/' +
          'getDocuments/' +
          documentName +
          '/' +
          platform +
          '/' +
          environment
      ).get(envType);
    } else {
      return RemoteResource.getConnection(
        dataPipelineDevUrl +
          'nosql/' +
          'getDocuments/' +
          documentName +
          '/' +
          platform +
          '/' +
          environment
      ).get(envType);
    }
  }

  //GET DATA HARMONIZATION S3 BUCKET DOCUMENTS LIST
  getDHNOSQLDocumentList(environment, platform, envType) {
    if (environment.toLowerCase() === 'prod') {
      return RemoteResource.getConnection(
        dataPipelineProdUrl + 'nosql/' + 'getBucketDocuments' + '/' + platform + '/' + environment
      ).get(envType);
    } else {
      return RemoteResource.getConnection(
        dataPipelineDevUrl + 'nosql/' + 'getBucketDocuments' + '/' + platform + '/' + environment
      ).get(envType);
    }
  }

  //GET DATA HARMONIZATION FHIR to FHIRLITE Transformed Data Result Counts
  getDHFHIRToFHIRLITEDataResult(environment, platform, table, envType) {
    if (environment.toLowerCase() === 'prod') {
      return RemoteResource.getConnection(dataPipelineProdUrl).post(
        'nosql/getFHIRDocumentPaths/' + platform + '/' + environment + '/' + envType,
        { table: table }
      );
    } else {
      return RemoteResource.getConnection(dataPipelineDevUrl).post(
        'nosql/getFHIRDocumentPaths/' + platform + '/' + environment + '/' + envType,
        { table: table }
      );
    }
  }

  //GET DATA HARMONIZATION FHIR/FHIRLITE Bucket Data
  getDHFHIRBucketData(body, environment, platform, envType) {
    if (environment.toLowerCase() === 'prod') {
      return RemoteResource.getConnection(dataPipelineProdUrl).post(
        'nosql/getFHIRBucketData/' + platform + '/' + environment + '/' + envType,
        body
      );
    } else {
      return RemoteResource.getConnection(dataPipelineDevUrl).post(
        'nosql/getFHIRBucketData/' + platform + '/' + environment + '/' + envType,
        body
      );
    }
  }

  //GET DATA HARMONIZATION FHIR/FHIRLITE Environment Tables
  getDHFHIREnvironmentTables(environment, platform) {
    if (environment.toLowerCase() === 'prod') {
      return RemoteResource.getConnection(
        dataPipelineProdUrl + 'nosql/getFHIREnvironmentTables/' + platform
      ).get(environment);
    } else {
      return RemoteResource.getConnection(
        dataPipelineDevUrl + 'nosql/getFHIREnvironmentTables/' + platform
      ).get(environment);
    }
  }

  //Dataharmonization Sales force S3 Documents
  getSFDCDocuments(environment, platform, envType) {
    if (environment.toLowerCase() === 'prod') {
      return RemoteResource.getConnection(
        dataPipelineProdUrl + 'nosql/getSFDCDocuments/' + platform + '/' + environment
      ).get(envType);
    } else {
      return RemoteResource.getConnection(
        dataPipelineDevUrl + 'nosql/getSFDCDocuments/' + platform + '/' + environment
      ).get(envType);
    }
  }

  getDHSFDCBucketData(body, environment, platform, envType) {
    if (environment.toLowerCase() === 'prod') {
      return RemoteResource.getConnection(dataPipelineProdUrl).post(
        'nosql/getSFDCDocumentData/' + platform + '/' + environment + '/' + envType,
        body
      );
    } else {
      return RemoteResource.getConnection(dataPipelineDevUrl).post(
        'nosql/getSFDCDocumentData/' + platform + '/' + environment + '/' + envType,
        body
      );
    }
  }

  getDHGlobalSchemaEnvironments(schema, platform, envType) {
    return RemoteResource.getConnection(
      dataPipelineDevUrl + 'getGlobalSchemaEnvironments/' + platform + '/' + schema
    ).get(envType);
  }

  getDHGlobalData(environment, platform, envType) {
    return RemoteResource.getConnection(
      dataPipelineDevUrl + 'queryGlobalData/' + platform + '/' + environment
    ).get(envType);
  }
  //////////////////////////////////
}
