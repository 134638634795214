import { call, put, takeLatest } from 'redux-saga/effects';
import RemoteResource from '../../lib/RemoteResource';

import {
  HEIMDALL_SETTING_CREATE_REQUESTED,
  HEIMDALL_SETTING_CREATE_FAILED,
  HEIMDALL_SETTING_CREATE_SUCCEEDED,
  HEIMDALL_SETTING_GET_ALL_REQUESTED,
  HEIMDALL_SETTING_GET_ALL_FAILED,
  HEIMDALL_SETTING_GET_ALL_SUCCEEDED,
  HEIMDALL_SETTING_UPDATE_REQUESTED,
  HEIMDALL_SETTING_UPDATE_FAILED,
  HEIMDALL_SETTING_UPDATE_SUCCEEDED,
  HEIMDALL_SETTING_DELETE_REQUESTED,
  HEIMDALL_SETTING_DELETE_FAILED,
  HEIMDALL_SETTING_DELETE_SUCCEEDED
} from '../actions';

const remoteResource = new RemoteResource();

function* fetchAllHeimdallSettings() {
  try {
    const setting = yield call(remoteResource.getAllSetting);
    yield put({
      type: HEIMDALL_SETTING_GET_ALL_SUCCEEDED,
      payload: setting.data
    });
  } catch (e) {
    yield put({ type: HEIMDALL_SETTING_GET_ALL_FAILED, payload: e.message });
  }
}

function* createHeimdallSetting(action) {
  try {
    const { payload } = action;
    const setting = yield call(remoteResource.createSetting, payload);
    yield put({
      type: HEIMDALL_SETTING_CREATE_SUCCEEDED,
      payload: setting.data
    });
  } catch (e) {
    yield put({ type: HEIMDALL_SETTING_CREATE_FAILED, payload: e.message });
  }
}

function* updateHeimdallSetting(action) {
  try {
    const { payload, id } = action.payload;
    const setting = yield call(remoteResource.updateSetting, id, payload);
    yield put({
      type: HEIMDALL_SETTING_UPDATE_SUCCEEDED,
      payload: setting.data
    });
  } catch (e) {
    yield put({ type: HEIMDALL_SETTING_UPDATE_FAILED, payload: e.message });
  }
}

function* deleteHeimdallSetting(action) {
  try {
    const { id } = action.payload;
    const setting = yield call(remoteResource.deleteSetting, id);
    yield put({
      type: HEIMDALL_SETTING_DELETE_SUCCEEDED,
      payload: setting.data
    });
  } catch (e) {
    yield put({ type: HEIMDALL_SETTING_DELETE_FAILED, payload: e.message });
  }
}

export function* watchHeimdallSetting() {
  yield takeLatest(HEIMDALL_SETTING_CREATE_REQUESTED, createHeimdallSetting);
  yield takeLatest(HEIMDALL_SETTING_UPDATE_REQUESTED, updateHeimdallSetting);
  yield takeLatest(HEIMDALL_SETTING_GET_ALL_REQUESTED, fetchAllHeimdallSettings);
  yield takeLatest(HEIMDALL_SETTING_DELETE_REQUESTED, deleteHeimdallSetting);
}
