export default (theme) => {
  return {
    linkContainer: {
      display: 'inline-block',
      position: 'relative',
      marginLeft: '5px'
    },
    searchIcon: {
      fontSize: '32px',
      margin: '8px 10px 0 0'
    },
    searchTagContainer: {
      display: 'flex',
      margin: '20px',
      padding: '0 10px'
    },
    searchTagChipContainer: {
      flexGrow: 4
    },
    infoBoxesContainer: {
      margin: '20px',
      padding: '0 10px'
    },
    infoBoxContainer: {
      paddingRight: '20px'
    },
    switchContainer: {
      marginRight: '20px'
    }
  };
};
