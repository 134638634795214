import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { MainCard } from 'heimdall-components';
import { Link } from 'react-router-dom';
import { withStyles, Button, CircularProgress, Tooltip } from '@material-ui/core';
import { Refresh, AddCircleOutline } from '@material-ui/icons';
import moment from 'moment';
import _ from 'lodash';
import styles from './styles';

import {
  BUILDS_FETCH_REQUESTED,
  API_BUILDS_FETCH_REQUESTED,
  API_BUILDS_DIALOG,
  BUILDS_DIALOG,
  TAB_ZERO_CLICKED,
  LOAD_BUILDS_DIALOG,
  LOAD_BUILDS_FETCH_REQUESTED,
  BUILDS_CHINA_FETCH_REQUESTED
} from '../../redux/actions';

import BuildsTable from './BuildsTable';
import { ScrollableTabs } from '../../components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';

class Builds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      region: 'US',
      checkedRegion: false
    };
    this.handleChangeRegion = this.handleChangeRegion.bind(this);
  }

  handleChangeRegion(event) {
    if (event.target.checked) {
      this.setState({
        ...this.state,
        ...{ region: 'CHINA', [event.target.name]: event.target.checked }
      });
    } else {
      this.setState({
        ...this.state,
        ...{ region: 'US', [event.target.name]: event.target.checked }
      });
    }
  }

  componentDidMount() {
    this.props.defaultTab();
  }

  calculateTime(startTime, endTime) {
    const end = endTime ? moment(endTime) : moment();
    const start = moment(startTime);
    return moment.duration(end.diff(start)).humanize();
  }

  parseBuilds(isChina = false) {
    const { builds, builds_china } = this.props;
    let buildsToProcess = builds;
    if (isChina) {
      buildsToProcess = builds_china;
    }
    return _.map(buildsToProcess, (r) => {
      return [
        r.TEST_RUN_NAME || '',
        r.NORA_SITE || '',
        r.EXECUTOR || '',
        r.TEST_TAG || '',
        r.sourceVersion || '',
        r.currentPhase || '',
        r.buildStatus || '',
        moment(r.startTime).format('YYYY-MM-DD HH:mm') || '',
        this.calculateTime(r.startTime, r.endTime) || '',
        r.id || ''
      ];
    });
  }

  parseAPIBuilds() {
    const { api_builds: builds } = this.props;
    return _.map(builds, (r) => {
      return [
        r.TEST_RUN_NAME || '',
        r.ENVIRONMENT || r.BASE_URL || '',
        r.EXECUTOR || '',
        r.TEST_TAG || '',
        r.sourceVersion || '',
        r.currentPhase || '',
        r.buildStatus || '',
        moment(r.startTime).format('YYYY-MM-DD HH:mm') || '',
        this.calculateTime(r.startTime, r.endTime) || '',
        r.id || ''
      ];
    });
  }

  parseLoadBuilds() {
    const { load_builds: builds } = this.props;
    return _.map(builds, (r) => {
      return [
        r.ENV ? r.ENV.toUpperCase() : '',
        r.HOST || '',
        r.EXECUTOR || '',
        r.TEST_FILE || '',
        r.USERS || '',
        r.RUN_TIME || '',
        r.SPAWN_RATE ? `${r.SPAWN_RATE} users/sec` : '',
        r.sourceVersion || '',
        r.currentPhase || '',
        r.buildStatus || '',
        moment(r.startTime).format('YYYY-MM-DD HH:mm') || '',
        this.calculateTime(r.startTime, r.endTime) || '',
        r.id || ''
      ];
    });
  }

  renderBuildsContent() {
    const { builds, builds_china, classes, builds_china_fetching, builds_fetching } = this.props;
    const { region } = this.state;
    if (region === 'US') {
      if (builds_fetching) {
        return <CircularProgress size={50} color="primary" className={classes.progress} />;
      }

      if (builds.length > 0) {
        return <BuildsTable data={this.parseBuilds()} />;
      }
    } else if (region === 'CHINA') {
      if (builds_china_fetching) {
        return <CircularProgress size={50} color="primary" className={classes.progress} />;
      }

      if (builds_china.length > 0) {
        return <BuildsTable isChina={true} data={this.parseBuilds(true)} />;
      }
    }
  }

  renderAPIBuildsContent() {
    const { api_builds: builds, classes, api_builds_fetching: builds_fetching } = this.props;
    if (builds_fetching) {
      return <CircularProgress size={50} color="primary" className={classes.progress} />;
    }

    if (builds.length > 0) {
      return <BuildsTable isAPI={true} data={this.parseAPIBuilds()} />;
    }
  }

  renderLoadBuildsContent() {
    const { load_builds: builds, classes, load_builds_fetching: builds_fetching } = this.props;
    if (builds_fetching) {
      return <CircularProgress size={50} color="primary" className={classes.progress} />;
    }

    if (builds.length > 0) {
      return <BuildsTable isLoad={true} data={this.parseLoadBuilds()} />;
    }
  }

  render() {
    const {
      classes: cs,
      authState,
      activeTab,
      onBuildsRequest,
      onBuildsRequestChina,
      onAPIBuildsRequest,
      onBuildCreate,
      onLoadBuildsRequest,
      onLoadBuildCreate,
      onAPIBuildCreate
    } = this.props;
    const { region } = this.state;
    const disableBuild = authState !== 'logged_in';
    const buildTip = disableBuild ? 'Please sign in' : 'Create new build';
    let buildRequest;
    let buildCreate;
    let tabName;
    let repo;
    if (activeTab === 0) {
      buildRequest = onBuildsRequest;
      if (region === 'CHINA') {
        buildRequest = onBuildsRequestChina;
      }
      buildCreate = onBuildCreate;
      tabName = 'Frontend Test';
      repo = 'https://science37.atlassian.net/wiki/spaces/TECH/pages/1131577369/In+House+Tools';
    } else if (activeTab === 1) {
      buildRequest = onAPIBuildsRequest;
      buildCreate = onAPIBuildCreate;
      tabName = 'Backend Test';
      repo = 'https://github.com/Science37/heimdall-api/blob/master/README.md';
    } else {
      buildRequest = onLoadBuildsRequest;
      buildCreate = onLoadBuildCreate;
      tabName = 'Load Test';
      repo = 'https://github.com/Science37/heimdall-load/blob/master/README.md';
    }

    return (
      <React.Fragment>
        <MainCard
          title="Builds"
          headerAction={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <Tooltip title={buildTip}>
                  <span>
                    <Button
                      onClick={buildCreate}
                      className={cs.headerButton}
                      color="primary"
                      variant="outlined"
                      disabled={disableBuild}
                    >
                      New Build - {tabName}
                      <AddCircleOutline className={cs.headerIcon} />
                    </Button>
                  </span>
                </Tooltip>

                <Button
                  onClick={buildRequest}
                  className={cs.headerButton}
                  color="primary"
                  variant="outlined"
                >
                  Refresh
                  <Refresh className={cs.headerIcon} />
                </Button>
                <Button
                  component={Link}
                  to={{
                    pathname: repo
                  }}
                  className={cs.headerButton}
                  target="_blank"
                >
                  View Test Repo
                </Button>
              </div>
              {activeTab === 0 && (
                <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                  <FormGroup>
                    <FormControlLabel
                      className={cs.title}
                      control={
                        <Switch
                          checked={this.state.checkedRegion}
                          onChange={this.handleChangeRegion}
                          name="checkedRegion"
                        />
                      }
                      label={this.state.region.toUpperCase()}
                    />
                  </FormGroup>
                </div>
              )}
            </div>
          }
        />
        <ScrollableTabs
          tab1Name="Frontend"
          tab1Component={<React.Fragment>{this.renderBuildsContent()}</React.Fragment>}
          tab2Name="Backend"
          tab2Component={<React.Fragment>{this.renderAPIBuildsContent()}</React.Fragment>}
          tab3Name="Load"
          tab3Component={<React.Fragment>{this.renderLoadBuildsContent()}</React.Fragment>}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    builds: state.testRuns.builds,
    builds_china: state.testRuns.builds_china,
    builds_china_fetching: state.testRuns.builds_china_fetching,
    builds_fetching: state.testRuns.builds_fetching,
    api_builds: state.apiTestRuns.builds,
    api_builds_fetching: state.apiTestRuns.builds_fetching,
    load_builds: state.loadTestRuns.builds,
    load_builds_fetching: state.loadTestRuns.builds_fetching,
    authState: state.login.authState,
    activeTab: state.tab.active
  };
};

const mapDispatchToProps = (dispatch) => ({
  onBuildCreate: () => {
    dispatch({ type: BUILDS_DIALOG });
  },
  onAPIBuildCreate: () => {
    dispatch({ type: API_BUILDS_DIALOG });
  },
  onLoadBuildCreate: () => {
    dispatch({ type: LOAD_BUILDS_DIALOG });
  },
  onBuildsRequest: () => {
    dispatch({ type: BUILDS_FETCH_REQUESTED });
  },
  onBuildsRequestChina: () => {
    dispatch({ type: BUILDS_CHINA_FETCH_REQUESTED });
  },
  onAPIBuildsRequest: () => {
    dispatch({ type: API_BUILDS_FETCH_REQUESTED });
  },
  onLoadBuildsRequest: () => {
    dispatch({ type: LOAD_BUILDS_FETCH_REQUESTED });
  },
  defaultTab: () => {
    dispatch({ type: TAB_ZERO_CLICKED });
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(Builds);
