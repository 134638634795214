import DashboardPage from 'views/Dashboard';
import TestRuns from 'views/TestRuns';
import Builds from 'views/Builds';
import TopFails from 'views/TopFails';
import HistoryView from 'views/History';
import SpiraView from 'views/Spira';
import DynamicTagsView from 'views/DynamicTags';
import TraceabilityMatrixView from 'views/TraceabilityMatrix';
import DataHarmonizationView from '../views/DataHarmonization';
import SettingView from '../views/Setting';

import {
  Dashboard,
  LibraryBooks,
  FormatListNumbered,
  MoodBad,
  History,
  Label,
  NewReleases,
  Assessment,
  StorageRounded,
  Settings
} from '@material-ui/icons';

const dashboardRoutes = [
  {
    path: '/dashboard',
    routePath: '/dashboard',
    sidebarName: 'Dashboard',
    navbarName: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage
  },
  {
    path: '/builds',
    routePath: '/builds',
    sidebarName: 'Builds',
    navbarName: 'Builds',
    icon: LibraryBooks,
    component: Builds
  },
  {
    path: '/test_runs',
    routePath: '/test_runs/:key?/:test_key?',
    sidebarName: 'Test Reports',
    navbarName: 'Test Reports',
    icon: FormatListNumbered,
    component: TestRuns
  },
  {
    path: '/traceability-matrix',
    routePath: '/traceability-matrix',
    sidebarName: 'Traceability Matrix',
    navbarName: 'Traceability Matrix',
    icon: Assessment,
    component: TraceabilityMatrixView
  },
  // {
  //   path: '/spira',
  //   routePath: '/spira',
  //   sidebarName: 'Nora Releases',
  //   navbarName: 'Nora Releases',
  //   icon: NewReleases,
  //   component: SpiraView
  // },
  {
    path: '/top_fails',
    routePath: '/top_fails',
    sidebarName: 'Top Fails',
    navbarName: 'Top Failed Tests in the last 2 months',
    icon: MoodBad,
    component: TopFails
  },
  {
    path: '/history',
    routePath: '/history/:id?',
    sidebarName: 'Test History',
    navbarName: 'Test History',
    icon: History,
    component: HistoryView
  },
  {
    path: '/dtags',
    routePath: '/dtags',
    sidebarName: 'Dynamic Tags',
    navbarName: 'Dynamic Tags',
    icon: Label,
    component: DynamicTagsView
  },
  {
    path: '/data',
    isDHRoute: true,
    routePath: '/data',
    sidebarName: 'Data Harmonization',
    navbarName: 'Data Harmonization',
    icon: StorageRounded,
    component: DataHarmonizationView
  },
  {
    path: '/settings',
    routePath: '/settings',
    sidebarName: 'Setting',
    navbarName: 'Daily Run Scheduler',
    icon: Settings,
    component: SettingView
  },
  { redirect: true, path: '/', to: '/dashboard', navbarName: 'Redirect' }
];

export default dashboardRoutes;
