import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import styles from './styles';

class SearchTag extends React.Component {
  constructor(props) {
    super(props);

    this.anchorEl = React.createRef();

    this.state = {
      suggestions: [],
      suggestionsOpen: false
    };
  }

  handleClose = (event) => {
    this.setState({ suggestionsOpen: false });
  };

  handleAutocompleteSelect = (chip) => {
    this.handleAddTag(chip);
  };

  render() {
    const { classes: cs, tagList, tags, handleUpdate, ...rest } = this.props;

    return (
      <div className={cs.SearchTag}>
        <ChipInput
          classes={{
            chip: cs.chip
          }}
          fullWidth
          value={tags}
          datasource={tagList}
          dataSourceConfig={{ text: 'text', value: 'text' }}
          onAdd={this.handleAddTag}
          onDelete={this.handleRemoveTag}
          onChange={handleUpdate}
          onUpdateInput={this.handletextFieldInputChange}
          {...rest}
        />
        <Popper
          open={this.state.suggestionsOpen}
          anchorEl={this.anchorEl.current}
          transition
          style={{ zIndex: 20, position: 'absolute' }}
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper className={cs.pop}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {this.state.suggestions.map((suggestion, idx) => {
                      return (
                        <MenuItem
                          className={cs.popItem}
                          key={idx}
                          onClick={() => this.handleAutocompleteSelect(suggestion)}
                        >
                          {suggestion.text}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }

  handletextFieldInputChange = (e) => {
    const newTag = e.target.value.toLowerCase();
    const { tagList } = this.props;
    const suggestions = tagList
      .filter((tag) => tag.text.startsWith(newTag))
      .sort((a, b) => a.text.localeCompare(b.text));

    this.setState({ suggestionsOpen: true, suggestions });
  };

  /**
   * If tag already exist, then we create a relationship between the tag and the test
   * If tag does note exist, we create the tag first before creating the relationship
   * @para {object} chip - The value we want to add
   */
  handleAddTag = (chip) => {
    const newTag = chip.text.toLowerCase();
    const { tags, tagList } = this.props;

    const foundInTags = tags.find((tag) => tag.text === newTag);
    const foundInTagList = tagList.find((tag) => tag.text === newTag);

    this.setState({ suggestionsOpen: false });

    if (foundInTags || !foundInTagList) {
      return;
    }

    const newTags = tags.concat([chip]);

    this.props.handleUpdate(newTags);
  };

  handleRemoveTag = (value) => {
    const newTag = value.toLowerCase();
    const { tags } = this.props;
    const newTags = tags.filter((tag) => tag.text !== newTag);
    this.props.handleUpdate(newTags);
  };
}

SearchTag.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    tagList: state.dtags.tagList
  };
};

const mapDispatchToProps = (dispatch) => ({});

SearchTag.propTypes = {
  classes: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SearchTag);
