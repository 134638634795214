import React, { Component } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { FailColor, PassColor, TotalColor } from '../../assets/jss/colors';
import { CircularProgress, withStyles } from '@material-ui/core';
import moment from 'moment';
const MIN_TEST_TOTALS = 25; // minimum tests that ran
// const EXECUTOR = ['@daily_robot', '@daily_robot_rerun_failed'];
const EXECUTOR = ['@daily_robot'];

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  progressContainer: {
    display: 'flex',
    marginTop: '100px',
    justifyContent: 'center'
  },
  chartContainer: {
    padding: '0 20px 20px 20px'
  }
});

class ApiTestRunsGraph extends Component {
  getData(testRuns) {
    let data = [];
    for (let i = testRuns.length - 1; i >= 0; i--) {
      let obj = {};
      const { total, failed, passed } = testRuns[i];
      if (EXECUTOR.includes(testRuns[i].executor)) {
        obj['name'] = moment(testRuns[i].test_run_name, 'YYY-MM-DD_hh_mm-ss').format('MM-DD');
        obj['total'] = parseInt(total, 10);
        obj['failed'] = parseInt(failed, 10);
        obj['passed'] = parseInt(passed, 10);
        data.push(obj);
      }
    }

    return data;
  }

  render() {
    const { testRuns, classes: cs } = this.props;
    const data = this.getData(testRuns);
    if (data !== undefined && data.length > 0) {
      return (
        <div className={cs.chartContainer}>
          <ResponsiveContainer width="98%" height={400}>
            <LineChart
              outerRadius="100%"
              data={data}
              margin={{ top: 40, right: 10, left: 10, bottom: 5 }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="total" stroke={TotalColor} />
              <Line type="monotone" dataKey="passed" stroke={PassColor} />
              <Line type="monotone" dataKey="failed" stroke={FailColor} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    } else {
      return (
        <div className={cs.progressContainer}>
          <CircularProgress size={50} color="primary" className={cs.progress} />
        </div>
      );
    }
  }
}

export default withStyles(styles)(ApiTestRunsGraph);
