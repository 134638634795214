import React, { useEffect, useState } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css"
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import emoji from 'react-easy-emoji';

import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './styles';
import { filterCaseInsensitive, reactTableHelper } from '../../lib/utils';
import Bar from './Bar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RmsAction from './RmsAction';
import { Button, Grid } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TestRunCharts from '../Dashboard/TestRunCharts';
import { AddCircleOutline } from '@material-ui/icons';
import BugViewAction from './BugViewAction';
import HistoryAction from './HistoryAction';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles(styles);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export default function TraceabilityTable ({ matrixData }) {
  const cs = useStyles();

  const preventDefault = (event) => event.preventDefault();
  const [hideInactiveRms, setHideInactiveRms] = useState(true);
  const [data, setData] = useState(matrixData);
  const [filterData, setFilterData] = useState(matrixData);

  useEffect(()=> {
    if(matrixData){
      setData(matrixData)
    }
  }, [matrixData])

  useEffect(()=> {
    if(matrixData){
      setData(matrixData)
    }
  }, [])

  useEffect(()=> {
    if(hideInactiveRms && data){
      setFilterData(data.filter(d => d.rmsDetail.noTestNeeded !== 1))
    }else{
      setFilterData(data);
    }
  }, [hideInactiveRms, data])


  const getTestLink = (filePath, tcName) => {
    let file = filePath.replace('/app', '');
    const repoRoot = 'https://github.com/Science37/heimdall-next/tree';
    const url =`${repoRoot}/master/${file}`;
    return <a  href={url} target="_blank">{tcName}</a>;
  }

  const getJiraLink = (jira) => {
    return <a key={jira} href={`https://science37.atlassian.net/browse/${jira}`} target="_blank">{jira}</a>;
  }
  const renderPassFail = (tr) => {
    let finalStatus;
      if(tr.status === "passed"){
        finalStatus = emoji('✅');
      }else if(tr.status === "failed"){
        if(tr.errorMessage && tr.errorMessage.includes("nxl")) {
          finalStatus = emoji(['❌', getJiraLink(tr.errorMessage.toUpperCase())]);
        } else if(tr.errorMessage && tr.errorMessage.includes("Pending reason")){
          finalStatus = emoji(`⌛  ${tr.errorMessage.split('==')[1]}`);
        }else{
          finalStatus = emoji('❌');
        }
      }else{
        if(tr.errorMessage && tr.errorMessage.includes("Pending reason")){
          finalStatus = emoji(`⌛  ${tr.errorMessage.split('==')[1]}`);
        }else{
          finalStatus = emoji('⌛');
        }
      }
      return finalStatus
  }
  const renderTestDetail = (testRuns) => {
      if(testRuns && testRuns.length > 0){
        return (<List  aria-label="main mailbox folders">
          {testRuns.map((tr, index) => {
            return (<ListItem key={index}>
                            <ListItemIcon style={{marginRight: "15px"}}>
                              {tr.filePath ? getTestLink(tr.filePath,`TC${tr.testId}`)
                              : `TC${tr.testId}`}
                            </ListItemIcon>
                            <ListItemText className={cs.newLineText} primary={tr.name} secondary={renderPassFail(tr)} />
                            <HistoryAction id={tr.testId}/>
                  </ListItem>)
          })}
        </List>)
      }
      return null;
  }
  const renderTestRuns = (testRuns) => {
    const totalTests = testRuns.length;
    testRuns = testRuns.sort((a,b) => {
      let statusA = a.status || "not run";
      let statusB = b.status || "not run";
     return (statusA > statusB) ? 1 : ((statusB > statusA) ? -1 : 0)
    })
    const passed = testRuns.filter(tr => tr.status === 'passed').length;
    const failed = testRuns.filter(tr => tr.status === 'failed').length;
    const pending = testRuns.filter(tr => tr.status === null).length;
    const data =
      [{color:'#008000', percent: Math.ceil((passed/totalTests)*100)},
        {color:'#db2d2d', percent: Math.ceil((failed/totalTests)*100)},
        {color:'#808080', percent: Math.ceil((pending/totalTests)*100)}
        ];
    return  (
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Bar data={data} totalTests={totalTests}/>
        </AccordionSummary>
        <AccordionDetails>
          {renderTestDetail(testRuns)}
        </AccordionDetails>
    </Accordion>


  )
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const getRmsAction = (rmsDetail) => {
    if (rmsDetail.rms === 'ERROR'){
      return null;
    }else{
      return <RmsAction rmsDetail={rmsDetail} />;
    }
  }



  const RMS = React.forwardRef(function RMS(props, ref) {
    const { rmsDetail }= props;
    // If you need a toolTip
    // return (<div {...props} ref={ref}><a href={`https://science37.atlassian.net/browse/${rmsDetail.rms}`} target="_blank">{rmsDetail.rms}</a> <div className={cs.newLineText}>{rmsDetail.summary}</div></div>);
    return (<div>
          <div style={{display: "flex"}}>
            <a href={`https://science37.atlassian.net/browse/${rmsDetail.rms}`} target="_blank">{rmsDetail.rms}</a>
            {rmsDetail.epic && <Chip
              style={{
                marginLeft: "10px",
                background: "#FF00875A"
                }}
              size="small"
              label={rmsDetail.epic}
              />
            }
            {rmsDetail.type && <Chip
              style={{
                marginLeft: "10px"
              }}
              size="small"
              label={rmsDetail.type}
            />
            }
          </div>
        <div className={cs.newLineText}>{rmsDetail.summary}</div>
    </div>);
  });

  const NOTE = React.forwardRef(function RMS(props, ref) {
    return (<div><div className={cs.newLineText}>{props.value}</div></div>);
  });

  function getTrProps(state, rowInfo) {
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          background: rowInfo.row._original.rmsDetail.noTestNeeded ? "#d5ccbf": "",
        },
      };
    } else {
      return {};
    }
  }
  const headerStyle = {
    textAlign: "center",
    backgroundColor: "#70A4A6",
    fontSize: "large",
    fontWeight: "500",
  }
  const columns = [
    {
      Header: "RMS",
      accessor: "rmsDetail",
      sortable: false,
      headerStyle,
      Cell: (props) => {
        if (props.value) {
          const rmsDetail = props.value;
          // If you need a tooltip
          // return <HtmlTooltip title={rmsDetail.summary}><RMS rmsDetail={rmsDetail} /></HtmlTooltip>
          return <RMS rmsDetail={rmsDetail} />
        }
        return props.value;
      },
      filterMethod: (filter, row) => {
        return (String(row[filter.id].summary.toLowerCase()).includes(filter.value.toLowerCase()) ||
          String(row[filter.id].rms.toLowerCase()).includes(filter.value.toLowerCase()) ||
          String((row[filter.id].epic || "").toLowerCase()).includes(filter.value.toLowerCase())
        );
      }
    },
    {
      Header: "Test Runs",
      accessor: "testRuns",
      sortable: false,
      headerStyle,
      Cell: (props) => {
        if (props.value && props.value.length > 0) {
          const testRuns = props.value;
          return (renderTestRuns(testRuns))
        }
        return props.value;
      },
      filterMethod: (filter, row) => {
        if (filter.value === "all") {
          return true;
        } else if (filter.value === "notCovered") {
          return row[filter.id].length === 0
        } else if (filter.value === "covered") {
          return row[filter.id].length > 0
        } else if (filter.value === "100") {
          if(row[filter.id].length > 0){
            return row[filter.id].filter(tr => tr.status !== 'passed').length === 0
          }else{
            return false;
          }
        } else if (filter.value === "not100") {
          if(row[filter.id].length > 0) {
            return row[filter.id].filter(tr => tr.status !== 'passed').length > 0
          }else{
            return false;
          }
        } else {
          return false;
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="notCovered">Not Covered</option>
          <option value="covered">Covered</option>
          <option value="100">100% passed</option>
          <option value="not100">{`< 100% passed`}</option>
        </select>
      )
    },
    {
      Header: "Notes",
      maxWidth: 250,
      accessor: "rmsDetail.note",
      sortable: false,
      headerStyle,
      Cell: (props) => {
        if (props.value) {
          return <NOTE {...props} />
        }
        return props.value;
      }
    },
    {
      Header: "Action",
      maxWidth: 70,
      accessor: "rmsDetail",
      sortable: false,
      filterable: false,
      headerStyle,
      // headerStyle: {...headerStyle, ...{
      //     backgroundColor: "#FE7303",
      // }},
      Cell: (props) => {
        if (props.value) {
          return getRmsAction(props.value);
        }
        return props.value;
      }
    }
    ]

  return (<>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <FormGroup row style={{ marginLeft: "18px"}}>
          <FormControlLabel
            control={<Switch checked={hideInactiveRms} onClick={(e) => setHideInactiveRms(e.target.checked)}  name="checkedEnv" />}
            label={"Hide Inactive RMS"}
          />
        </FormGroup>
        <div style={{display: "flex", alignSelf: "center", marginRight: "15px"}}>
          <BugViewAction data={data}/>
        </div>
      </div>
    <ReactTable
      pageSizeOptions={[25, 50, 100]}
      data={filterData}
      filterable
      resizable={true}
      columns={columns}
      defaultPageSize={25}
      showPaginationBottom={true}
      className="-striped -highlight"
      getTrProps={getTrProps}
      defaultFilterMethod={filterCaseInsensitive}
    >
      {(state, makeTable, instance) => {
        return reactTableHelper(state, makeTable, instance);
      }}
    </ReactTable>

    </>
  )
}


