import React, { useEffect, forwardRef } from 'react';

import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useDispatch, useSelector } from 'react-redux';

import {
  HEIMDALL_SETTING_CREATE_REQUESTED,
  HEIMDALL_SETTING_DELETE_REQUESTED,
  HEIMDALL_SETTING_GET_ALL_REQUESTED,
  HEIMDALL_SETTING_UPDATE_REQUESTED
} from '../../redux/actions';
import { Grid } from '@material-ui/core';
import { MainCard } from '../../heimdall-components';
import makeStyles from '@material-ui/core/styles/makeStyles';
import styles from './styles';
const useStyles = makeStyles(styles);
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export default function University() {
  const cs = useStyles();

  const getColumns = () => {
    return [
      {
        title: 'Name *',
        field: 'name',
        defaultSort: 'asc',
        validate: (rowData) => Boolean(rowData.name)
      },
      {
        title: 'Description',
        field: 'description'
      },
      {
        title: 'Is Active',
        field: 'isActive',
        lookup: {
          0: 'False',
          1: 'True'
        }
      }
    ];
  };

  const settings = useSelector((state) => state.heimdallSetting.settings);
  const saveSuccess = useSelector((state) => state.heimdallSetting.heimdallSettingCreateSuccess);
  const deleteSuccess = useSelector((state) => state.heimdallSetting.heimdallSettingDeleteSuccess);
  const updateSuccess = useSelector((state) => state.heimdallSetting.heimdallSettingUpdateSuccess);
  const dispatch = useDispatch();

  useEffect(() => {
    if (saveSuccess === true) {
      dispatch({ type: HEIMDALL_SETTING_GET_ALL_REQUESTED });
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (updateSuccess === true) {
      dispatch({ type: HEIMDALL_SETTING_GET_ALL_REQUESTED });
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (deleteSuccess === true) {
      dispatch({ type: HEIMDALL_SETTING_GET_ALL_REQUESTED });
    }
  }, [deleteSuccess]);

  const renderContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <div className={cs.chartContainer}>
            <>
              <MaterialTable
                title=""
                icons={tableIcons}
                columns={getColumns()}
                data={settings}
                options={{
                  // actionsColumnIndex: -1,
                  exportButton: false,
                  pageSize: 25,
                  pageSizeOptions: [25, 50],
                  headerStyle: {
                    backgroundColor: '#304753',
                    color: '#FFF'
                  }
                }}
                editable={{
                  onRowDelete: (newData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        dispatch({
                          type: HEIMDALL_SETTING_DELETE_REQUESTED,
                          payload: newData
                        });
                      }, 200);
                    }),
                  onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        dispatch({
                          type: HEIMDALL_SETTING_CREATE_REQUESTED,
                          payload: newData
                        });
                      }, 200);
                    }),
                  onRowUpdate: (newData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        resolve();
                        dispatch({
                          type: HEIMDALL_SETTING_UPDATE_REQUESTED,
                          payload: { payload: newData, id: newData.id }
                        });
                      }, 200);
                    })
                }}
              />
            </>
          </div>
        </Grid>
      </Grid>
    );
  };
  return <MainCard title="Daily Run Scheduler" content={renderContent()} />;
}
