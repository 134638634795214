import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  FormControl,
  NativeSelect,
  InputLabel,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  API_TEST_RESULTS_DIALOG_CLOSE,
  API_BUILDS_CREATE_REQUESTED,
  BUILDS_DIALOG_CLOSE,
  API_BUILDS_DIALOG_CLOSE
} from '../../redux/actions';

import { API_ENV, PARALLEL_OPTIONS, RUN_TYPE } from './constants';

import styles from './styles';
import { apiTestSubset } from '../../assets/config/testConfig';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
const useStyles = makeStyles(styles);

const DEFAULT_STATE = {
  branch: 'master'
};

export default function APIBuildDialog(props) {
  const cs = useStyles();

  const dispatch = useDispatch();

  const [branch, setBranch] = useState(DEFAULT_STATE.branch);
  const [env, setEnv] = useState(API_ENV[0].value);
  const [tag, setTag] = useState();
  const [parallelRunner, setParallelRunner] = useState(1);
  const [runType, setRunType] = useState(RUN_TYPE.RUN_ALL);
  const [subSet, setSubSet] = useState('');

  const open = useSelector((state) => state.apiBuildParams.open);
  const executor = useSelector((state) => state.login.userData.email);
  const incomingTags = useSelector((state) => state.apiBuildParams.tags);

  useEffect(() => {
    if (incomingTags) {
      setRunType(RUN_TYPE.BY_TAGS);
      setTag(incomingTags);
    } else {
      setRunType(RUN_TYPE.RUN_ALL);
      setTag('');
    }
  }, [incomingTags]);

  const handleClose = () => {
    setBranch(DEFAULT_STATE.branch);
    dispatch({ type: API_BUILDS_DIALOG_CLOSE });
  };

  const onBuildsCreateRequest = (payload) => {
    dispatch({ type: API_BUILDS_CREATE_REQUESTED, payload: payload });
    dispatch({ type: BUILDS_DIALOG_CLOSE });
    dispatch({ type: API_TEST_RESULTS_DIALOG_CLOSE });
  };

  const buildTags = () => {
    let runnerCount = parallelRunner;

    if (runType === RUN_TYPE.BY_TAGS && tag) {
      return tag;
    } else if (runType === RUN_TYPE.BY_SUB_SET && subSet) {
      if (runnerCount === 1) {
        return subSet;
      } else {
        const apiTag = apiTestSubset.find((t) => {
          return t.tag === subSet;
        });
        if (apiTag.length === 0) {
          return subSet;
        }
        return apiTag.testId.join('|');
      }
    }
    return '';
  };

  const checkIfAllValid = () => {
    let isValid = true;
    if (!env) {
      isValid = false;
    }

    if (!branch) {
      isValid = false;
    }
    if ((runType === RUN_TYPE.BY_TAGS && !tag) || (runType === RUN_TYPE.BY_SUB_SET && !subSet)) {
      isValid = false;
    }
    if (!branch) {
      isValid = false;
    }
    return isValid;
  };
  const handleCreateBuilds = () => {
    if (!checkIfAllValid()) {
      return;
    }

    onBuildsCreateRequest({
      parallel_runners: parallelRunner,
      branch,
      env,
      tag: buildTags(),
      executor
    });

    handleClose();
    props.history.push(`/builds`);
  };

  const renderParallelOptions = () => {
    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <InputLabel htmlFor="parallel-machines">Parallel Runners</InputLabel>
          <NativeSelect
            value={parallelRunner}
            onChange={(e) => setParallelRunner(e.target.value)}
            input={<Input name="Parallel Runners" id="parallel-machines" />}
          >
            {renderOptions(PARALLEL_OPTIONS)}
          </NativeSelect>
        </FormControl>
      </Grid>
    );
  };

  const renderBranch = () => {
    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <TextField
            label="Heimdal-API Branch"
            id="branch"
            onChange={(e) => setBranch(e.target.value)}
            value={branch || ''}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderTag = () => {
    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <TextField
            label="tag"
            id="tag"
            onChange={(e) => setTag(e.target.value)}
            value={tag || ''}
          />
        </FormControl>
      </Grid>
    );
  };

  const renderEnv = () => {
    return (
      <>
        <Grid item>
          <FormControl className={cs.formControl}>
            <InputLabel htmlFor="nora-site">Env</InputLabel>
            <NativeSelect
              value={env}
              onChange={(e) => setEnv(e.target.value)}
              input={<Input name="Environment" id="env" />}
            >
              {renderOptions(API_ENV)}
            </NativeSelect>
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderSubsetTags = () => {
    apiTestSubset.sort((a, b) => {
      return a.name.toUpperCase() - b.name.toUpperCase();
    });
    const ts = apiTestSubset.map((sub) => {
      const label = `${sub.name} (${sub.count}+)`;
      return (
        <MenuItem key={sub.tag} value={sub.tag}>
          {label}
        </MenuItem>
      );
    });
    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <InputLabel htmlFor="subSet">Test SubSet</InputLabel>
          <Select
            value={subSet}
            onChange={(e) => setSubSet(e.target.value)}
            inputProps={{
              name: 'subSet',
              id: 'subSet'
            }}
          >
            {ts}
          </Select>
        </FormControl>
      </Grid>
    );
  };
  const renderOptions = (options) => {
    return options.map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });
  };

  const renderRunTypeChoices = () => {
    return (
      <RadioGroup
        className={cs.radioGroup}
        aria-label="position"
        name="Run-Type"
        value={runType}
        onChange={(e) => setRunType(e.target.value)}
        row
      >
        <FormControlLabel
          value={RUN_TYPE.RUN_ALL}
          control={<Radio color="primary" />}
          label="All Tests"
        />
        <FormControlLabel
          value={RUN_TYPE.BY_TAGS}
          control={<Radio color="primary" />}
          label="By Test Tags"
        />
        <FormControlLabel
          value={RUN_TYPE.BY_SUB_SET}
          control={<Radio color="primary" />}
          label="By Sub Set"
        />
      </RadioGroup>
    );
  };
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Create Build (Backend)</DialogTitle>
      <DialogContent>
        <Grid container>{renderRunTypeChoices()}</Grid>
        <Grid container direction="column">
          {renderParallelOptions()}
          {runType === RUN_TYPE.BY_TAGS && renderTag()}
          {runType === RUN_TYPE.BY_SUB_SET && renderSubsetTags()}
          {renderEnv()}
          {renderBranch()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={cs.buttonsContainer}>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateBuilds} color="primary" autoFocus>
            Create Builds
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
