import { combineReducers } from 'redux';
import TestRunsReducer from './TestRuns';
import ApiTestRunsReducer from './ApiTestRuns';
import LoadTestRunsReducer from './LoadTestRuns';
import DynamicTagsReducer from './DynamicTags';
import PrintReducer from './Print';
import BuildDialog from './BuildDialog';
import APIBuildDialog from './APIBuildDialog';
import LoadBuildDialog from './LoadBuildDialog';
import TestResults from './TestResults';
import ApiTestResults from './ApiTestResults';
import TestResultDetail from './TestResultDetail';
import APITestResultDetail from './APITestResultDetail';
import Filter from './Filter';
import Login from './Login';
import Tab from './Tab';
import Spira from './SpiraResults';
import APIRequestLogDetail from './APIRequestLogDetail';
import TraceabilityMatrix from './TraceabilityMatrix';
import DHValidation from './DHValidation';
import HeimdallSetting from './HeimdallSetting';

const rootReducer = combineReducers({
  testRuns: TestRunsReducer,
  apiTestRuns: ApiTestRunsReducer,
  loadTestRuns: LoadTestRunsReducer,
  buildParams: BuildDialog,
  apiBuildParams: APIBuildDialog,
  loadBuildParams: LoadBuildDialog,
  testResults: TestResults,
  apiTestResults: ApiTestResults,
  testDetail: TestResultDetail,
  apiTestDetail: APITestResultDetail,
  apiRequestLog: APIRequestLogDetail,
  dtags: DynamicTagsReducer,
  filter: Filter,
  login: Login,
  tab: Tab,
  print: PrintReducer,
  spira: Spira,
  traceabilityMatrix: TraceabilityMatrix,
  dhValidation: DHValidation,
  heimdallSetting: HeimdallSetting
});

export default rootReducer;
