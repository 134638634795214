import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  FormControl,
  NativeSelect,
  InputLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  BUILDS_DIALOG_CLOSE,
  LOAD_BUILDS_DIALOG_CLOSE,
  LOAD_BUILDS_CREATE_REQUESTED
} from '../../redux/actions';

import { API_ENV, LOAD_LOCATION, LOAD_TEST_FILES, TEAM } from './constants';

import styles from './styles';
import Alert from '@material-ui/lab/Alert';
import { LOAD_RUN_TYPE } from '../APIBuildDialog/constants';
const useStyles = makeStyles(styles);

const DEFAULT_STATE = {
  branch: 'master',
  users: '20',
  runTime: '5m',
  spawnRate: '0.2',
  team: TEAM[0].value,
  runType: LOAD_RUN_TYPE.GENERAL
};

export default function LoadBuildDialog(props) {
  const cs = useStyles();

  const dispatch = useDispatch();

  const [validationError, setValidationError] = useState();
  const [env, setEnv] = useState(API_ENV[0].value);
  const [customEnv, setCustomEnv] = useState();
  const [host, setHost] = useState();
  const [branch, setBranch] = useState(DEFAULT_STATE.branch);
  const [team, setTeam] = useState(DEFAULT_STATE.team);
  const [testFile, setTestFile] = useState(LOAD_TEST_FILES[DEFAULT_STATE.team][0].value);
  const [runTime, setRunTime] = useState(DEFAULT_STATE.runTime);
  const [spawnRate, setSpawnRate] = useState(DEFAULT_STATE.spawnRate);
  const [loadRegion, setLoadRegion] = useState(LOAD_LOCATION[0].value);
  const [users, setUsers] = useState(DEFAULT_STATE.users);
  const [query, setQuery] = useState();
  const [runType, setRunType] = useState(DEFAULT_STATE.runType);

  const executor = useSelector((state) => state.login.userData.email);

  const open = useSelector((state) => state.loadBuildParams.open);

  useEffect(() => {
    if (!open) {
      setEnv(API_ENV[0].value);
      setCustomEnv('');
      setHost('');
    }
  }, [open]);

  useEffect(() => {
    setTestFile(LOAD_TEST_FILES[team][0].value);
  }, [team]);

  useEffect(() => {
    if (runType === LOAD_RUN_TYPE.CUSTOM_DB) {
      setEnv(API_ENV.filter((env) => env.dbTest)[0].value);
      setTestFile(LOAD_TEST_FILES.db[1].value);
      setRunTime('2m');
      setUsers('2');
    } else if (runType === LOAD_RUN_TYPE.GENERAL) {
      setEnv(API_ENV[0].value);
      setTestFile(LOAD_TEST_FILES[DEFAULT_STATE.team][0].value);
      setRunTime('5m');
      setUsers('20');
      setQuery('');
    }
  }, [runType]);

  const handleClose = () => {
    setBranch(DEFAULT_STATE.branch);
    setRunTime(DEFAULT_STATE.runTime);
    setUsers(DEFAULT_STATE.users);
    dispatch({ type: LOAD_BUILDS_DIALOG_CLOSE });
    setValidationError('');
  };

  const onBuildsCreateRequest = (payload) => {
    dispatch({ type: LOAD_BUILDS_CREATE_REQUESTED, payload: payload });
    dispatch({ type: BUILDS_DIALOG_CLOSE });
  };

  const checkIfAllValid = () => {
    let isValid = true;
    setValidationError('');
    if (runType === LOAD_RUN_TYPE.CUSTOM_DB) {
      if (!query) {
        isValid = false;
        setValidationError('DB Query is missing');
      } else if (query.includes('\n')) {
        isValid = false;
        setValidationError('DB Query is invalid');
      }
    }

    if (env === 'Other') {
      if (!host) {
        isValid = false;
        setValidationError('Missing custom host');
      }
    }
    if (!env) {
      console.log('Invalid env', env);
      isValid = false;
      setValidationError('Invalid Env');
    }
    if (!branch) {
      console.log('Invalid branch', branch);
      isValid = false;
      setValidationError('Invalid branch');
    }

    if (!testFile) {
      console.log('Invalid testFile', testFile);
      isValid = false;
      setValidationError('Invalid testFile');
    }

    if (!(runTime.includes('m') || runTime.includes('s'))) {
      console.log('Invalid duration', runTime);
      isValid = false;
      setValidationError('Define duration in min or sec. eg: 5m or 20s');
    }

    if (!users) {
      console.log('Invalid users', users);
      isValid = false;
      setValidationError('Invalid users');
    }

    return isValid;
  };
  const handleCreateBuilds = () => {
    if (!checkIfAllValid()) {
      return;
    }
    let finalEnv = env;
    let finalHost = API_ENV.find((e) => e.value === env).host;
    if (finalEnv === 'Other') {
      finalEnv = customEnv || 'N/A';
      finalHost = host;
    }
    onBuildsCreateRequest({
      branch,
      env: finalEnv,
      host: finalHost,
      testFile,
      runTime,
      spawnRate,
      users,
      executor,
      loadRegion,
      query
    });

    handleClose();
    props.history.push(`/builds`);
  };

  const renderOtherSiteOptions = () => {
    return (
      <Grid item style={{ display: 'flex', marginTop: '4%' }}>
        <FormControl className={cs.formControlMin}>
          <TextField
            label="Env (Not Mandatory)"
            id="new-env"
            placeholder="global_dev1"
            onChange={(e) => setCustomEnv(e.target.value)}
            value={customEnv}
          />
        </FormControl>
        <FormControl className={cs.formControlMin}>
          <TextField
            label="Host"
            id="new-host"
            placeholder="https://dev1-platform.s37dev.com/"
            onChange={(e) => setHost(e.target.value)}
            value={host}
          />
        </FormControl>
      </Grid>
    );
  };
  const renderGeneralChoices = () => {
    return (
      <>
        {validationError && (
          <Grid item>
            <Alert severity="error">{validationError}</Alert>
          </Grid>
        )}
        <Grid item>
          <FormControl className={cs.formControl}>
            <InputLabel htmlFor="nora-site">Env</InputLabel>
            <NativeSelect
              value={env}
              onChange={(e) => setEnv(e.target.value)}
              input={<Input name="Environment" id="env" />}
            >
              {renderOptions(API_ENV)}
            </NativeSelect>
          </FormControl>
        </Grid>
        {env === 'Other' && renderOtherSiteOptions()}
        <Grid item>
          <FormControl className={cs.formControl}>
            <InputLabel htmlFor="team">Team</InputLabel>
            <NativeSelect
              value={team}
              onChange={(e) => setTeam(e.target.value)}
              input={<Input name="Team" id="team" />}
            >
              {renderOptions(TEAM)}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={cs.formControl}>
            <InputLabel htmlFor="feature">Feature</InputLabel>
            <NativeSelect
              value={testFile}
              onChange={(e) => setTestFile(e.target.value)}
              input={<Input name="Test Feature" id="testFeature" />}
            >
              {renderOptions(LOAD_TEST_FILES[`${team}`])}
            </NativeSelect>
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderCustomDBChoices = () => {
    return (
      <>
        {validationError && (
          <Grid item>
            <Alert severity="error">{validationError}</Alert>
          </Grid>
        )}
        <Grid item>
          <FormControl className={cs.formControl}>
            <InputLabel htmlFor="nora-site">Env</InputLabel>
            <NativeSelect
              value={env}
              onChange={(e) => setEnv(e.target.value)}
              input={<Input name="Environment" id="env" />}
            >
              {renderOptions(API_ENV.filter((env) => env.dbTest))}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={cs.formControl}>
            <TextField
              label="DB Query"
              id="dbQuery"
              rows={4}
              multiline
              onChange={(e) => setQuery(e.target.value)}
              value={query || ''}
            />
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderLoadChoices = () => {
    return (
      <>
        <Grid style={{ display: 'flex' }}>
          <FormControl className={cs.formControlMin}>
            <TextField
              label="No. of Users"
              id="users"
              onChange={(e) => setUsers(e.target.value)}
              value={users || ''}
            />
          </FormControl>
          <FormControl className={cs.formControlMin}>
            <TextField
              label="Duration"
              id="runTime"
              onChange={(e) => setRunTime(e.target.value)}
              value={runTime || ''}
            />
          </FormControl>
        </Grid>
        <Grid item style={{ display: 'flex', marginTop: '4%' }}>
          <FormControl className={cs.formControlMin}>
            <TextField
              label="Spawn Rate (user/sec)"
              id="spawnRate"
              onChange={(e) => setSpawnRate(e.target.value)}
              value={spawnRate || ''}
            />
          </FormControl>
          <FormControl className={cs.formControlMin}>
            <InputLabel htmlFor="loadRegion">Load Location</InputLabel>
            <NativeSelect
              value={loadRegion}
              onChange={(e) => setLoadRegion(e.target.value)}
              input={<Input name="Load Location" id="loadRegion" />}
            >
              {renderOptions(LOAD_LOCATION)}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={cs.formControl}>
            <TextField
              label="Heimdal-Load Branch"
              id="branch"
              onChange={(e) => setBranch(e.target.value)}
              value={branch || ''}
            />
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderOptions = (options) => {
    return options.map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });
  };

  const renderRunTypeChoices = () => {
    return (
      <RadioGroup
        className={cs.radioGroup}
        aria-label="position"
        name="Run-Type"
        value={runType}
        onChange={(e) => setRunType(e.target.value)}
        row
      >
        <FormControlLabel
          value={LOAD_RUN_TYPE.GENERAL}
          control={<Radio color="primary" />}
          label="General"
        />
        <FormControlLabel
          value={LOAD_RUN_TYPE.CUSTOM_DB}
          control={<Radio color="primary" />}
          label="Custom DB"
        />
      </RadioGroup>
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">Create Build (Load)</DialogTitle>
      <DialogContent>
        <Grid container>{renderRunTypeChoices()}</Grid>
        <Grid container direction="column">
          {runType === LOAD_RUN_TYPE.GENERAL && renderGeneralChoices()}
          {runType === LOAD_RUN_TYPE.CUSTOM_DB && renderCustomDBChoices()}
          {renderLoadChoices()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={cs.buttonsContainer}>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateBuilds} color="primary" autoFocus>
            Create Builds
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
