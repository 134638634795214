const testSubset = [
  {
    name: 'Smoke Test',
    count: 19,
    tag: '@smoke',
    testId: [
      '@4797',
      '@6193',
      '@4721',
      '@4457',
      '@5332',
      '@6015',
      '@7436',
      '@7332',
      '@7338',
      '@7371',
      '@7237',
      '@5044',
      '@5053',
      '@7394',
      '@7017',
      '@6021',
      '@5579',
      '@5302',
      '@4471'
    ]
  },
  {
    name: 'Leads',
    count: 49,
    tag: '@leads',
    testId: [
      '@4989',
      '@4991',
      '@4953',
      '@4992',
      '@4955',
      '@4954',
      '@4986',
      '@4956',
      '@4952',
      '@4464',
      '@4951',
      '@4916',
      '@4907',
      '@4933',
      '@4927',
      '@4936',
      '@4913',
      '@4439',
      '@4482',
      '@4920',
      '@4702',
      '@4443',
      '@4921',
      '@4905',
      '@4982',
      '@4904',
      '@4709',
      '@4976',
      '@4919',
      '@4698',
      '@4720',
      '@4457',
      '@4721',
      '@4725',
      '@4726',
      '@4727',
      '@4793',
      '@4892',
      '@4869',
      '@4985',
      '@6071',
      '@6073',
      '@6175',
      '@6074',
      '@4859',
      '@4852',
      '@4868',
      '4857'
    ]
  },
  {
    name: 'Clinical Trial',
    count: 43,
    tag: '@clinicalTrials',
    testId: [
      '@5183',
      '@5182',
      '@7557',
      '@5190',
      '@5174',
      '@5189',
      '@5188',
      '@5191',
      '@5170',
      '@5184',
      '@5178',
      '@5171',
      '@5173',
      '@5187',
      '@5172',
      '@5186',
      '@5141',
      '@4839',
      '@5071',
      '@5136',
      '@7301',
      '@5053',
      '@6231',
      '@5048',
      '@5058',
      '@4797',
      '@5049',
      '@5055',
      '@5062',
      '@5044',
      '@5054',
      '@4801',
      '@5069',
      '@5046',
      '@4854',
      '@4800',
      '@5151',
      '@5148',
      '@5159',
      '@5149',
      '@5150',
      '@5157',
      '@5155'
    ]
  },
  {
    name: 'Qualifying Project',
    count: 30,
    tag: '@qualifyingProject',
    testId: [
      '@5665',
      '@5664',
      '@6233',
      '@5579',
      '@5659',
      '@6931',
      '@5658',
      '@5662',
      '@5663',
      '@5581',
      '@5661',
      '@5660',
      '@5621',
      '@5617',
      '@5616',
      '@5614',
      '@5615',
      '@5582',
      '@5610',
      '@5576',
      '@5619',
      '@5618',
      '@5611',
      '@5613',
      '@5574'
    ]
  },
  {
    name: 'Form Builder',
    count: 31,
    tag: '@forms',
    testId: [
      '@6899',
      '@6061',
      '@6898',
      '@6062',
      '@6063',
      '@6066',
      '@6897',
      '@6901',
      '@6065',
      '@6064',
      '@6909',
      '@6900',
      '@6196',
      '@7622',
      '@6197',
      '@6930',
      '@6195',
      '@7607',
      '@6194',
      '@7487',
      '@6953',
      '@6198',
      '@6191',
      '@6202',
      '@6199',
      '@6200',
      '@6192',
      '@6201',
      '@6193',
      '@7468',
      '@7436'
    ]
  },
  {
    name: 'Integration Admin',
    count: 14,
    tag: '@integrationadmin',
    testId: [
      '@7500',
      '@7402',
      '@7501',
      '@7615',
      '@7394',
      '@7400',
      '@7616',
      '@7401',
      '@7399',
      '@7398',
      '@7488',
      '@7393',
      '@7499',
      '@7489'
    ]
  },
  {
    name: 'Reports Management',
    count: 23,
    tag: '@reports',
    testId: [
      '@7129',
      '@7180',
      '@7175',
      '@7181',
      '@7167',
      '@7177',
      '@7158',
      '@7176',
      '@7166',
      '@7159',
      '@7163',
      '@7173',
      '@7172',
      '@7162',
      '@7160',
      '@7170',
      '@7179',
      '@7169',
      '@7168',
      '@7178',
      '@7171',
      '@7161'
    ]
  },
  {
    name: 'User Accounts',
    count: 14,
    tag: '@users',
    testId: [
      '@7023',
      '@7485',
      '@7484',
      '@7016',
      '@7486',
      '@7017',
      '@7111',
      '@7483',
      '@7576',
      '@7018',
      '@7112',
      '@7577',
      '@7019',
      '@7113'
    ]
  },
  {
    name: 'eConsent',
    count: 7,
    tag: '@eConsent',
    testId: ['@7617', '@6019', '@7612', '@7110', '@6015', '@6972', '@7316']
  },
  {
    name: 'Edit Reason',
    count: 7,
    tag: '@editReason',
    testId: ['@7743', '@7742', '@7741', '@7744', '@7745', '@7747', '@7746']
  },
  {
    name: 'eSignature',
    count: 4,
    tag: '@esig',
    testId: ['@7630', '@7644', '@7627', '@7645']
  },
  {
    name: 'Fill Schedule Form',
    count: 8,
    tag: '@fillScheduleForm',
    testId: ['@7368', '@7594', '@7371', '@7630', '@7644', '@7627', '@7645', '@7593']
  },
  {
    name: 'Flagging Feature',
    count: 2,
    tag: '@flagging',
    testId: ['@7364', '@7332']
  },
  {
    name: 'Localization',
    count: 15,
    tag: '@localization',
    testId: [
      '@7502',
      '@6294',
      '@6078',
      '@7621',
      '@7639',
      '@7620',
      '@7617',
      '@6019',
      '@7612',
      '@6015',
      '@7442',
      '@8657',
      '@8654',
      '@8078',
      '@8655'
    ]
  },
  {
    name: 'Mobile Web',
    count: 5,
    tag: '@mobileWeb',
    testId: ['@7683', '@7688', '@7689', '@7685', '@7684']
  },
  {
    name: 'Patient Calendar',
    count: 3,
    tag: '@calendar',
    testId: ['@7020', '@7021', '@7118']
  },
  {
    name: 'Patient Contacts',
    count: 4,
    tag: '@contacts',
    testId: ['@4673', '@4668', '@7012', '@4652', '@4666']
  },
  {
    name: 'Patient Documents',
    count: 9,
    tag: '@documents',
    testId: ['@7676', '@6948', '@6949', '@6942', '@6946', '@7353', '@6947', '@6945', '@6944']
  },
  {
    name: 'Patient Photos',
    count: 23,
    tag: '@photos',
    testId: [
      '@5909',
      '@5900',
      '@4505',
      '@5901',
      '@5911',
      '@4514',
      '@5908',
      '@4522',
      '@5913',
      '@5897',
      '@4538',
      '@5912',
      '@5907',
      '@5892',
      '@5893',
      '@5906',
      '@5891',
      '@5888',
      '@5898',
      '@5899',
      '@5889',
      '@5905',
      '@5890'
    ]
  },
  {
    name: 'Trial Landing Page',
    count: 13,
    tag: '@tlps',
    testId: [
      '4470',
      '4439',
      '4471',
      '4482',
      '5198',
      '7613',
      '8176',
      '8682',
      '8683',
      '8684',
      '8689',
      '8700',
      '8704'
    ]
  },
  {
    name: 'Query Management',
    count: 50,
    tag: '@queryMgmt',
    testId: [
      '@7536',
      '@7537',
      '@7585',
      '@7538',
      '@7539',
      '@7540',
      '@7586',
      '@7534',
      '@7543',
      '@7529',
      '@7542',
      '@7541',
      '@7526',
      '@7531',
      '@7528',
      '@7530',
      '@7544',
      '@7527',
      '@7532',
      '@7533',
      '@7596',
      '@7597',
      '@7535',
      '@7560',
      '@7564',
      '@7435',
      '@7584',
      '@7432',
      '@7431',
      '@7429',
      '@7430',
      '@7494',
      '@7587',
      '@7339',
      '@7423',
      '@7491',
      '@7490',
      '@7335',
      '@7338',
      '@7417',
      '@7416',
      '@7337',
      '@7419',
      '@7503',
      '@7420',
      '@7424',
      '@7563',
      '@7595',
      '@7559',
      '@7583'
    ]
  },
  {
    name: 'Patient Web Portal',
    count: 14,
    tag: '@patientWebPortal',
    testId: [
      '@7502',
      '@6294',
      '@6078',
      '@7623',
      '@7621',
      '@7639',
      '@7620',
      '@7617',
      '@6019',
      '@7612',
      '@7110',
      '@6015',
      '@6972',
      '@7442'
    ]
  },
  {
    name: 'Reference Data',
    count: 9,
    tag: '@referenceData',
    testId: ['@7237', '@7239', '@7238', '@7224', '@7225', '@7223', '@5165', '@4996', '@7217']
  },
  {
    name: 'Security Matrix',
    count: 90,
    tag: '@securityMatrix',
    testId: [
      '@7386',
      '@7384',
      '@7379',
      '@7378',
      '@7385',
      '@7380',
      '@7381',
      '@7383',
      '@7377',
      '@7382',
      '@7376',
      '@7256',
      '@7257',
      '@7255',
      '@7254',
      '@7251',
      '@7248',
      '@7249',
      '@7250',
      '@7264',
      '@7252',
      '@7253',
      '@7265',
      '@7413',
      '@7403',
      '@7412',
      '@7410',
      '@7409',
      '@7408',
      '@7411',
      '@7414',
      '@7404',
      '@7405',
      '@7415',
      '@7443',
      '@7407',
      '@7406',
      '@7143',
      '@7203',
      '@7208',
      '@7211',
      '@7210',
      '@7209',
      '@7205',
      '@7266',
      '@7126',
      '@7206',
      '@7207',
      '@7554',
      '@7545',
      '@7555',
      '@7547',
      '@7546',
      '@7556',
      '@7553',
      '@7646',
      '@7552',
      '@7549',
      '@7550',
      '@7551',
      '@7548',
      '@7327',
      '@7324',
      '@7329',
      '@7320',
      '@7330',
      '@7321',
      '@7328',
      '@7323',
      '@7322',
      '@7308',
      '@7311',
      '@7310',
      '@7309',
      '@7312',
      '@7313',
      '@7306',
      '@7307',
      '@7305',
      '@7315',
      '@7314',
      '@7304',
      '@7363',
      '@7362',
      '@7360',
      '@7356',
      '@7357',
      '@7361',
      '@7358',
      '@7359'
    ]
  },
  {
    name: 'Patient Interaction',
    count: 150,
    tag: '@patientInteraction',
    testId: [
      '@7316',
      '@7676',
      '@6948',
      '@6949',
      '@6942',
      '@6946',
      '@7353',
      '@6947',
      '@6945',
      '@6944',
      '@7536',
      '@7537',
      '@7585',
      '@7538',
      '@7539',
      '@7540',
      '@7586',
      '@7534',
      '@7543',
      '@7529',
      '@7542',
      '@7541',
      '@7526',
      '@7531',
      '@7528',
      '@7530',
      '@7544',
      '@7527',
      '@7532',
      '@7533',
      '@7596',
      '@7597',
      '@7535',
      '@7560',
      '@7564',
      '@5584',
      '@5670',
      '@5669',
      '@7364',
      '@7332',
      '@5909',
      '@5900',
      '@4505',
      '@5901',
      '@5911',
      '@4514',
      '@5908',
      '@4522',
      '@5913',
      '@5897',
      '@4538',
      '@5912',
      '@5907',
      '@5892',
      '@5893',
      '@5906',
      '@5891',
      '@5888',
      '@5898',
      '@5899',
      '@5889',
      '@5905',
      '@5890',
      '@5665',
      '@5664',
      '@6233',
      '@5579',
      '@5659',
      '@6931',
      '@5658',
      '@5662',
      '@5663',
      '@5581',
      '@5661',
      '@5660',
      '@5621',
      '@5617',
      '@5616',
      '@5614',
      '@5615',
      '@5582',
      '@5610',
      '@5576',
      '@5619',
      '@5618',
      '@5611',
      '@5613',
      '@5574',
      '@7196',
      '@7342',
      '@7743',
      '@7742',
      '@7741',
      '@7744',
      '@7745',
      '@7747',
      '@7746',
      '@5578',
      '@5636',
      '@5637',
      '@7151',
      '@7575',
      '@7344',
      '@7343',
      '@7368',
      '@7594',
      '@7371',
      '@7630',
      '@7644',
      '@7627',
      '@7645',
      '@7593',
      '@4673',
      '@4668',
      '@7012',
      '@4652',
      '@4666',
      '@7020',
      '@7021',
      '@7118',
      '@7435',
      '@7584',
      '@7432',
      '@7431',
      '@7429',
      '@7430',
      '@7494',
      '@7587',
      '@7339',
      '@7423',
      '@7491',
      '@7490',
      '@7335',
      '@7338',
      '@7417',
      '@7416',
      '@7337',
      '@7419',
      '@7503',
      '@7420',
      '@7424',
      '@7563',
      '@7595',
      '@7559',
      '@7583',
      '@7683',
      '@7688',
      '@7689',
      '@7685',
      '@7684'
    ]
  },
  {
    name: 'Patient Management',
    count: 32,
    tag: '@patientManagement',
    testId: [
      '@5334',
      '@5335',
      '@5301',
      '@5333',
      '@5332',
      '@5339',
      '@5286',
      '@5296',
      '@5279',
      '@5278',
      '@5285',
      '@5295',
      '@5280',
      '@5288',
      '@5281',
      '@5291',
      '@5283',
      '@5282',
      '@5292',
      '@7373',
      '@5346',
      '@5345',
      '@5277',
      '@6022',
      '@6021',
      '@6020',
      '@5302',
      '@5297',
      '@5303',
      '@5305',
      '@5304'
    ]
  },
  {
    name: 'Respondents',
    count: 7,
    tag: '@respondents',
    testId: ['@8682', '@8683', '@8684', '@8689', '@8700', '@8704', '@8797']
  },
  {
    name: 'Regression',
    count: 57,
    tag: '@regression',
    testId: [
      '7019',
      '7113',
      '7400',
      '5335',
      '6020',
      '4991',
      '4913',
      '4721',
      '4725',
      '6955',
      '7623',
      '7617',
      '7110',
      '5850',
      '7237',
      '5178',
      '5170',
      '4839',
      '7316',
      '6942',
      '7527',
      '7788',
      '7332',
      '4514',
      '6931',
      '7196',
      '7746',
      '5578',
      '7575',
      '7344',
      '7368',
      '7594',
      '7630',
      '4666',
      '7432',
      '7423',
      '7416',
      '7559',
      '6062',
      '6194',
      '6200',
      '6192',
      '4380',
      '4381',
      '5668',
      '7332',
      '7364',
      '7796',
      '7845',
      '7797',
      '5297',
      '5587',
      '7023',
      '8609'
    ]
  },
  {
    name: 'SP1006 - Automation',
    count: 150,
    tag: '@SP1006_-_Automation',
    testId: [
      '@SP1006_-_Automation1',
      '@SP1006_-_Automation2',
      '@SP1006_-_Automation3',
      '@SP1006_-_Automation4'
    ]
  },
  {
    name: 'RL0007 - Automation',
    count: 122,
    tag: '@RL0007_-_Automation',
    testId: [
      '@RL0007_-_Automation1',
      '@RL0007_-_Automation2',
      '@RL0007_-_Automation3',
      '@RL0007_-_Automation4'
    ]
  },
  {
    name: 'EFC16855 COVID-19 - Automation',
    count: 5,
    tag: '@EFC16855_COVID-19_-_Automation',
    testId: [
      '@EFC16855_COVID-19_-_Automation1',
      '@EFC16855_COVID-19_-_Automation2',
      '@EFC16855_COVID-19_-_Automation3',
      '@EFC16855_COVID-19_-_Automation4'
    ]
  },
  {
    name: 'Incyte_COVID-19 Mitigation - Automation',
    count: 5,
    tag: '@Incyte_COVID-19_Mitigation_-_Automation',
    testId: [
      '@Incyte_COVID-19_Mitigation_-_Automation1',
      '@Incyte_COVID-19_Mitigation_-_Automation2',
      '@Incyte_COVID-19_Mitigation_-_Automation3',
      '@Incyte_COVID-19_Mitigation_-_Automation4'
    ]
  },
  {
    name: 'NOPRODNAP0017 - Automation',
    count: 79,
    tag: '@NOPRODNAP0017_-_Automation',
    testId: [
      '@NOPRODNAP0017_-_Automation1',
      '@NOPRODNAP0017_-_Automation2',
      '@NOPRODNAP0017_-_Automation3',
      '@NOPRODNAP0017_-_Automation4'
    ]
  },
  {
    name: 'UAT_AMG20180060 Episodic Migraine - Automation',
    count: 106,
    tag: '@UAT_AMG20180060_Episodic_Migraine_-_Automation',
    testId: [
      '@UAT_AMG20180060_Episodic_Migraine_-_Automation1',
      '@UAT_AMG20180060_Episodic_Migraine_-_Automation2',
      '@UAT_AMG20180060_Episodic_Migraine_-_Automation3',
      '@UAT_AMG20180060_Episodic_Migraine_-_Automation4'
    ]
  },
  {
    name: 'USC Atopic Dermatitis - Automation',
    count: 52,
    tag: '@USC_Atopic_Dermatitis_-_Automation',
    testId: [
      '@USC_Atopic_Dermatitis_-_Automation1',
      '@USC_Atopic_Dermatitis_-_Automation2',
      '@USC_Atopic_Dermatitis_-_Automation3',
      '@USC_Atopic_Dermatitis_-_Automation4'
    ]
  }
];

// TODO: Update Test IDs on the subset
const apiTestSubset = [
  {
    name: 'Smoke Test',
    count: 121,
    tag: '@smoke',
    testId: []
  },
  {
    name: 'Trial API Test',
    count: 122,
    tag: '@trialApi',
    testId: []
  },
  {
    name: 'User Admin API Test',
    count: 82,
    tag: '@userAdminApi',
    testId: []
  },
  {
    name: 'Patient API Test',
    count: 74,
    tag: '@patientApi',
    testId: []
  },
  {
    name: 'Patient Contact API Test',
    count: 39,
    tag: '@patientContactsApi',
    testId: []
  },
  {
    name: 'Tenant API Test',
    count: 31,
    tag: '@tenantApi',
    testId: []
  },
  {
    name: 'Meeting API Test',
    count: 17,
    tag: '@meetingsApi',
    testId: []
  },
  {
    name: 'Visit Plan API Test',
    count: 38,
    tag: '@visitPlanApi',
    testId: []
  },
  {
    name: 'Form Controls API Test',
    count: 5,
    tag: '@formControlsApi',
    testId: []
  },
  {
    name: 'Form Scaffold API Test',
    count: 5,
    tag: '@formScaffoldApi',
    testId: []
  },
  {
    name: 'Form Builder API Test',
    count: 40,
    tag: '@formBuilderApi',
    testId: []
  },
  {
    name: 'Audit Trail API Test',
    count: 5,
    tag: '@auditTrailApi',
    testId: []
  },
  {
    name: 'Notifications Management API Test',
    count: 16,
    tag: '@notificationsManagementApi',
    testId: []
  }
];

const nextGenTestSubSet = [
  {
    name: 'Smoke Test',
    count: 20,
    tag: '@smoke',
    testId: [
      '9061',
      '9059',
      '7483',
      '9224',
      '9233',
      '4797',
      '4854',
      '5148',
      '5150',
      '9047',
      '9041',
      '9308',
      '5292',
      '5281',
      '8953',
      '8976',
      '6942',
      '6947',
      '4522',
      '5899',
      '9382'
    ]
  },
  {
    name: 'Appointments',
    count: 9,
    tag: '@appointments',
    testId: ['8972', '8954', '8956', '8968', '8957', '8953', '8975', '8976', '8955']
  },
  {
    name: 'Visit Plans',
    count: 6,
    tag: '@visitPlans',
    testId: ['9044', '9045', '9047', '9042', '9049', '9041']
  },
  {
    name: 'Video Visit / Zoom',
    count: 2,
    tag: '@videoVisit',
    testId: ['9307', '9308']
  },
  {
    name: 'Tenant Setting',
    count: 2,
    tag: '@tenantSetting',
    testId: ['9223', '9224']
  },
  {
    name: 'Patient Management',
    count: 11,
    tag: '@patientManagement',
    testId: ['5302', '5292', '5286', '5279', '5278', '5285', '5280', '5288', '5281', '5283', '5282']
  },
  {
    name: 'Patient Documents',
    count: 8,
    tag: '@patientDocuments',
    testId: ['9228', '6948', '6949', '6942', '6947', '6945', '6944', '9234']
  },
  {
    name: 'Patient Photos',
    count: 9,
    tag: '@patientPhotos',
    testId: ['9281', '4514', '4522', '9280', '5913', '4538', '9237', '5888', '5899']
  },
  {
    name: 'Patient Contacts',
    count: 0,
    tag: '@patientContacts',
    testId: []
  },
  {
    name: 'User Management',
    count: 12,
    tag: '@userManagement',
    testId: [
      '7016',
      '9064',
      '7017',
      '9062',
      '9063',
      '9061',
      '9205',
      '9056',
      '9060',
      '9059',
      '7483',
      '7576'
    ]
  },
  {
    name: 'Clinical Trial',
    count: 22,
    tag: '@clinicalTrial',
    testId: [
      '9233',
      '9236',
      '9213',
      '9235',
      '9225',
      '5058',
      '4797',
      '5049',
      '5062',
      '4797',
      '5046',
      '4854',
      '4800',
      '5148',
      '5151',
      '5148',
      '5159',
      '5149',
      '5150',
      '5148',
      '5157',
      '5155'
    ]
  },
  {
    name: 'Form Builder',
    count: 40,
    tag: '@formBuilder',
    testId: [
      '9382',
      '9436',
      '9437',
      '9451',
      '9455',
      '9459',
      '9462',
      '9499',
      '9500',
      '9525',
      '9526',
      '9570',
      '9622',
      '9665',
      '9670',
      '9573',
      '9674',
      '9686',
      '9688',
      '9689',
      '9690',
      '9785',
      '9786',
      '9807',
      '9831',
      '9860',
      '9863',
      '9868',
      '9870',
      '9871',
      '9872',
      '9974',
      '10046',
      '10141',
      '10162',
      '10176',
      '10187',
      '9975',
      '10069',
      '10229',
      '10231',
      '10276'
    ]
  },
  {
    name: 'Form Management',
    count: 10,
    tag: '@formManagement',
    testId: [
      '10060',
      '10061',
      '10062',
      '10063',
      '10064',
      '10065',
      '10066',
      '100667',
      '9965',
      '9880',
      '9790'
    ]
  }
];

const nextGenMajorTags = [
  '@clinicalTrials',
  '@formBuilder',
  '@formManagement',
  '@formTemplate',
  '@appointments',
  '@patientContacts',
  '@patientDocuments',
  '@patientPhotos',
  '@patientManagement',
  '@patientPortal',
  '@patientProfile',
  '@tenantSetting',
  '@userManagement',
  '@visitPlans',
  '@caConsent',
  '@videoVisit',
  '@visitFlyout',
  '@consentManagement',
  '@auditTrail',
  '@reports',
  '@translation',
  '@studies',
  '@tenants',
  '@userInvite',
  '@activation',
  '@notification',
  '@participantRep',
  '@DH',
  '@EG',
  '@GI',
  '@WO'
];

const legacyMajorTags = [
  '@clinicalTrials',
  '@ehr',
  '@forms',
  '@integrationadmin',
  '@leads',
  '@appointments',
  '@eConsent',
  '@patientWebPortal',
  '@editReason',
  '@fillScheduleForm',
  '@mobileWeb',
  '@applyScheduleTemplate',
  '@moveScheduleTemplate',
  '@calendar',
  '@contacts',
  '@documents',
  '@photos',
  '@qualifyingProject',
  '@patientScheduleForm',
  '@patientSaveForm',
  '@queryMgmt',
  '@registrySurvey',
  '@trialLevelOngoingForm',
  '@patientManagement',
  '@referenceData',
  '@respondents',
  '@reports',
  '@ruleBuilder',
  '@securityMatrix',
  '@users',
  '@videoVisit'
];

const apiMajorTags = [
  '@trialApi',
  '@userAdminApi',
  '@patientApi',
  '@patientContactsApi',
  '@tenantApi',
  '@meetingsApi',
  '@visitPlanApi',
  '@formControlsApi',
  '@formScaffoldApi',
  '@formBuilderApi',
  '@appointmentApi',
  '@auditTrailApi',
  '@notificationsManagementApi',
  '@nestAssetExports',
  '@nestSiteLevelForms',
  '@nestAppointments',
  '@nestTrialDataExport',
  '@nestInvestigatorDashboard',
  '@nestTrial',
  '@nestPatients',
  '@nestFormQueries',
  '@nestPendingForms',
  '@nestRoleMapping',
  '@nestZoomMeeting',
  '@nestScheduledExports'
];

module.exports = {
  testSubset,
  apiTestSubset,
  nextGenTestSubSet,
  nextGenMajorTags,
  legacyMajorTags,
  apiMajorTags
};
