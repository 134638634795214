import React, { useState, useEffect, useCallback } from 'react';
import { MainCard } from 'heimdall-components';
import { DataGrid, GridRowsProp, GridColDef } from '@material-ui/data-grid';
import { connect, useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ApiIcon from '@mui/icons-material/Api';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {
  DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED,
  DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED
} from '../../redux/actions';

import {
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  LinearProgress,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Divider
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import dhClass from './dataharmonization.module.css';
import * as _ from 'lodash';

export default function DHLegacyComponent({
  legacyData,
  environment,
  platform,
  sqlType,
  envType,
  dataFetching
}) {
  const onGridRowSelected = (id) => {
    let rowVal = legacyData.data.find((a) => a['id'] === id);
  };

  return (
    <div style={{ width: '100%', display: platform?.indexOf('legacy') !== -1 ? 'block' : 'none' }}>
      <h5>Legacy</h5>
      <DataGrid
        id="legacygrid"
        style={{ minHeight: '55vh' }}
        rows={legacyData.data ? legacyData.data : []}
        columns={legacyData.columns ? legacyData.columns : []}
        onSelectionModelChange={(ids) => onGridRowSelected(ids)}
      />
    </div>
  );
}
