import React from 'react';
import { Avatar, withStyles, Card, CardHeader, CardContent } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { TEST_RESULTS_DIALOG_CLOSE } from '../../redux/actions';
import PassIcon from '@material-ui/icons/CheckCircle';
import FailIcon from '@material-ui/icons/HighlightOff';
import SkipIcon from '@material-ui/icons/PauseCircleOutline';
import moment from 'moment';
import path from 'path';

import { FailColor, PassColor, SkipColor } from '../../assets/jss/colors';

const TIMESTAMP_FORMAT = 'YYYY-MM-DD hh:mm:ss.SSS';

const styles = {
  card: {
    width: '100%'
  },
  cardContent: {
    display: 'flex'
  },
  errorContainers: {
    display: 'inline-block',
    margin: '20px'
  },
  errorContainer: {
    marginBottom: '20px'
  },
  errorLabel: {
    fontWeight: 'bold'
  },
  failAvatar: {
    color: FailColor,
    backgroundColor: 'transparent'
  },
  skipAvatar: {
    color: SkipColor,
    backgroundColor: 'transparent'
  },
  passAvatar: {
    color: PassColor,
    backgroundColor: 'transparent'
  },
  icon: {
    fontSize: 25
  },
  host: {
    paddingLeft: 20,
    paddingRight: 20,
    color: 'black'
  },
  duration: {
    marginLeft: 'auto',
    marginRight: '30px'
  },
  subheader: {
    display: 'flex'
  }
};

class HistoryCard extends React.Component {
  constructor(props) {
    super(props);
    const { id, file, start_time, error_message } = props.testResult;
    const athenaTimestampFormat = 'YYYY-MM-DD hh:mm:ss.SSS';
    this.datetime = moment(start_time, athenaTimestampFormat).format('MM-DD-YYYY');
    this.pending = error_message.includes('Pending reason');

    // If we don't get to the scenario, we don't have all the data
    this.id = id || path.basename(file).split('_')[0];
  }

  renderIcon() {
    const { passed } = this.props.testResult;
    const cs = this.props.classes;
    let icon = <FailIcon className={cs.icon} />;
    let avatarClass = cs.failAvatar;
    if (passed) {
      icon = <PassIcon className={cs.icon} />;
      avatarClass = cs.passAvatar;
    } else if (this.pending) {
      icon = <SkipIcon className={cs.icon} />;
      avatarClass = cs.skipAvatar;
    }
    return <Avatar className={avatarClass}>{icon}</Avatar>;
  }

  duration() {
    const { start_time, end_time } = this.props.testResult;
    const endTime = moment.utc(end_time, TIMESTAMP_FORMAT);
    const startTime = moment.utc(start_time, TIMESTAMP_FORMAT);
    const timeInSec = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
    return `Duration: ${timeInSec} sec`;
  }

  renderSubheader() {
    const { scenario, nora_site, test_run_key } = this.props.testResult;
    const cs = this.props.classes;
    return (
      <div className={cs.subheader}>
        <div>
          <span>{scenario}</span>
          <span className={cs.host}>{nora_site}</span>
          <span className={cs.key}>{test_run_key}</span>
        </div>
        <div className={cs.duration}>{this.duration()}</div>
      </div>
    );
  }

  renderContent() {
    const cs = this.props.classes;
    let { title, screenshot, error_message: errorMessage, passed } = this.props.testResult;
    if (passed) {
      return (
        <CardContent>
          <div>{title}</div>
        </CardContent>
      );
    }
    if (this.pending) {
      const skipMessage = `Skip reason: ${errorMessage.split('==')[1]}`;
      return (
        <CardContent>
          <div>{title}</div>
          {skipMessage}
        </CardContent>
      );
    }
    let icon;
    if (screenshot) {
      const baseS3 = 'https://s3-us-west-2.amazonaws.com/test-automation-science37/output';
      const src = `${baseS3}/${screenshot}`;
      icon = (
        <a href={src} target="_blank" rel="noopener noreferrer">
          <img src={src} alt="screenshot" height="256px" width="240px" />
        </a>
      );
    }
    return (
      <CardContent className={cs.cardContent}>
        {icon}
        <div className={cs.errorContainers}>
          <div className={cs.errorContainer}>
            <div className={cs.errorLabel}>Error Message:</div>
            <div className={cs.errorMessage}>{errorMessage}</div>
          </div>
          {this.renderConsoleErrors()}
          {this.renderRequestErrors()}
        </div>
      </CardContent>
    );
  }

  renderTitle() {
    const { name } = this.props.testResult;
    const fullTitle = `TC${this.id}: ${name || ''}`;
    const cs = this.props.classes;
    return (
      <div className={cs.subheader}>
        <div>{fullTitle}</div>
        <div className={cs.duration}>{this.datetime}</div>
      </div>
    );
  }

  renderRequestErrors() {
    const { request_errors } = this.props.testResult;
    const cs = this.props.classes;

    if (!request_errors) {
      return null;
    }

    return (
      <div className={cs.errorContainer}>
        <div className={cs.errorLabel}>Request Errors</div>
        <div className={cs.errorMessage}>
          <p>{request_errors}</p>
        </div>
      </div>
    );
  }

  renderConsoleErrors() {
    const { console_errors } = this.props.testResult;
    const cs = this.props.classes;

    if (!console_errors) {
      return null;
    }

    return (
      <div className={cs.errorContainer}>
        <div className={cs.errorLabel}>Console Errors</div>
        <div className={cs.errorMessage}>
          <p>{console_errors}</p>
        </div>
      </div>
    );
  }

  render() {
    const cs = this.props.classes;

    return (
      <Card className={cs.card}>
        <CardHeader
          avatar={this.renderIcon()}
          title={this.renderTitle()}
          subheader={this.renderSubheader()}
        />
        {this.renderContent()}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch({ type: TEST_RESULTS_DIALOG_CLOSE });
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HistoryCard);
