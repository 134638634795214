import { deepOrange } from '@material-ui/core/colors';

export default (theme) => {
  return {
    headerIcon: {
      marginLeft: 10
    },
    avatar: {
      width: 30,
      height: 30
    },
    profileIcon: {
      marginTop: 50
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },


    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    oauth: {
      marginTop: "20px",
      width: "100%"
    },
    logoImage: {
      width: '80px',
      maxHeight: '50px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: "block"
    },
    img: {
      width: '80px',
      top: '22px',
      border: '0'
    },
    card: {
      minWidth: "300px",
      minHeight: "150px",
      alignItem: 'center',
      alignText: "center"
    },
    title: {
      marginTop: "30px",
      fontSize: 30,
    },
  };

};
