import { call, put, takeLatest } from 'redux-saga/effects';
import {
  DATAHARMONIZATION_FETCH_SQL_DATA_REQUESTED,
  DATAHARMONIZATION_FETCH_SQL_DATA_FAILED,
  DATAHARMONIZATION_FETCH_SQL_DATA_SUCCEEDED,
  DATAHARMONIZATION_FETCH_NOSQL_DATA_REQUESTED,
  DATAHARMONIZATION_FETCH_NOSQL_DATA_FAILED,
  DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED,
  DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_REQUESTED,
  DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_SUCCEEDED,
  DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_FAILED,
  DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_SUCCEEDED,
  DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_FAILED,
  DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED,
  DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED,
  DATAHARMONIZATION_FHIR_TO_FHIRLITE_REQUESTED,
  DATAHARMONIZATION_FHIR_TO_FHIRLITE_FAILED,
  DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED,
  DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED,
  DATAHARMONIZATION_FHIR_BUCKET_DATA_FAILED,
  DATAHARMONIZATION_SFDC_DOCUMENTS_REQUESTED,
  DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED,
  DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_SUCCEEDED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_FAILED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED,
  DATAHARMONIZATION_SFDC_DOCUMENTS_SUCCEEDED,
  DATAHARMONIZATION_SFDC_DOCUMENTS_FAILED
} from '../actions/index';
import testResource from '../../lib/TestResource';
import * as _ from 'lodash';

//Fill Environments by Selected Platform
function* fetchEnvironmentsByPlatform(action) {
  try {
    const payload = action.payload;
    const results = yield call(
      testResource.getDataHarmonizationPlatformEnvironments,
      payload.platform,
      payload.type,
      payload.envType
    );

    yield put({ type: DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_SUCCEEDED, payload: results.data });
  } catch (e) {
    yield put({ type: DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_FAILED, payload: e.message });
  }
}

function* fetchDataHarmonizationSQLData(action) {
  try {
    let payload = action.payload;

    // yield put({
    //   type: DATAHARMONIZATION_FETCH_SQL_DATA_SUCCEEDED,
    //   payload: { data: [], gridColumns: [] }
    // });

    const results = yield call(
      testResource.getDataHarmonizationSQLData,
      payload.environment,
      payload.platform,
      payload.type,
      payload.envType
    );

    let columns = [
      { field: 'entity', headerName: 'Entity', flex: 1 },
      { field: 'sourceCount', headerName: 'My Sql', flex: 1 },
      { field: 'destinationCount', headerName: 'S3 (Athena)', flex: 1 },
      { field: 'difference', headerName: 'Difference (S - D)', flex: 1 },
      { field: 'status', headerName: 'Status', flex: 1 }
    ];

    if (results.data) {
      let cnt = 0;
      results.data.forEach((element) => {
        element.id = cnt++; //Assigning uniqueid
        element.difference = element['sourceCount'] - element['destinationCount'];
      });
    }

    yield put({
      type: DATAHARMONIZATION_FETCH_SQL_DATA_SUCCEEDED,
      payload: { data: results.data, gridColumns: columns }
    });
  } catch (e) {
    yield put({ type: DATAHARMONIZATION_FETCH_SQL_DATA_SUCCEEDED, payload: [] });
    yield put({ type: DATAHARMONIZATION_FETCH_SQL_DATA_FAILED, payload: e.message });
  }
}

//NO SQL DOCUMENTS LIST
function* fetchDataHarmonizationNoSqlDocumentList(action) {
  try {
    let payload = action.payload;
    const results = yield call(
      testResource.getDataHarmonizationNOSQLDocumentList,
      payload.environment,
      payload.platform,
      payload.envType
    );
    yield put({
      type: DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_SUCCEEDED,
      payload: results.data.data
    });
  } catch (e) {
    yield put({ type: DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_FAILED, payload: e.message });
  }
}

function* fetchDataHarmonizationNoSqlData(action) {
  try {
    const payload = action.payload;
    let result = [];
    let columns = [
      { field: 'entity', headerName: 'Entity', flex: 1 },
      { field: 'sourceCount', headerName: 'My Sql', flex: 1 },
      { field: 'destinationCount', headerName: 'S3 (Athena)', flex: 1 },
      { field: 'difference', headerName: 'Difference (S - D)', flex: 1 },
      { field: 'status', headerName: 'Status', flex: 1 }
    ];

    //Fetch Document List
    const documentsResult = yield call(
      testResource.getDataHarmonizationNOSQLDocumentList,
      payload.environment,
      payload.platform,
      payload.envType
    );

    const documentList = documentsResult.data.data;

    yield put({
      type: DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_SUCCEEDED,
      payload: { data: documentList, gridColumns: columns }
    });

    if (documentList) {
      //1)
      for (let docName of documentList) {
        result = Object.assign([], result);
        result.push({ id: docName, entity: docName, status: 'Pending' });
      }
      yield put({
        type: DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED,
        payload: { data: result, gridColumns: columns }
      });

      //2)
      for (let docName of documentList) {
        let entityRecord = result.find((a) => a['entity'] === docName);
        entityRecord['status'] = 'Loading';
        yield put({
          type: DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED,
          payload: { data: result, gridColumns: columns }
        });

        //3)
        try {
          const docData = yield call(
            testResource.getDataHarmonizationNOSQLData,
            payload.environment,
            docName,
            payload.platform,
            payload.envType
          );

          if (docData.data) {
            docData.data.data.forEach((element) => {
              entityRecord['difference'] = element['dbCount'] - element['s3Count'];
              entityRecord['sourceCount'] = element['dbCount'];
              entityRecord['destinationCount'] = element['s3Count'];
              entityRecord['status'] = 'Done';
            });
          }
        } catch (err) {
          entityRecord['status'] = 'Failed';
        }

        //4)
        result = Object.assign([], result);
        yield put({
          type: DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED,
          payload: { data: result }
        });
      }
    } else {
      yield put({
        type: DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED,
        payload: { data: result, gridColumns: columns }
      });
    }
  } catch (e) {
    yield put({ type: DATAHARMONIZATION_FETCH_NOSQL_DATA_FAILED, payload: e.message });
  }
}

//FHIR to FHIR-LITE
function* fetchDataHarmonizationFHIRtoFHIRLite(action) {
  //getDataHarmonizationFhirToFhirLiteTransformationData
  try {
    const payload = action.payload;
    let result = [];

    //Grid Columns
    const columns = [
      { field: 'entity', headerName: 'Entity', flex: 1 },
      { field: 'fhirCount', headerName: 'FHIR File Count', flex: 1 },
      { field: 'fhirLiteCount', headerName: 'FHIR LITE File Count', flex: 1 },
      { field: 'redshiftCount', headerName: 'Redshift Data Count', flex: 1 }
      // { field: 'difference', headerName: 'Difference', flex: 1 }
    ];

    //Fetch Tables for the selected Environment
    const environmentTablesResult = yield call(
      testResource.getDataHarmonizationFhirEnvironmentTables,
      payload.environment,
      payload.platform
    );
    let tablesResult = environmentTablesResult.data.data;

    for (let table of tablesResult) {
      let entityRecord = {};
      entityRecord['id'] = result.length + 1;
      entityRecord['entity'] = table;
      entityRecord['status'] = 'Pending';
      result.push(entityRecord);
    }

    //Fetch Table FHIR, FHIR LITE, and Redshift COUNTS
    yield put({
      type: DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED,
      payload: { data: result, gridColumns: columns }
    });

    for (let entityRecord of result) {
      entityRecord['status'] = 'Processing..';

      try {
        yield put({
          type: DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED,
          payload: { data: result, gridColumns: columns }
        });

        const dataResult = yield call(
          testResource.getDataHarmonizationFhirToFhirLiteTransformationData,
          payload.environment,
          payload.platform,
          entityRecord['entity'], //Table,
          payload.envType
        );

        dataResult.data.data.forEach((record, index) => {
          entityRecord['status'] = 'Success';
          entityRecord['difference'] = record[1]['fhirCount'] - record[1]['fhirLiteCount'];
          entityRecord['fhirCount'] = record[1]['fhirCount'];
          entityRecord['fhirLiteCount'] = record[1]['fhirLiteCount'];
          entityRecord['jsonFHIRBuckets'] = record[1]['jsonFHIRBuckets'];
          entityRecord['jsonFHIRLiteBuckets'] = record[1]['jsonFHIRLiteBuckets'];
          entityRecord['redshiftCount'] = record[1]['redshiftCount'];
          entityRecord['docdbCount'] = record[1]['docdbCount'];
          entityRecord['docdbCollection'] = record[1]['docdbCollection'];
        });

        yield put({
          type: DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED,
          payload: { data: result, gridColumns: columns }
        });
      } catch (ex) {
        entityRecord['status'] = 'Failed';
        yield put({
          type: DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED,
          payload: { data: result, gridColumns: columns }
        });
      }
    }
  } catch (e) {
    yield put({ type: DATAHARMONIZATION_FHIR_TO_FHIRLITE_FAILED, payload: e.message });
  }
}

//FHIR BUCKET JSON DATA
function* fetchDataHarmonizationFhirBucketData(action) {
  try {
    const payload = action.payload;
    const fhirBuckets = payload.fhirBuckets;

    if (fhirBuckets) {
      //1)
      for (let bucket of fhirBuckets.entries()) {
        bucket[1][1]['status'] = 'Pending';
      }
      yield put({ type: DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED, payload: fhirBuckets });

      //2)
      for (let bucket of fhirBuckets.entries()) {
        bucket[1][1]['status'] = 'Requested';
        yield put({ type: DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED, payload: fhirBuckets });

        //3)
        try {
          const result = yield call(
            testResource.getDataHarmonizationFhirBucketData,
            bucket[1],
            payload.environment,
            payload.platform,
            payload.envType
          );

          bucket[1][1]['status'] = 'Success';
          if (result) {
            let docData = result.data.data;
            bucket[1][1]['jsonFile']['count'] = docData['fhirCount'];
            bucket[1][1]['liteFile']['count'] = docData['fhirLiteCount'];
            bucket[1][1]['docdb']['count'] = docData['dbCount'];
            bucket[1][1]['difference'] = false;
            if (
              parseInt(docData['fhirCount']) !== parseInt(docData['fhirLiteCount']) ||
              parseInt(docData['dbCount']) !== parseInt(docData['fhirCount']) ||
              parseInt(docData['dbCount']) !== parseInt(docData['fhirLiteCount'])
            ) {
              bucket[1][1]['difference'] = true;
            }

            if (bucket[1][1]['difference'] === true) {
              bucket[1][1]['status'] = 'Count Error';
            }
            yield put({ type: DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED, payload: fhirBuckets });
          }
        } catch (err) {
          console.log(err);
          bucket[1][1]['status'] = 'Failed';
        }
      }

      yield put({ type: DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED, payload: fhirBuckets });
    } else {
      yield put({ type: DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED, payload: fhirBuckets });
    }
  } catch (e) {
    yield put({ type: DATAHARMONIZATION_FHIR_BUCKET_DATA_FAILED, payload: e.message });
  }
}

//SFDC
//FHIR to FHIR-LITE
function* fetchSFDCDocuments(action) {
  //getDataHarmonizationFhirToFhirLiteTransformationData
  try {
    const payload = action.payload;
    let result = [];
    const dataResult = yield call(
      testResource.getDataHarmonizationSFDCDocuments,
      payload.environment,
      payload.platform,
      payload.envType
    );

    dataResult.data.data
      .sort((a, b) => (a['lastUpdated'] < b['lastUpdated'] ? 1 : -1))
      .forEach((record) => {
        let entityRecord = {};
        entityRecord['id'] = record['lastUpdated'];
        entityRecord['entity'] = record['bucketName'];
        entityRecord['bucket'] = record['bucketName'];

        let indexOfT = record['lastUpdated'].indexOf('T');

        entityRecord['lastUpdated'] = record['lastUpdated'].substr(0, indexOfT);
        entityRecord['docPath'] = record['docPath'];
        entityRecord['MoreDetails'] = 'Click for more Details';
        result.push(entityRecord);
      });

    //Grid Columns
    const columns = [
      { field: 'lastUpdated', headerName: 'Date', flex: 1 },
      { field: 'entity', headerName: 'Bucket File', flex: 1 },
      { field: 'MoreDetails', headerName: '*', flex: 1 }
    ];

    yield put({
      type: DATAHARMONIZATION_SFDC_DOCUMENTS_SUCCEEDED,
      payload: { data: result, gridColumns: columns }
    });
  } catch (e) {
    yield put({ type: DATAHARMONIZATION_SFDC_DOCUMENTS_FAILED, payload: e.message });
  }
}

function* fetchDataHarmonizationSFDCBucketData(action) {
  try {
    yield put({ type: DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED, payload: {} });

    const payload = action.payload;
    const sfdcBucket = payload.bucket;
    if (sfdcBucket) {
      //1)
      sfdcBucket['status'] = 'Pending';
      yield put({ type: DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED, payload: sfdcBucket });

      //2)
      sfdcBucket['status'] = 'Processing...';
      yield put({ type: DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED, payload: sfdcBucket });

      //3)
      try {
        const result = yield call(
          testResource.getDataHarmonizationSFDCBucketData,
          sfdcBucket,
          payload.environment,
          payload.platform,
          payload.envType
        );

        sfdcBucket['status'] = 'Success';

        if (result) {
          let docData = result.data;
          sfdcBucket['s3Data'] = docData['s3Data'];
          sfdcBucket['mySqlData'] = docData['mySqlData'];
          sfdcBucket['combinedResultData'] = docData['combinedResultData'];

          sfdcBucket['descrepancies'] = [];
          sfdcBucket['available'] = [];
          //Adding Unique Id
          sfdcBucket['combinedResultData'].forEach((val, index) => {
            if (val['exist'] === false) {
              let descRecord = val;
              descRecord.record['id'] = sfdcBucket['descrepancies'].length++;
              sfdcBucket['descrepancies'].push(descRecord.record);
            } else {
              let availRecord = val;
              availRecord.record['id'] = sfdcBucket['available'].length++;
              sfdcBucket['available'].push(availRecord.record);
            }
          });

          yield put({ type: DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED, payload: sfdcBucket });
        }
      } catch (err) {
        console.log(err);
        sfdcBucket['status'] = 'Failed';
      }

      //4)
      //result = Object.assign([], result);

      yield put({ type: DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED, payload: sfdcBucket });
    } else {
      yield put({ type: DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED, payload: sfdcBucket });
    }
  } catch (e) {
    yield put({ type: DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED, payload: e.message });
  }
}

//Global Schema Data
function* fetchGlobalSchemaEnvironments(action) {
  const payload = action.payload;
  const schema = payload.schema;
  try {
    const dataEnvironments = yield call(
      testResource.getDataHarmonizationGlobalSchemaEnvironments,
      schema,
      payload.platform,
      payload.envType
    );

    let globalData = [];
    if (dataEnvironments) {
      dataEnvironments.data.data.forEach((record, index) => {
        let entityRecord = {};
        entityRecord['id'] = index;
        entityRecord['entity'] = record;
        entityRecord['message'] = '';
        entityRecord['status'] = 'Pending';
        globalData.push(entityRecord);
      });

      //Grid Columns
      const columns = [
        { field: 'entity', headerName: 'Global Environment', flex: 1 },
        { field: 'status', headerName: 'Status', flex: 1 }
      ];

      yield put({
        type: DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_SUCCEEDED,
        payload: { data: globalData, gridColumns: columns }
      });

      for (let record of globalData) {
        record['status'] = 'Processing';
        globalData = [...globalData];
        yield put({
          type: DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_SUCCEEDED,
          payload: { data: globalData, gridColumns: columns }
        });

        const dataResult = yield call(
          testResource.getDataHarmonizationGlobalData,
          record['entity'], //Environment
          payload.platform,
          payload.envType
        );

        if (dataResult && dataResult.data.result) {
          record['tableCounts'] = dataResult.data.result;
        } else {
          record['tableCounts'] = [];
        }
        record['status'] = '';
        globalData = [...globalData];
        yield put({
          type: DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_SUCCEEDED,
          payload: { data: globalData, gridColumns: columns }
        });
      }
    }
  } catch (err) {
    console.log(err);
    yield put({ type: DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_FAILED, payload: err.message });
  }
}

export function* watchDataHarmonizationValidation() {
  yield takeLatest(DATAHARMONIZATION_FETCH_NOSQL_DATA_REQUESTED, fetchDataHarmonizationNoSqlData);
  yield takeLatest(DATAHARMONIZATION_FETCH_SQL_DATA_REQUESTED, fetchDataHarmonizationSQLData);
  yield takeLatest(
    DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_REQUESTED,
    fetchDataHarmonizationNoSqlDocumentList
  );
  yield takeLatest(DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED, fetchEnvironmentsByPlatform);
  yield takeLatest(
    DATAHARMONIZATION_FHIR_TO_FHIRLITE_REQUESTED,
    fetchDataHarmonizationFHIRtoFHIRLite
  );
  yield takeLatest(
    DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED,
    fetchDataHarmonizationFhirBucketData
  );
  yield takeLatest(DATAHARMONIZATION_SFDC_DOCUMENTS_REQUESTED, fetchSFDCDocuments);
  yield takeLatest(
    DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED,
    fetchDataHarmonizationSFDCBucketData
  );
  yield takeLatest(
    DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED,
    fetchGlobalSchemaEnvironments
  );
}
