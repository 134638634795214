import React from 'react';

import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { Authenticator, AuthPiece, Greetings, SignUp } from 'aws-amplify-react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import { watcherSaga } from './redux/sagas';
import reducers from './redux/reducers';

import AmplifyConfigure from './lib/Amplify';
// import CustomSignIn from "./heimdall-components/Login/CustomLogin";

import Dashboard from 'layouts/Dashboard/Dashboard.jsx';
import Print from 'views/Print';

import 'assets/css/material-dashboard-react.css?v=1.2.0';

AmplifyConfigure();

const hist = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
applyMiddleware(sagaMiddleware)(createStore);

let middlewares = [sagaMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middlewares = [...middlewares, logger];
}
const store = createStore(reducers, compose(applyMiddleware(...middlewares)));

sagaMiddleware.run(watcherSaga);

class App extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ['signedIn'];
  }

  showComponent(theme) {
    return (
      <div className="App">
        <Router history={hist}>
          <Provider store={store}>
            <Switch>
              <Route exact path="/print/:key?/:test_key?" component={Print} />
              <Route path="/" component={Dashboard} />
            </Switch>
          </Provider>
        </Router>
      </div>
    );
  }
}

class AppWithAuthenticator extends React.Component {
  render() {
    return (
      // <Authenticator hide={[Greetings, SignIn]}>
      <Authenticator hide={[Greetings, SignUp]}>
        <App />
      </Authenticator>
    );
  }
}

export default AppWithAuthenticator;
