import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import { CircularProgress, FormControl } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { HISTORY_FETCH_REQUESTED, TRACEABILITY_RMS_UPDATE_REQUESTED } from '../../redux/actions';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import emoji from 'react-easy-emoji';
const TIMESTAMP_FORMAT = 'YYYY-MM-DD hh:mm:ss.SSS';

const useStyles = makeStyles(styles);

export default function RmsAction ({ id }) {
  const cs = useStyles();

  const dispatch = useDispatch();


  const [open, setOpen] = React.useState(false);
  const [noraSite, setNoraSite] = React.useState('https://acme.dev1-platform.s37dev.com/');

  const nextGenHistory = useSelector(state => state.testRuns.nextGenHistory)
  const nextGenHistoryFetching = useSelector(state => state.testRuns.history_fetching)

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if(open && id > 0 && !nextGenHistory[id] && !nextGenHistoryFetching){
      handleSearch();
    }
  }, [open, id])

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = () => {
    dispatch({ type: HISTORY_FETCH_REQUESTED, payload: { id, isNextGen: true, noraSite } });
  };

  const duration = (run) => {
    const { start_time, end_time } = run;
    const endTime = moment.utc(end_time, TIMESTAMP_FORMAT);
    const startTime = moment.utc(start_time, TIMESTAMP_FORMAT);
    const timeInSec = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
    return `${timeInSec} sec`;
  }

  const renderPassFail = (passFail) => {
    if(passFail === true){
      return emoji('✅');
    }else if(passFail === false){
      return emoji('❌');
    }else{
      return emoji('⌛')
    }
  }

  const getResultLink = (tr) => {
    const endpoint = `${window.location.protocol}//${window.location.host}/test_runs/${tr.test_run_key}/${tr.test_run_test_id}`;
    return  <a href={endpoint} target="_blank">Test Report</a>
  }

  const renderHistory = (detail) => {
    return detail.map((run, i) => {
      const { start_time, passed } = run;
      return (
        <div key={i} className={cs.historyContainer}>
          <div className={cs.groupContainer}>
            <div className={cs.historyDate}>
              {moment.utc(start_time, TIMESTAMP_FORMAT).format('LLL')}
            </div>
            <div className={cs.historyStatus}>
              <div>
                {renderPassFail(passed)}
              </div>
            </div>
          </div>
          <div className={cs.viewResult}>
            {getResultLink(run)}
          </div>
        </div>
      )
    })
  }

  return (<div>
      <IconButton color="primary" aria-label="Edit RMS Detail" onClick={handleClickOpen}>
        <HistoryIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Test History for {`TC${id}`}</DialogTitle>
        <DialogContent>
          {nextGenHistoryFetching &&
            <div style={{
              display: "flex",
              justifyContent: "center"
            }}>
              <CircularProgress size={50} color="primary" />
            </div>
          }
          {nextGenHistory[id] && renderHistory(nextGenHistory[id])}
        </DialogContent>
      </Dialog>
    </div>
  )
}


