export default function(theme) {
  return {
    headerIcon: {
      marginLeft: '10px'
    },
    headerButton: {
      marginLeft: '20px'
    },
    actionButton: {
      width: '20px'
    },
    progress: {
      margin: '20px auto',
      display: 'block'
    }
  };
}
