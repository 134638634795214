import testResource from '../../lib/TestResource';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  API_TEST_RESULTS_FETCH_SUCCEEDED,
  API_TEST_RESULTS_FETCH_FAILED,
  API_TEST_RESULTS_FETCH_REQUESTED
} from '../actions';

function* fetchApiTestResults(action) {
  try {
    const key = action.payload;
    const testResults = yield call(testResource.getApiTestResults, key);
    yield put({
      type: API_TEST_RESULTS_FETCH_SUCCEEDED,
      payload: testResults.data
    });
  } catch (e) {
    yield put({ type: API_TEST_RESULTS_FETCH_FAILED, payload: e.message });
  }
}

export function* watchApiTestResults() {
  yield takeLatest(API_TEST_RESULTS_FETCH_REQUESTED, fetchApiTestResults);
}
