export default (theme) => {
  return {
    linkContainer: {
      display: 'inline-block',
      position: 'relative',
      marginLeft: '5px'
    },
    searchIcon: {
      fontSize: '32px',
      margin: '8px 10px 0 0'
    },
    searchTagContainer: {
      display: 'flex',
      margin: '20px',
      padding: '0 10px',
      alignItems: "flex-end"
    },
    searchTagChipContainer: {
      flexGrow: 2,
    },
    filterList: {
      flexGrow: 3,
      marginLeft: "50px",
      display: "flex",
      alignItems: "flex-end"
    },
    infoBoxesContainer: {
      margin: '20px',
      padding: '0 10px'
    },
    infoBoxContainer: {
      paddingRight: '20px'
    },
    switchContainer: {
      marginRight: '20px'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  };
};
