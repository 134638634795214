export default (theme) => {
  return {
    screenshot: {
      display: 'block',
      margin: 'auto'
    },
    screenshotTimestamp: {
      float: 'left',
      marginBottom: 10,
      fontSize: 10
    },
    screenshotCount: {
      float: 'right'
    },
    card_steps: {
      marginTop: 20
    }
  };
};
