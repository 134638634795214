import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './styles';
import { MainCard } from '../../heimdall-components';
import {  Grid } from '@material-ui/core';
import TraceabilityTable from './TraceabilityTable';
const useStyles = makeStyles(styles);


export default function TraceabilityMatrix (props) {
  const cs = useStyles();

  const matrixData = useSelector(state => state.traceabilityMatrix.traceabilityMatrix)
  const dataFetching = useSelector(state => state.traceabilityMatrix.fetching)


  const renderContent = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <div className={cs.chartContainer}>
            <div className={cs.chartText}>NextGen UI Automation Coverage
              {dataFetching && matrixData.length === 0 && (<CircularProgress size={25}/>)}
            </div>
            {matrixData && <TraceabilityTable matrixData={matrixData}/>}
          </div>
        </Grid>
      </Grid>
    );
  }
  return (
    <MainCard title="Traceability Matrix" content={renderContent()} />
  );
}


