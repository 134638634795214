import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles, Button } from '@material-ui/core';

import { Replay } from '@material-ui/icons';
import { HISTORY_FETCH_REQUESTED } from '../../redux/actions';
import HistoryCard from './HistoryCard';
import { Line } from 'rc-progress';
import { MainCard } from 'heimdall-components';

import { CircularProgress } from '@material-ui/core';

const styles = {
  idLabel: {
    display: 'inline-block',
    height: '30px',
    fontSize: '20px',
    padding: '5px',
    color: '#000000'
  },
  idInput: {
    display: 'inline-block',
    height: '30px',
    fontSize: '20px',
    padding: '5px',
    marginBottom: '20px'
  },
  retrieveIcon: {
    marginLeft: '10px'
  },
  retrieveButton: {
    margin: '0 0 4px 10px'
  }
};

class History extends React.Component {
  constructor(props) {
    super(props);

    const testId = this.props.match.params.id || '';
    this.state = {
      testId: testId
    };

    if (testId !== '') {
      this.props.requestHistory(testId);
    }
  }

  handleId = (event) => {
    event.stopPropagation();
    this.setState({
      testId: event.target.value
    });
  };

  handleKeyPressId = (event) => {
    if (event.key === 'Enter') {
      const { testId } = this.state;
      if (testId !== '') {
        this.props.requestHistory(testId);
      }
    }
  };

  getPassFailBar() {
    let pass = 0;
    let fail = 0;
    if (this.props.history.length > 0) {
      this.props.history.forEach((history) => {
        if (history.passed === true) {
          pass += 1;
        } else {
          fail += 1;
        }
      });
      let passPercentage = (pass / (pass + fail)) * 100;
      return (
        <Line percent={passPercentage} strokeWidth="1" strokeColor="#39ac73" trailColor="#ff471a" />
      );
    }
  }

  renderHistory() {
    const { fetching } = this.props;
    if (this.props.history.length > 0) {
      return this.props.history.map((testResult, i) => {
        return <HistoryCard testResult={testResult} key={i} />;
      });
    } else if (fetching) {
      return <CircularProgress size={50} color="primary" className={this.props.classes.progress} />;
    }
  }

  renderControl() {
    const { classes: cs, requestHistory } = this.props;
    const { testId } = this.state;
    const hasTestId = testId !== '';
    return (
      <div className="control">
        <label className={cs.idLabel}>Test ID:</label>
        <input
          id="test-id"
          className={cs.idInput}
          type="number"
          placeholder="Test ID"
          value={this.state.testId}
          onChange={this.handleId}
          onKeyPress={this.handleKeyPressId}
        />
        <Button
          onClick={() => requestHistory(testId)}
          className={cs.retrieveButton}
          color="primary"
          variant="outlined"
          disabled={!hasTestId}
        >
          Retrieve
          <Replay className={cs.retrieveIcon} />
        </Button>
        {this.getPassFailBar()}
      </div>
    );
  }

  render({ ...props }) {
    return (
      <MainCard
        title="Test History"
        content={
          <div>
            {this.renderControl()}
            {this.renderHistory()}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    history: state.testRuns.history,
    fetching: state.testRuns.history_fetching
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestHistory: (id) => {
    dispatch({ type: HISTORY_FETCH_REQUESTED, payload: { id } });
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(History);
