import {
  API_TEST_RESULTS_FETCH_SUCCEEDED,
  API_TEST_RESULTS_FETCH_FAILED,
  API_TEST_RESULTS_FETCH_REQUESTED,
  API_TEST_RESULTS_DIALOG_OPEN,
  API_TEST_RESULTS_DIALOG_CLOSE
} from '../actions';

const defaultState = {
  fetching: false,
  error: false,
  open: false,
  test_run_key: '',
  test_key: ''
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case API_TEST_RESULTS_FETCH_REQUESTED:
      return {
        ...state,
        test_run_key: action.payload,
        error: false,
        fetching: true
      };
    case API_TEST_RESULTS_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        test_key: '',
        error: true
      };
    case API_TEST_RESULTS_FETCH_SUCCEEDED:
      const testResults = action.payload;
      const key = testResults.length > 0 ? testResults[0].test_run_key : '';
      return {
        ...state,
        test_run_key: key,
        [key]: testResults,
        error: false,
        fetching: false
      };
    case API_TEST_RESULTS_DIALOG_OPEN: {
      const { test_run_key, test_key } = action.payload;
      return {
        ...state,
        test_run_key,
        test_key,
        open: true
      };
    }
    case API_TEST_RESULTS_DIALOG_CLOSE:
      return {
        ...state,
        test_key: '',
        open: false
      };
    default:
      return state;
  }
}
