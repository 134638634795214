import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './styles';
import Login from '../Login';
import Grid from '@material-ui/core/Grid';

function MainCard({ ...props }) {
  const { classes: cs, headerAction, title, content, hideLogin } = props;

  return (
    <div>
      <div >
        <div>
          <Grid container alignItems="center">
            <Grid item xs={8}>
               <div className={cs.headerTitle}>{title}</div>
            </Grid>
            <Grid item xs={4} className={cs.alignRight}>
              { !hideLogin && <span className={cs.loginContainer}>
              <Login />
            </span>}
            </Grid>
            <Grid item xs={12} className={cs.headerActions}>
              {headerAction}
            </Grid>
            </Grid>
          </div>
        </div>
      <div className={cs.contentContainer}>{content}</div>
    </div>
  );
}

MainCard.propTypes = {
  classes: PropTypes.object.isRequired,
  cardTitle: PropTypes.string,
  content: PropTypes.node
};

export default withStyles(styles)(MainCard);
