import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import path from 'path';
import _ from 'lodash';
import { legacyMajorTags, nextGenMajorTags } from '../../assets/config/testConfig';

import {
  FormControlLabel,
  Switch,
  withStyles,
  CircularProgress,
  Tooltip,
  Slide,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  withMobileDialog,
  FormControl,
  InputLabel,
  NativeSelect,
  Input
} from '@material-ui/core';

import { ChevronLeft, Replay } from '@material-ui/icons';
import { Link as LinkIcon, Search as SearchIcon } from '@material-ui/icons';

import {
  BUILDS_DIALOG,
  CLEAR_FILTER,
  UPDATE_FILTER,
  TEST_RESULTS_DIALOG_CLOSE,
  TEST_RESULTS_FETCH_REQUESTED,
  JIRA_FETCH_REQUESTED
} from '../../redux/actions';

import InfoCard from '../InfoCard';
import TestResultCard from '../TestResultCard';
import SearchTag from '../SearchTag';

import styles from './styles';
import moment from 'moment';
import { PARALLEL_OPTIONS } from '../APIBuildDialog/constants';
const TIMESTAMP_FORMAT = 'YYYY-MM-DD hh:mm:ss.SSS';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

class TestResultsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: null
    };
  }

  renderTestResults() {
    const { testResults, error } = this.props;
    if (testResults.length > 0) {
      const testIds = testResults.map((test) => test.id || path.basename(test.file).split('_')[0]);
      const { byTestId } = this.props;
      const jiraTicket = [];
      const numberRegex = /^\d+$/;
      for (let i = 0; i < testIds.length; i++) {
        const testId = testIds[i];
        const tags = byTestId[testId] || [];
        const jiraTags = tags
          .map((tag) => tag.text.toLowerCase().trim())
          .filter((tag) => tag.startsWith('nxl-') && numberRegex.test(tag.split('nxl-').pop()));
        jiraTicket.push(...jiraTags);
      }
      if (jiraTicket.length > 0) {
        this.props.requestJiraDetail({
          jiraTickets: jiraTicket
        });
      }

      return testResults.map((testResult, i) => {
        return <TestResultCard testResult={testResult} key={i} />;
      });
    } else if (error) {
      return <div>Error occurred fetching results</div>;
    } else {
      return <CircularProgress size={50} color="primary" className={this.props.classes.progress} />;
    }
  }

  handleRerunClicked = () => {
    const unstableIDs = this.getUnstableIds();
    const tag = _(this.props.testResults)
      .reject((result) => {
        const { error_message, passed, id } = result;
        const pending = error_message.includes('Pending reason');
        return passed || pending || unstableIDs.includes(id);
      })
      .map((result) => {
        let { id } = result;
        id = id || path.basename(result.file).split('_')[0];
        return `@${id}`;
      })
      .uniq()
      .value()
      .join('|');

    this.props.onBuildCreate(tag);
  };

  handleUnstableRerunClicked = () => {
    const unstableIDs = this.getUnstableIds();
    const tag = _(this.props.testResults)
      .reject((result) => {
        const { id } = result;
        return !unstableIDs.includes(id);
      })
      .map((result) => {
        let { id } = result;
        id = id || path.basename(result.file).split('_')[0];
        return `@${id}`;
      })
      .uniq()
      .value()
      .join('|');

    this.props.onBuildCreate(tag);
  };

  componentDidUpdate() {
    const { test_run_key, testResults, fetching, error } = this.props;

    if (test_run_key && testResults.length === 0 && !fetching && !error) {
      this.props.requestTestResults(test_run_key);
    }
  }

  getUnstableIds() {
    const { dTags } = this.props;
    let unstableIDs = [];
    if (dTags['@unstable']) {
      unstableIDs = dTags['@unstable'].test_ids;
      unstableIDs = unstableIDs.map(String);
    }
    return unstableIDs;
  }

  findTestRun() {
    const testRun = this.props.testRuns.find((run) => {
      return run.test_run_key === this.props.test_run_key;
    });
    if (testRun) {
      const newTestRun = JSON.parse(JSON.stringify(testRun));
      const { testResults } = this.props;
      newTestRun.unstable = 0;
      const unstableIDs = this.getUnstableIds();
      if (unstableIDs) {
        const unstableCount = testResults.filter((d) => {
          return d.passed === false && unstableIDs.includes(d.id);
        }).length;
        newTestRun.failed = newTestRun.failed - unstableCount;
        newTestRun.unstable = unstableCount;
      }
      return newTestRun;
    }
    return testRun;
  }

  duration() {
    const { start_time, end_time } = this.props.testResult;
    const endTime = moment.utc(end_time, TIMESTAMP_FORMAT);
    const startTime = moment.utc(start_time, TIMESTAMP_FORMAT);
    const timeInSec = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
    return `Duration: ${timeInSec} sec`;
  }

  secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    let hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    let mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    let sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
  }

  getTotalTime = () => {
    const { testResults } = this.props;
    const seconds = testResults.reduce(function(a, fun) {
      const { start_time, end_time } = fun;
      const endTime = moment.utc(end_time, TIMESTAMP_FORMAT);
      const startTime = moment.utc(start_time, TIMESTAMP_FORMAT);
      const timeInSec = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
      return a + timeInSec;
    }, 0);
    return this.secondsToHms(seconds);
  };

  renderSwitch(filterName) {
    const { updateFilter, filter } = this.props;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={filter[filterName]}
            onChange={() => updateFilter({ [filterName]: !filter[filterName] })}
            value={filterName}
          />
        }
        label="Hide"
      />
    );
  }

  handleUpdateOnFilterTags = (tags) => {
    this.props.updateFilter({ tags });
  };

  handleUpdateOnFilterList = (filterValue) => {
    this.props.updateFilter({ filterList: filterValue });
  };

  getHistory() {
    const testRun = this.findTestRun();
    if (this.state.history) {
      return this.state.history;
    }
    const history = this.props.testHistory;
    const myHistory = history.filter((f) => f.url == testRun.nora_site);
    this.setState({ history: myHistory });
    return myHistory;
  }

  isNewFailure = (id, history) => {
    if (!id) {
      return false;
    }
    const myHistory = history.filter((hist) => hist.testId == id);
    if (myHistory.length === 0) return false;
    if (myHistory.length > 1) {
      return myHistory[0].status === 'failed' && myHistory[1].status != 'failed';
    } else {
      return myHistory[0].status === 'failed';
    }
    return false;
  };

  hasThreeFailure = (id, history) => {
    const myHistory = history.filter((hist) => hist.testId == id);
    if (myHistory.length >= 3) {
      return (
        myHistory[0].status === 'failed' &&
        myHistory[1].status === 'failed' &&
        myHistory[2].status === 'failed'
      );
    }
    return false;
  };

  getFilterCount = () => {
    const testResults = this.props.testResults;
    const filterList = this.props.filterList;
    const myHistory = this.getHistory();
    let resultCount = 0;
    if (filterList && testResults.length > 0 && myHistory.length > 0) {
      const allIds = testResults.filter((r) => r.passed === false).map((r) => r.id);
      for (let i = 0; i < allIds.length; i++) {
        if (filterList == 'newFail') {
          if (this.isNewFailure(allIds[i], myHistory)) {
            resultCount += 1;
          }
        } else if (filterList == 'threeFail') {
          if (this.hasThreeFailure(allIds[i], myHistory)) {
            resultCount += 1;
          }
        }
      }
      return resultCount;
    }
    return null;
  };

  getTagGroup(isNextGen = false) {
    const testResults = this.props.testResults;
    let failedTestResults = testResults.filter((r) => r.passed === false);
    // If a test failed on before step, it does not report the id
    // We are manually getting the id from file name in that case
    failedTestResults = failedTestResults.map((test) => {
      if (test.id) {
        return test;
      } else {
        return { ...test, id: path.basename(test.file).split('_')[0] };
      }
    });
    const tags = isNextGen ? nextGenMajorTags : legacyMajorTags;
    const tagGroup = [];
    const allfailedTestId = [...new Set(failedTestResults.map((test) => test.id))];
    let capturedFailedTestId = [];
    for (let i = 0; i < tags.length; i++) {
      const failedTests = failedTestResults.filter((result) => {
        return result.tags ? result.tags.includes(tags[i]) : false;
      });
      const failedIds = failedTests.map((test) => test.id);
      capturedFailedTestId.push(...failedIds);
      const failedIdString = [...new Set(failedIds)].map((id) => `@${id}`).join('|');
      const failedCount = failedTests.length;
      if (failedCount > 0) {
        tagGroup.push({
          tag: tags[i],
          failedCount,
          failedIds: failedIdString
        });
      }
    }
    tagGroup.sort((a, b) => b.failedCount - a.failedCount);
    capturedFailedTestId = [...new Set(capturedFailedTestId)];

    // Getting the test IDs that did not have the tags we have configured
    let diff = _.difference(allfailedTestId, capturedFailedTestId);
    diff = diff.filter((n) => n != undefined && n != 'undefined');
    if (diff.length > 0) {
      tagGroup.push({
        tag: 'Unknown',
        failedCount: diff.length,
        failedIds: diff.map((id) => `@${id}`).join('|')
      });
    }
    return tagGroup;
  }

  getTagGroupSummaryComponent(isNextGen = false) {
    const tagGroup = this.getTagGroup(isNextGen);
    if (!tagGroup || !(tagGroup.length > 0)) return null;
    return (
      <CopyToClipboard
        text={`Failed Summary: \n${tagGroup
          .map((g) => `${g.tag}: ${g.failedCount} -> ${g.failedIds}`)
          .join('\n')}`}
      >
        <Button size="small" disableElevation disableFocusRipple color="primary">
          Copy Summary
        </Button>
      </CopyToClipboard>
    );
  }

  getTagGroupComponent(isNextGen = false) {
    const tagGroup = this.getTagGroup(isNextGen);
    if (!tagGroup) return null;
    const outcome = tagGroup.map((group, id) => {
      return (
        <div
          key={id}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            backgroundColor: 'lightgrey',
            borderRadius: '25px',
            padding: '5px',
            margin: '5px'
          }}
        >
          <span>{group.tag} - </span>
          <span style={{ marginLeft: '5px' }}>{group.failedCount}</span>
          <CopyToClipboard text={group.failedIds}>
            <Button color="primary">
              <LinkIcon color="primary" />
            </Button>
          </CopyToClipboard>
        </div>
      );
    });
    return outcome;
  }

  renderInfoBoxes() {
    const { classes: cs } = this.props;
    const testRun = this.findTestRun();

    if (!testRun) {
      return;
    }

    return (
      <div>
        <Grid container>
          <Grid item className={cs.infoBoxContainer} xs={6} sm={3} md={3}>
            <InfoCard title="Total" description={testRun.total} />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xs={6} sm={3} md={3}>
            <InfoCard title="Total Duration" description={this.getTotalTime()} />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xs={6} sm={6} md={6}>
            <InfoCard
              title="Failed Summary"
              description={this.getTagGroupSummaryComponent(testRun.is_next_gen)}
              summary={this.getTagGroupComponent(testRun.is_next_gen)}
            />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xs={12} sm={6} md={3}>
            <InfoCard
              title="Passed"
              description={testRun.passed}
              sideContent={this.renderSwitch('passed')}
            />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xs={12} sm={6} md={3}>
            <InfoCard
              title="Failed"
              description={testRun.failed}
              sideContent={this.renderFailedContent()}
            />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xs={12} sm={6} md={3}>
            <InfoCard
              title="Skipped"
              description={testRun.pending}
              sideContent={this.renderSwitch('skipped')}
            />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xs={12} sm={6} md={3}>
            <InfoCard
              title="Unstable"
              description={testRun.unstable}
              sideContent={this.renderUnstableContent()}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  renderRerunIcon(onlyUnstable) {
    if (this.props.testResults.length > 0) {
      const { authState } = this.props;
      const disableBuild = authState !== 'logged_in';
      const buildTip = disableBuild ? 'Please sign in' : 'Rerun failed tests.';
      const handleClick = onlyUnstable ? this.handleUnstableRerunClicked : this.handleRerunClicked;
      return (
        <Tooltip title={buildTip}>
          <span>
            <Button
              onClick={handleClick}
              color="secondary"
              variant="outlined"
              disabled={disableBuild}
            >
              Rerun
              <Replay color="secondary" />
            </Button>
          </span>
        </Tooltip>
      );
    }
  }
  renderFailedContent() {
    return (
      <div>
        {this.renderSwitch('failed')}
        {this.renderRerunIcon()}
      </div>
    );
  }

  renderUnstableContent() {
    return (
      <div>
        {this.renderSwitch('unstable')}
        {this.renderRerunIcon(true)}
      </div>
    );
  }

  render() {
    const testRun = this.findTestRun();

    const { open } = this.props;
    const cs = this.props.classes;
    const runLink = `${window.location.protocol}//${window.location.host}/test_runs/${this.props.test_run_key}`;
    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.props.onClose}
        aria-labelledby="responsive-dialog-title"
        TransitionComponent={Transition}
      >
        <DialogTitle id="responsive-dialog-title">
          <Button onClick={this.props.onClose} color="primary" style={{ margin: 0 }}>
            <ChevronLeft color="primary" />
          </Button>
          {this.props.test_run_key}

          <div className={cs.linkContainer}>
            <Tooltip title="Copy to clipboard">
              <CopyToClipboard text={runLink}>
                <Button color="primary">
                  <LinkIcon color="primary" />
                </Button>
              </CopyToClipboard>
            </Tooltip>
          </div>
        </DialogTitle>

        <DialogContent>
          <div>
            {testRun && (
              <>
                {this.props.testHistory.length > 0 && (
                  <div className={cs.infoBoxesContainer}>{this.renderInfoBoxes()}</div>
                )}
                <div className={cs.searchTagContainer}>
                  <SearchIcon className={cs.searchIcon} />
                  <div className={cs.searchTagChipContainer}>
                    <SearchTag
                      tags={this.props.filterTags}
                      className={cs.searchTag}
                      handleUpdate={this.handleUpdateOnFilterTags}
                      placeholder="Filter by Dynamic Tags"
                    />
                  </div>
                  <div className={cs.filterList}>
                    <FormControl className={cs.formControl}>
                      <InputLabel htmlFor="filterList">Filter Failure List</InputLabel>
                      <NativeSelect
                        value={this.props.filterList}
                        onChange={(e) => this.handleUpdateOnFilterList(e.target.value)}
                        input={<Input name="Filter List" id="filterList" />}
                      >
                        <option value=""></option>
                        <option value="newFail">New Failure</option>
                        <option value="threeFail">Failed for three days</option>
                      </NativeSelect>
                    </FormControl>
                    {this.props.testHistory.length > 0 && this.getFilterCount() && (
                      <span>Total: {this.getFilterCount()}</span>
                    )}
                  </div>
                </div>
              </>
            )}
            <Grid container>{this.renderTestResults()}</Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterTags: state.filter.tags,
    filterList: state.filter.filterList,
    filter: state.filter,
    testRuns: state.testRuns.testRuns,
    testResults: state.testResults[state.testResults.test_run_key] || [],
    test_run_key: state.testResults.test_run_key,
    open: state.testResults.open,
    fetching: state.testResults.fetching,
    authState: state.login.authState,
    error: state.testResults.error,
    dTags: state.dtags.byTag,
    byTestId: state.dtags.byTestId,
    testHistory: state.traceabilityMatrix.testResultHistory
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestTestResults: (key) => {
    dispatch({ type: TEST_RESULTS_FETCH_REQUESTED, payload: key });
  },
  onBuildCreate: (tag) => {
    dispatch({ type: BUILDS_DIALOG, payload: tag });
  },
  onClose: () => {
    dispatch({ type: TEST_RESULTS_DIALOG_CLOSE });
  },
  updateFilter: (filter) => {
    dispatch({ type: UPDATE_FILTER, payload: filter });
  },
  clearFilter: () => {
    dispatch({ type: CLEAR_FILTER });
  },
  requestJiraDetail: (jiraTickets) => {
    dispatch({ type: JIRA_FETCH_REQUESTED, payload: jiraTickets });
  }
});

TestResultsDialog.propTypes = {
  testResults: PropTypes.array,
  testRuns: PropTypes.array,
  test_run_key: PropTypes.string
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  connect(mapStateToProps, mapDispatchToProps)
)(TestResultsDialog);
