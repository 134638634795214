import React, { Component } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer
} from 'recharts';

import { CircularProgress, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';


const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  progressContainer: {
    display: 'flex',
    marginTop: '100px',
    justifyContent: 'center'
  },
  chartContainer: {
    padding: '0 20px 20px 20px'
  }
});


class SpiraCharts extends Component {


  getFilteredData(option, data) {
    if (option === 'all') {
      return data;
    } else if(option === 'withOq'){
      return data.filter(d => d.totalOQ !== 0);
    }else if(option === 'major') {
      return data.filter(d => d.releaseVersion.includes('.0'));
    }else if(option === 'withIn') {
      return data.filter(d => d.incidentCount !== 0);
    }
    return data;
  }

  render() {
    const { classes: cs, option } = this.props;
    const data = this.props.report;

    if (data !== undefined && data.length > 0) {
      const filteredData = this.getFilteredData(option, data);
      return (
        <div className={cs.chartContainer}>
          <ResponsiveContainer width="98%" height={500} >
            <BarChart width={1000} data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="releaseVersion" interval={0} orientation="top"/>
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="totalOQ" fill="#B1E2CC" />
              <Bar dataKey="incidentClose" stackId="x" fill="#6E9293" >
              </Bar>
              <Bar dataKey="incidentOpen" stackId="x" fill="#D8586E">
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    } else {
      return (
        <div className={cs.progressContainer}>
          <CircularProgress size={50} color="primary" className={cs.progress} />
        </div>
      );
    }
  }
}


const mapStateToProps = (state) => {
  return {
    report: state.spira.releaseReport,
  };
};
export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
  ),
)(SpiraCharts);
