export default function(theme) {
  return {
    deleteContainer: {
      float: 'right'
    },
    dynamicTagContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridColumnGap: '20px',
      gridRowGap: '20px'
    },
    tagContainer: {
      backgroundColor: '#f2f7f7',
      padding: '10px'
    },
    tag: {
      fontWeight: 600,
      marginBottom: '5px'
    },
    testContainer: {
      marginTop: '10px'
    },
    testName: {
      paddingLeft: '5px',
      color: 'grey'
    }
  };
}
