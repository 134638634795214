import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { apiMajorTags } from '../../assets/config/testConfig';

import {
  FormControlLabel,
  Switch,
  withStyles,
  CircularProgress,
  Tooltip,
  Slide,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  withMobileDialog
} from '@material-ui/core';

import { ChevronLeft, Replay, Search as SearchIcon } from '@material-ui/icons';
import { Link as LinkIcon } from '@material-ui/icons';

import {
  CLEAR_FILTER,
  UPDATE_FILTER,
  API_TEST_RESULTS_DIALOG_CLOSE,
  API_TEST_RESULTS_FETCH_REQUESTED,
  TEST_RESULTS_FETCH_REQUESTED,
  BUILDS_DIALOG,
  TEST_RESULTS_DIALOG_CLOSE,
  API_BUILDS_DIALOG
} from '../../redux/actions';

import InfoCard from '../InfoCard';
import APITestResultCard from '../APITestResultCard';
import APILogDetail from '../APILogDetail';

import styles from './styles';
import { legacyMajorTags, nextGenMajorTags } from '../../assets/config/testConfig';
import _ from 'lodash';
import path from 'path';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function getSuiteName(labels) {
  if (labels && labels.length > 0) {
    return labels[0].value;
  }
  return null;
}

class APITestResultsDialog extends React.Component {
  constructor() {
    super();
    this.state = { showAPI: false };
  }

  renderTestResults() {
    const { testResults, error } = this.props;
    if (testResults.length > 0) {
      return testResults.map((testResult, i) => {
        return <APITestResultCard testResult={testResult} key={i} />;
      });
    } else if (error) {
      return <div>Error occurred fetching results</div>;
    } else {
      return <CircularProgress size={50} color="primary" className={this.props.classes.progress} />;
    }
  }

  getAPITagGroup() {
    const testResults = this.props.testResults;
    const failedTestResults = testResults.filter((r) => r.passed === false);
    const tags = apiMajorTags;
    const tagGroup = [];
    const allfailedTestId = [...new Set(failedTestResults.map((test) => test.test_run_test_id))];
    let capturedFailedTestId = [];
    for (let i = 0; i < tags.length; i++) {
      const failedTests = failedTestResults.filter((result) => {
        return result.tags ? result.tags.includes(tags[i]) : false;
      });
      const failedIds = failedTests.map((test) => test.test_run_test_id);
      const failedTestName = [...new Set(failedTests.map((test) => test.name))].join('\n');
      capturedFailedTestId.push(...failedIds);
      const failedCount = failedTests.length;
      if (failedCount > 0) {
        tagGroup.push({
          tag: tags[i],
          failedCount,
          failedTestName
        });
      }
    }
    tagGroup.sort((a, b) => b.failedCount - a.failedCount);
    capturedFailedTestId = [...new Set(capturedFailedTestId)];

    // Getting the test IDs that did not have the tags we have configured
    let diff = _.difference(allfailedTestId, capturedFailedTestId);
    diff = diff.filter((n) => n != undefined && n != 'undefined');
    const diffTestName = [
      ...new Set(
        failedTestResults
          .filter((result) => diff.includes(result.test_run_test_id))
          .map((test) => test.name)
      )
    ].join('\n');
    if (diff.length > 0) {
      tagGroup.push({
        tag: 'Unknown',
        failedCount: diff.length,
        failedTestName: diffTestName
      });
    }
    const outcome = tagGroup.map((group, id) => {
      return (
        <div
          key={id}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            backgroundColor: 'lightgrey',
            borderRadius: '25px',
            padding: '5px',
            margin: '5px'
          }}
        >
          <span>{group.tag} - </span>
          <span style={{ marginLeft: '5px' }}>{group.failedCount}</span>
          <CopyToClipboard text={group.failedTestName}>
            <Button color="primary">
              <LinkIcon color="primary" />
            </Button>
          </CopyToClipboard>
        </div>
      );
    });
    return outcome;
  }

  componentDidUpdate() {
    const { test_run_key, testResults, fetching, error } = this.props;

    if (test_run_key && testResults.length === 0 && !fetching && !error) {
      this.props.requestTestResults(test_run_key);
    }
  }

  findTestRun() {
    const testRun = this.props.testRuns.find((run) => {
      return run.test_run_key === this.props.test_run_key;
    });
    if (testRun) {
      const newTestRun = JSON.parse(JSON.stringify(testRun));
      return newTestRun;
    }
    return testRun;
  }

  renderSwitch(filterName) {
    const { updateFilter, filter } = this.props;
    return (
      <FormControlLabel
        control={
          <Switch
            checked={filter[filterName]}
            onChange={() => updateFilter({ [filterName]: !filter[filterName] })}
            value={filterName}
          />
        }
        label="Hide"
      />
    );
  }

  handleUpdateOnFilterTags = (tags) => {
    this.props.updateFilter({ tags });
  };

  handleRerunClicked = () => {
    const tag = _(this.props.testResults)
      .filter((result) => {
        const { passed } = result;
        return passed === false;
      })
      .map((result) => result.name)
      .uniq()
      .value()
      .join('|');
    this.props.onAPIBuildCreate(tag);
  };

  renderRerunIcon() {
    if (this.props.testResults.length > 0) {
      const { authState } = this.props;
      const disableBuild = authState !== 'logged_in';
      const buildTip = disableBuild ? 'Please sign in' : 'Rerun failed tests.';
      const handleClick = this.handleRerunClicked;
      return (
        <Tooltip title={buildTip}>
          <span>
            <Button
              onClick={handleClick}
              color="secondary"
              variant="outlined"
              disabled={disableBuild}
            >
              Rerun
              <Replay color="secondary" />
            </Button>
          </span>
        </Tooltip>
      );
    }
  }

  renderFailedContent() {
    return (
      <div>
        {this.renderSwitch('failed')}
        {this.renderRerunIcon()}
      </div>
    );
  }
  renderInfoBoxes() {
    const { classes: cs } = this.props;
    const testRun = this.findTestRun();
    if (!testRun) {
      return;
    }
    return (
      <div>
        <Grid container>
          <Grid item className={cs.infoBoxContainer} xs={12} sm={6} md={4}>
            <InfoCard
              title="Total"
              description={testRun.total}
              sideContent={
                <Button
                  color="primary"
                  size="large"
                  disableElevation
                  onClick={() => {
                    this.setState({ showAPI: true });
                  }}
                >
                  View API Requests <ArrowForwardIosIcon />
                </Button>
              }
            />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xhs={12} sm={6} md={4}>
            <InfoCard
              title="Passed"
              description={testRun.passed}
              sideContent={this.renderSwitch('passed')}
            />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xs={12} sm={6} md={4}>
            <InfoCard
              title="Failed"
              description={testRun.failed}
              sideContent={this.renderFailedContent()}
            />
          </Grid>
          <Grid item className={cs.infoBoxContainer} xs={12} sm={12} md={12}>
            <InfoCard title="Failed Summary" summary={this.getAPITagGroup()} />
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { open } = this.props;
    const cs = this.props.classes;
    const runLink = `${window.location.protocol}//${window.location.host}/test_runs/${this.props.test_run_key}?be=1`;

    return (
      <>
        <Dialog
          fullScreen
          open={open}
          onClose={this.props.onClose}
          aria-labelledby="responsive-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle id="responsive-dialog-title">
            <Button onClick={this.props.onClose} color="primary" style={{ margin: 0 }}>
              <ChevronLeft color="primary" />
            </Button>
            {this.props.test_run_key}

            <div className={cs.linkContainer}>
              <Tooltip title="Copy to clipboard">
                <CopyToClipboard text={runLink}>
                  <Button color="primary">
                    <LinkIcon color="primary" />
                  </Button>
                </CopyToClipboard>
              </Tooltip>
            </div>
          </DialogTitle>

          <DialogContent>
            <div>
              {this.props.testResults.length > 0 && (
                <div className={cs.infoBoxesContainer}>{this.renderInfoBoxes()}</div>
              )}
              <Grid container>{this.renderTestResults()}</Grid>
            </div>
          </DialogContent>
        </Dialog>
        {this.state.showAPI && (
          <APILogDetail
            testRunKey={this.props.test_run_key}
            open={this.state.showAPI}
            handleClose={() => this.setState({ showAPI: false })}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filterTags: state.filter.tags,
    filter: state.filter,
    testRuns: state.apiTestRuns.testRuns,
    testResults: state.apiTestResults[state.apiTestResults.test_run_key] || [],
    test_run_key: state.apiTestResults.test_run_key,
    open: state.apiTestResults.open,
    fetching: state.apiTestResults.fetching,
    authState: state.login.authState,
    error: state.apiTestResults.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestTestResults: (key) => {
    dispatch({ type: API_TEST_RESULTS_FETCH_REQUESTED, payload: key });
  },
  onAPIBuildCreate: (tag) => {
    dispatch({ type: API_BUILDS_DIALOG, payload: tag });
  },
  onClose: () => {
    dispatch({ type: API_TEST_RESULTS_DIALOG_CLOSE });
  },
  updateFilter: (filter) => {
    dispatch({ type: UPDATE_FILTER, payload: filter });
  },
  clearFilter: () => {
    dispatch({ type: CLEAR_FILTER });
  }
});

APITestResultsDialog.propTypes = {
  testResults: PropTypes.array,
  testRuns: PropTypes.array,
  test_run_key: PropTypes.string
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  connect(mapStateToProps, mapDispatchToProps)
)(APITestResultsDialog);
