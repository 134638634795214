
export default (theme) => {
  return {
    formControl: {
      margin: '20px',
      minWidth: 200,
      width: 500,
      maxWidth: 500,
      minHeight: '48px'
    },
    buttonsContainer: {
      margin: '0 40px 20px'
    }
  };
};
