import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import {
  DTAG_ADD_REQUESTED,
  DTAG_CREATE_REQUESTED,
  DTAG_REMOVE_REQUESTED
} from '../../redux/actions';

import styles from './styles';

class DynamicTag extends React.Component {
  constructor(props) {
    super(props);

    this.anchorEl = React.createRef();

    this.state = {
      suggestions: [],
      suggestionsOpen: false
    };
  }

  handleClose = (event) => {
    this.setState({ suggestionsOpen: false });
  };

  handleAutocompleteSelect = (chip) => {
    this.handleAddTag(chip);
  };

  render() {
    const { classes: cs, tagList, byTestId, testId } = this.props;
    const tags = byTestId[testId] || [];

    return (
      <div className={cs.dynamicTagContainer}>
        <ChipInput
          classes={{
            chip: cs.chip
          }}
          fullWidth
          value={tags}
          datasource={tagList}
          dataSourceConfig={{ text: 'text', value: 'value' }}
          onAdd={this.handleAddTag}
          onDelete={this.handleRemoveTag}
          onUpdateInput={this.handletextFieldInputChange}
          placeholder="add dynamic tags"
        />
        <Popper
          open={this.state.suggestionsOpen}
          anchorEl={this.anchorEl.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper className={cs.pop}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {this.state.suggestions.map((suggestion, idx) => {
                      return (
                        <MenuItem
                          className={cs.popItem}
                          key={idx}
                          onClick={() => this.handleAutocompleteSelect(suggestion)}
                        >
                          {suggestion.text}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }

  handletextFieldInputChange = (e) => {
    const newTag = e.target.value.toLowerCase();
    const { tagList } = this.props;
    const suggestions = tagList
      .filter((tag) => tag.text.startsWith(newTag))
      .sort((a, b) => a.text.localeCompare(b.text));

    this.setState({ suggestionsOpen: true, suggestions });
  };

  /**
   * If tag already exist, then we create a relationship between the tag and the test
   * If tag does note exist, we create the tag first before creating the relationship
   * @para {object} chip - The value we want to add
   */
  handleAddTag = (chip) => {
    const { tagList, byTestId, testId, testName } = this.props;
    const testTags = byTestId[testId] || [];
    const newTag = chip.text.toLowerCase();
    const foundInTestList = testTags.find((tag) => tag.text === newTag);
    const foundInTagList = tagList.find((tag) => tag.text === newTag);

    this.setState({ suggestionsOpen: false });

    if (foundInTestList) {
      return;
    }

    if (foundInTagList) {
      const dtagId = foundInTagList.value;
      this.props.onAddTag(testId, dtagId, testName);
    } else {
      this.props.onCreateTag(testId, newTag, testName);
    }
  };

  /**
   * Deletes the relationship between a tag and a test
   * @para {int} value - This is the id of the tagged_tests table
   */
  handleRemoveTag = (obj) => {
    this.props.onRemoveTag(obj.value);
  };
}

DynamicTag.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    tagList: state.dtags.tagList,
    byTestId: state.dtags.byTestId
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreateTag: (testId, dtag, testName) => {
    dispatch({ type: DTAG_CREATE_REQUESTED, payload: { dtag, testId, testName } });
  },
  onAddTag: (testId, dtagId, testName) => {
    dispatch({ type: DTAG_ADD_REQUESTED, payload: { dtagId, testId, testName } });
  },
  onRemoveTag: (id) => {
    dispatch({ type: DTAG_REMOVE_REQUESTED, payload: { id } });
  }
});

DynamicTag.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DynamicTag);
