import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import MUIDataTable from 'mui-datatables';
import moment from 'moment';
import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';

const options = {
  filterType: 'checkbox',
  selectableRows: false,
  download: false,
  search: false,
  print: false,
  responsive: 'scroll',
  rowsPerPage: 15,
  rowsPerPageOptions: [10, 15, 20, 30, 100]
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: '20px'
      }
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        padding: '0 0 0 25px'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        maxWidth: '240px',
        width: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '20px 10px 10px 20px',
        '&:nth-child(2)': { width: '6%' },
        '&:nth-child(4)': { width: '8%' },
        '&:nth-child(6)': { width: '6%' },
        '&:nth-child(8)': { width: '6%' },
        '&:nth-child(10)': { width: '6%' },
        '&:nth-child(12)': { width: '6%' },
        '&:nth-child(14)': { width: '6%' },
        '&:nth-child(16)': { width: '6%' },
        '&:nth-child(18)': { width: '6%' },
        '&:last-child': { paddingRight: 0 }
      }
    }
  }
});

function getColumnConfig() {
  return [
    {
      name: 'Date',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Name',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Executor',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'URL',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Failed',
      options: {
        filter: false,
        sort: true
      }
    }
  ];
}

function getPlatformUrl(env, platform) {
  if (platform === 'nextGen') {
    if (env === 'dev') {
      return 'https://groovy.dev1-platform.s37dev.com/';
    } else if (env === 'stage') {
      return 'https://stage-platform.s37dev.com/';
    }
  } else if (platform === 'legacy') {
    if (env === 'dev') {
      return 'https://dev.nora.science37.com/';
    } else if (env === 'stage') {
      return 'https://stage.nora.science37.com/';
    }
  }
}
const EXECUTOR = '@daily_robot_rerun_failed';

function getData(testRuns, env, platform) {
  let data = [];
  const platformUrl = getPlatformUrl(env, platform);
  for (let i = testRuns.length - 1; i >= 0; i--) {
    let obj = {};
    if (testRuns[i].nora_site.includes(platformUrl) && testRuns[i].executor === EXECUTOR) {
      obj['date'] = moment(testRuns[i].test_run_name, 'YYYY-MM-DD_HH-mm-ss-SSS').format(
        'YYYY-MM-DD'
      );
      obj['name'] = testRuns[i].test_run_name;
      obj['executor'] = EXECUTOR;
      obj['url'] = testRuns[i].nora_site;
      obj['failed'] = testRuns[i].failed;
      data.push(obj);
    }
  }
  return data;
}

function parseData(data) {
  return _.map(data, (r) => {
    return [r.date || '', r.name || '', r.executor || '', r.url || '', r.failed || ''];
  });
}

export default (props) => {
  const { testRuns, env, platform } = props;
  const data = getData(testRuns.slice().reverse(), env, platform);
  let getConfig = getColumnConfig();
  if (data === undefined || data.length === 0) {
    return (
      <div>
        <CircularProgress size={50} color="primary" />
      </div>
    );
  }
  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable options={options} columns={getConfig} data={parseData(data)} />
    </MuiThemeProvider>
  );
};
