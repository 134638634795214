import React, { useState, useEffect, useCallback } from 'react';
import { MainCard } from 'heimdall-components';
import { DataGrid, GridRowsProp, GridColDef } from '@material-ui/data-grid';
import { connect, useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ApiIcon from '@mui/icons-material/Api';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Tab from '@mui/material/Tab';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  DATAHARMONIZATION_FETCH_SQL_DATA_REQUESTED,
  DATAHARMONIZATION_FETCH_NOSQL_DATA_REQUESTED,
  DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_REQUESTED,
  DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED,
  DATAHARMONIZATION_FHIR_TO_FHIRLITE_REQUESTED,
  DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED,
  DATAHARMONIZATION_SFDC_DOCUMENTS_REQUESTED,
  DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED
} from '../../redux/actions';

import {
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  LinearProgress,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import dhClass from './dataharmonization.module.css';
import * as _ from 'lodash';
import DHGlobalComponent from './dhGlobalComponent';
import DHFhirComponent from './dhFhirComponent';
import { Typography } from '@mui/material';
import DHSFDCComponent from './dhSFDC';
import DHLegacyComponent from './dhLegacyComponent';

function DataHarmonization() {
  const [platform, setPlatform] = useState('legacy'); //Platforms
  const [environment, setEnvironment] = useState(''); //Environments
  const [globalSchema, setGlobalSchema] = useState('trial'); //global Schema
  const [globalEnvType, setGlobalEnvType] = useState('dev');
  const [envType, setEnvType] = useState('');

  const [sqlType, setSqlType] = useState(''); //Environments
  const [showSqlType, setShowSqlType] = useState(true);
  const [showEnvironment, setShowEnvironment] = useState(true);
  const [showGlobalSchema, setShowGlobalSchema] = useState(false);
  const [tab, setTab] = useState('1');
  const [expanded, setExpanded] = React.useState(true);

  const handleChange = (panel) => () => {
    setExpanded(!expanded);
  };

  const handleTabsChange = (event, newValue) => {
    debugger;
    setTab(newValue);
  };

  const globalEnvTypes = ['dev', 'stage'];
  const envTypes = ['dev', 'stage'];

  let data = useSelector((state) => {
    return { data: state.dhValidation.data, columns: state.dhValidation.gridColumns };
  });
  let dataFetching = useSelector((state) => state.dhValidation.fetching);
  let dataDocumentslist = useSelector((state) => state.dhValidation.documentList);
  let dataEnvironments = useSelector((state) => state.dhValidation.environments);
  let dataFetchingError = useSelector((state) => state.dhValidation.error);
  let dataGlobalEnvironments = useSelector((state) => state.dhValidation.globalEnvironments);
  let dataGlobalData = useSelector((state) => state.dhValidation.globalData);
  let dataGlobalColumns = useSelector((state) => state.dhValidation.globalColumns);
  let dataFHIRData = useSelector((state) => state.dhValidation.fhirData);
  let dataFHIRColumns = useSelector((state) => state.dhValidation.fhirColumns);

  const dispatch = useDispatch();

  const platforms = [
    {
      value: 'legacy',
      alias: 'Legacy',
      active: true,
      sqlTypes: [
        { value: 'sql', alias: 'sql', active: true },
        { value: 'noSql', alias: 'noSql', active: true }
      ]
    },
    {
      value: 'nextGen',
      alias: 'NextGen',
      active: true,
      sqlTypes: [
        { value: 'sql', alias: 'sql', active: false },
        { value: 'noSql', alias: 'noSql', active: true }
      ]
    },
    {
      value: 'sfdc',
      alias: 'SFDC',
      active: true,
      sqlTypes: [{ value: 'sql', alias: 'sql', active: true }]
    },
    {
      value: 'global',
      alias: 'Global',
      active: true,
      sqlTypes: [{ value: 'all', alias: 'all', active: true }]
    }
  ];

  //Default Sort
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'entity',
      sort: 'asc'
    }
  ]);

  const dataGlobalSchema = ['trial', 'admin', 'notifications', 'scheduler', 'tenant', 'reference'];

  useEffect(() => {
    dispatch({
      type: DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED,
      payload: { platform: platform, type: sqlType }
    });
  }, []);

  const fetchData = () => {
    if (showGlobalSchema === true) {
      //1.GLOBAL
      dispatch({
        type: DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED,
        payload: { platform: platform, schema: globalSchema, envType: globalEnvType }
      });
    } else {
      if (environment.indexOf('fhir') !== -1) {
        //2. FHIR _ FHIRLITE
        dispatch({
          type: DATAHARMONIZATION_FHIR_TO_FHIRLITE_REQUESTED,
          payload: {
            environment: environment,
            platform: platform,
            envType: envType
          }
        });
      } else if (environment.indexOf('sfdc') !== -1) {
        //3. SFDC
        dispatch({
          type: DATAHARMONIZATION_SFDC_DOCUMENTS_REQUESTED,
          payload: { environment: environment, platform: platform, envType: envType }
        });
      } else {
        //4. NO SQL
        if (sqlType === 'noSql') {
          // const ind = environment.indexOf('-');
          // const env = environment.substr(0, ind);

          dispatch({
            type: DATAHARMONIZATION_FETCH_NOSQL_DATA_REQUESTED,
            payload: {
              environment: environment,
              platform: platform,
              type: sqlType,
              envType: envType
            }
          });
        } else {
          dispatch({
            type: DATAHARMONIZATION_FETCH_SQL_DATA_REQUESTED,
            payload: {
              environment: environment,
              platform: platform,
              type: sqlType,
              envType: envType
            }
          });
        }
      }
    }
  };

  //Handle Platform Change
  const handlePlatformChange = (e) => {
    setPlatform(e.target.value);
    setSqlType(null);
    setEnvType(null);
    setEnvironment(null);

    setShowSqlType(true);
    setShowEnvironment(true);

    if (e.target.value === 'global') {
      setShowSqlType(false);
      setShowEnvironment(false);
      setShowGlobalSchema(true);
    } else if (e.target.value === 'nextGen') {
      setShowGlobalSchema(false);
    } else {
      //if (e.target.value === 'sfdc') { {
      setShowGlobalSchema(false);

      dispatch({
        type: DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED,
        payload: { platform: e.target.value, type: sqlType, envType: envType }
      });
    }
  };

  //Handle Sql Type change
  const handleSqlTypeChange = (e) => {
    setSqlType(e.target.value);
    setEnvType(null);
    setEnvironment(null);

    dispatch({
      type: DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED,
      payload: { platform: platform, type: e.target.value, envType: envType }
    });
  };

  //Handle Global Schema Change
  const handleGlobalSchemaChange = (value, selectType) => {
    switch (selectType) {
      case 'schema': {
        setGlobalSchema(value);
        break;
      }
      case 'envType': {
        setGlobalEnvType(value);
        break;
      }
      default: {
        break;
      }
    }
  };

  //Handle Environment Change
  const handleEnvironmentChange = (e) => {
    setEnvironment(e.target.value);
  };

  //Env Type changed
  const onEnvTypeSelected = (value) => {
    setEnvType(value);

    dispatch({
      type: DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED,
      payload: { platform: platform, type: sqlType, envType: value }
    });
  };

  return (
    <MainCard
      title="Data Harmonization"
      content={
        <div>
          <React.Fragment>
            <Box m={2} pt={3}>
              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleTabsChange} aria-label="DH Tabs">
                    <Tab label="Data Validation" value="1" />
                    <Tab label="Airflow Jobs" value="2" />
                    <Tab label="----" value="3" />
                  </TabList>
                </Box>

                <TabPanel value="1">
                  <Stack direction="row" spacing={2}>
                    {/* --PLATFORM-- */}
                    <FormControl style={{ width: '150px' }}>
                      <InputLabel id="demo-simple-select-label">Platform</InputLabel>
                      <Select
                        style={{ width: '100%' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={platform}
                        label="Platform"
                        disabled={dataFetching}
                        onChange={handlePlatformChange}
                      >
                        {platforms.map((plt) => (
                          <MenuItem key={plt.value} value={plt.value} disabled={!plt.active}>
                            {plt.alias}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* --Sql Type-- */}
                    <FormControl
                      style={{ width: '150px', display: showSqlType ? 'block' : 'none' }}
                    >
                      <InputLabel id="demo-simple-select-label">Type</InputLabel>
                      <Select
                        style={{ width: '100%' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sqlType}
                        label="Platform"
                        disabled={dataFetching}
                        onChange={handleSqlTypeChange}
                      >
                        {platforms
                          .find((a) => a['value'] === platform)
                          ['sqlTypes'].map((env) => (
                            <MenuItem key={env.value} value={env.value} disabled={!env.active}>
                              {env.alias}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      style={{ width: '150px', display: showEnvironment ? 'block' : 'none' }}
                    >
                      <InputLabel id="demo-simple-select-label">Environment Type</InputLabel>

                      <Select
                        style={{ width: '100%' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={envType}
                        label="EnvironmentType"
                        disabled={dataFetching}
                        onChange={(e) => onEnvTypeSelected(e.target.value)}
                      >
                        {envTypes.map((envType) => (
                          <MenuItem key={envType} value={envType}>
                            {envType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* --Environment-- */}
                    <FormControl
                      style={{ width: '150px', display: showEnvironment ? 'block' : 'none' }}
                    >
                      <InputLabel id="demo-simple-select-label">Environment</InputLabel>
                      <Select
                        style={{ width: '100%' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={environment}
                        label="Platform"
                        disabled={dataFetching}
                        onChange={handleEnvironmentChange}
                      >
                        {dataEnvironments
                          .filter((a) => a['envType'] === envType)
                          .map((env) => (
                            <MenuItem key={env.value} value={env.value} disabled={!env.active}>
                              {env.alias}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    {/* --Global Schema-- */}
                    <FormControl
                      style={{ width: '150px', display: showGlobalSchema ? 'block' : 'none' }}
                    >
                      <InputLabel id="demo-simple-select-label">Global Schema</InputLabel>
                      <Select
                        style={{ width: '100%' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalSchema}
                        label="GlobalSchema"
                        disabled={dataFetching}
                        onChange={(e) => handleGlobalSchemaChange(e.target.value, 'schema')}
                      >
                        {dataGlobalSchema.map((schema) => (
                          <MenuItem key={schema} value={schema}>
                            {schema}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      style={{ width: '150px', display: showGlobalSchema ? 'block' : 'none' }}
                    >
                      <InputLabel id="demo-simple-select-label">Global Environment Type</InputLabel>

                      <Select
                        style={{ width: '100%' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={globalEnvType}
                        label="GlobalSchema"
                        disabled={dataFetching}
                        onChange={(e) => handleGlobalSchemaChange(e.target.value, 'envType')}
                      >
                        {globalEnvTypes.map((envType) => (
                          <MenuItem key={envType} value={envType}>
                            {envType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/*GLOBAL END*/}
                    <Button
                      variant="contained"
                      onClick={() => fetchData()}
                      color="primary"
                      disabled={dataFetching}
                    >
                      Run Report
                    </Button>

                    {dataFetchingError ? <label>Unknown error while fetching data</label> : ''}
                  </Stack>
                  <Paper>
                    {dataFetching ? (
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                      </Box>
                    ) : (
                      <div style={{ padding: '15px', height: 'auto', width: '100%' }}>
                        <DHLegacyComponent
                          style={{ display: platform?.indexOf('legacy') !== -1 ? 'block' : 'none' }}
                          legacyData={data}
                          environment={environment}
                          platform={platform}
                          sqlType={sqlType}
                          envType={envType}
                          dataFetching={dataFetching}
                        ></DHLegacyComponent>

                        {/*FHIR FHIRLITE REDSHIFT COUNTS SCHEMA*/}
                        <Accordion
                          expanded={expanded}
                          onChange={handleChange()}
                          style={{
                            display: platform?.indexOf('nextGen') !== -1 ? 'block' : 'none'
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>{platform}</Typography>
                            <Typography sx={{ width: '33%', color: 'text.secondary' }}>
                              {sqlType}
                            </Typography>
                            <Typography sx={{ width: '33%', color: 'text.secondary' }}>
                              {environment}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <DHFhirComponent
                              fhirData={dataFHIRData}
                              columns={dataFHIRColumns}
                              environment={environment}
                              platform={platform}
                              sqlType={sqlType}
                              envType={envType}
                            ></DHFhirComponent>
                          </AccordionDetails>
                        </Accordion>
                        {/***********/}

                        {/*GLOBAL SCHEMA*/}
                        <DHGlobalComponent
                          globalData={dataGlobalData}
                          platform={platform}
                          style={{ display: platform?.indexOf('global') !== -1 ? 'block' : 'none' }}
                        ></DHGlobalComponent>
                        {/***********/}

                        {/* SFDC */}
                        <DHSFDCComponent
                          sfdcDetails={data}
                          environment={environment}
                          platform={platform}
                          sqlType={sqlType}
                          envType={envType}
                          dataFetching={dataFetching}
                          style={{ display: platform?.indexOf('sfdc') !== -1 ? 'block' : 'none' }}
                        ></DHSFDCComponent>
                        {/********/}
                      </div>
                    )}
                  </Paper>
                </TabPanel>
                <TabPanel value="2">Airflow Jobs. Coming soon...</TabPanel>
                <TabPanel value="3">Coming soon...</TabPanel>
              </TabContext>
            </Box>
          </React.Fragment>
        </div>
      }
    ></MainCard>
  );
}

export default DataHarmonization;
