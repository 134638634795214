import RemoteResource from './RemoteResource';
//import FileResource from './FileResource'; // For faster local testing

class TestResource {
  static resource = new RemoteResource();
  //static resource = new FileResource();

  getTopFails() {
    return TestResource.resource.getTopFails();
  }

  getHistory(id, isNextGen, noraSite) {
    return TestResource.resource.getHistory(id, isNextGen, noraSite);
  }

  getApiTestResults(key, force) {
    return TestResource.resource.getApiTestResults(key, force);
  }

  getTraceabilityMatrix() {
    return TestResource.resource.getTraceabilityMatrix();
  }

  getTestResultHistory() {
    return TestResource.resource.getTestResultHistory();
  }

  updateRMS(id, payload) {
    return TestResource.resource.updateRMS(id, payload);
  }

  getApiTestRuns(force) {
    return TestResource.resource.getApiTestRuns(force);
  }

  getLoadTestRuns(force) {
    return TestResource.resource.getLoadTestRuns(force);
  }

  getTestRuns(force) {
    return TestResource.resource.getTestRuns(force);
  }

  getTestResultDetail(key, id) {
    return TestResource.resource.getTestResultDetail(key, id);
  }

  getAPITestResultDetail(key, id) {
    return TestResource.resource.getApiTestResultDetail(key, id);
  }

  getAPIRequestLogDetail(key) {
    return TestResource.resource.getApiRequestLogDetail(key);
  }

  async getTestResults(key) {
    return TestResource.resource.getTestResults(key);
  }

  getBuilds() {
    return TestResource.resource.getBuilds();
  }

  getChinaBuilds() {
    return TestResource.resource.getChinaBuilds();
  }

  getAPIBuilds() {
    return TestResource.resource.getAPIBuilds();
  }

  getLoadBuilds() {
    return TestResource.resource.getLoadBuilds();
  }

  createBuilds(payload) {
    return TestResource.resource.createBuilds(payload);
  }

  createChinaBuilds(payload) {
    return TestResource.resource.createChinaBuilds(payload);
  }

  createAPIBuilds(payload) {
    return TestResource.resource.createAPIBuilds(payload);
  }

  createLoadBuilds(payload) {
    return TestResource.resource.createLoadBuilds(payload);
  }

  fetchTags() {
    return TestResource.resource.fetchTags();
  }

  addTag(dtagId, testId, testName) {
    return TestResource.resource.addTag(dtagId, testId, testName);
  }

  deleteTag(id) {
    return TestResource.resource.deleteTag(id);
  }

  removeTag(id) {
    return TestResource.resource.removeTag(id);
  }

  createTag(dtag, testId, testName) {
    return TestResource.resource.createTag(dtag, testId, testName);
  }

  ///////////////////////////////
  // START - DATA HARMONIZATION//
  getDataHarmonizationPlatformEnvironments(platform, sqlType, envType) {
    return TestResource.resource.getDHPlatformEnvironments(platform, sqlType, envType);
  }

  getDataHarmonizationSQLData(environment, platform, type, envType) {
    return TestResource.resource.getDHSQLData(environment, platform, type, envType);
  }

  getDataHarmonizationNOSQLData(environment, document, platform, envType) {
    return TestResource.resource.getDHNOSQLData(environment, document, platform, envType);
  }

  getDataHarmonizationNOSQLDocumentList(environment, platform, envType) {
    return TestResource.resource.getDHNOSQLDocumentList(environment, platform, envType);
  }

  getDataHarmonizationFhirToFhirLiteTransformationData(environment, platform, table, envType) {
    return TestResource.resource.getDHFHIRToFHIRLITEDataResult(
      environment,
      platform,
      table,
      envType
    );
  }

  getDataHarmonizationFhirBucketData(body, environment, platform, envType) {
    return TestResource.resource.getDHFHIRBucketData(body, environment, platform, envType);
  }

  getDataHarmonizationFhirEnvironmentTables(environment, platform) {
    return TestResource.resource.getDHFHIREnvironmentTables(environment, platform);
  }

  getDataHarmonizationSFDCDocuments(environment, platform, envType) {
    return TestResource.resource.getSFDCDocuments(environment, platform, envType);
  }

  getDataHarmonizationSFDCBucketData(body, environment, platform, envType) {
    return TestResource.resource.getDHSFDCBucketData(body, environment, platform, envType);
  }

  getDataHarmonizationGlobalSchemaEnvironments(schema, platform, envType) {
    return TestResource.resource.getDHGlobalSchemaEnvironments(schema, platform, envType);
  }

  getDataHarmonizationGlobalData(environment, platform, envType) {
    return TestResource.resource.getDHGlobalData(environment, platform, envType);
  }
  // END - DATA HARMONIZATION//
  /////////////////////////////
}

export default new TestResource();
