export default function(theme) {
  return {
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    formControl: {
      margin: '20px',
      minWidth: 200,
      width: 400,
      maxWidth: 500,
      minHeight: '48px'
    },
    buttonsContainer: {
      margin: '0 40px 20px'
    },
    radioGroup: {
      margin: '0 20px'
    },
    searchTag: {
      paddingTop: '5px'
    }
  };
}
