import testResource from '../../lib/TestResource';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  TEST_RESULTS_STEPS_FETCH_SUCCEEDED,
  TEST_RESULTS_STEPS_FETCH_FAILED,
  TEST_RESULTS_STEPS_FETCH_REQUESTED,
  API_TEST_RESULTS_DETAIL_FETCH_REQUESTED,
  API_TEST_RESULTS_DETAIL_FETCH_FAILED,
  API_TEST_RESULTS_DETAIL_FETCH_SUCCEEDED,
  API_REQUEST_LOG_FETCH_SUCCEEDED,
  API_REQUEST_LOG_FETCH_FAILED,
  API_REQUEST_LOG_FETCH_REQUESTED,
} from '../actions';

function* fetchTestResultSteps(action) {
  try {
    const { key, id } = action.payload;
    const testResults = yield call(testResource.getTestResultDetail, key, id);
    yield put({
      type: TEST_RESULTS_STEPS_FETCH_SUCCEEDED,
      payload: testResults.data
    });
  } catch (e) {
    yield put({ type: TEST_RESULTS_STEPS_FETCH_FAILED, payload: e.message });
  }
}

function* fetchAPITestResultDetail(action) {
  try {
    const { key, id } = action.payload;
    const testResults = yield call(testResource.getAPITestResultDetail, key, id);
    yield put({
      type: API_TEST_RESULTS_DETAIL_FETCH_SUCCEEDED,
      payload: testResults.data
    });
  } catch (e) {
    yield put({ type: API_TEST_RESULTS_DETAIL_FETCH_FAILED, payload: e.message });
  }
}

function* fetchAPIRequestLogDetail(action) {
  try {
    const { key } = action.payload;
    const testResults = yield call(testResource.getAPIRequestLogDetail, key);
    yield put({
      type: API_REQUEST_LOG_FETCH_SUCCEEDED,
      payload: {
        data: testResults.data,
        key
      }
    });
  } catch (e) {
    yield put({ type: API_REQUEST_LOG_FETCH_FAILED, payload: e.message });
  }
}

export function* watchTestResultSteps() {
  yield takeLatest(TEST_RESULTS_STEPS_FETCH_REQUESTED, fetchTestResultSteps);
  yield takeLatest(API_TEST_RESULTS_DETAIL_FETCH_REQUESTED, fetchAPITestResultDetail);
  yield takeLatest(API_REQUEST_LOG_FETCH_REQUESTED, fetchAPIRequestLogDetail);
}
