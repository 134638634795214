import axios from 'axios';

// This file is for extra API calls that is not going to Bifrost
const GITHUB_BOT_URL = 'https://gmenjnb38e.execute-api.us-west-2.amazonaws.com/Prod';

export const getJiraDetail = (payload) => {
  return axios.post(`${GITHUB_BOT_URL}/getJiraDetail`, payload);
};

export const fetchSpiraReport = () => {
  return axios.get(`${GITHUB_BOT_URL}/releaseDetail`);
};

export const fetchSpiraRelease = () => {
  return axios.get(`${GITHUB_BOT_URL}/spiraRelease`);
};

export const fetchJiraReleaseVersions = () => {
  return axios.get(`${GITHUB_BOT_URL}/jiraReleaseVersions`);
};
