export default function(theme) {
  return {
    chartContainer: {
      minHeight: '750px',
      width: '100%',
      backgroundColor: '#fff'
    },
    chartText: {
      padding: '40px 0 0 40px',
      fontWeight: '520',
      fontSize: '25px',
      textAlign: 'center',
      textDecoration: "underline",
    },
    wrapper: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    newLineText: {
      whiteSpace: "initial"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    switchText: {
      display: "flex",
      paddingRight: '20px',
      fontWeight: '400',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '90px'
    },
    formControl: {
      margin: '20px',
      minWidth: 200,
      width: 500,
      maxWidth: 500,
      minHeight: '48px'
    },
    groupOption: {
      display: "flex",
      alignContent: "justify-center",
      width: "auto"
    },
    alignCenter: {
      alignContent: "justify-center",
      alignSelf: "center"
    },
    jiraContainer: {
      display: "flex",
      fontFamily: "initial",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    historyContainer: {
      display: "flex",
      fontFamily: "initial",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    initialJiraGroup: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "flex-start",
      justifyContent: "start",
    },
    groupContainer: {
      display: "flex",
      flexWrap: "nowrap",
      width: "220px",
      marginRight: "20px",
      justifyContent: "space-between",
    },
    jiraTicket: {
      flex: "0 0 auto",
      marginRight: "5px",
    },
    historyDate: {
      flex: "0 0 auto",
      marginRight: "15px",
    },
    jiraSummary: {
      selfAlign: "start"
    },
    historyStatus: {
      selfAlign: "start",
      marginLeft: "10px",
    },
    jiraStatus: {
      marginLeft: "5px"
    },
    viewResult: {
      display: "flex"
    }
  };
}
