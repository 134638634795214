import Amplify, { Auth } from 'aws-amplify';

//const redirectSignIn = 'http://localhost:3000';
//const redirectSignOut = 'http://localhost:3000';
const domain =
  process.env.REACT_APP_COGNITO_DOMAIN || 'heimdall-dev.auth.us-west-2.amazoncognito.com';

const aws_cognito_region = process.env.REACT_APP_COGNITO_REGION || 'us-west-2';
const aws_user_pools_id = process.env.REACT_APP_COGNITO_POOLS_ID || 'us-west-2_OOx9ZfqtP';
const aws_user_pools_web_client_id =
  process.env.REACT_APP_COGNITO_WEB_CLIENT_ID || '4d9vim0532vhjk52sphspeokhf';

//const redirect_uri = `${window.location.origin}`;
const redirectSignIn = window.location.origin;
const redirectSignOut = window.location.origin;

const oauth = {
  domain,
  redirectSignIn,
  redirectSignOut,

  // Authorized scopes
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],

  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  responseType: 'code',
  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true
  }
};

export default function() {
  Amplify.configure({
    aws_cognito_region,
    aws_user_pools_id,
    aws_user_pools_web_client_id
  });

  Auth.configure({ oauth });
}
