import {
  NEXT_GEN_HISTORY_FETCH_SUCCEEDED,
  HISTORY_FETCH_REQUESTED,
  HISTORY_FETCH_SUCCEEDED,
  HISTORY_FETCH_FAILED,
  TOP_FAILS_FETCH_REQUESTED,
  TOP_FAILS_FETCH_FAILED,
  TOP_FAILS_FETCH_SUCCEEDED,
  RESULTS_DETAIL_DIALOG_OPEN,
  RESULTS_DETAIL_DIALOG_CLOSE,
  TEST_RUNS_FETCH_SUCCEEDED,
  TEST_RUNS_FETCH_FAILED,
  TEST_RUNS_FETCH_REQUESTED,
  BUILDS_FETCH_FAILED,
  BUILDS_FETCH_REQUESTED,
  BUILDS_FETCH_SUCCEEDED,
  BUILDS_CHINA_FETCH_FAILED,
  BUILDS_CHINA_FETCH_REQUESTED,
  BUILDS_CHINA_FETCH_SUCCEEDED
} from '../actions';

const defaultState = {
  testResults: [],
  detailResult: {},
  testRuns: [],
  builds: [],
  builds_china: [],
  test_runs_error: false,
  builds_error: false,
  builds_china_error: false,
  test_runs_fetching: false,
  test_run_key: '',
  builds_fetching: false,
  builds_china_fetching: false,
  results_detail_dialog_open: false,
  history: [],
  nextGenHistory: {},
  fails: [],
  history_fetching: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case HISTORY_FETCH_FAILED:
      return {
        ...state,
        history: [],
        history_fetching: false
      };
    case HISTORY_FETCH_SUCCEEDED:
      return {
        ...state,
        history: action.payload,
        history_fetching: false
      };
    case NEXT_GEN_HISTORY_FETCH_SUCCEEDED:
      const { id, data } = action.payload;
      return {
        ...state,
        nextGenHistory: {
          ...state.nextGenHistory,
          [id]: data
        },
        history_fetching: false
      };
    case HISTORY_FETCH_REQUESTED:
      return {
        ...state,
        history: [],
        history_fetching: true
      };
    case TOP_FAILS_FETCH_FAILED:
      return {
        ...state,
        fails: []
      };
    case TOP_FAILS_FETCH_REQUESTED:
      return {
        ...state,
        fails: []
      };
    case TOP_FAILS_FETCH_SUCCEEDED:
      return {
        ...state,
        fails: action.payload
      };
    case TEST_RUNS_FETCH_REQUESTED:
      return {
        ...state,
        testRuns: [],
        test_runs_fetching: true,
        test_runs_error: false
      };
    case TEST_RUNS_FETCH_FAILED:
      return { ...state, test_runs_fetching: false, test_runs_error: true };
    case TEST_RUNS_FETCH_SUCCEEDED:
      return {
        ...state,
        testRuns: action.payload,
        test_runs_error: false,
        test_runs_fetching: false
      };
    case RESULTS_DETAIL_DIALOG_OPEN:
      return {
        ...state,
        results_detail_dialog_open: true,
        detailResult: action.payload
      };
    case RESULTS_DETAIL_DIALOG_CLOSE:
      return {
        ...state,
        results_detail_dialog_open: false,
        detailResult: {}
      };
    case BUILDS_FETCH_REQUESTED:
      return {
        ...state,
        builds: [],
        builds_error: false,
        builds_fetching: true
      };
    case BUILDS_FETCH_SUCCEEDED:
      return {
        ...state,
        builds: action.payload,
        builds_error: false,
        builds_fetching: false
      };
    case BUILDS_FETCH_FAILED:
      return { ...state, builds_fetching: false, builds_error: false };
    case BUILDS_CHINA_FETCH_REQUESTED:
      return {
        ...state,
        builds_china: [],
        builds_china_error: false,
        builds_china_fetching: true
      };
    case BUILDS_CHINA_FETCH_SUCCEEDED:
      return {
        ...state,
        builds_china: action.payload,
        builds_china_error: false,
        builds_china_fetching: false
      };
    case BUILDS_CHINA_FETCH_FAILED:
      return { ...state, builds_china_fetching: false, builds_china_error: false };
    default:
      return state;
  }
}
