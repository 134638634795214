export const API_ENV = [
  {
    value: 'dev1',
    webBaseURL: 'https://dev1-platform.s37dev.com/',
    label: 'DEV1 - dev1-platform'
  },
  {
    value: 'stage',
    webBaseURL: 'https://stage-platform.s37dev.com/',
    label: 'STAGE - stage-platform'
  },
  {
    value: 'dev',
    webBaseURL: 'https://dev.nora.science37.com/',
    label: 'Legacy Dev'
  },
  {
    value: 'stage',
    webBaseURL: 'https://stage.nora.science37.com/',
    label: 'Legacy Stage'
  }
];

export const DATABASE_HOSTS = [
  {
    value: 'db-nora-dev.cti10lnrh4rb.us-west-2.rds.amazonaws.com',
    label: 'db-nora-dev'
  },
  {
    value: 'db-nora-stage.cti10lnrh4rb.us-west-2.rds.amazonaws.com',
    label: 'db-nora-stage'
  }
];

export const PARALLEL_OPTIONS = [
  {
    value: 1,
    label: 'One Instance'
  },
  {
    value: 2,
    label: 'Two Instances'
  },
  {
    value: 3,
    label: 'Three Instances'
  },
  {
    value: 4,
    label: 'Four Instances'
  },
  {
    value: 5,
    label: 'Five Instances'
  },
  {
    value: 6,
    label: 'Six Instances'
  },
  {
    value: 7,
    label: 'Seven Instances'
  },
  {
    value: 8,
    label: 'Eight Instances'
  },
  {
    value: 9,
    label: 'Nine Instances'
  },
  {
    value: 10,
    label: 'Ten Instances'
  }
];

export const RUN_TYPE = {
  RUN_ALL: 'run_all',
  BY_TAGS: 'by_tags',
  BY_SUB_SET: 'by_sub_set'
};

export const LOAD_RUN_TYPE = {
  GENERAL: 'general',
  CUSTOM_DB: 'customDB'
};
