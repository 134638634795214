import {
  DATAHARMONIZATION_FETCH_SQL_DATA_SUCCEEDED,
  DATAHARMONIZATION_FETCH_SQL_DATA_FAILED,
  DATAHARMONIZATION_FETCH_SQL_DATA_REQUESTED,
  DATAHARMONIZATION_FETCH_NOSQL_DATA_REQUESTED,
  DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED,
  DATAHARMONIZATION_FETCH_NOSQL_DATA_FAILED,
  DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_REQUESTED,
  DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_SUCCEEDED,
  DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_FAILED,
  DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED,
  DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_SUCCEEDED,
  DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_FAILED,
  DATAHARMONIZATION_FHIR_TO_FHIRLITE_REQUESTED,
  DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED,
  DATAHARMONIZATION_FHIR_TO_FHIRLITE_FAILED,
  DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED,
  DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED,
  DATAHARMONIZATION_FHIR_BUCKET_DATA_FAILED,
  DATAHARMONIZATION_SFDC_DOCUMENTS_REQUESTED,
  DATAHARMONIZATION_SFDC_DOCUMENTS_SUCCEEDED,
  DATAHARMONIZATION_SFDC_DOCUMENTS_FAILED,
  DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED,
  DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED,
  DATAHARMONIZATION_SFDC_DOCUMENT_DATA_FAILED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_SUCCEEDED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_FAILED,
  DATAHARMONIZATION_GLOBAL_DATA_REQUESTED,
  DATAHARMONIZATION_GLOBAL_DATA_SUCCEEDED,
  DATAHARMONIZATION_GLOBAL_DATA_FAILED
} from '../actions';

const defaultState = {
  data: [],
  fetching: false,
  error: false,
  documentList: [],
  environments: [],
  gridColumns: [],
  fhirData: [],
  fhirColumns: [],
  globalEnvironments: [],
  globalData: [],
  globalColumns: []
};

const DataHarmonizationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DATAHARMONIZATION_FETCH_SQL_DATA_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_FETCH_SQL_DATA_SUCCEEDED:
      return {
        ...state,
        data: action.payload.data,
        gridColumns: action.payload.gridColumns,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_FETCH_SQL_DATA_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    case DATAHARMONIZATION_FETCH_NOSQL_DATA_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_FETCH_NOSQL_DATA_SUCCEEDED:
      return {
        ...state,
        data: action.payload.data,
        gridColumns: action.payload.gridColumns,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_FETCH_NOSQL_DATA_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    case DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_SUCCEEDED:
      return {
        ...state,
        documentList: action.payload,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_FETCH_NOSQL_DOCUMENTS_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    case DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_SUCCEEDED:
      return {
        ...state,
        environments: action.payload,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_PLATFORM_ENVIRONMENTS_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    case DATAHARMONIZATION_FHIR_TO_FHIRLITE_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_FHIR_TO_FHIRLITE_SUCCEEDED:
      return {
        ...state,
        fhirData: action.payload.data,
        fhirColumns: action.payload.gridColumns,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_FHIR_TO_FHIRLITE_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };

    case DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_FHIR_BUCKET_DATA_SUCCEEDED:
      return {
        ...state,
        fhirBuckets: action.payload,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_FHIR_BUCKET_DATA_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    case DATAHARMONIZATION_SFDC_DOCUMENTS_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_SFDC_DOCUMENTS_SUCCEEDED:
      return {
        ...state,
        data: action.payload.data,
        gridColumns: action.payload.gridColumns,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_SFDC_DOCUMENTS_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    case DATAHARMONIZATION_SFDC_DOCUMENT_DATA_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_SFDC_DOCUMENT_DATA_SUCCEEDED:
      return {
        ...state,
        sfdcDocumentData: action.payload,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_SFDC_DOCUMENT_DATA_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    case DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_SUCCEEDED:
      return {
        ...state,
        globalData: action.payload.data,
        globalColumns: action.payload.gridColumns,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    case DATAHARMONIZATION_GLOBAL_DATA_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case DATAHARMONIZATION_GLOBAL_DATA_SUCCEEDED:
      return {
        ...state,
        globalData: action.payload,
        error: false,
        fetching: false
      };
    case DATAHARMONIZATION_GLOBAL_DATA_FAILED:
      return {
        ...state,
        error: true,
        fetching: false
      };
    default:
      return state;
  }
};

export default DataHarmonizationReducer;
