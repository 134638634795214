export default function(theme) {
  return {
    chartContainer: {
      height: '100%',
      minHeight: "500px",
      width: '100%',
      backgroundColor: '#fff'
    },
    chartText: {
      display: "flex",
      marginLeft: '40px',
      paddingLeft: '20px',
      paddingRight: '20px',
      fontWeight: '400',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '90px'
    },
    title: {
      display: "flex",
      marginLeft: '25px',
      marginTop: '-15px',
      paddingLeft: '20px',
      fontWeight: '400',
      textAlign: 'center',
      verticalAlign: 'middle',
      lineHeight: '90px'
    }
  };
}
