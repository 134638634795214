import testResource from '../../lib/TestResource';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  LOAD_TEST_RUNS_FETCH_SUCCEEDED,
  LOAD_TEST_RUNS_FETCH_FAILED,
  LOAD_TEST_RUNS_FETCH_REQUESTED
} from '../actions';

function* fetchLoadTestRuns(action) {
  try {
    const testRuns = yield call(testResource.getLoadTestRuns, action.payload);
    yield put({
      type: LOAD_TEST_RUNS_FETCH_SUCCEEDED,
      payload: testRuns.data
    });
  } catch (e) {
    yield put({ type: LOAD_TEST_RUNS_FETCH_FAILED, payload: e.message });
  }
}

export function* watchLoadTestRuns() {
  yield takeLatest(LOAD_TEST_RUNS_FETCH_REQUESTED, fetchLoadTestRuns);
}
