import testResource from '../../lib/TestResource';
import { call, put, takeLatest } from 'redux-saga/effects';

import { PRINT_FETCH_SUCCEEDED, PRINT_FETCH_FAILED, PRINT_FETCH_REQUESTED } from '../actions';

function* fetchTestResultSteps(action) {
  try {
    const { key, id } = action.payload;
    const testResults = yield call(testResource.getTestResultDetail, key, id);
    yield put({
      type: PRINT_FETCH_SUCCEEDED,
      payload: testResults.data
    });
  } catch (e) {
    yield put({ type: PRINT_FETCH_FAILED, payload: e.message });
  }
}

export function* watchPrint() {
  yield takeLatest(PRINT_FETCH_REQUESTED, fetchTestResultSteps);
}
