import React from 'react';
import moment from 'moment';

import { Tooltip, Button } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { OpenInNew } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import DonutChart from './DonutChart';
import { LOAD_TEST_FILES } from '../../heimdall-components/LoadBuildDialog/constants';

const options = {
  filterType: 'checkbox',
  selectableRows: false,
  download: false,
  print: false,
  responsive: 'scroll',
  rowsPerPage: 15,
  rowsPerPageOptions: [10, 15, 20, 30, 100]
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiButton: {
      root: {
        minWidth: '20px'
      }
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        padding: '0 0 0 25px'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        maxWidth: '240px',
        width: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '20px 10px 10px 20px',
        '&:nth-child(2)': { width: '5%' },
        '&:nth-child(4)': { width: '5%' },
        '&:nth-child(6)': { width: '5%' },
        '&:nth-child(8)': { width: '3%' },
        '&:nth-child(10)': { width: '3%' },
        '&:nth-child(12)': { width: '3%' },
        '&:nth-child(14)': { width: '3%' },
        '&:nth-child(16)': { width: '3%' },
        '&:nth-child(18)': { width: '6%' },
        '&:last-child': { paddingRight: 0 }
      }
    }
  }
});

//const numberSort = (data, colIndex, order) => {
//return data.sort((a, b) => {
//return a - b;
//});
//}

function getColumnConfig(props) {
  return [
    {
      name: 'Test_Region',
      options: {
        // setCellProps: () => ({ style: { minWidth: "100px", maxWidth: "200px" }}),
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            return `🇨🇳`;
          } else {
            return `🇺🇸`;
          }
        }
      }
    },
    {
      name: 'Name',
      options: {
        filter: false,
        sort: true
      }
    },

    {
      name: 'Env',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Executor',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Total',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Passed',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Failed',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Skipped',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Chart',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <DonutChart testRun={value} />;
        }
      }
    },
    {
      name: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex' }}>
              <Tooltip title="More Details">
                <Button color="primary" onClick={() => props.onClick(value)}>
                  <OpenInNew />
                </Button>
              </Tooltip>
            </div>
          );
        }
      }
    }
  ];
}

function getLoadColumnConfig(props) {
  return [
    {
      name: 'Env',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: 'Host',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'Feature',
      options: {
        filter: true,
        sort: true
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return `${LOAD_TEST_FILES.find((f) => f.value == value).label} (${value})`;
        // }
      }
    },
    {
      name: 'Executor',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Users',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Duration',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Spawn Rate',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Location',
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: 'Date',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return moment
            .utc(value)
            .local()
            .format('lll');
        }
      }
    },
    {
      name: 'View Report',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex' }}>
              <Tooltip title="View Report in new Browser">
                <a
                  href={value}
                  target={'_blank'}
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  <OpenInNew />
                </a>
              </Tooltip>
            </div>
          );
        }
      }
    }
  ];
}

export default (props) => {
  const { data, isLoad } = props;
  const colConfig = isLoad ? getLoadColumnConfig(props) : getColumnConfig(props);
  return (
    <MuiThemeProvider theme={theme}>
      <MUIDataTable options={options} columns={colConfig} data={data} />
    </MuiThemeProvider>
  );
};
