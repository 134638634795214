export default (theme) => {
  return {
    headerTitle: {
      alignSelf: 'flex-start',
      padding: '30px',
      fontSize: '28px',
      marginRight: 'auto'
    },
    alignRight: {
      textAlign: "right"
    },
    headerIcon: {
      marginLeft: '10px'
    },
    headerButton: {
      marginLeft: '20px'
    },
    headerActions: {
      // padding: 'auto',
      // marginLeft: 'auto'
    },
    contentContainer: {
      margin: '20px 30px'
    },
    loginContainer: {
      margin: '20px',
    }
  };
};
