import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSpiraReport, fetchSpiraRelease, fetchJiraReleaseVersions } from './helper';

import {
  SPIRA_RELEASE_SUCCEEDED,
  SPIRA_RELEASE_REQUESTED,
  SPIRA_RELEASE_FAILED,
  SPIRA_ALL_RELEASE_FAILED,
  SPIRA_ALL_RELEASE_REQUESTED,
  SPIRA_ALL_RELEASE_SUCCEEDED,
  JIRA_RELEASE_VERSION_REQUESTED,
  JIRA_RELEASE_VERSION_SUCCEEDED,
  JIRA_RELEASE_VERSION_FAILED
} from '../actions';

function* fetchReleaseReport() {
  try {
    const spiraReport = yield call(fetchSpiraReport);
    yield put({
      type: SPIRA_RELEASE_SUCCEEDED,
      payload: spiraReport.data
    });
  } catch (e) {
    yield put({ type: SPIRA_RELEASE_FAILED, payload: e.message });
  }
}

function* fetchSpiraReleases() {
  try {
    const spiraReleases = yield call(fetchSpiraRelease);
    yield put({
      type: SPIRA_ALL_RELEASE_SUCCEEDED,
      payload: spiraReleases.data
    });
  } catch (e) {
    yield put({ type: SPIRA_ALL_RELEASE_FAILED, payload: e.message });
  }
}

function* fetchJiraRelease() {
  try {
    const jiraReleases = yield call(fetchJiraReleaseVersions);
    yield put({
      type: JIRA_RELEASE_VERSION_SUCCEEDED,
      payload: jiraReleases.data
    });
  } catch (e) {
    yield put({ type: JIRA_RELEASE_VERSION_FAILED, payload: e.message });
  }
}

export function* watchReleaseReport() {
  yield takeLatest(JIRA_RELEASE_VERSION_REQUESTED, fetchJiraRelease);
  yield takeLatest(SPIRA_RELEASE_REQUESTED, fetchReleaseReport);
  yield takeLatest(SPIRA_ALL_RELEASE_REQUESTED, fetchSpiraReleases);
}
