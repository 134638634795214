import testResource from '../../lib/TestResource';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  DTAG_CREATE_SUCCEEDED,
  DTAG_CREATE_FAILED,
  DTAG_CREATE_REQUESTED,
  DTAG_FETCH_SUCCEEDED,
  DTAG_FETCH_FAILED,
  DTAG_FETCH_REQUESTED,
  DTAG_ADD_SUCCEEDED,
  DTAG_ADD_FAILED,
  DTAG_ADD_REQUESTED,
  DTAG_DELETE_SUCCEEDED,
  DTAG_DELETE_FAILED,
  DTAG_DELETE_REQUESTED,
  DTAG_REMOVE_SUCCEEDED,
  DTAG_REMOVE_FAILED,
  DTAG_REMOVE_REQUESTED,
  JIRA_DETAIL_FETCH_REQUESTED,
  JIRA_FETCH_SUCCEEDED,
  JIRA_FETCH_FAILED, JIRA_FETCH_REQUESTED,
} from '../actions';
import { getJiraDetail } from './helper';

function* fetchTags() {
  try {
    const results = yield call(testResource.fetchTags);
    yield put({
      type: DTAG_FETCH_SUCCEEDED,
      payload: results.data
    });
  } catch (e) {
    yield put({ type: DTAG_FETCH_FAILED, payload: e.message });
  }
}

function* createTag(action) {
  try {
    const { dtag, testId, testName } = action.payload;
    const results = yield call(testResource.createTag, dtag, testId, testName);
    yield put({
      type: DTAG_CREATE_SUCCEEDED,
      payload: results.data
    });
    yield put({ type: DTAG_FETCH_REQUESTED });
  } catch (e) {
    yield put({ type: DTAG_CREATE_FAILED, payload: e.message });
  }
}

function* deleteTag(action) {
  try {
    const { id } = action.payload;
    const results = yield call(testResource.deleteTag, id);
    yield put({
      type: DTAG_DELETE_SUCCEEDED,
      payload: results.data
    });
    yield put({ type: DTAG_FETCH_REQUESTED });
  } catch (e) {
    yield put({ type: DTAG_DELETE_FAILED, payload: e.message });
  }
}
function* addTag(action) {
  try {
    const { dtagId, testId, testName } = action.payload;
    const results = yield call(testResource.addTag, dtagId, testId, testName);
    yield put({
      type: DTAG_ADD_SUCCEEDED,
      payload: results.data
    });
    yield put({ type: DTAG_FETCH_REQUESTED });
  } catch (e) {
    yield put({ type: DTAG_ADD_FAILED, payload: e.message });
  }
}

function* removeTag(action) {
  try {
    const { id } = action.payload;
    const results = yield call(testResource.removeTag, id);
    yield put({
      type: DTAG_REMOVE_SUCCEEDED,
      payload: results.data
    });
    yield put({ type: DTAG_FETCH_REQUESTED });
  } catch (e) {
    yield put({ type: DTAG_REMOVE_FAILED, payload: e });
  }
}

function* geJiraTickets(action) {
  try {
    const {payload} = action;
    const jira = yield call(getJiraDetail, payload);
    yield put({
      type: JIRA_FETCH_SUCCEEDED,
      payload: jira.data
    });
  } catch (e) {
    console.log("Error", e);
    yield put({ type: JIRA_FETCH_FAILED, payload: e.message });
  }
}

export function* watchDynamicTags() {
  yield takeLatest(DTAG_CREATE_REQUESTED, createTag);
  yield takeLatest(DTAG_FETCH_REQUESTED, fetchTags);
  yield takeLatest(DTAG_ADD_REQUESTED, addTag);
  yield takeLatest(DTAG_DELETE_REQUESTED, deleteTag);
  yield takeLatest(DTAG_REMOVE_REQUESTED, removeTag);
  yield takeLatest(JIRA_FETCH_REQUESTED, geJiraTickets);
}
