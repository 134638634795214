import { FailColor, PassColor, SkipColor } from '../../assets/jss/colors';

export default (theme) => {
  return {
    root: {
      '@media print': {
        position: 'absolute !important'
      }
    },
    action: {
      marginLeft: 10
    },
    logMessage: {
      color: 'darkgoldenrod',
      fontWeight: 600
    },
    codeLine: {
      fontSize: 12,
      marginLeft: 10
    },
    codeLink: {
      color: 'grey'
    },
    pagebreak: {
      pageBreakInside: 'avoid'
    },
    screenshot: {
      display: 'block',
      margin: 'auto'
    },
    screenshotTimestamp: {
      float: 'left',
      marginBottom: 10,
      fontSize: 10
    },
    screenshotCount: {
      float: 'right'
    },
    linkContainer: {
      display: 'inline-block',
      position: 'relative',
      marginLeft: '5px'
    },
    card: {
      width: '100%'
    },
    failAvatar: {
      color: FailColor,
      backgroundColor: 'transparent'
    },
    skipAvatar: {
      color: SkipColor,
      backgroundColor: 'transparent'
    },
    passAvatar: {
      color: PassColor,
      backgroundColor: 'transparent'
    },
    icon: {
      fontSize: 25
    },
    duration: {
      marginLeft: 'auto',
      marginRight: '30px'
    },
    step: {
      marginBottom: 7,
      lineHeight: 1.3
    },
    step_check_icon: {
      position: 'relative',
      color: PassColor,
      marginLeft: 10,
      marginBottom: 2,
      fontSize: 11
    },
    step_fail_icon: {
      position: 'relative',
      color: FailColor,
      marginLeft: 10,
      marginBottom: 2,
      fontSize: 11
    },
    description: {
      padding: '10px'
    },
    body_container: {
      'white-space': 'pre',
      color: '#657b83',
      padding: '10px',
      'background-color': '#fdf6e3'
    },
    card_steps: {
      marginTop: 20
    },
    steps_container: {
      'line-height': 1,
      padding: '10px'
    },
    subheader: {
      display: 'flex'
    },
    timerIcon: {
      fontSize: 20,
      position: 'relative',
      top: '5px',
      paddingLeft: '5px'
    },
    errorIcon: {}
  };
};
