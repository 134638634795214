import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import { withStyles } from '@material-ui/core';

import { Sidebar } from 'components';

import dashboardRoutes from 'routes/dashboard.jsx';
import {
  Footer,
  BuildDialog,
  APIBuildDialog,
  LoadBuildDialog,
  ResultDetailDialog
} from 'heimdall-components';

import appStyle from 'assets/jss/material-dashboard-react/appStyle.jsx';
import logo from 'assets/img/heimdall_icon.png';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {
  TEST_RUNS_FETCH_REQUESTED,
  API_TEST_RUNS_FETCH_REQUESTED,
  BUILDS_FETCH_REQUESTED,
  DTAG_FETCH_REQUESTED,
  API_BUILDS_FETCH_REQUESTED,
  SPIRA_RELEASE_REQUESTED,
  TRACEABILITY_MATRIX_FETCH_REQUESTED,
  SPIRA_ALL_RELEASE_REQUESTED,
  TEST_RESULT_HISTORY_FETCH_REQUESTED,
  LOAD_BUILDS_FETCH_REQUESTED,
  LOAD_TEST_RUNS_FETCH_REQUESTED,
  HEIMDALL_SETTING_GET_ALL_REQUESTED,
  JIRA_RELEASE_VERSION_REQUESTED,
  BUILDS_CHINA_FETCH_REQUESTED
} from '../../redux/actions';

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      }
      return <Route path={prop.routePath} component={prop.component} key={key} />;
    })}
  </Switch>
);

const dhRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if (prop.isDHRoute) return <Route path="*" component={prop.component} key={key} />;
    })}
  </Switch>
);

const outerTheme = createMuiTheme({
  overrides: {
    MuiDialog: {
      root: {
        '@media print': {
          position: 'absolute'
        }
      },
      /* Styles applied to the container element. */
      container: {
        height: '100%',
        // We disable the focus ring for mouse, touch and keyboard users.
        outline: 'none',
        '@media print': {
          height: 'auto'
        }
      },
      /* Styles applied to the `Paper` component. */
      paper: {
        display: 'flex',
        flexDirection: 'column',
        margin: 48,
        position: 'relative',
        overflowY: 'auto', // Fix IE 11 issue, to remove at some point.
        '@media print': {
          overflowY: 'visible',
          boxShadow: 'none'
        }
      }
    }
  }
});

class App extends React.Component {
  state = {
    testRunView: false,
    isDH: window.location.hostname.includes('dh.s37dev')
  };
  handleDrawerToggle = (e) => {
    e.preventDefault();
    this.setState({ testRunView: !this.state.testRunView });
  };
  componentDidMount() {
    if (!this.state.isDH) {
      this.props.requestTestRuns();
      this.props.requestApiTestRuns();
      this.props.requestLoadTestRuns();
      this.props.requestBuilds();
      this.props.requestChinaBuilds();
      this.props.requestLoadBuilds();
      this.props.requestTags();
      this.props.requestAPIBuilds();
      this.props.requestJiraReleaseVersions();
      // this.props.requestSpiraReleaseReport();
      this.props.requestForTraceabilityMatrix();
      this.props.requestForAllReleases();
      this.props.requestTestResultHistory();
      this.props.requestHeimdallSetting();
    }
  }

  componentDidUpdate() {
    this.refs.mainPanel.scrollTop = 0;
  }

  render() {
    const { classes, ...rest } = this.props;
    if (this.state.isDH) {
      return (
        <div className={classes.wrapper}>
          <div className={classes.container}>{dhRoutes}</div>
        </div>
      );
    }
    return (
      <div className={classes.wrapper}>
        <Sidebar
          handleDrawerToggle={this.handleDrawerToggle}
          routes={dashboardRoutes}
          logoText={'Heimdall'}
          logo={logo}
          open={!this.state.testRunView}
          color="orange"
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
          <div className={classes.container}>{switchRoutes}</div>
        </div>
        <MuiThemeProvider theme={outerTheme}>
          <ResultDetailDialog />
        </MuiThemeProvider>
        <BuildDialog fullscreen />
        <APIBuildDialog fullscreen {...this.props} />
        <LoadBuildDialog fullscreen {...this.props} />
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  requestTestRuns: () => {
    dispatch({ type: TEST_RUNS_FETCH_REQUESTED });
  },
  requestApiTestRuns: () => {
    dispatch({ type: API_TEST_RUNS_FETCH_REQUESTED });
  },
  requestLoadTestRuns: () => {
    dispatch({ type: LOAD_TEST_RUNS_FETCH_REQUESTED });
  },
  requestBuilds: () => {
    dispatch({ type: BUILDS_FETCH_REQUESTED });
  },
  requestChinaBuilds: () => {
    dispatch({ type: BUILDS_CHINA_FETCH_REQUESTED });
  },
  requestAPIBuilds: () => {
    dispatch({ type: API_BUILDS_FETCH_REQUESTED });
  },
  requestLoadBuilds: () => {
    dispatch({ type: LOAD_BUILDS_FETCH_REQUESTED });
  },
  requestTags: () => {
    dispatch({ type: DTAG_FETCH_REQUESTED });
  },
  requestSpiraReleaseReport: () => {
    dispatch({ type: SPIRA_RELEASE_REQUESTED });
  },
  requestJiraReleaseVersions: () => {
    dispatch({ type: JIRA_RELEASE_VERSION_REQUESTED });
  },
  requestForTraceabilityMatrix: () => {
    dispatch({ type: TRACEABILITY_MATRIX_FETCH_REQUESTED });
  },
  requestForAllReleases: () => {
    dispatch({ type: SPIRA_ALL_RELEASE_REQUESTED });
  },
  requestTestResultHistory: () => {
    dispatch({ type: TEST_RESULT_HISTORY_FETCH_REQUESTED });
  },
  requestHeimdallSetting: () => {
    dispatch({ type: HEIMDALL_SETTING_GET_ALL_REQUESTED });
  }
});

export default compose(
  withStyles(appStyle, { withTheme: true }),
  connect(null, mapDispatchToProps)
)(App);
