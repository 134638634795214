import { Auth } from 'aws-amplify';
import axios from 'axios';

export default class RemoteConnection {
  constructor(baseURL) {
    this.connection = axios.create({
      baseURL,
      timeout: 4500000
    });
  }

  async options() {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      }
    };
  }

  async post(path, payload) {
    return this.connection.post(path, payload, await this.options());
  }

  async put(path, payload) {
    return this.connection.put(path, payload, await this.options());
  }

  async delete(path) {
    return this.connection.delete(path, await this.options());
  }

  async get(path) {
    return this.connection.get(path, await this.options());
  }
}
