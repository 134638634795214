import {
  TRACEABILITY_MATRIX_FETCH_FAILED,
  TRACEABILITY_MATRIX_FETCH_REQUESTED,
  TRACEABILITY_MATRIX_FETCH_SUCCEEDED,
  TRACEABILITY_RMS_UPDATE_FAILED,
  TRACEABILITY_RMS_UPDATE_REQUESTED,
  TRACEABILITY_RMS_UPDATE_SUCCEEDED,
  JIRA_DETAIL_FETCH_FAILED,
  JIRA_DETAIL_FETCH_REQUESTED,
  JIRA_DETAIL_FETCH_SUCCEEDED,
  TEST_RESULT_HISTORY_FETCH_SUCCEEDED,
} from '../actions';

const defaultState = {
  fetching: false,
  error: false,
  rmsUpdating: false,
  rmsUpdateError: false,
  jiraFetching: false,
  jiraError: false,
  jiraDetail: [],
  traceabilityMatrix: [],
  testResultHistory: [],
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case TRACEABILITY_MATRIX_FETCH_REQUESTED:
      return {
        ...state,
        fetching: true,
        error: false
      };
    case TRACEABILITY_MATRIX_FETCH_SUCCEEDED:
      return {
        ...state,
        traceabilityMatrix: action.payload,
        error: false,
        fetching: false
      };
    case TRACEABILITY_MATRIX_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        error: true
      };
    case TRACEABILITY_RMS_UPDATE_REQUESTED:
      return {
        ...state,
        rmsUpdating: true,
        rmsUpdateError: false
      };
    case TRACEABILITY_RMS_UPDATE_SUCCEEDED:
      return {
        ...state,
        rmsUpdateError: false,
        rmsUpdating: false
      };
    case TRACEABILITY_RMS_UPDATE_FAILED:
      return {
        ...state,
        rmsUpdating: false,
        rmsUpdateError: true
      };
    case JIRA_DETAIL_FETCH_REQUESTED:
      return {
        ...state,
        jiraFetching: true,
        jiraError: false
      };
    case JIRA_DETAIL_FETCH_SUCCEEDED:
      return {
        ...state,
        jiraDetail: action.payload,
        jiraError: false,
        jiraFetching: false
      };
    case JIRA_DETAIL_FETCH_FAILED:
      return {
        ...state,
        jiraFetching: false,
        jiraError: true
      };
    case TEST_RESULT_HISTORY_FETCH_SUCCEEDED:
      return {
        ...state,
        testResultHistory: action.payload,
      };
    default:
      return state;
  }
}
