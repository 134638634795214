import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MainCard } from 'heimdall-components';
import { Delete } from '@material-ui/icons';

import { DTAG_REMOVE_REQUESTED, DTAG_DELETE_REQUESTED } from '../../redux/actions';

import styles from './styles';

class DynamicTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderTests(tests) {
    const { classes: cs } = this.props;
    if (!tests) {
      return null;
    }
    return tests.map((test) => {
      const { id, name } = test;
      return (
        <div className={cs.testContainer} key={id}>
          <div>
            <span className={cs.testId}>{`TC${id}`}</span>
            <span className={cs.testName}>{name}</span>
          </div>
        </div>
      );
    });
  }

  handleDeleteTag = (id) => {
    this.props.onDeleteTag(id);
  };

  render() {
    const { classes: cs, byTag } = this.props;
    if (byTag) {
      return (
        <MainCard
          title="Dynamic Tags"
          content={
            <div className={cs.dynamicTagContainer}>
              {Object.keys(byTag)
                .sort()
                .map((tag) => {
                  const { id, tests } = byTag[tag];
                  return (
                    <div key={tag} className={cs.tagContainer}>
                      <div className={cs.tag}>
                        <span>{tag}</span>
                        <span className={cs.deleteContainer}>
                          <Button
                            className={cs.actionButton}
                            onClick={() => this.handleDeleteTag(id)}
                            color="secondary"
                          >
                            <Delete className={cs.actionIcon} />
                          </Button>
                        </span>
                      </div>
                      {this.renderTests(tests)}
                    </div>
                  );
                })}
            </div>
          }
        />
      );
    }
    return <div />;
  }
}

DynamicTag.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    byTag: state.dtags.byTag
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRemoveTag: (id) => {
    dispatch({ type: DTAG_REMOVE_REQUESTED, payload: { id } });
  },
  onDeleteTag: (id) => {
    dispatch({ type: DTAG_DELETE_REQUESTED, payload: { id } });
  }
});

DynamicTag.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DynamicTag);
