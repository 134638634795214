import React, { useState, useEffect, useCallback } from 'react';
import { MainCard } from 'heimdall-components';
import { DataGrid, GridRowsProp, GridColDef } from '@material-ui/data-grid';
import { connect, useDispatch, useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ErrorIcon from '@mui/icons-material/Error';
import DoneIcon from '@mui/icons-material/Done';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ApiIcon from '@mui/icons-material/Api';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {
  DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED,
  DATAHARMONIZATION_GLOBAL_SCHEMA_ENVIRONMENTS_REQUESTED
} from '../../redux/actions';

import {
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  LinearProgress,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Divider
} from '@material-ui/core';
import Stack from '@mui/material/Stack';
import dhClass from './dataharmonization.module.css';
import * as _ from 'lodash';
import { WarningAmber } from '@mui/icons-material';

export default function DHFhirComponent({
  fhirData,
  columns,
  environment,
  platform,
  sqlType,
  envType
}) {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedRecordRedshiftCount, setSelectedRecordRedshiftCount] = useState(0);
  const [selectedRecordDocDbCount, setSelectedRecordDocDbCount] = useState(0);

  let dataFhirBuckets = useSelector((state) => state.dhValidation.fhirBuckets);

  //FHIR, FHIR LITE BLOCK SELECTED
  const onFhirRecordSelected = (id) => {
    let rowVal = fhirData.find((a) => a['id'] === id);

    //Redshift Count
    setSelectedRecordRedshiftCount(rowVal['redshiftCount']);
    setSelectedRecordDocDbCount(rowVal['docdbCount']);

    setSelectedRecord(rowVal);
    setShowDetails(true);

    let fhirMap = new Map();
    let jsonfhirBuckets = rowVal['jsonFHIRBuckets'];
    let jsonfhirLiteBuckets = rowVal['jsonFHIRLiteBuckets'];
    let mongoDBDocs = rowVal['docdbCollection'];

    if (jsonfhirBuckets) {
      jsonfhirBuckets.forEach((jsonPath) => {
        let lastIndex = jsonPath.lastIndexOf('/');
        let bucketName = jsonPath.substr(lastIndex + 1);
        if (!fhirMap.has(bucketName)) {
          fhirMap.set(bucketName, {
            table: rowVal['entity'],
            status: 'Pending...',
            jsonFile: {
              name: jsonPath,
              count: 0
            },
            liteFile: {
              name: null,
              count: 0
            },
            docdb: {
              count: 0
            },
            difference: 0,
            missingInS3: false
          });
        }
      });

      jsonfhirLiteBuckets.forEach((jsonPath) => {
        let lastIndex = jsonPath.lastIndexOf('/');
        let liteBucketName = jsonPath.substr(lastIndex + 1);
        let bucketName = liteBucketName.replace('-lite', '');

        if (!fhirMap.has(bucketName)) {
          fhirMap.set(bucketName, {
            table: rowVal['entity'],
            jsonFile: {
              name: null,
              count: 0
            },
            liteFile: {
              name: jsonPath,
              count: 0
            },
            docdb: {
              count: 0
            },
            difference: 0,
            missingInS3: false
          });
        } else {
          let val = fhirMap.get(bucketName);
          val['liteFile']['name'] = jsonPath;
          val['liteFile']['count'] = 0;

          fhirMap.set(bucketName, val);
        }
      });

      //Mongo Records that are missing in fhir/fhir-lite buckets
      mongoDBDocs.forEach((docdbRecord) => {
        if (!fhirMap.has(docdbRecord['name'] + '.json')) {
          fhirMap.set(docdbRecord['name'], {
            table: rowVal['entity'],
            jsonFile: {
              name: docdbRecord['name'],
              count: 0
            },
            liteFile: {
              name: null,
              count: 0
            },
            docdb: {
              count: 0
            },
            difference: 0,
            missingInS3: true
          });
        }
      });

      let entries = [...fhirMap.entries()];

      dispatch({
        type: DATAHARMONIZATION_FHIR_BUCKET_DATA_REQUESTED,
        payload: {
          fhirBuckets: entries,
          environment: environment,
          platform: platform,
          type: sqlType,
          envType: envType
        }
      });
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {fhirData?.map((record, index) => (
        <Card
          style={{ width: '32%', display: !showDetails ? 'inline-block' : 'none', margin: '5px' }}
          key={index}
        >
          <CardContent>
            <p>{record['entity']}</p>
            <label>FHIR File Count: {record['fhirCount']} </label>
            <br />
            <label>FHIR LITE File Count: {record['fhirLiteCount']} </label>
            <br />
            <label>MONGO DB File Count: {record['docdbCount']}</label>
            <br />
            <label>REDSHIFT Count: {record['redshiftCount']}</label>
            <br />
            <Button
              variant="contained"
              onClick={() => onFhirRecordSelected(record['id'])}
              color="primary"
              disabled={record['status']?.indexOf('Success') === -1 ? true : false}
            >
              {record['status']?.indexOf('Success') === -1 ? record['status'] : 'More Details'}
            </Button>
          </CardContent>
        </Card>
      ))}

      {/* FHIR DETAILS */}
      <div style={{ display: showDetails ? 'block' : 'none', width: '100%', height: '100%' }}>
        <Card style={{ width: '49%', display: 'inline-block', height: '100%' }}>
          <CardContent>
            <Button variant="contained" onClick={() => setShowDetails(false)} color="primary">
              Back
            </Button>

            {dataFhirBuckets ? (
              <div>
                <h2>{selectedRecord['entity']}</h2>

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemIcon>
                      <HourglassBottomIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {[...dataFhirBuckets.values()].filter((v) => v[1]['status'] === 'Pending')
                        .length + ' Pending'}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ApiIcon></ApiIcon>
                    </ListItemIcon>
                    <ListItemText>
                      {[...dataFhirBuckets.values()].filter((v) => v[1]['status'] === 'Requested')
                        .length + ' Loading'}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <DoneAllIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {[...dataFhirBuckets.values()].filter((v) => v[1]['status'] === 'Success')
                        .length + ' Success'}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <BrokenImageIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {[...dataFhirBuckets.values()].filter((v) => v[1]['status'] === 'Failed')
                        .length + ' Failed'}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ErrorIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {[...dataFhirBuckets.values()].filter((v) => v[1]['difference'] !== 0)
                        .length + ' Unequal Counts'}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <ConfirmationNumberIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {'Docdb Count: ' +
                        [...dataFhirBuckets.values()].reduce(
                          (prev, curr) => prev + curr[1]['docdb']['count'],
                          0
                        ) +
                        ', JSON (FHIR): ' +
                        [...dataFhirBuckets.values()].reduce(
                          (prev, curr) => prev + curr[1]['jsonFile']['count'],
                          0
                        ) +
                        ', FHIR Lite: ' +
                        [...dataFhirBuckets.values()].reduce(
                          (prev, curr) => prev + curr[1]['liteFile']['count'],
                          0
                        ) +
                        ', Redshift Count: ' +
                        selectedRecordRedshiftCount}
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            ) : (
              '0'
            )}
          </CardContent>
        </Card>
        <Card style={{ width: '49%', display: 'inline-block', height: '100%' }}>
          <CardContent style={{ height: '60vh', overflowY: 'scroll' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {dataFhirBuckets?.map((bucket, i) => {
                let [key, value] = bucket;

                return (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <div>
                        {value['status'] === 'Requested' ? (
                          <ApiIcon />
                        ) : value['status'] === 'Pending' ? (
                          <HourglassBottomIcon />
                        ) : value['status'] === 'Failed' ? (
                          <BrokenImageIcon />
                        ) : value['difference'] === true ? (
                          <ErrorIcon />
                        ) : (
                          <DoneAllIcon />
                        )}
                      </div>
                    </ListItemIcon>
                    <ListItemIcon>
                      {value['missingInS3'] === true ? <WarningAmber></WarningAmber> : null}
                    </ListItemIcon>
                    <ListItemText
                      secondary={key}
                      primary={
                        'Docdb Count: ' +
                        value['docdb']['count'] +
                        ' | Json Count: ' +
                        value['jsonFile']['count'] +
                        ' | ' +
                        'Lite Count: ' +
                        value['liteFile']['count'] +
                        ' | Status: ' +
                        value['status']
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
