import { FailColor, PassColor, SkipColor } from '../../assets/jss/colors';

export default (theme) => {
  return {
    resultContainer: {
      display: 'flex',
      width: '100%',
      margin: '20px',
      flexDirection: 'row'
    },
    errorImageContainer: {
      padding: '10px'
    },
    screenshot: {
      height: '512px',
      width: '480px'
    },
    idContainer: {
      fontWeight: 600,
      paddingLeft: '5px',
      marginBottom: '5px',
      width: '100%'
    },
    id: {
      display: 'inline-block',
      textAlign: 'center'
    },
    subheader1: {
      fontSize: '15px',
      paddingLeft: '10px'
    },
    subheader2: {
      fontSize: '15px',
      paddingLeft: '10px'
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '20px',
      padding: '10px',
      flexGrow: 2
    },
    card: {
      width: '100%'
    },
    footer: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'row'
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      width: '50%'
    },
    button: {
      marginLeft: '10px',
      maxHeight: '40px'
    },
    buttonIcon: {
      marginLeft: '10px'
    },
    failAvatar: {
      color: FailColor,
      backgroundColor: 'transparent',
      display: 'inline-block',
      margin: '3px 5px 3px 3px'
    },
    failed: {
      color: FailColor,
      backgroundColor: 'transparent',
      display: 'block',
      whiteSpace: 'pre-wrap'
    },
    skipAvatar: {
      margin: '3px 5px 3px 3px',
      color: SkipColor,
      backgroundColor: 'transparent',
      display: 'inline-block'
    },
    passAvatar: {
      display: 'inline-block',
      margin: '3px 5px 3px 3px',
      color: PassColor,
      backgroundColor: 'transparent'
    },
    icon: {
      fontSize: 25
    },
    duration: {
      marginLeft: 'auto',
      marginRight: '30px'
    },
    cardContent: {
      display: 'flex'
    },
    errorContainer: {
      marginTop: '10px',
      flexGrow: 2
    },
    errorLabel: {
      color: 'orange'
    },
    errorMessage: {
      paddingLeft: '10px',
      overflow: 'scroll',
      maxHeight: '100px'
    },
    timerIcon: {
      fontSize: 20,
      position: 'relative',
      top: '5px',
      paddingLeft: '5px'
    },
    steps_container: {
      'line-height': 1,
      padding: '10px'
    },
    step: {
      marginBottom: 4,
      lineHeight: 1.3
    },
    step_check_icon: {
      position: 'relative',
      color: PassColor,
      'font-size': '11px'
    },
    step_fail_icon: {
      position: 'relative',
      color: FailColor,
      'font-size': '11px'
    },
    error_container: {
      display: "flex"
    },
    error_name: {
      fontWeight: "bold",
      marginRight: "5px",
      color: '#657b83',
    },
    error_message: {
      fontWeight: "600",
      marginRight: "5px",
      color: '#ee5365',
    },
    log_container: {
      display: "flex",
    },
    detail_container: {
    },
    detail_script_container: {
      'white-space': 'pre',
      color: '#657b83',
      padding: '10px',
      margin: "10px",
      'background-color': '#fdf6e3',
      overflowY: "auto",
      overflowX: "auto",
      maxWidth: "800px",
      maxHeight: "500px",
    },
    detail_api_log_container: {
      'white-space': 'pre',
      color: '#657b83',
      padding: '10px',
      margin: "10px",
      'background-color': '#e7e4dd',
      overflowY: "auto",
      overflowX: "auto",
      maxWidth: "800px",
      maxHeight: "500px",
    },
    detail_console_container: {
      'white-space': 'pre',
      color: '#657b83',
      padding: '10px',
      margin: "10px",
      overflowY: "auto",
      maxHeight: "500px",
      'background-color': '#e7e4dd'
    },
    apiLoader: {
      marginBottom: "15px"
    },
    apiLog: {
      display: "flex",
      justifyContent: "space-between"
    },
    apiResponseTime: {
      color: '#c72dd9',
    },
    flexIt: {
      display: "flex",
    },
    spaceToRight: {
      marginRight: "5px"
    },
    logStatus: {
      color: '#238e81',
      marginRight: "5px"
    },
    logMethod: {
      fontWeight: "bold",
      marginRight: "5px"
    }
  };
};
