import { grayColor, defaultFont } from 'assets/jss/material-dashboard-react.jsx';

export default (theme) => {
  return {
    card: {
      display: 'inline-block',
      position: 'relative',
      width: '100%',
      margin: '25px 0',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
      borderRadius: '3px',
      color: 'rgba(0, 0, 0, 0.87)',
      background: '#fff',
      minHeight: '20px'
    },
    cardTitle: {
      margin: '0',
      ...defaultFont,
      fontSize: '1.625em'
    },
    cardTitleSmall: {
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1',
      color: '#777'
    },
    cardContent: {
      textAlign: 'left',
      paddingTop: '10px',
      padding: '15px 20px'
    },
    cardCategory: {
      marginBottom: '0',
      color: grayColor,
      margin: '0 0 10px',
      ...defaultFont
    }
  };
};
