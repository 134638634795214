import React, { PureComponent } from 'react';
import { Grid, Input, NativeSelect, withStyles } from '@material-ui/core';
import SpiraCharts from './SpiraCharts';
import { compose } from 'redux';
import styles from './styles';
import { MainCard } from 'heimdall-components';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


class Spira extends PureComponent {

  state = {
    type: 'major',
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  getChoices(){
    const { classes: cs } = this.props;
    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <InputLabel htmlFor="type">View Option</InputLabel>
          <NativeSelect
            value={this.state.type}
            onChange={this.handleChange('type')}
            input={<Input name="type" id="type" />}
          >
            <option value={'major'}>
              {"Major Releases"}
            </option>
            <option value={'all'}>
              {"All Releases"}
            </option>
            <option value={'withOq'}>
              {"Release with OQs"}
            </option>
            <option value={'withIn'}>
              {"Release with Incidents"}
            </option>
          </NativeSelect>
        </FormControl>
      </Grid>)
  }
  renderContent() {
    const { classes: cs } = this.props;
    const { type } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <a href="https://science37.atlassian.net/wiki/spaces/TECH/pages/137134123/NORA+Release+Notes"
             rel="noopener noreferrer" target="_blank">
            Release Notes
          </a>
        </Grid>
        <Grid item xs={12}>
          <div className={cs.chartContainer}>
            {this.getChoices()}
            <div className={cs.chartText}>OQ and Incident Report</div>
            <SpiraCharts option={type}/>
          </div>
        </Grid>

      </Grid>
    );
  }

  render() {
    return <MainCard title="Nora Releases" content={this.renderContent()} />;
  }
}

export default compose(
  withStyles(styles, { withTheme: true })
)(Spira);

