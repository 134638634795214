import React from 'react';
import { withStyles, Button, Avatar, Menu, MenuItem } from '@material-ui/core';
import { AccountCircleOutlined, Close } from '@material-ui/icons';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withOAuth } from 'aws-amplify-react';
import { Auth, Hub } from 'aws-amplify';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { LOGIN_LOGGED_IN, LOGIN_LOGGED_OUT } from '../../redux/actions';
import UserProfilePage from '../UserProfile';
import styles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authState: 'Loading',
      avatarEl: null,
      openProfile: false
    };
    Hub.listen('auth', this);
  }

  handleSignOutClick = async () => {
    this.setState({ loginEl: null });
    try {
      await Auth.signOut();
    } catch (err) {
      console.error(err);
    }
  };

  handleProfileModalClose = () => {
    this.setState({ openProfile: false, loginEl: null });
  };

  handleProfileClick = async () => {
    this.setState({ openProfile: true, loginEl: null });
  };

  handleAvatarClicked = (event) => {
    this.setState({ loginEl: event.currentTarget });
  };

  handleLoginMenuClose = (event) => {
    this.setState({ loginEl: null });
  };

  updateUserInfo() {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const { attributes } = user;
        this.props.loggedIn(attributes);
      })
      .catch((e) => {
        this.props.loggedOut();
        console.log(e);
      });
  }

  onHubCapsule = (capsule) => {
    switch (capsule.payload.event) {
      case 'signIn':
        this.updateUserInfo();
        break;
      case 'signUp':
        this.updateUserInfo();
        break;
      case 'signOut':
        this.props.loggedOut();
        break;
      case 'signIn_failure':
        this.props.loggedOut();
        break;
      case 'configured':
        break;
      default:
        break;
    }
  };

  renderLoginButton() {
    const { classes: cs } = this.props;
    return (
      <Button
        onClick={this.props.OAuthSignIn}
        className={cs.headerButton}
        color="primary"
        variant="outlined"
      >
        Sign in
        <AccountCircleOutlined className={cs.headerIcon} />
      </Button>
    );
  }

  renderProfileButton() {
    const {
      classes: cs,
      name,
      user: { picture: avatar },
      user
    } = this.props;
    const { loginEl } = this.state;
    const open = Boolean(loginEl);
    return (
      <span>
        Hello{user.name ? `, ${user.name}` : ``}
        <Button onClick={this.handleAvatarClicked}>
          {avatar ? (
            <Avatar alt={name} src={avatar} className={cs.avatar} />
          ) : (
            <Avatar className={cs.orange}>
              {user.name ? `${user.name.charAt(0).toUpperCase()}` : 'U'}
            </Avatar>
          )}
        </Button>
        <Menu
          anchorEl={loginEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          className={cs.profileIcon}
          open={open}
          onClose={this.handleLoginMenuClose}
        >
          <MenuItem onClick={this.handleProfileClick}>Profile</MenuItem>
          <MenuItem onClick={this.handleSignOutClick}>Sign out</MenuItem>
        </Menu>
        <Dialog
          open={this.state.openProfile}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleProfileModalClose}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle id="classic-modal-slide-title" disableTypography>
            <Button
              key="close"
              aria-label="Close"
              color="primary"
              onClick={this.handleProfileModalClose}
            >
              <Close />
            </Button>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description">
            <UserProfilePage modalClose={this.handleProfileModalClose} />
          </DialogContent>
        </Dialog>
      </span>
    );
  }

  componentDidMount() {
    // check the current user when the App component is loaded
    Auth.currentUserInfo()
      .then((user) => {
        const { attributes } = user;
        this.props.loggedIn(attributes);
      })
      .catch((e) => {
        console.log(e);
        this.props.loggedOut();
      });
  }

  render() {
    const { authState } = this.props;
    if (authState === 'logged_in') {
      return this.renderProfileButton();
    }
    return this.renderLoginButton();
  }
}

Login.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    authState: state.login.authState,
    user: state.login.userData
  };
};

const mapDispatchToProps = (dispatch) => ({
  loggedIn: (attributes) => {
    dispatch({ type: LOGIN_LOGGED_IN, payload: attributes });
  },
  loggedOut: () => {
    dispatch({ type: LOGIN_LOGGED_OUT });
  }
});

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withOAuth,
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
