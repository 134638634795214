import React, { useEffect } from 'react';
import { useSelector, connect } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

// core components
import styles from './styles';
import { Button, LinearProgress, FormControl, Grid, TextField } from '@material-ui/core';
import { LOGIN_USER_UPDATE } from '../../redux/actions';

const useStyles = makeStyles(styles);

const UserProfilePage = ({ modalClose, updateUserData }) => {
  const [user, setUser] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [attributes, setAttributes] = React.useState({});

  const userProfile = useSelector((state) => state.login.userData);
  useEffect(() => {
    setUser(userProfile);
    setAttributes(getAttributes(userProfile));
  }, [userProfile]);

  const getAttributes = (obj) => {
    const att = {};
    for (let [key, value] of Object.entries(obj)) {
      if (key.includes('custom') || key.includes('name')) {
        att[key] = value;
      }
    }
    return att;
  };

  const handleChange = (name) => (event) => {
    setAttributes({ ...attributes, [name]: event.target.value });
  };

  const handleClose = (event, reason) => {
    setErrorOpen(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const handleProfileUpdate = (event) => {
    Auth.currentAuthenticatedUser()
      .then((authUser) => {
        Auth.updateUserAttributes(authUser, attributes)
          .then((data) => {
            updateUserData(attributes);
            setIsLoading(false);
            modalClose();
          })
          .catch((err) => {
            setIsLoading(false);
            setErrorMsg(err);
            setErrorOpen(true);
          });
      })
      .catch((e) => {
        setIsLoading(false);
        this.props.loggedOut();
      });
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {errorMsg && (
        <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {JSON.stringify(errorMsg) || 'Error'}
          </Alert>
        </Snackbar>
      )}
      <Grid container justify="center" direction="column">
        <Grid item>
          <FormControl className={classes.formControl}>
            <TextField label="Email" id="email" value={user.email || ''} disabled />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <TextField
              label="Name"
              id="name"
              onChange={handleChange('name')}
              value={attributes['name'] || ''}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <TextField
              label="Spira User Name"
              id="custom:spiraUser"
              onChange={handleChange('custom:spiraUser')}
              value={attributes['custom:spiraUser'] || ''}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <TextField
              label="Spira API Key"
              placeholder="{Spira Key should be enclosed in curly braces}"
              id="custom:spiraPass"
              onChange={handleChange('custom:spiraPass')}
              value={attributes['custom:spiraPass'] || ''}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.buttonsContainer}>
        <Button
          onClick={() => {
            setIsLoading(true);
            handleProfileUpdate();
          }}
          color="secondary"
          autoFocus
          variant={'contained'}
          fullWidth
        >
          Update
        </Button>
        {isLoading && <LinearProgress size={50} color="primary" className={classes.progress} />}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.login.userData
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (payload) => {
    dispatch({ type: LOGIN_USER_UPDATE, payload });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
