import testResource from '../../lib/TestResource';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getJiraDetail } from './helper'

import {
  TRACEABILITY_MATRIX_FETCH_REQUESTED,
  TRACEABILITY_MATRIX_FETCH_SUCCEEDED,
  TRACEABILITY_MATRIX_FETCH_FAILED,
  TRACEABILITY_RMS_UPDATE_FAILED,
  TRACEABILITY_RMS_UPDATE_REQUESTED,
  TRACEABILITY_RMS_UPDATE_SUCCEEDED,
  JIRA_DETAIL_FETCH_FAILED,
  JIRA_DETAIL_FETCH_REQUESTED,
  JIRA_DETAIL_FETCH_SUCCEEDED,
  TEST_RESULT_HISTORY_FETCH_SUCCEEDED,
  TEST_RESULT_HISTORY_FETCH_REQUESTED,
  TEST_RESULT_HISTORY_FETCH_FAILED
} from '../actions';

function* fetchTraceabilityMatrix(action) {
  try {
    const traceabilityMatrix = yield call(testResource.getTraceabilityMatrix);
    yield put({
      type: TRACEABILITY_MATRIX_FETCH_SUCCEEDED,
      payload: traceabilityMatrix.data
    });
  } catch (e) {
    yield put({ type: TRACEABILITY_MATRIX_FETCH_FAILED, payload: e.message });
  }
}

function* fetchTestResultHistory() {
  try {
    const testHistory = yield call(testResource.getTestResultHistory);
    yield put({
      type: TEST_RESULT_HISTORY_FETCH_SUCCEEDED,
      payload: testHistory.data
    });
  } catch (e) {
    yield put({ type: TEST_RESULT_HISTORY_FETCH_FAILED, payload: e.message });
  }
}

function* updateRMS(action) {
  try {
    const {id, payload} = action.payload;
    const data = yield call(testResource.updateRMS, id, payload);
    yield put({
      type: TRACEABILITY_RMS_UPDATE_SUCCEEDED,
    });
    yield put({
      type: TRACEABILITY_MATRIX_FETCH_REQUESTED,
    });
  } catch (e) {
    console.log("ERRIR", e);
    yield put({ type: TRACEABILITY_RMS_UPDATE_FAILED, payload: e.message });
  }
}

function* geJiraTickets(action) {
  try {
    const {payload} = action.payload;
    const jira = yield call(getJiraDetail, payload);
    yield put({
      type: JIRA_DETAIL_FETCH_SUCCEEDED,
      payload: jira.data
    });
  } catch (e) {
    console.log("ERRIR", e);
    yield put({ type: JIRA_DETAIL_FETCH_FAILED, payload: e.message });
  }
}

export function* watchTraceabilityMatrix() {
  yield takeLatest(TRACEABILITY_MATRIX_FETCH_REQUESTED, fetchTraceabilityMatrix);
  yield takeLatest(TRACEABILITY_RMS_UPDATE_REQUESTED, updateRMS);
  yield takeLatest(JIRA_DETAIL_FETCH_REQUESTED, geJiraTickets);
  yield takeLatest(TEST_RESULT_HISTORY_FETCH_REQUESTED, fetchTestResultHistory);
}
