import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import {
  withStyles,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';

import sidebarStyle from 'assets/jss/material-dashboard-react/sidebarStyle.jsx';

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const { classes, color, logo, image, logoText, routes } = props;

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) return null;
        if (prop.hideFromSideBar) return null;
        const listItemClasses = cx({
          [' ' + classes[color]]: activeRoute(prop.path)
        });
        const whiteFontClasses = cx({
          [' ' + classes.whiteFont]: activeRoute(prop.path)
        });
        return (
          <NavLink to={prop.path} className={classes.item} activeClassName="active" key={key}>
            <ListItem button className={classes.itemLink + listItemClasses}>
              <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                <prop.icon />
              </ListItemIcon>
              <ListItemText
                primary={prop.sidebarName}
                className={classes.itemText + whiteFontClasses}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  const brand = (
    <div className={classes.logo}>
      <a href="/" className={classes.logoLink}>
        <div>
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
          <span className={classes.logoText}>{logoText}</span>
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden smDown>
        <Drawer
          variant="persistent"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <a href="/documentation/index.html" target="_blank" className={classes.logoChildLink}>
            API Documentation
          </a>
          <a
            href="/documentation/test-index.html"
            target="_blank"
            className={classes.logoChildLink}
          >
            Directory of Legacy UI Tests
          </a>
          <a
            href="/documentation/next-gen-test-index.html"
            target="_blank"
            className={classes.logoChildLink}
          >
            Directory of NextGen UI Tests
          </a>
          <a
            href="/documentation/mobile-test-index.html"
            target="_blank"
            className={classes.logoChildLink}
          >
            Directory of Mobile UI Tests
          </a>
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div className={classes.background} style={{ backgroundImage: 'url(' + image + ')' }} />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
