export default function(theme) {
  return {
    chartContainer: {
      height: '750px',
      width: '100%',
      backgroundColor: '#fff'
    },
    chartText: {
      padding: '40px 0 0 60px',
      fontWeight: '400',
      "text-align": 'center',
    },
    formControl: {
      margin: '20px',
      minWidth: 100,
      width: 200,
      maxWidth: 200,
      minHeight: '50px'
    },
  };
}
