import { DTAG_FETCH_SUCCEEDED, JIRA_FETCH_SUCCEEDED } from '../actions';

const defaultState = {
  tagList: [],
  byTestId: {},
  byTag: {},
  jiraDetail: [],
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case DTAG_FETCH_SUCCEEDED:
      return {
        ...state,
        ...{ fetched: true, ...action.payload }
      };
    case JIRA_FETCH_SUCCEEDED:
      return{
        ...state,
        jiraDetail: action.payload
      }
    default:
      return state;
  }
}
