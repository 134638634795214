import {
  API_TEST_RESULTS_DETAIL_FETCH_FAILED,
  API_TEST_RESULTS_DETAIL_FETCH_REQUESTED,
  API_TEST_RESULTS_DETAIL_FETCH_SUCCEEDED,
} from '../actions';

const defaultState = {
  fetching: false,
  error: false
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case API_TEST_RESULTS_DETAIL_FETCH_REQUESTED:
      return {
        ...state,
        error: false,
        fetching: true
      };
    case API_TEST_RESULTS_DETAIL_FETCH_SUCCEEDED:
      const testResult = action.payload[0] || {};
      return {
        ...state,
        [testResult.test_run_test_id]: testResult,
        error: false,
        fetching: false
      };
    case API_TEST_RESULTS_DETAIL_FETCH_FAILED:
      return {
        ...state,
        fetching: false,
        error: true
      };
    default:
      return state;
  }
}
