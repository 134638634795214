import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIDataTable from 'mui-datatables';

import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  MuiThemeProvider
} from '@material-ui/core';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

import styles from './styles';
import { API_REQUEST_LOG_FETCH_REQUESTED } from '../../redux/actions';
import _ from 'lodash';

const theme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none'
      }
    },
    MUIDataTableHeadCell: {
      root: {
        padding: '0 0 0 25px'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        maxWidth: '240px',
        width: '20px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '20px 10px 10px 20px',
        '&:nth-child(2)': { width: '5%' },
        '&:nth-child(6)': { width: '5%' },
        '&:nth-child(8)': { width: '20%' },
        '&:last-child': { paddingRight: 0 }
      }
    }
  }
});
const useStyles = makeStyles(styles);

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function APILogDetail({ open, handleClose, testRunKey }) {
  const cs = useStyles();

  const dispatch = useDispatch();

  const [apiLog, setApiLog] = useState(null);
  const apiRequestLog = useSelector((state) => state.apiRequestLog);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  React.useEffect(() => {
    if (!apiLog) {
      dispatch({ type: API_REQUEST_LOG_FETCH_REQUESTED, payload: { key: testRunKey } });
    }
  }, []);

  React.useEffect(() => {
    if (apiRequestLog[testRunKey] && apiLog === null) {
      setApiLog(apiRequestLog[testRunKey]);
    }
  }, [apiRequestLog]);

  const parseData = (apiLog) => {
    return _.map(apiLog, (r) => {
      return [r.method || '', r.url || '', r.status || '', r.responsetime || '', r.id || ''];
    });
  };

  const renderResult = () => {
    const rows = parseData(apiLog);
    const options = {
      selectableRows: false,
      download: true,
      filterType: 'dropdown',
      filter: true,
      print: false,
      responsive: 'scroll',
      rowsPerPage: 100,
      rowsPerPageOptions: [100, 1000, 10000]
    };
    const columns = [
      {
        name: 'method',
        label: 'Method',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'url',
        label: 'URL',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'responsetime',
        label: 'Response Time (ms)',
        options: {
          filter: true,
          sort: true
        }
      }
    ];
    return (
      <div style={{ height: 600, width: '100%' }}>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable options={options} data={rows} columns={columns} />
        </MuiThemeProvider>
      </div>
    );
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          API Requests
        </DialogTitle>
        <DialogContent dividers id="scroll-dialog-description">
          {apiLog ? renderResult() : <LinearProgress />}
        </DialogContent>
      </Dialog>
    </div>
  );
}
