import React from 'react';

export const regionToLocationMapping = (region) => {
  const location = {
    'us-west-2': 'US (Oregon)',
    'eu-central-1': 'EUROPE (Frankfurt)',
    'ap-southeast-2': 'Australia (Sydney)',
    'ap-southeast-1': 'Asia (Singapore)'
  };
  return location[region];
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== null && typeof row[id] === 'string') {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  } else {
    return String(row[filter.id]) === filter.value;
  }
};

// Need to display Total Count in React Table
export const reactTableHelper = (state, makeTable) => {
  let recordsInfoText;
  let coverage;
  const { sortedData } = state;

  if (sortedData && sortedData.length > 0) {
    let totalRecords = sortedData.length;
    let recordWithTests = sortedData.filter((d) => d.testRuns.length > 0).length;
    recordsInfoText = `Total RMS: ${totalRecords}`;
    coverage = `Coverage: ${((recordWithTests / totalRecords) * 100).toFixed(2)}%`;
  } else {
    recordsInfoText = 'Total RMS: 0';
    coverage = 'Coverage: N/A';
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <span
          style={{
            fontSize: '1.063rem',
            marginLeft: '10px',
            padding: '2px'
          }}
        >
          {recordsInfoText}
        </span>
        <span
          style={{
            fontSize: '1.063rem',
            marginLeft: '10px',
            padding: '2px'
          }}
        >
          {coverage}
        </span>
      </div>
      {makeTable()}
    </div>
  );
};
