import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { testSubset, nextGenTestSubSet } from '../../assets/config/testConfig';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withMobileDialog,
  Input,
  Radio,
  RadioGroup,
  FormControl,
  NativeSelect,
  FormControlLabel,
  InputLabel,
  TextField,
  Switch,
  withStyles,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Box
} from '@material-ui/core';

import {
  TEST_RESULTS_DIALOG_CLOSE,
  BUILDS_CREATE_REQUESTED,
  BUILDS_DIALOG_CLOSE,
  BUILDS_CHINA_CREATE_REQUESTED
} from '../../redux/actions';

import {
  NORA_SITES,
  NORA_SITES_NEXT,
  DATABASE_HOSTS,
  PARALLEL_OPTIONS,
  RUN_TYPE,
  LANGUAGE_LEGACY,
  LANGUAGE_NEXT_GEN,
  languageCompare,
  LEGACY_TRANSLATION_TEST_TAG,
  NEXTGEN_TRANSLATION_TEST_TAG,
  LEGACY_TRANSLATION_TEST_SITE,
  NEXTGEN_TRANSLATION_TEST_SITE,
  nextGenLanguageCompare
} from './constants';

import SearchTag from '../SearchTag';

import styles from './styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { validateEmail } from '../../lib/utils';

const DEFAULT_STATE = {
  run_type: RUN_TYPE.RUN_ALL,
  parallel_runners: 1,
  releaseId: '',
  tag: null,
  branch: 'master',
  database_host: DATABASE_HOSTS[0].value,
  nora_site: NORA_SITES[0].value,
  build_region: 'US',
  dynamicTags: [],
  subSet: '',
  enable_coverage: false,
  new_nora_site: '',
  new_database_host: '',
  platform: 'Legacy',
  checkedPlatform: false,
  translationLanguageCode: [],
  translationReportToEmail: '',
  numberOfPatientsCreated: ''
};

class BuildDialog extends React.Component {
  constructor() {
    super();
    this.state = DEFAULT_STATE;
    this.handlePlatformChange = this.handlePlatformChange.bind(this);
    this.handleMultiSelectChange = this.handleMultiSelectChange.bind(this);
  }

  handleClose = () => {
    this.setState(DEFAULT_STATE);
    this.props.onClose();
  };

  handleMultiSelectChange = (event) => {
    const {
      target: { value }
    } = event;
    const actualValue = typeof value === 'string' ? value.split(',') : value;
    this.setState({
      translationLanguageCode: actualValue
    });
  };

  createBuildsByDynamicTags() {
    const {
      dynamicTags,
      branch,
      nora_site,
      database_host,
      parallel_runners,
      enable_coverage,
      checkedPlatform,
      build_region
    } = this.state;

    const {
      executor = 'unknown',
      byTag,
      onBuildsCreateRequest,
      onBuildsCreateRequestChina
    } = this.props;
    let buildFunc = onBuildsCreateRequest;
    if (build_region === 'CHINA') {
      buildFunc = onBuildsCreateRequestChina;
    }
    // find all tests with the dynamic tags
    let testIds = [];
    dynamicTags.forEach((dtag) => {
      const foundTags = byTag[dtag.text] && byTag[dtag.text].test_ids;
      if (foundTags) {
        testIds = testIds.concat(foundTags);
      }
    });

    // remove duplicate tests
    const uniqIds = _(testIds)
      .map((id) => `@${id}`)
      .uniq()
      .value();

    buildFunc({
      branch,
      tag: uniqIds.join('|'),
      nora_site,
      database_host,
      parallel_runners,
      executor,
      enable_coverage,
      isNextGen: checkedPlatform
    });
  }

  buildTags() {
    const { parallel_runners } = this.state;
    let runnerCount = parallel_runners;

    const { run_type, tag, subSet } = this.state;
    if (
      (run_type === RUN_TYPE.BY_TAGS ||
        run_type === RUN_TYPE.OQ_TEST ||
        run_type === RUN_TYPE.TRANSLATION_TEST) &&
      tag
    ) {
      return tag;
    } else if (run_type === RUN_TYPE.BY_SUB_SET && subSet && !this.state.checkedPlatform) {
      if (runnerCount === 1) {
        return subSet;
      } else {
        const testIds = testSubset.filter((t) => {
          return t.tag === subSet;
        });
        return testIds[0].testId.join('|');
      }
    } else if (run_type === RUN_TYPE.BY_SUB_SET && subSet && this.state.checkedPlatform) {
      if (runnerCount === 1) {
        return subSet;
      } else {
        const testIds = nextGenTestSubSet.filter((t) => {
          return t.tag === subSet;
        });
        return testIds[0].testId.join('|');
      }
    }
    return '';
  }

  handleCreateBuilds = () => {
    const {
      branch,
      nora_site,
      new_nora_site,
      database_host,
      new_database_host,
      parallel_runners,
      enable_coverage,
      checkedPlatform,
      releaseId,
      translationLanguageCode,
      translationReportToEmail,
      numberOfPatientsCreated,
      build_region
    } = this.state;
    const {
      executor = 'unknown',
      userData,
      onBuildsCreateRequest,
      onBuildsCreateRequestChina
    } = this.props;
    let buildFunc = onBuildsCreateRequest;
    if (build_region === 'CHINA') {
      buildFunc = onBuildsCreateRequestChina;
    }
    let runnerCount = parallel_runners;
    let finalNoraSite = nora_site;
    let finalDBHost = database_host;

    if (database_host === 'Other' && new_database_host === '') {
      return;
    } else if (database_host === 'Other' && new_database_host !== '') {
      finalDBHost = new_database_host;
    }

    if (nora_site === 'Other' && new_nora_site === '') {
      return;
    } else if (nora_site === 'Other' && new_nora_site !== '') {
      finalNoraSite = new_nora_site;
    }

    if (
      this.state.run_type === RUN_TYPE.OQ_TEST &&
      (_.isEmpty(userData['custom:spiraPass']) || _.isEmpty(userData['custom:spiraUser']))
    ) {
      alert('Please provide SPIRA credentials, in your profile first');
      return;
    }

    if (translationReportToEmail) {
      const emails = translationReportToEmail.replace(/\s/g, '').split(',');
      for (let i = 0; i < emails.length; i++) {
        if (!validateEmail(emails[i].trim())) {
          alert('Please enter valid email');
          return;
        } else {
          if (!emails[i].endsWith('science37.com')) {
            alert('Only Science37 email supported');
            return;
          }
        }
      }
    }
    if (this.state.run_type === RUN_TYPE.TRANSLATION_TEST) {
      if (_.isEmpty(this.state.translationLanguageCode)) {
        alert('Please select translation language code(s)');
        return;
      }
    }

    if (this.state.run_type === RUN_TYPE.BY_DYNAMIC_TAGS) {
      this.createBuildsByDynamicTags();
    } else if (this.state.run_type === RUN_TYPE.TRANSLATION_TEST) {
      const tBranch = 'stage';
      const tTags =
        this.state.platform === DEFAULT_STATE.platform
          ? LEGACY_TRANSLATION_TEST_TAG
          : NEXTGEN_TRANSLATION_TEST_TAG;
      const tSite =
        this.state.platform === DEFAULT_STATE.platform
          ? LEGACY_TRANSLATION_TEST_SITE
          : NEXTGEN_TRANSLATION_TEST_SITE;
      buildFunc({
        branch: tBranch,
        tag: tTags,
        nora_site: tSite,
        parallel_runners: 1,
        executor,
        mode: this.state.run_type,
        isNextGen: checkedPlatform,
        translationLanguageCode,
        translationReportToEmail: translationReportToEmail.replace(/\s/g, '') // remove all whitespace
      });
    } else if (this.state.run_type === RUN_TYPE.BULK_IMAGE_UPLOAD) {
      buildFunc({
        branch,
        tag: '@photoUpload',
        nora_site: finalNoraSite,
        parallel_runners: runnerCount,
        executor,
        numberOfPatientsCreated,
        isNextGen: checkedPlatform
      });
    } else {
      buildFunc({
        branch,
        tag: this.buildTags(),
        nora_site: finalNoraSite,
        database_host: finalDBHost,
        parallel_runners: runnerCount,
        executor,
        enable_coverage,
        mode: this.state.run_type,
        spiraCredentialsString:
          userData['custom:spiraUser'] && userData['custom:spiraPass']
            ? userData['custom:spiraUser'].concat('|', userData['custom:spiraPass'])
            : ' | ',
        isNextGen: checkedPlatform,
        releaseId,
        translationLanguageCode,
        translationReportToEmail: translationReportToEmail.replace(/\s/g, '') // remove all whitespace
      });
    }
    this.handleClose();
    this.props.history.push(`/builds`);
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ tag: nextProps.incomingTags });

    if (nextProps.incomingTags) {
      this.setState({ run_type: RUN_TYPE.BY_TAGS });
    }
  }

  handleSubsetChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handlePlatformChange(event) {
    if (event.target.checked) {
      this.setState({
        ...this.state,
        ...{
          platform: 'NextGen',
          [event.target.name]: event.target.checked,
          nora_site: NORA_SITES_NEXT[0].value,
          translationLanguageCode: []
        }
      });
    } else {
      this.setState({
        ...this.state,
        ...{
          platform: 'Legacy',
          [event.target.name]: event.target.checked,
          nora_site: NORA_SITES[0].value,
          translationLanguageCode: []
        }
      });
    }
  }

  handleSwitchChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  renderRunTypeChoices() {
    const { classes: cs } = this.props;
    return (
      <Grid item>
        <RadioGroup
          className={cs.radioGroup}
          aria-label="position"
          name="Run-Type"
          value={this.state.run_type}
          onChange={this.handleChange('run_type')}
          row
        >
          <FormControlLabel
            value={RUN_TYPE.RUN_ALL}
            control={<Radio color="primary" />}
            label="All Tests"
          />
          <FormControlLabel
            value={RUN_TYPE.BY_TAGS}
            control={<Radio color="primary" />}
            label="By Test Tags"
          />
          {/*<FormControlLabel*/}
          {/*  value={RUN_TYPE.BY_DYNAMIC_TAGS}*/}
          {/*  control={<Radio color="primary" />}*/}
          {/*  label="By Dynamic Tags"*/}
          {/*/>*/}
          <FormControlLabel
            value={RUN_TYPE.BY_SUB_SET}
            control={<Radio color="primary" />}
            label="By Sub Set"
          />
          <FormControlLabel
            value={RUN_TYPE.OQ_TEST}
            control={<Radio color="primary" />}
            label="OQ Test"
          />
          <FormControlLabel
            value={RUN_TYPE.TRANSLATION_TEST}
            control={<Radio color="primary" />}
            label="Translation Test"
          />
          <FormControlLabel
            value={RUN_TYPE.BULK_IMAGE_UPLOAD}
            control={<Radio color="primary" />}
            label="Bulk Image Upload"
          />
        </RadioGroup>
      </Grid>
    );
  }

  renderParallelOptions() {
    const { classes: cs } = this.props;

    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <InputLabel htmlFor="parallel-machines">Parallel Runners</InputLabel>
          <NativeSelect
            value={this.state.parallel_runners}
            onChange={this.handleChange('parallel_runners')}
            input={<Input name="Parallel Runners" id="parallel-machines" />}
          >
            {this.renderOptions(PARALLEL_OPTIONS)}
          </NativeSelect>
        </FormControl>
      </Grid>
    );
  }

  renderBranchOptions() {
    const { classes: cs } = this.props;

    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <TextField
            label={`Heimdall${this.state.checkedPlatform ? '-Next' : ''} Branch`}
            id="branch"
            onChange={this.handleChange('branch')}
            value={this.state.branch}
          />
        </FormControl>
      </Grid>
    );
  }

  renderTranslationOptions() {
    const { classes: cs } = this.props;

    return (
      <>
        <Grid item>
          <FormControl className={cs.formControl}>
            <TextField
              label="Report Translation Screenshots to Email"
              id="translation-email"
              placeholder="email1@science37.com,email2@science37.com"
              onChange={this.handleChange('translationReportToEmail')}
              value={this.state.translationReportToEmail}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={cs.formControl}>
            <InputLabel htmlFor="releaseId">Translation Language To Test</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={this.state.translationLanguageCode}
              onChange={this.handleMultiSelectChange}
              input={
                <OutlinedInput id="select-multiple-chip" label="Translation Language To Test" />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 100 * 4.5 + 8,
                    width: 250
                  }
                }
              }}
            >
              {this.state.checkedPlatform
                ? LANGUAGE_NEXT_GEN.sort(nextGenLanguageCompare).map((lang) => (
                    <MenuItem key={lang.languageName} value={lang.languageName}>
                      {lang.languageName}
                    </MenuItem>
                  ))
                : LANGUAGE_LEGACY.sort(languageCompare).map((lang) => (
                    <MenuItem key={lang.languageCode} value={lang.languageCode}>
                      {lang.language}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <div className={cs.translationNote}>
          <span>Note: This test runs on Stage env.</span>
        </div>
      </>
    );
  }

  renderBulkImageOptions() {
    const { classes: cs } = this.props;

    return (
      <>
        <Grid item>
          <FormControl className={cs.formControl}>
            <TextField
              label="Number of Patients"
              id="bulk-upload"
              placeholder="10"
              onChange={this.handleChange('numberOfPatientsCreated')}
              value={this.state.numberOfPatientsCreated}
            />
          </FormControl>
        </Grid>
      </>
    );
  }

  renderOQOptions() {
    const { classes: cs } = this.props;

    return (
      <>
        <Grid item>
          <FormControl className={cs.formControl}>
            <InputLabel htmlFor="releaseId">Spira Release</InputLabel>
            <NativeSelect
              value={this.state.releaseId}
              onChange={this.handleChange('releaseId')}
              input={<Input name="Parallel Runners" id="parallel-machines" />}
            >
              <option value=""> </option>
              {this.props.spiraReleases.map((release) => (
                <option key={release.ReleaseId} value={release.ReleaseId}>
                  {release.FullName}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={cs.formControl}>
            <InputLabel htmlFor="releaseId">Jira Release Version</InputLabel>
            <NativeSelect
              value={this.state.releaseId}
              onChange={this.handleChange('releaseId')}
              input={<Input name="Parallel Runners" id="parallel-machines" />}
            >
              <option value=""> </option>
              {this.props.jiraReleases.map((release) => (
                <option key={release.name} value={release.name}>
                  {release.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Grid>
      </>
    );
  }

  renderSiteOptions() {
    const { classes: cs } = this.props;

    return (
      <FormControl className={cs.siteField}>
        <InputLabel htmlFor="nora-site">Nora Site</InputLabel>
        <NativeSelect
          value={this.state.nora_site}
          onChange={this.handleChange('nora_site')}
          input={<Input name="NORA Site" id="nora-site" />}
        >
          {this.renderOptions(this.state.checkedPlatform ? NORA_SITES_NEXT : NORA_SITES)}
        </NativeSelect>
      </FormControl>
    );
  }

  renderRegionOptions() {
    const { classes: cs } = this.props;

    return (
      <FormControl className={cs.regionField}>
        <InputLabel htmlFor="nora-site">Build Region</InputLabel>
        <NativeSelect
          value={this.state.build_region}
          onChange={this.handleChange('build_region')}
          input={<Input name="Build Region" id="build_region" />}
        >
          {this.renderOptions([
            {
              value: 'US',
              label: 'US'
            },
            {
              value: 'CHINA',
              label: 'CHINA'
            }
          ])}
        </NativeSelect>
      </FormControl>
    );
  }

  renderOtherSiteOptions() {
    const { classes: cs } = this.props;

    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <TextField
            label="Select Nora Site"
            id="new-nora-site"
            placeholder="https://dev.nora.science37.com/"
            onChange={this.handleChange('new_nora_site')}
            value={this.state.new_nora_site}
          />
        </FormControl>
      </Grid>
    );
  }

  renderPlatformOptions() {
    const { classes: cs } = this.props;

    return (
      <FormControlLabel
        className={cs.isNextGen}
        control={
          <Switch
            checked={this.state.checkedPlatform}
            onChange={this.handlePlatformChange}
            name="checkedPlatform"
          />
        }
        label={this.state.platform.toUpperCase()}
      />
    );
  }

  renderDynamicTags() {
    const { classes: cs } = this.props;
    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <SearchTag
            tags={this.state.dynamicTags}
            className={cs.searchTag}
            handleUpdate={this.handleUpdateOnFilterTags}
            placeholder="Filter by Dynamic Tags"
          />
        </FormControl>
      </Grid>
    );
  }

  renderOQTest() {
    const { classes: cs } = this.props;
    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <TextField
            label="Run Tests with Tag"
            id="tag"
            onChange={this.handleChange('tag')}
            value={this.state.tag}
          />
        </FormControl>
      </Grid>
    );
  }

  renderTestTags() {
    const { classes: cs } = this.props;

    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <TextField
            label="Run Tests with Tag"
            id="tag"
            onChange={this.handleChange('tag')}
            value={this.state.tag}
          />
        </FormControl>
      </Grid>
    );
  }

  renderSubsetTags() {
    const { classes: cs } = this.props;
    const finalTestSubset = this.state.checkedPlatform ? nextGenTestSubSet : testSubset;
    finalTestSubset.sort((a, b) => {
      return a.name.toUpperCase() - b.name.toUpperCase();
    });
    const ts = finalTestSubset.map((sub) => {
      const label = `${sub.name} (${sub.count}+)`;
      return (
        <MenuItem key={sub.tag} value={sub.tag}>
          {label}
        </MenuItem>
      );
    });
    return (
      <Grid item>
        <FormControl className={cs.formControl}>
          <InputLabel htmlFor="subSet">Test SubSet</InputLabel>
          <Select
            value={this.state.subSet}
            onChange={this.handleSubsetChange}
            inputProps={{
              name: 'subSet',
              id: 'subSet'
            }}
          >
            {ts}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  handleUpdateOnFilterTags = (dynamicTags) => {
    this.setState({ dynamicTags });
  };

  renderRunTypeOptions() {
    const { run_type } = this.state;

    if (run_type === RUN_TYPE.RUN_ALL) {
      return this.renderParallelOptions();
    } else if (run_type === RUN_TYPE.BY_TAGS || run_type === RUN_TYPE.TRANSLATION_TEST) {
      return (
        <React.Fragment>
          {this.renderParallelOptions()}
          {this.renderTestTags()}
        </React.Fragment>
      );
    } else if (run_type === RUN_TYPE.BULK_IMAGE_UPLOAD) {
      return <React.Fragment>{this.renderParallelOptions()}</React.Fragment>;
    } else if (run_type === RUN_TYPE.BY_DYNAMIC_TAGS) {
      return (
        <React.Fragment>
          {this.renderParallelOptions()}
          {this.renderDynamicTags()}
        </React.Fragment>
      );
    } else if (run_type === RUN_TYPE.BY_SUB_SET) {
      return (
        <React.Fragment>
          {this.renderParallelOptions()}
          {this.renderSubsetTags()}
        </React.Fragment>
      );
    } else if (run_type === RUN_TYPE.OQ_TEST) {
      return (
        <React.Fragment>
          {this.renderParallelOptions()}
          {this.renderOQTest()}
        </React.Fragment>
      );
    }
  }

  renderOptions(options) {
    return options.map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      );
    });
  }

  render() {
    const { fullScreen, open, classes: cs } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Create Build (Frontend)</DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            {this.renderRunTypeChoices()}
            {this.state.run_type !== RUN_TYPE.TRANSLATION_TEST && this.renderRunTypeOptions()}
            {this.state.run_type !== RUN_TYPE.TRANSLATION_TEST && this.renderBranchOptions()}
            {this.state.run_type === RUN_TYPE.TRANSLATION_TEST && this.renderTranslationOptions()}
            {this.state.run_type === RUN_TYPE.BULK_IMAGE_UPLOAD && this.renderBulkImageOptions()}
            {this.state.run_type === RUN_TYPE.OQ_TEST &&
              this.props.spiraReleases.length > 0 &&
              this.renderOQOptions()}

            <Grid item style={{ display: 'flex' }}>
              {this.renderPlatformOptions()}
              {this.state.run_type !== RUN_TYPE.TRANSLATION_TEST && this.renderSiteOptions()}
            </Grid>
            {this.state.checkedPlatform && (
              <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {this.renderRegionOptions()}
              </Grid>
            )}
            {this.state.nora_site === 'Other' && this.renderOtherSiteOptions()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={cs.buttonsContainer}>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleCreateBuilds} color="primary" autoFocus>
              Create Builds
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

BuildDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  return {
    byTag: state.dtags.byTag,
    branch: state.buildParams.branch,
    incomingTags: state.buildParams.tags,
    nora_site: state.buildParams.nora_site,
    database_host: state.buildParams.database_host,
    executor: state.login.userData.email,
    open: state.buildParams.open,
    userData: state.login.userData,
    spiraReleases: state.spira.spiraReleases,
    jiraReleases: state.spira.jiraReleases
  };
};

const mapDispatchToProps = (dispatch) => ({
  onBuildsCreateRequest: (payload) => {
    dispatch({ type: BUILDS_CREATE_REQUESTED, payload: payload });
    dispatch({ type: BUILDS_DIALOG_CLOSE });
    dispatch({ type: TEST_RESULTS_DIALOG_CLOSE });
  },
  onBuildsCreateRequestChina: (payload) => {
    dispatch({ type: BUILDS_CHINA_CREATE_REQUESTED, payload: payload });
    dispatch({ type: BUILDS_DIALOG_CLOSE });
    dispatch({ type: TEST_RESULTS_DIALOG_CLOSE });
  },
  onClose: () => {
    dispatch({ type: BUILDS_DIALOG_CLOSE });
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BuildDialog);
