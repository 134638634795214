export default (theme) => {
  return {
    footer: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      padding: 0,
      width: '100%'
    },
    copyright: {
      marginRight: '5px'
    },
    footerContainer: {
      padding: '0 30px',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  };
};
