import { FailColor, PassColor, SkipColor } from '../../assets/jss/colors';

export default (theme) => {
  return {
    resultContainer: {
      display: 'flex',
      width: '100%',
      margin: '20px',
      flexDirection: 'row'
    },
    errorImageContainer: {
      padding: '10px'
    },
    screenshot: {
      height: '512px',
      width: '480px'
    },
    idContainer: {
      fontWeight: 600,
      backgroundColor: '#e6e6e6',
      paddingLeft: '5px',
      marginBottom: '5px',
      display: "flex",
      justifyContent: "space-between"
    },
    withMarginRight: {
      marginRight: "15px"
    },
    id: {
      display: 'inline-block'
    },
    subheader: {
      paddingLeft: '10px'
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '20px',
      padding: '10px',
      flexGrow: 2
    },
    card: {
      width: '100%'
    },
    footer: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'row'
    },
    jiraDetail: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column'
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      width: '50%'
    },
    button: {
      marginLeft: '10px',
      maxHeight: '40px'
    },
    buttonIcon: {
      marginLeft: '10px'
    },
    failAvatar: {
      color: FailColor,
      backgroundColor: 'transparent',
      display: 'inline-block',
      margin: '3px 5px 3px 3px'
    },
    skipAvatar: {
      margin: '3px 5px 3px 3px',
      color: SkipColor,
      backgroundColor: 'transparent',
      display: 'inline-block'
    },
    passAvatar: {
      display: 'inline-block',
      margin: '3px 5px 3px 3px',
      color: PassColor,
      backgroundColor: 'transparent'
    },
    icon: {
      fontSize: 25
    },
    duration: {
      marginLeft: 'auto',
      marginRight: '30px'
    },
    cardContent: {
      display: 'flex'
    },
    errorContainer: {
      marginTop: '10px',
      flexGrow: 2
    },
    errorLabel: {
      color: 'orange'
    },
    jiraLabel: {
      color: 'orange',
      fontWeight: 'bold'
    },
    errorMessage: {
      paddingLeft: '10px',
      overflow: 'scroll',
      maxHeight: '100px'
    },
    timerIcon: {
      fontSize: 20,
      position: 'relative',
      top: '5px',
      paddingLeft: '5px'
    }
  };
};
