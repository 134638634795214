import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import path from 'path';

import { compose } from 'redux';
import { connect } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  withStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  withMobileDialog,
  Slide,
  Avatar,
  Card,
  Grid,
  CardHeader,
  CardContent,
  Tooltip,
  Button
} from '@material-ui/core';

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PassIcon from '@material-ui/icons/CheckCircle';
import FailIcon from '@material-ui/icons/HighlightOff';
import SkipIcon from '@material-ui/icons/PauseCircleOutline';
import LinkIcon from '@material-ui/icons/Link';

import styles from './styles';
import ScreenshotCard from '../ScreenshotCard';

import {
  RESULTS_DETAIL_DIALOG_CLOSE,
  TEST_RESULTS_DIALOG_CLOSE,
  TEST_RESULTS_STEPS_FETCH_REQUESTED
} from '../../redux/actions';

import { isEmpty } from 'lodash';

const TIMESTAMP_FORMAT = 'YYYY-MM-DD hh:mm:ss.SSS';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
class ResultDetailDialog extends React.Component {
  beforeRender() {
    const { testResult } = this.props;

    if (isEmpty(testResult)) {
      return;
    }

    const {
      id,
      file,
      root_path,
      branch,
      error_message,
      test_run_test_id: test_run_id
    } = testResult;
    this.filePath = file.replace(root_path, '');
    this.pending = error_message.includes('Pending reason');
    // if we don't get to the scenario, we don't have all the data
    this.id = id || path.basename(file).split('_')[0];
    this.test_run_id = test_run_id;
    this.branch = branch;
  }

  duration() {
    const { start_time, end_time } = this.props.testResult;
    const endTime = moment.utc(end_time, TIMESTAMP_FORMAT);
    const startTime = moment.utc(start_time, TIMESTAMP_FORMAT);
    const timeInSec = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
    return `Duration: ${timeInSec} sec`;
  }

  componentDidUpdate() {
    const { testResult, test_run_key, detail, fetching, error } = this.props;
    const { test_run_test_id: id } = testResult;
    const details = detail[id];

    if (id && !details && !fetching && !error) {
      this.props.onRequestDetail(test_run_key, id);
    }
  }

  renderTitle() {
    const { test_run_key, classes: cs } = this.props;
    const runLink = `${window.location.protocol}//${window.location.host}/test_runs/${test_run_key}/${this.test_run_id}`;
    return (
      <DialogTitle id="responsive-dialog-title">
        <Button onClick={this.props.onCloseAll} color="primary" style={{ margin: 0 }}>
          <ChevronLeft color="primary" />
        </Button>
        {test_run_key}
        <Button onClick={this.props.onClose} color="primary" style={{ margin: 0 }}>
          <ChevronLeft color="primary" />
        </Button>
        Test Case: {this.id}
        <div className={cs.linkContainer}>
          <Tooltip title="Copy to clipboard">
            <CopyToClipboard text={runLink}>
              <Button color="primary">
                <LinkIcon color="primary" />
              </Button>
            </CopyToClipboard>
          </Tooltip>
        </div>
      </DialogTitle>
    );
  }

  renderSubheader() {
    const { scenario, tags, executor } = this.props.testResult;
    const cs = this.props.classes;
    return (
      <div className={cs.subheader}>
        <div>
          <div>{scenario} </div>
          <div> {this.filePath} </div>
          <div>
            <strong> Tags: </strong> {tags}
          </div>
          <div>
            <strong> Executor: </strong> {executor}
          </div>
        </div>
        <div className={cs.duration}>{this.duration()}</div>
      </div>
    );
  }

  // TODO:  move test results methods to a library
  renderIcon() {
    const { passed } = this.props.testResult;
    const cs = this.props.classes;
    let icon = <FailIcon className={cs.icon} />;
    let avatarClass = cs.failAvatar;
    if (passed) {
      icon = <PassIcon className={cs.icon} />;
      avatarClass = cs.passAvatar;
    } else if (this.pending) {
      icon = <SkipIcon className={cs.icon} />;
      avatarClass = cs.skipAvatar;
    }
    return <Avatar className={avatarClass}>{icon}</Avatar>;
  }

  renderHeader() {
    const cs = this.props.classes;
    const { title } = this.props.testResult;
    const fullTitle = `${title || ''}`;
    return (
      <Card className={cs.card}>
        <CardHeader
          avatar={this.renderIcon()}
          title={fullTitle}
          subheader={this.renderSubheader()}
        />
      </Card>
    );
  }

  renderTestBody() {
    const body = this.props.testResult.body || "Not found! Probably failed at 'Before' action";
    return body;
  }

  formatLine(line) {
    const { classes: cs } = this.props;
    // sample line: 'at Object.clickLeftMenu (/app/lib/steps/global.js:25:10)'
    if (line) {
      // eslint-disable-next-line
      const [at, method, file_location] = line.split(' ');
      if (file_location) {
        let [file, lineNumber] = file_location.split(':');
        // app is the base directory in docker
        file = file.replace('(/app/', '');
        const bitbucketRoot = 'https://github.com/Science37/heimdall/tree';
        const src = `${bitbucketRoot}/${this.branch}/${file}#L${lineNumber}`;
        return (
          <a className={cs.codeLink} href={src} target="_blank" rel="noopener noreferrer">
            at {method}
          </a>
        );
      }
    }
  }

  renderDetailSteps(steps) {
    const { classes: cs } = this.props;

    return steps.map((step, i) => {
      const { action, passed, line } = step;
      const icon = passed ? (
        <PassIcon className={cs.step_check_icon} />
      ) : (
        <FailIcon className={cs.step_fail_icon} />
      );

      let message = (
        <li key={i} className={cs.step}>
          {icon}
          <span className={cs.action}>{action}</span>
          <span className={cs.codeLine}>{this.formatLine(line)}</span>
        </li>
      );

      const logMessage = action.split('"')[1];

      if (action.startsWith('I.log(')) {
        message = (
          <li key={i} className={cs.step}>
            <span className={cs.logMessage}>{logMessage}</span>
            <span className={cs.codeLine}>{this.formatLine(line)}</span>
          </li>
        );
      }

      return message;
    });
  }

  renderSteps() {
    const { detail } = this.props;
    const details = detail[this.test_run_id];
    const json_steps = details.json_steps || [];
    return this.renderDetailSteps(json_steps);
  }

  renderBeforeSteps() {
    const { detail } = this.props;
    const details = detail[this.test_run_id] || {};
    const json_steps = details.json_before_steps || [];
    return this.renderDetailSteps(json_steps);
  }

  renderScreenshots() {
    const { detail, classes: cs } = this.props;
    const details = detail[this.test_run_id] || {};
    const screenshots = details.screenshots || [];
    const json_steps = details.json_steps || [];

    if (!details || screenshots.length <= 0) {
      return;
    }

    const baseS3 = 'https://s3-us-west-2.amazonaws.com/test-automation-science37';
    const icons = [];
    let index = 0;
    let steps = [];

    json_steps.forEach((step, i) => {
      const { action, passed, codeLocation } = step;

      const icon = passed ? (
        <PassIcon className={cs.step_check_icon} />
      ) : (
        <FailIcon className={cs.step_fail_icon} />
      );

      const screenShotAction = action.includes('I.saveScreenshot');

      steps.push(
        <li key={i} className={cs.step}>
          {icon} {action} - {this.formatLine(codeLocation)}
        </li>
      );

      if (screenShotAction) {
        const { id, description, screenshotpath, timestamp } = screenshots[index];
        const src = `${baseS3}/${screenshotpath}`;
        icons.push(
          <Grid key={id} item xs={12} className={cs.pagebreak}>
            <ScreenshotCard
              description={description}
              src={src}
              index={index}
              screenshots={screenshots}
              timestamp={timestamp}
              steps={steps}
            />
          </Grid>
        );
        index++;
        steps = [];
      }
    });
    return (
      <Card>
        <CardHeader title="Test Details" />
        <CardContent>
          <Grid container justify="flex-start" alignItems="flex-start" direction="row" spacing={32}>
            {icons}
          </Grid>
        </CardContent>
      </Card>
    );
  }

  renderErrorCard() {
    const { classes: cs } = this.props;
    const { screenshot, error_message: errorMessage, passed } = this.props.testResult;
    let icon;
    if (passed || this.pending) {
      return;
    }
    if (screenshot) {
      const baseS3 = 'https://s3-us-west-2.amazonaws.com/test-automation-science37/output';
      const src = `${baseS3}/${screenshot}`;
      icon = (
        <a href={src} target="_blank" rel="noopener noreferrer">
          <img src={src} alt="error occurred" height="512px" width="480px" />
        </a>
      );
    }
    return (
      <Card>
        <CardHeader title="Error Screenshot" subheader={errorMessage} />
        <CardContent className={cs.errorIcon}>{icon}</CardContent>
      </Card>
    );
  }

  renderScenarioSteps() {
    const { classes: cs, detail, error } = this.props;
    const details = detail[this.test_run_id];

    if (details) {
      return (
        <div className={cs.steps_container}>
          <p>
            <strong>Scenario Steps</strong>
          </p>
          <ol className="steps">{this.renderSteps()}</ol>
        </div>
      );
    } else if (error) {
      return <div>Error occurred fetching test details...</div>;
    } else {
      return (
        <div className={cs.steps_container}>
          <p>
            <strong>Scenario Steps</strong>
          </p>
          Loading...
        </div>
      );
    }
  }

  renderBeforeStepContents() {
    const { classes: cs, detail } = this.props;
    const details = detail[this.test_run_id];

    if (details) {
      return (
        <div className={cs.steps_container}>
          <p>
            <strong>Scenario Before Steps</strong>
          </p>
          <ol className="steps">{this.renderBeforeSteps()}</ol>
        </div>
      );
    } else {
      return (
        <div className={cs.steps_container}>
          <p>
            <strong>Scenario Before Steps</strong>
          </p>
          Loading...
        </div>
      );
    }
  }

  renderBody() {
    const { testResult, classes: cs } = this.props;
    const { description } = testResult;
    return (
      <Grid>
        {this.renderScreenshots()}
        {this.renderErrorCard()}
        <Card>{this.renderScenarioSteps()}</Card>
        <Card>{this.renderBeforeStepContents()}</Card>
        <Card>
          <div className={cs.description} dangerouslySetInnerHTML={{ __html: description }} />
        </Card>
        <Card>
          <div className={cs.body_container}>
            <p>
              <strong>Script</strong>
            </p>
            <code className="body">{this.renderTestBody()}</code>
          </div>
        </Card>
      </Grid>
    );
  }

  render() {
    const { open, classes } = this.props;
    this.beforeRender();

    return (
      <Dialog
        fullScreen={true}
        open={open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
        // TransitionComponent={Transition}
        aria-labelledby="responsive-dialog-title"
        classes={{
          root: classes.root
        }}
      >
        {this.renderTitle()}
        <DialogContent>
          {this.renderHeader()}
          {this.renderBody()}
        </DialogContent>
      </Dialog>
    );
  }
}

ResultDetailDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  testResult: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    test_run_key: state.testResults.test_run_key,
    testResult: state.testRuns.detailResult,
    open: state.testRuns.results_detail_dialog_open,
    detail: state.testDetail,
    fetching: state.testDetail.fetching,
    error: state.testDetail.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCloseAll: () => {
    dispatch({ type: TEST_RESULTS_DIALOG_CLOSE });
    dispatch({ type: RESULTS_DETAIL_DIALOG_CLOSE });
  },
  onClose: () => {
    dispatch({ type: RESULTS_DETAIL_DIALOG_CLOSE });
  },
  onRequestDetail: (key, id) => {
    dispatch({ type: TEST_RESULTS_STEPS_FETCH_REQUESTED, payload: { key, id } });
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withMobileDialog(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ResultDetailDialog);
