import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Lion as LoaderButton } from "react-button-loaders";

import PassIcon from '@material-ui/icons/CheckCircle';
import FailIcon from '@material-ui/icons/HighlightOff';

import styles from './styles';
import {
  API_TEST_RESULTS_DETAIL_FETCH_REQUESTED,
} from '../../redux/actions';

const TIMESTAMP_FORMAT = 'YYYY-MM-DD hh:mm:ss.SSS';
const useStyles = makeStyles(styles);

const truncateText = (str, length = 100, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};


export default function APITestResultCard (props) {
  const cs = useStyles();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [key, setKey] = React.useState(null);
  const [apiLog, setAPILog] = React.useState(null);
  const [branch, setBranch] = React.useState(null);
  const [isScriptView, setIsScriptView] = React.useState(true);

  const filter = useSelector((state) => state.filter);
  const apiLogFetching = useSelector((state) => state.apiTestDetail.fetching);
  const apiDetail = useSelector((state) => state.apiTestDetail);

  useEffect(() => {
    if(apiDetail[id] && apiLog === null){
      setAPILog(apiDetail[id].apiRequests)
    }
  }, [apiDetail])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getAPILog = () => {
    if(!apiLog){
      dispatch({ type: API_TEST_RESULTS_DETAIL_FETCH_REQUESTED, payload: { key, id } });
    }
    setIsScriptView(!isScriptView)
  }

  useEffect(() => {
    const { passed } = props.testResult;

    if (passed && filter.passed) {
      setShow(false);
    }else if (!passed && filter.failed) {
      setShow(false);
    }else {
      setShow(true);
    }
  }, [filter])

  useEffect(() => {
      const { testResult : { test_run_test_id, test_run_key, branch } } = props;
      setId(test_run_test_id);
      setKey(test_run_key);
      setBranch(branch);
  }, [])


  const duration = () => {
    const { start_time, end_time } = props.testResult;
    const endTime = moment.utc(end_time, TIMESTAMP_FORMAT);
    const startTime = moment.utc(start_time, TIMESTAMP_FORMAT);
    const timeInSec = moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
    return `Duration: ${timeInSec} sec`;
  }


  const formatLine = (givenLine) => {
    // sample line: 'at Object.clickLeftMenu (/app/lib/steps/global.js:25:10)'
    // It can sometimes have string before "at "
    const line  = givenLine.substring(givenLine.indexOf("at "))
    if (line) {
      // eslint-disable-next-line
      const [at, method, file_location] = line.trim().split(' ');
      if (file_location) {
        let [file, lineNumber] = file_location.split(':');
        // app is the base directory in docker
        file = file.replace('(', '');
        file = file.replace('/app', '');
        const repoRoot = 'https://github.com/Science37/heimdall-api/tree';
        const src = `${repoRoot}/${branch}/${file}#L${lineNumber}`;
        return (<>
            <div>
              <a className={cs.codeLink} href={src} target="_blank" rel="noopener noreferrer">
                View Error File
              </a>
            </div>
            <pre>{`${line}`}</pre>
          </>
        );
      }else{
        return <pre >{`${line}`}</pre>}
    }
  }

  const renderIcon = () => {
    const { passed } = props.testResult;
    let icon = <FailIcon className={cs.icon} />;

    let avatarClass = cs.failAvatar;
    if (passed) {
      icon = <PassIcon className={cs.icon} />;
      avatarClass = cs.passAvatar;
    }
    return <div className={avatarClass}>{icon}</div>;
  }


  const renderHeader = () => {
    const { title, scenario, baseurl } = props.testResult;

    return (
      <div className={cs.header}>
        <div className={cs.idContainer}>
          {renderIcon()}
          <div className={cs.id}>{title}</div>
        </div>
        <div className={cs.subheader1}>{scenario}</div>
        <div className={cs.subheader1}>{`Base Url: ${baseurl}`}</div>
        <div className={cs.subheader2}>{duration()}</div>
      </div>
    );
  }


  const renderDetail = () => {
    if(!props.testResult){
      return null;
    }
    const { error_message, consoleLog, body } = props.testResult;

    return (
      <>
        {error_message && <div>
          <div className={cs.error_container}>
            {error_message.name && <div className={cs.error_name}><p>{`${error_message.name}:`}</p></div>}
            {error_message.message && <div className={cs.error_message}><p>{error_message.message}</p></div>}
          </div>
          <div>
            {error_message.stack && formatLine(error_message.stack)}
          </div>
        </div>
        }
        <div className={cs.detail_container}>
          <div>
            <LoaderButton
              onClick={getAPILog}
              state={apiLogFetching ? "loading" : null}
              disabled={apiLogFetching}
              className={cs.apiLoader}
            >
              {!isScriptView ? "View Script" : "View API Requests"}
            </LoaderButton>
          </div>
          <div className={cs.log_container}>
          {consoleLog && consoleLog.length > 0 &&
          <div className={cs.detail_console_container}>
            <p style={{textDecoration: "underline"}}>
              <strong>Console Log</strong>
            </p>
            {
              consoleLog.map((cLog, id) => {
                if(typeof cLog === "string"){
                  return <p key={id}>{truncateText(cLog)}</p>

                }
              }
              )
            }
          </div>
          }
          {body && isScriptView && <div className={cs.detail_script_container}>
              <p style={{textDecoration: "underline"}}>
                <strong>Script</strong>
              </p>
              <code className="body">{body}</code>
            </div>
          }
            {!isScriptView && apiLog && <div className={cs.detail_api_log_container}>
              <p style={{textDecoration: "underline"}}>
                <strong>API Requests</strong>
              </p>
              {apiLog.map((log, i) => {
                return <div key={i} className={cs.apiLog}>
                  <div className={cs.flexIt}>
                    <p className={cs.logMethod}>{log.method}</p>
                    <p className={cs.spaceToRight}>{log.url}</p>
                    <p className={cs.logStatus}>{log.status}</p>
                  </div>
                  <div className={cs.flexIt}>
                    <AccessTimeIcon className={cs.spaceToRight}/><p className={cs.apiResponseTime}>{`${log.responsetime} ms`}</p>
                  </div>
                  </div>
              })}
            </div>
            }
          </div>
        </div>
      </>
    );
  }
    return (
      <>
      { show &&
      <div className={cs.resultContainer}>
        <div className={cs.infoContainer}>

          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {renderHeader()}
            </AccordionSummary>
            <AccordionDetails style={{display: "block"}}>
              {expanded && renderDetail()}
            </AccordionDetails>
          </Accordion>

        </div>
      </div>
      }
</>
    );

}

APITestResultCard.propTypes = {
  testResult: PropTypes.object
};
