import { API_BUILDS_DIALOG, API_BUILDS_DIALOG_CLOSE } from '../actions';

const defaultState = {
  open: false,
  tags: null
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case API_BUILDS_DIALOG:
      return {
        ...state,
        open: true,
        tags: action.payload
      };
    case API_BUILDS_DIALOG_CLOSE:
      return { ...state, open: false };
    default:
      return state;
  }
}
