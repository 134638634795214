import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  withStyles,
  CircularProgress,
  Card,
  Grid,
  CardHeader,
  CardContent
} from '@material-ui/core';
import PassIcon from '@material-ui/icons/CheckCircle';
import FailIcon from '@material-ui/icons/HighlightOff';

import { PRINT_FETCH_REQUESTED } from '../../redux/actions';
import ScreenshotCard from '../../heimdall-components/ScreenshotCard';

import styles from './styles';

class Print extends React.Component {
  constructor(props) {
    super(props);

    const testRunKey = this.props.match.params.key || '';
    const testKey = this.props.match.params.test_key || '';

    if (testRunKey.length > 0) {
      this.props.requestTestDetails(testRunKey, testKey);
    }
  }

  renderScreenshots() {
    const { result, classes: cs } = this.props;

    const screenshots = result.screenshots || [];
    const json_steps = result.json_steps || [];

    if (!result || screenshots.length <= 0) {
      return <div>Nothing Found!</div>;
    }

    const baseS3 = 'https://s3-us-west-2.amazonaws.com/test-automation-science37';
    const icons = [];
    let index = 0;
    let steps = [];

    json_steps.forEach((step, i) => {
      const { action, passed } = step;

      const icon = passed ? (
        <PassIcon className={cs.step_check_icon} />
      ) : (
        <FailIcon className={cs.step_fail_icon} />
      );

      const screenShotAction = action.includes('I.saveScreenshot');

      if (screenShotAction) {
        const { id, description, screenshotpath, timestamp } = screenshots[index];
        const src = `${baseS3}/${screenshotpath}`;
        icons.push(
          <Grid key={id} item xs={12} className={cs.pagebreak}>
            <ScreenshotCard
              description={description}
              src={src}
              index={index}
              screenshots={screenshots}
              timestamp={timestamp}
              steps={steps}
            />
          </Grid>
        );
        index++;
        steps = [];
      } else {
        steps.push(
          <li key={i} className={cs.step}>
            {icon} {action}
          </li>
        );
      }
    });

    return (
      <Card>
        <CardHeader title="Test Detail" />
        <CardContent>
          <Grid container justify="flex-start" alignItems="flex-start" direction="row" spacing={32}>
            {icons}
          </Grid>
        </CardContent>
      </Card>
    );
  }

  render() {
    const { error, fetching, classes: cs } = this.props;

    if (error) {
      return <div> An error occurred fetching results </div>;
    }

    if (fetching) {
      return <CircularProgress size={50} color="primary" className={cs.progress} />;
    }

    return this.renderScreenshots();
  }
}

const mapStateToProps = (state) => {
  return {
    result: state.print.result,
    error: state.print.error,
    fetching: state.print.fetching
  };
};

const mapDispatchToProps = (dispatch) => ({
  requestTestDetails: (test_run_key, test_key) => {
    dispatch({ type: PRINT_FETCH_REQUESTED, payload: { key: test_run_key, id: test_key } });
  }
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Print);
