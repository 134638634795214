import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import purple from '@material-ui/core/colors/purple';

const TotalColor = purple[500];
const FailColor = red[500];
const SkipColor = grey[500];
const PassColor = green[500];

export { TotalColor, FailColor, PassColor, SkipColor };
