import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import TestRunCharts from './TestRunCharts';
import ApiTestRunCharts from './ApiTestRunCharts';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { withStyles, Grid } from '@material-ui/core';

import { MainCard } from 'heimdall-components';

import styles from './styles';
import TestRunTable from './TestRunTable';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      checkedEnv: true,
      env: 'dev',
      platform: 'legacy',
      checkedView: true,
      view: 'graph'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  handleChange(event) {
    if(event.target.checked){
      this.setState({ ...this.state, ...{env: 'dev', [event.target.name]: event.target.checked} });
    }else{
      this.setState({ ...this.state, ...{env: 'stage', [event.target.name]: event.target.checked} });
    }
  }

  handleChangeView(event) {
    if(event.target.checked){
      this.setState({ ...this.state, ...{view: 'graph', [event.target.name]: event.target.checked} });
    }else{
      this.setState({ ...this.state, ...{view: 'table', [event.target.name]: event.target.checked} });
    }
  }

  handleRadioChange(event) {
    this.setState({ ...this.state, ...{platform: event.target.value } });
  }


  renderContent() {
    const { classes: cs } = this.props;
    return (
      <div>
        <Grid >
          <Grid item xs={12}>
            <div className={cs.chartContainer}>
                <div className={cs.chartText}>Daily UI Test Runs</div>
                <FormControl component="fieldset" className={cs.title}>
                <RadioGroup value={this.state.platform} row aria-label="position" name="position" defaultValue="top"
                            onChange={this.handleRadioChange}>
                  <FormControlLabel
                    value="legacy"
                    control={<Radio color="primary" />}
                    label="Legacy"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="nextGen"
                    control={<Radio color="primary" />}
                    label="NextGen"
                    labelPlacement="start"
                  />
                </RadioGroup>
                </FormControl>
              <FormGroup row style={{ display: 'flex', justifyContent: 'space-between'}}>
                <FormControlLabel
                  className={cs.title}
                  control={<Switch checked={this.state.checkedEnv} onChange={this.handleChange} name="checkedEnv" />}
                  label={this.state.env.toUpperCase()}
                />
                <FormControlLabel
                  className={cs.title}
                  control={<Switch checked={this.state.checkedView} onChange={this.handleChangeView} name="checkedView" />}
                  label={this.state.view.toUpperCase()}
                />
              </FormGroup>
              {this.state.checkedView
                ? (<TestRunCharts testRuns={this.props.testRuns} env={this.state.env} platform={this.state.platform}/>)
                : (<TestRunTable testRuns={this.props.testRuns} env={this.state.env} platform={this.state.platform}/>)
              }
            </div>
          </Grid>
        </Grid>
        <Grid className={this.props.classes.chartContainer}  style={{marginTop: "30px"}}>
          <Grid item xs={12}>
            <div className={cs.chartContainer}>
              <div className={cs.chartText}>Daily Api Test Runs</div>
              <ApiTestRunCharts testRuns={this.props.apiTestRuns} />
            </div>
          </Grid>
        </Grid>
        {/*<Grid container>*/}
        {/*  <div className="embed-responsive embed-responsive-16by9">*/}
        {/*    <iframe*/}
        {/*      title="Lastest Client Coverage"*/}
        {/*      className="embed-responsive-item"*/}
        {/*      src="https://s3-us-west-2.amazonaws.com/heimdall-client-coverage.science37.com/index.html"*/}
        {/*      allowFullScreen*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</Grid>*/}
      </div>
    );
  }

  render() {
    return <MainCard title="Dashboard" content={this.renderContent()} />;
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    testRuns: state.testRuns.testRuns,
    apiTestRuns: state.apiTestRuns.testRuns
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(Dashboard);
