import { UPDATE_FILTER, CLEAR_FILTER } from '../actions';

const defaultState = {
  passed: true,
  failed: false,
  skipped: true,
  unstable: true,
  tags: [],
  filterList: '',
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_FILTER:
      return { defaultState };
    default:
      return state;
  }
}
